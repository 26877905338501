import { Component, OnInit, ViewChild } from '@angular/core';
import { UsermanagementService } from '../../service/userService';
import { HttpClient,HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
//import { AddVisaService } from 'src/app/service/addVisaSerivce';
import { AiloginserviceService } from 'src/app/service/ailoginservice.service';
import {  Router } from '@angular/router';


declare var $: any;
@Component({
  selector: 'app-aiprofile',
  templateUrl: './aiprofile.component.html',
  styleUrls: ['./aiprofile.component.css']
})
export class AiprofileComponent implements OnInit {

  @ViewChild('chngpass') form;
  userpassworddata:any={};
  public passMatch:boolean;
  private localData: any;
  token: any;
  visaUserType: string = null;
  profileimagepath: string = null;
  companylogopath: string = null;
  AIUserID:any=null
//resultdate: any = [];
  
  profiledata: any = {};
  constructor(private _userService: UsermanagementService,private _http: HttpClient, private _toastr: ToastrService,private _loginService:AiloginserviceService,
    private _router: Router) { }

  ngOnInit() {

    this.localData = JSON.parse(localStorage.getItem('currentUser'));
    if(this.localData==null)
    {
      this._router.navigate(['/']);
    }
    else{
    this.token = this.localData.token;
    this.visaUserType = this.localData.userType;
    this.AIUserID=  localStorage.getItem('AIUserID');
    }
    var self=this;
  }

  
  changepassword()
  {

    $('.required').fadeOut();
    var validcheck=true;
    if($('#txtCurrentPwd').val()=='')
    {
      $(".currentpwd").fadeIn();
        
      validcheck= false;
    }
    if($('#txtNewPwd').val()=='')
    {
      $(".newpwd").fadeIn();
        
      validcheck= false;
    }
    if($('#txtReenterPwd').val()=='')
    {
      $(".reenterpwd").fadeIn();
        
      validcheck= false;
    }

    if(validcheck)
    {
      
    }

  }

  checkPassword(event) {
    const password = this.userpassworddata.newPwd;
    const confirm_new_password = event.target.value;

    if (password !== undefined) {
      if (confirm_new_password !== password) {
        this.passMatch = true;
      } else {
        this.passMatch = false;
      }
    }
  }
  
  createChangepass=function() {     
    $("#changePwdBtn").prop('disabled', true);
    debugger;
     if(this.userpassworddata.oldPwd==undefined||this.userpassworddata.oldPwd==""||this.userpassworddata.oldPwd==null){
     
      this._toastr.error("Current Password Cant be empty!"); 
      $("#changePwdBtn").prop('disabled', false);     
      return false;
     }
     if(this.userpassworddata.newPwd==undefined||this.userpassworddata.newPwd==""||this.userpassworddata.newPwd==null){
     
      this._toastr.error("New Password Cant be empty!"); 
      $("#changePwdBtn").prop('disabled', false);     
      return false;
     }
     else  if(this.userpassworddata.newPwd!==this.userpassworddata.cnfrmPwd){
      debugger;
      this._toastr.error("New Password and Confirm password should be same."); 
      $("#changePwdBtn").prop('disabled', false);     
      return false;
    }
    else if(this.userpassworddata.oldPwd==this.userpassworddata.newPwd){
      
        this._toastr.error("Current and New Passowrd Can't be Same!");
        $("#changePwdBtn").prop('disabled', false);
        return false;
    }
    else{
      this._loginService._changePasswordAfterLogin(this.AIUserID,this.userpassworddata.oldPwd,this.userpassworddata.newPwd)  
        // this._http.post('http://192.168.60.101:83/api/UserDetails/ChangeUserPassword?EmpCode='+this.userId+'&OldPass='+this.userpassworddata.oldPwd+'&NewPass='+this.userpassworddata.newPwd+'')
        .subscribe(
          (SuccesData:any) => { 
            
            $("#changePwdBtn").prop('disabled', false);
            //console.log(SuccesData);
            if(SuccesData.status = true){
              this.userpassworddata={};
              this.form.form.markAsPristine();
              this.form.form.markAsUntouched();
              this.form.form.updateValueAndValidity();
              this._toastr.success(SuccesData.messsage);
            //  this._toastr.success('Password changed Successfully');              
            }else{
              this._toastr.error(SuccesData.messsage);
            }
          },
        (error: HttpErrorResponse) => {
          $("#changePwdBtn").prop('disabled', false);
          this.userpassworddata={};          
          this.err = error;
          //console.error(error);
          this._toastr.error('Password Updation Failed!');
        });
      } 
   };


}
