import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router} from '@angular/router';
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
@Injectable()

export class HttpConfigInterceptorService implements HttpInterceptor { 
  public reqHeader:any={};
  public token:string;
  constructor(private _router: Router, private _toaster: ToastrService) {   }
  
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const data: any = localStorage.getItem('currentUser');
    this.reqHeader=request;

    if (localStorage.getItem('currentUser')) {
     this.token = JSON.parse(localStorage.getItem('currentUser')).token;
    }
    // this._toaster.success(JSON.parse(localStorage.getItem('currentUser')).userId);
    // if(this.token!=undefined && this.reqHeader.headers.lazyUpdate!=null){
    //  if(this.reqHeader.headers.lazyUpdate.find(x=>x.name=="Authorization").value =='Bearer ' + this.token){
    //     // this._toaster.success("success");
    //  }else{
    //   //  this._toaster.error("Error");
    //  }
    // }
      
    if (localStorage.getItem('currentUser')) {
      // debugger;
        //let token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6IjIwOTciLCJuYmYiOjE1NjA4NjAwODMsImV4cCI6MTU2MDk0NjQ4MywiaWF0IjoxNTYwODYwMDgzfQ.m3tUnJy1RJbMMK5ksRxVnhkCtVRFS0HdZ6RNYP0zP_c";
        let token = JSON.parse(localStorage.getItem('currentUser')).token;
        //this._toaster.success(JSON.parse(localStorage.getItem('currentUser')).userId);     
        request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
    }

    // if (!request.headers.has('Content-Type')) {
    //     request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
    // }

    // request = request.clone({ headers: request.headers.set('Accept', 'application/json') });

    return next.handle(request).pipe(
        map((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
                console.log('event--->>>', event);
                if(event.status==401){
                  alert("Authentication Failed!");
                  this._router.navigate(['/']);
                }
                else if(event.status==500){
                  this._toaster.error("Internal Server Error!");                  
                }
            }
            if (event instanceof HttpErrorResponse){              
                this._toaster.error("Internal Server Error!");
            }
            return event;
        }));
}
}
