import { Component, OnInit,ViewChild,ElementRef } from '@angular/core';
import { Router ,ActivationEnd} from '@angular/router';
import { LoginModel } from '../service/LoginModel';
import { LoginService } from '../service/login-service.service';
import { HttpErrorResponse } from '@angular/common/http/src/response';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';

//import { LocaleData } from 'ngx-bootstrap/chronos/public_api';
declare var $: any;
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {
  id: number;
  OTPdiv: any;
  TimeLeftDiv:any;
  Pwddiv: any;
  AccessedByMobileMsg:any;
  localData: any = {};
  LoginButn:any;
  divLogin:any;
  TimeLeft:any;
  AccessedByMobile:any;
  GenerateOTP:number;
  userpassworddata:any={};
  forgotpwd_email='';
  @ViewChild('closeemailModal') closeemailModal: ElementRef;
  @ViewChild('closeresetpwdModal') closeresetpwdModal: ElementRef;
  

  constructor(private _router: Router, private _loginservice: LoginService, private _toaster: ToastrService) { 

    this._router.events.subscribe((ev) => {
      //debugger;
      if (ev instanceof ActivationEnd)
       {

       
   //if(!(window.location.href.indexOf( environment.AirIndiaURL)>-1   || window.location.href.indexOf( 'ailogin')>-1 ))
    { 
      
$(window).on("scroll", function () {
         
  
  //alert('scroll');
  if ($(document).scrollTop() > 0) {
  $("header").removeClass("large").addClass("small");
 } else {
   $("header").removeClass("small").addClass("large");
  }
 });
    }
 

        $(document).ready(function() {

       
     // console.info('loadslick')
      $('.form-slider').slick({
        dots: true,
        autoplay: true,
        arrow:true,
        autoplaySpeed: 2000,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll:1,
        responsive: [
          {
          breakpoint: 1200,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
          },
          {
          breakpoint:900,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
          },
          {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
          }
        ]
        });

        $('.hero').slick({
          dots: false,
          infinite: true,
          speed: 1000,
          fade: !0,
          cssEase: 'linear',
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 2000,
          draggable: true,
          arrows: false,
          responsive: [
          {
          breakpoint: 1024,
          settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true
              }
          },
          {
          breakpoint: 768,
          settings: {
          draggable: true,
              }
          },
          {
          breakpoint: 600,
          settings: {
          slidesToShow: 1,
          draggable: true,
          slidesToScroll: 1
              }
          },
          {
          breakpoint: 480,
          settings: {
          slidesToShow: 1,
          draggable: true,
          slidesToScroll: 1
              }
          }
        
              ]
              });
            });
    
    }



    
    });

  
   
  }


  ngOnInit(): void {


 //skew header
 

    this.TimeLeftDiv=false;
    this.TimeLeft=0;

    //this.setTimer();

    //this.checkMobileAccess();
    this.divLogin=true;
    this.AccessedByMobile=false;
    this.OTPdiv=false;
    this.Pwddiv=true;
    this.LoginButn="Login";
    this.GenerateOTP=0;
    this.localData = JSON.parse(localStorage.getItem('currentUser'));
    if (this.localData !== null) {     
     
      this._router.navigate([this.localData.userType+'/addApplication']);
     // this._router.navigate(['branchExecutive'+'/addApplication']);

    }   
    
    if(window.location.href.indexOf( environment.AirIndiaURL)>-1   )
    {
      this._router.navigate(['/ailogin']);   
    }

  }
  output: any;
  LoginModel: LoginModel = new LoginModel();

  login() {
    $("#visaLoader").show();
   // debugger;
    localStorage.clear();
    try {     

      this._loginservice.validateLoginUser(this.LoginModel,this.GenerateOTP)
        .subscribe((successData: any) => {   
         console.info(successData);
          if (successData.status == true) {        
              if(successData.isPasswordExpiredFlag==true)    {
                  this._loginservice.setLoginDetials(this.LoginModel);
                  this._router.navigate(['reset-default-password']);
              }
//console.info(successData);

             localStorage.setItem('currentUser', JSON.stringify({ userId: successData.empCode, token: successData.toKen, userType: successData.userType, userName: successData.userName, userBranchId: successData.userBranchId }));
             debugger;
            // this._loginservice._InsertB2BUserLogs("Login","Login",0,"Agent");
           //   localStorage.setItem('currentUser', JSON.stringify({ userId: successData.empCode, token: successData.toKen, userType: "", userName: successData.userName, userBranchId: successData.userBranchId }));


              
                if (successData.userType == "branchExecutive") {
               localStorage.setItem('branchExecutiveUser', JSON.stringify({ userId: this.LoginModel.Username, token: successData.toKen }));
                localStorage.setItem('uaeApptype',"1");
                //this._router.navigate(['branchExecutive/VisaApplications/New']);
                this._router.navigate(['/branchExecutive/addApplication'], { queryParams: { id: 0 } });
    
              /*   localStorage.setItem('branchExecutiveUser', JSON.stringify({ userId: this.LoginModel.Username, token: successData.toKen }));
                localStorage.setItem('uaeApptype',"1");
                //this._router.navigate(['branchExecutive/VisaApplications/New']);
                this._router.navigate(['/addApplication'], { queryParams: { id: 0 } });
*/
              }
              else if (successData.userType == "branchManager") {
                localStorage.setItem('branchManagerUser', JSON.stringify({ userId: this.LoginModel.Username, token: successData.toKen }));
                localStorage.setItem('uaeApptype',"1");
                //this._router.navigate(['branchManager/VisaApplications/New']);
                this._router.navigate(['/branchManager/addApplication'], { queryParams: { id: 0 } });
    
              }

             /* if (successData.userType == "Admin") {
                console.info("Router===",this._router);
                localStorage.setItem('adminUser', JSON.stringify({ userId: this.LoginModel.Username, token: successData.toKen }));
                localStorage.setItem('uaeApptype',"1");
                this._router.navigate(['Admin/VisaApplications/New']);
              }           
              else 
             
              else if (successData.userType == "collectionUser") {
                localStorage.setItem('collectionBoyUser', JSON.stringify({ userId: this.LoginModel.Username, token: successData.toKen }));
                localStorage.setItem('uaeApptype',"1");
                this._router.navigate(['collectionUser/VisaApplications/Pending']);
              }
              else if (successData.userType == "DubaiExecutive") {
                localStorage.setItem('DubaiExecutiveUser', JSON.stringify({ userId: this.LoginModel.Username, token: successData.toKen }));
                localStorage.setItem('uaeApptype',"2");
                this._router.navigate(['DubaiExecutive/VisaApplications/Open']);
              }
              else if (successData.userType == "DubaiManager") {
                localStorage.setItem('DubaiManagerUser', JSON.stringify({ userId: this.LoginModel.Username, token: successData.toKen }));
                localStorage.setItem('uaeApptype',"2");
                this._router.navigate(['DubaiManager/VisaApplications/Open']);
              }
              else if (successData.userType == "Accounts") {
                localStorage.setItem('AccountsUser', JSON.stringify({ userId: this.LoginModel.Username, token: successData.toKen }));
                localStorage.setItem('uaeApptype',"1");
                this._router.navigate(['Accounts/reports']);
              }
              else if (successData.userType == "Cashier") {
                localStorage.setItem('Cashier', JSON.stringify({ userId: this.LoginModel.Username, token: successData.toKen }));
                localStorage.setItem('uaeApptype',"1");
                this._router.navigate(['Cashier/cashierapp']);
              }
              else if (successData.userType == "CreditControl") {
                localStorage.setItem('CreditControl', JSON.stringify({ userId: this.LoginModel.Username, token: successData.toKen }));
                localStorage.setItem('uaeApptype',"1");
                this._router.navigate(['CreditControl/CreditControl']);
              }*/
          }

          else if(successData.status==false && successData.msg=="OTP"){

            this.LoginButn="Login";
            this.GenerateOTP=0;
            this._toaster.success("An OTP Sent Successfully to your Registered Mobile Number.");
           this.OTPdiv=true;
           this.Pwddiv=false;

           this.setTimer(function(){

            this.TimeLeftDiv=false;
            this.LoginButn="Re-Generate OTP";
            this.LoginModel.OTP="";

            this.OTPdiv=false;
            this.Pwddiv=true;

            this.GenerateOTP=1;
           }.bind(this));
        }

        else if(successData.status==false && successData.msg=="RequestOTP"){
          this._toaster.error("You are not authorized to access this application outside Riya Network. Please click on Generate OTP.");
          this.LoginButn="Generate OTP";
          this.GenerateOTP=1;
      }
       
      else if(successData.status==false && successData.msg=="OTP Expired"){

        this._toaster.error("OTP Expired! Kindly Re-Generate OTP.");
        this.LoginButn="Re-Generate OTP";
        this.GenerateOTP=1;
     }
   else if(successData.status==false && successData.isPasswordExpiredFlag==true){
              this._toaster.error(successData.msg);
              this._loginservice.setLoginDetials(this.LoginModel);
              this._router.navigate(['reset-default-password']);
          }
          else {
            console.info("User details not found.");
            
           // this._toaster.success("Invalid mobile number to send OTP.");
          //  this._toaster.error("Invalid Login ID or Password.");
            this._toaster.error(successData.msg);
          //  debugger;
          }
          $("#visaLoader").hide();
        },
       
          (error) => {
           // console.error(error);    // SHOW ERRORS IF ANY.
           console.info(error);
            if(error == "Something bad happened; please try again later."){
              this._toaster.error("We are facing technical issue at the moment!");
            }else if(error.status==500){
              this._toaster.error("Internal Server Error!");
            }else if(error.status==0){
              this._toaster.error("We are facing technical issue at the moment!"); 
            } else if(error.status==401){
              this._toaster.error("Authentication Failed!"); 
            }
            else{
              this._toaster.error(error.error.message);
            }
            $("#visaLoader").hide();
          });
        
    }
    catch (exception) {
     // console.error(exception);
    }
  }


  register() {
    console.info('register')
    this._router.navigate(['/registration']);
  }


  forgotEmailSubmit()
  {
    $("#visaLoader").show();
  
let mystring=$('#fpwdEmailID').val();

var regexp = new RegExp("^[a-zA-Z0-9_!#$%&'*+/=?`{|}~^.-]+@[a-zA-Z0-9.-]+$");
let testpwdformat = regexp.test(mystring);
console.info(testpwdformat);
if(!testpwdformat)
{
this._toaster.error("Invalid Email ID."); 
$("#visaLoader").hide();
return false;
}


    this.forgotpwd_email='******'+mystring.substring(mystring.indexOf('@')-2); 
      //$('#forgotpopup').modal('hide');
     
      this._loginservice._forgotPassword(mystring)
      .subscribe((successData: any) => {   
       console.info(successData);
        if (successData.status == true) {        
           
          $('#otpmodal').modal('show');
          this.closeemailModal.nativeElement.click();
          $("#visaLoader").hide();
     
          }
          else{
            this._toaster.error(successData.messsage);
            $("#visaLoader").hide();
          }
        });
       // $("#visaLoader").hide();
  }

resetpassword()
  {
    $("#visaLoader").show();
    if($('#txtOTP').val()==null || $('#txtOTP').val()=='' ){
     
      this._toaster.error("OTP Cant be empty!"); 
      //$("#changePwdBtn").prop('disabled', false);  
      $("#visaLoader").hide();   
      return false;
     }

    let emailid=$('#fpwdEmailID').val();
    let otp=$('#txtOTP').val();
    let newpwd=$('#newpwd').val();
    let confirmpwd=$('#txtConfirmPassword').val();

    if(newpwd==undefined||newpwd==""||newpwd==null){
     
      this._toaster.error("New Password Cant be empty!"); 
      //$("#changePwdBtn").prop('disabled', false);  
      $("#visaLoader").hide();   
      return false;
     }
   
     //var regexp = new RegExp('(?=^.{9,15}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$');
     let regexp = new RegExp('(?=^.{9,15}$)(?=.*[A-Z])(?=.*[a-z])(?=.*[@$!%*?&]).*$');
    
    var testpwdformat = regexp.test(newpwd);

 if(!testpwdformat)
 {
   this._toaster.error("Invalid Password (1 upper case, 1 lowercase,1 special character and minimum nine character)"); 
   $("#visaLoader").hide();   
   //this._toaster.error("Invalid Password (1 upper case, 1 lowercase, 1 special character and 1 digit, minimum nine character)"); 
   // $("#changePwdBtn").prop('disabled', false);     
    return false;
 }

 
     if(newpwd!==confirmpwd){
      debugger;
      this._toaster.error("New Password and Confirm password should be same."); 
     $("#visaLoader").hide();   
     // $("#changePwdBtn").prop('disabled', false);     
      return false;
    }

    
    

debugger;
    this._loginservice._resetUserPassword(emailid,otp,newpwd,confirmpwd)
    .subscribe((successData: any) => {   
     console.info(successData);
      if (successData.status == true) {        
         
        this.closeresetpwdModal.nativeElement.click();
        this._toaster.success(successData.messsage);
        $("#visaLoader").hide();   
        }
        else{
          this._toaster.warning(successData.messsage);
          $("#visaLoader").hide();   
        }
      });
     // $("#visaLoader").hide();   
  }

 off() {
    document.getElementById("overlay").style.display = "none";
}

  checkMobileAccess(){
    try {
      this._loginservice.CheckMobileAccess().subscribe((successData: any) => { 

        if(successData.status==true){
          this.AccessedByMobile=false;
          this.divLogin=true;
        }
        else{
         
         this._toaster.error(successData.msg);
          this.AccessedByMobile=true;
          this.divLogin=false;
          this.AccessedByMobileMsg=successData.msg;
        }
      },
      
      
      
      (error) => {
       
           this._toaster.error("We are facing technical issue at the moment!");
        
       }
      
      
      ); 
      
    } catch (error) {
      
    }
   
  }

  setTimer(callbacksettime){

    this.TimeLeftDiv=true;
    var timeleft = 1;


    var downloadTimer = setInterval(function(){

      
    this.TimeLeftDiv=false;
    this.LoginButn="Re-Generate OTP";

      this.TimeLeft=61-timeleft;

      document.getElementById("TimeLeft").innerHTML=this.TimeLeft;
      
      timeleft += 1;

      console.info("tiumeleft==",timeleft);

      if(timeleft>= 62){

        clearInterval(downloadTimer);
        callbacksettime();
        // this.TimeLeftDiv=false;
        // this.LoginButn="Re-Generate OTP";



      }
    }, 1000);
      }

      
}
