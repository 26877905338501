import { Component, OnInit } from '@angular/core';
import { Newappservice } from '../service/Newapplication_Service';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { ApplicationcountService } from '../service/CountService';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { NavMenuComponent } from '../nav-menu/nav-menu.component';

declare var $: any;
@Component({
  selector: 'app-submitted-app',
  templateUrl: './submitted-app.component.html',
  styleUrls: ['./submitted-app.component.css'],
  providers: [NavMenuComponent]
})
export class SubmittedAppComponent implements OnInit {
  visaName = 'Submitted ';
  id: number = 3;

  listobj: any = {};
  applicationarry = [];
  userupdatemodel = [];
  err: any;
  paginationSize = 10;
  page: number = 1;
  pageShow = 0;
  totalNoofpages: number = 0;      // ---- pending due to api response is 0.
  totalRecords: number = 0;
  selectedIndex: number = 0;
  viewCorporate: any = null;
  newdata: any;
  visaUserType: string = null;
  private data: any;
  PageSizeArray: number[];
  constructor(private _http: HttpClient, private _newappService: Newappservice, private toastr: ToastrService, private _router: Router,private _navMenu: NavMenuComponent) { }
  ngOnInit() {
    this.PageSizeArray = this._newappService.PageSizeArray;
    $('#searchTag').hide();
    this.data = JSON.parse(localStorage.getItem('currentUser'));
    this.visaUserType = this.data.userType;
    // --------------------------- MENU ID HAS TO PASS------------
    // this.id =3;
    this.id = 3;    
    //this._navMenu.getapplicationlist(this.id, this.page, this.paginationSize);
  }
  


  // ---- Pagination Code Start here ---- 
  currentPageBind(currPage: any) {
    this.selectedIndex = currPage;
    this.page = currPage;
    $('ul.pagination li').removeClass('active');
    this.ngOnInit();
  }
  nextPage(nextpageNumber: number) {
    this.page = nextpageNumber + 1;
    if (this.page <= this.totalNoofpages) {
      this.pageShow = this.pageShow + 10;
    }
  }
  previousPage(prevPageNumber: number) {
    if (prevPageNumber >= 0) {
      this.page = prevPageNumber + 1;
      this.pageShow = this.pageShow - 10;
    }
  }
  noOfRecordsToShow(event: any) {
    this.paginationSize = parseInt(event.target.value);
    this.page = 1;
    //this._navMenu.getapplicationlist(this.id, this.page, this.paginationSize);
  }
  navigateTableRow(id: any) {
    this._router.navigate(['/' + this.visaUserType + '/editVisaApplication'], { queryParams: { id: id } });
  }
}
