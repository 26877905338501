import { Component, OnInit } from '@angular/core';

import { BranchmanagementService } from '../../service/servicecall.service';
import { ToastrService } from 'ngx-toastr';
import { Newappservice } from '../../service/Newapplication_Service';
import { VisaprocessingService } from 'src/app/service/visaProcessingService';
import { AddVisaService } from 'src/app/service/addVisaSerivce';
import { VFSVASFeesService } from 'src/app/service/vfs-vfs.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown/multiselect.model';

declare var $: any;

@Component({
  selector: 'app-vfs-vas-fees',
  templateUrl: './vfs-vas-fees.component.html',
})
export class VFSVASFeesComponent implements OnInit {

  getallCountry: any = [];
  selectedCountry: any;

  dropdownSettings: IDropdownSettings = {};
  miscFeesOptions: any[] = [];
  miscFeesSelected: any[] = [];

  vfsVasFees: any[] = [];
  allVfsVasFees: any[] = [];

  isEdit: false;

  selectedFees: any;

  searchString: any;
  err: any = {};

  // -- Pagination --
  paginationSize = 10;
  page: number = 1;
  pageShow = 0;
  totalNoofpages: number = 0;
  selectedIndex: number = 0;
  totalcount: any;
  PageSizeArray: number[];
  fk_Countryid:any=0;
  constructor(private _visaprocessing: VisaprocessingService, private toastr: ToastrService,
    private _visaService: AddVisaService, private vfsVasFeesService: VFSVASFeesService, public _newappService: Newappservice) { }

  ngOnInit() {
    $("#visaLoader").show();
    this.PageSizeArray = this._newappService.PageSizeArray;
    this.pageLoadAPICalls(this.page, this.paginationSize, this.searchString);

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 1,
      allowSearchFilter: true,
      enableCheckAll: false,
      showSelectedItemsAtTop: true,
    };
  }

  pageLoadAPICalls(page, paginationSize, searchString="") { 
    
    this._visaprocessing.getCountry().subscribe(
      data => {
        this.getallCountry = { data };
        this.getallCountry = this.getallCountry.data;

        this._visaService._getvisaMiscChargesData()
          .subscribe((resData: any) => {
            if (resData && resData.result) {
              this.miscFeesOptions = resData.result.map(a => ({ id: a.id, name: a.feeType + "(₹" + a.charges + ")" }));
              this.getData(page, paginationSize, searchString);
            }
          },
            (err) => {
              console.log(err);    // SHOW ERRORS IF ANY.
            });
      },
      error => {
        this.err = error;
        this.toastr.error("Error :" + this.err.message);
      }
    );
  }

  getData(page, paginationSize, searchString=""){
    this.vfsVasFeesService.get(page, paginationSize,searchString,this.fk_Countryid).subscribe((data: any)=>{
      if(data.status){
        this.vfsVasFees = data.result;
        this.totalcount = data.totalCount;
        this.totalNoofpages = Math.ceil(data.totalCount / this.paginationSize);
        this.vfsVasFeesService.get(1, this.totalcount < 1 ? 10 : this.totalcount*2,'',this.fk_Countryid).subscribe((data: any)=>{
          if(data.status){
            this.allVfsVasFees = data.result;
            $("#visaLoader").hide();
          }
        },
        (err) => {
          this.toastr.error('Something went wrong.');
        });

      }
    },
    (err) => {
      this.toastr.error('Something went wrong.');
    });
  }

  SearchFees(){
    $("#visaLoader").show();
    this.getData(this.page,this.paginationSize,this.searchString);
  }

  getCountry_changeonCreate() {
    this.miscFeesSelected = [];
    if(this.allVfsVasFees && this.allVfsVasFees.length > 0){    
      var countryFees = this.allVfsVasFees.filter(a=>a.countryId == this.selectedCountry && a.isActive == true);
      if(countryFees.length > 0){
        var feesAlreadyPresent = countryFees.map(a=>a.miscChargesMasterId);
        this.miscFeesSelected = this.miscFeesOptions.filter(a=> feesAlreadyPresent.indexOf(a.id) > -1);
      }
    }
  }


  UpdateVAFVFSFees() {
    if (this.isEdit) {

    }
    else {
      var vfsVas = [];
      if (this.miscFeesSelected.length == 0) {
        vfsVas = [{
          id: 0,
          countryId: this.selectedCountry,
          miscChargesMasterId: -1
        }]
      }
      else{
        this.miscFeesSelected.forEach(item => {
          vfsVas.push({
            id: 0,
            countryId: this.selectedCountry,
            miscChargesMasterId: item.id
          })
        });
      }
      
      this.vfsVasFeesService.updateVFSVASFees(vfsVas).subscribe((data: any) => {
        if(data.status){
          this.toastr.success('Record updated');
          this.ngOnInit();
        }
      },
        (err) => {
          this.toastr.error('Something went wrong.');
        });
    }
  }

  toggleFees(){
    $("#visaLoader").show();
    this.vfsVasFeesService.toggleVFSVASFees(this.selectedFees.id).subscribe((data: any) => {
      if(data.status){
        this.toastr.success('Record updated');
        this.getData(this.page,this.paginationSize, this.searchString);
      }
    },
    (err) => {
      this.toastr.error('Something went wrong.');
    });
  }

  Reload() {
    this.searchString='';
    this.ngOnInit();
  }

  noofRecordstoDisplay(args: any) {
    // tslint:disable-next-line:radix
    this.paginationSize = parseInt(args.target.value);
    this.page = 1;// setting to default value
    this.pageShow = 0;
  }
  currentPageBind(currPage: any) {
    this.selectedIndex = currPage;
    this.page = currPage;
    $('ul.pagination li').removeClass('active');
    this.ngOnInit();
  }
  nextPage(nextpageNumber: number) {
    this.page = nextpageNumber + 1;
    if (this.page <= this.totalNoofpages) {
      this.pageShow = this.pageShow + 10;
    }
  }
  previousPage(prevPageNumber: number) {
    if (prevPageNumber >= 0) {
      this.page = prevPageNumber + 1;
      this.pageShow = this.pageShow - 10;
    }
  }
  noOfRecordsToShow(event: any) {
    this.paginationSize = parseInt(event.target.value);
    this.page = 1;
    this.getData(this.page, this.paginationSize,this.searchString);
  }

getvisaTypeonChange($event)
{
 
   if($event!=null)
   {
    this.pageLoadAPICalls(this.page, this.paginationSize, this.searchString);
            
   }
   
}

}
