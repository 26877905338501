import { AppPage } from './../../../e2e/src/app.po';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})

export class GlobalsearchService {

  private data = [];
  private applicationarry = [];
  category: any;
  searchstring: any;
  action: any;
  localData: any = {};
  token: string = null;
  SearchTag_List: any = [];
  // -- Pagination --
  totalNoofpages: number = 0;
  totalcount: any;
  currentPage: any;
  pageSize: any;
  rowCount: any;
  SearchJson: any =
    {
      "page": 1,
      "pageSize": 10,
      "fromDate": "",
      "toDate": "",
      "lstVisaExecutiveId": [],
      "lstCurrentStatus": [],
      "lstVisaType": [],
      "lstVendorId": [],
      "lstApplicantName": [],
      "lstPassportNo": [],
      "lstVisaCountry": [],
      "lstBarcode": [],
      "lstBranch": [],
      "lstIcust": []
    };
    public filersvalue:any=0;
    public subject= new Subject<any>();
    private filters=new BehaviorSubject(this.filersvalue);
    public CurrentFilterVal=this.filters.asObservable();

   public dubaiVisaFilter:any={};
   private serchDubaVisaStatus=new BehaviorSubject(this.dubaiVisaFilter);
   public getDubaiVisaStatus=this.serchDubaVisaStatus.asObservable();
  constructor(private _http: HttpClient, private router: Router) {
    this.localData = JSON.parse(localStorage.getItem('currentUser'));
    if(this.localData!=null){
     this.token = this.localData.token;
    }
  }
   CurrentFilter(filterVal:any)
   {
     this.filters.next(filterVal);
   }
   SendDubaiVisaSearch(dubaiVisaFilter:any)
   {
     this.serchDubaVisaStatus.next(dubaiVisaFilter);
   }
  _globalsearchApi(Category: number, text: string) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.get(environment.ApiUrl + '/GlobalSearch/GetCategory?CategoryId=' + Category + '&SerachText=' + text + '', { headers: headers });
  }


  _sendSearchData(searchobj) {

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    //return this._http.post(environment.ApiUrl + '/GlobalSearch/GlobalSearchRequest', searchobj, { headers: headers });
    return this._http.post(environment.ApiUrl + '/GlobalSearch/GlobalSearchRequestProc', searchobj, { headers: headers });
  }

  _getDubaiVisaStatus() {
   
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.get(environment.ApiUrl + "/StatusMaster/GetStatus", { headers: headers });
  }

   BranchChangedByUser(appid,coid,transferbranchid,branchid) {

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    //return this._http.post(environment.ApiUrl + '/GlobalSearch/GlobalSearchRequest', searchobj, { headers: headers });
    return this._http.get(environment.ApiUrl + '/GlobalSearch/BranchChangedByUser?id='+appid+'&coid='+coid+'&transferbranchid='+transferbranchid+'&branchid='+branchid+'', { headers: headers });
  }



  // ------- Setting search result from visa home component ------------------------
  setData(data) {
    this.data = data;
    this.applicationarry=data;
    console.info('data==',this.data);
    //this.applicationarry=data;
  }

  // ------- Passing search result from visa home to global search component ------------------------
  getData() {
    const temp = this.data;
    this.clearData();
    return temp;
  }
  
   getDataPageWise(start,end) {
	   
	   console.info('start==',start);
	   console.info('end==',end);
    const temp = this.data.slice(start, end);
   // this.clearData();
    return temp;
  }

  SetSearchTag(SearchTag_List) {
    this.SearchTag_List.push(SearchTag_List);
  }
  GetSearchTag() {
    const SearchTag_List = this.SearchTag_List;
    this.clearData();
    return SearchTag_List;
  }

  getCleaSearchValue() {
	  
    this.SearchTag_List = [];
    const clearsearch = this.SearchTag_List;
    return clearsearch;
  }

  setPagination(totalcount, totalNoofpages, pageSize, rowCount,currentPage) {
    this.totalcount = totalcount;
    this.totalNoofpages = totalNoofpages;
    this.pageSize = pageSize;
    this.rowCount = rowCount;
    this.currentPage = currentPage;
  }

  gettotalcount() {
    const totalcount = this.totalcount;
    return totalcount;
  }
  
  getcurrentPage() {
    const currentPage = this.currentPage;
    return currentPage;
  }

  gettotalNoofpages() {
    const totalNoofpages = this.totalNoofpages;
    return totalNoofpages;
  }

  getpageSize() {
    const pageSize = this.pageSize;
    return pageSize;
  }
  getrowCount() {
    const rowCount = this.rowCount;
    return rowCount;
  }
  setJsonSearch_Pagination(searchJson) {
    this.SearchJson = searchJson;

  }

  clearData() {
    this.data = [];
    this.SearchTag_List = [];
  }

}
