import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CashierCreditService } from 'src/app/service/cashier-credit.service';
import { Newappservice } from 'src/app/service/Newapplication_Service';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import * as XLSX from 'xlsx';
@Component({
  selector: 'app-invoice-report',
  templateUrl: './invoice-report.component.html',
  styleUrls: ['./invoice-report.component.css']
})
export class InvoiceReportComponent implements OnInit {
  @ViewChild('InvoiceReportDownload') InvoiceReportDownload: ElementRef;
  filterData: any = {
    PNRNo: '',
    PassportNo: '',
    fromDate: '',
    ToDate: '',
    InvoiceNo: '',
    page: 1,
    Pagesize: 50
  };
  PageSizeArray: number[];
  paginationSize = 50;
  page: number = 1;
  selectedIndex: number = 0;
  totalPage: number = 0;
  pageShow:number= 0;
  CashierApplications:any=[];
  private localData: any;
  totalRecords: any=0;
  visaUserType:any="";
  constructor(private cashiercredit:CashierCreditService, public _newappService: Newappservice,private _toastr: ToastrService, private router: Router) { 

    this.localData = JSON.parse(localStorage.getItem('currentUser'));
    this.PageSizeArray = this._newappService.PageSizeArray;
    this.visaUserType = this.localData.userType;
  }

  ngOnInit() {
    this.getInvoiceList(this.filterData,1,50);
  }


  currentPageBind(currPage: any) {
    this.selectedIndex = currPage;
    this.page = currPage;
    this.getInvoiceList(this.filterData,this.page,this.paginationSize);
   
  }

  nextPage(nextpageNumber: number) {
    this.page = nextpageNumber + 1;
    if (this.page <= this.totalPage) {
      this.pageShow = this.pageShow + 10;
    }
    this.getInvoiceList(this.filterData,this.page,this.paginationSize);
  }

  previousPage(prevPageNumber: number) {
    if (prevPageNumber >= 0) {
      this.page = prevPageNumber + 1;
      this.pageShow = this.pageShow - 10;
    }
    this.getInvoiceList(this.filterData,this.page,this.paginationSize);
  }
  private getInvoiceList(Filter: any,Page:any,PageSize:any)
  {
    this.cashiercredit.GetInvoiceList(Filter,Page,PageSize)
    .subscribe((data: any) => {
      debugger;
      if(data.status==true)
      {
        console.log(data);
        this.CashierApplications = data.result;
        this.totalRecords = data.rowCount;
        this.totalPage = Math.ceil(this.totalRecords / data.pageSize);
      }
      else{
        this._toastr.error("Data Not Found");
      }
    },
    (err: HttpErrorResponse) => {
      this._toastr.error("Data Not Found");
      console.error(err);    // SHOW ERRORS IF ANY.
    });
  }

  private GetSearchingInvoice()
  {

    this.getInvoiceList(this.filterData,this.page,this.paginationSize);

  }

 DownloadInvoiceReport()
{
  const ws: XLSX.WorkSheet=XLSX.utils.table_to_sheet(this.InvoiceReportDownload.nativeElement);
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'InvoiceReport');
  
  /* save to Unsuccessfull file By Fateh */
  XLSX.writeFile(wb, 'InvoiceReport.xlsx');
  
}

}
