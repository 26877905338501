import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})

export  class VisaFeesAutomationService{
    localData: any = {};
    token: string = null;
    constructor(private _http: HttpClient) {
        this.localData = JSON.parse(localStorage.getItem('currentUser'));
        this.token = this.localData.token;
        // this.userId = this.data.userId;  
    }
    GetCountryByVisaFees() {

        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
        return this._http.get(environment.ApiUrl + '/VisaFeesMaster_FeeAutomation/GetCountryByVisaFees', { headers: headers });
      }
      GetVisaTypeByVisaFees(Id) {

        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
        return this._http.get(environment.ApiUrl + '/VisaFeesMaster_FeeAutomation/GetVisaTypeByVisaFees?CountryId='+Id, { headers: headers });
      }
    
    GetAllVisaFeesAutomation(CountryId, VisaTypeId,page,pagesize)
    {
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
        return this._http.get(environment.ApiUrl + '/VisaFeesMaster_FeeAutomation/GetVisaFeeAutomation?CountryId='+CountryId+'&VisaTypeId='+VisaTypeId+'&page=' + page + '&pageSize=' + pagesize, { headers: headers });
      
    }
    GetVisaFeesAutomationDetails(Id,pageNo)
    {
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
        return this._http.get(environment.ApiUrl + '/VisaFeesMaster_FeeAutomation/GetVisaFeeAutomationDetails?Id='+Id+'&pageNo='+pageNo, { headers: headers });
      
    }
    UpdateVisaFeeAutomationDetails(Obj:any,Fees)
    {
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
        return this._http.post(environment.ApiUrl + '/VisaFeesMaster_FeeAutomation/UpdateVisaFeeAutomationDetails?Fees='+Fees,Obj, { headers: headers });
      
    }
}