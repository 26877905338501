import { Component, OnInit} from '@angular/core';
import { ApplicationcountService } from '../service/CountService';
import {HttpErrorResponse } from '@angular/common/http';
import { Newappservice } from '../service/Newapplication_Service';
import { ActivatedRoute,Router } from '@angular/router';
// import { interval } from 'rxjs';
import { GlobalsearchService } from '../service/GlobalsearchService';
import { ToastrService } from 'ngx-toastr';
import {Location} from '@angular/common'; 

declare var $: any;

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  // template:'<app-child [page2]="page2"></app-child>',
  styleUrls: ['./nav-menu.component.css']
  })


export class NavMenuComponent implements OnInit  {
  appcountarray= [];
  listobj:any= {};
  id: number;
  pageShow:number=0;
  paginationSize:number;
  selectedIndex: number = 0;
  page: number = 1;
  totalNoofpages: number = 0; 
  err: HttpErrorResponse;
  menucountarr = [];
  Object = Object;
  routname: any;
  menuFlag: boolean;
  localData:any={};
  parentMeassage="test";
  visaUserType:string=null;
  ChangeStatus:any=0;
  filterData:any={
    PNRNo:'',
    PassportNo:'',
    FirstName:'',
    fromdateTime:'',
    todateTime:'',
    Status:30,
    CIPV:'0',
    page:1,
    Pagesize:10,
    VisaCountry:1089
   
  };
  UaeAppType:any=0;
  constructor(private _router: Router,private _ApplicationCount: ApplicationcountService, private searchservice: GlobalsearchService, private toastr: ToastrService,private _newappService: Newappservice,private _activeroute: ActivatedRoute,private location: Location) { 
  
         this.localData = JSON.parse(localStorage.getItem('currentUser'));        
          this.visaUserType=this.localData.userType;  
          
          _activeroute.params.subscribe(val => {
          let nav_array={"New":1,"Pending":2,"Submitted":3,"Closed":4,'Open':30,'Document_Pending':31,'Document_Complete':32,'Not_Posted_Emigration':33,'Posted_Emigration':34,'Hold_Has_Resident':35,'Hold_Has_Valid_Visa':36,'Hold_Inside_Emir_by_Visa':37,'Hold_Other':38,'Uae_Approved':39,'Close':40,'Uae_Rejected':42};
          let activePath= window.location.pathname.split('/')[2];
        

          this.UaeAppType=JSON.parse(localStorage.getItem('uaeApptype'));
          if(this.UaeAppType!=null && this.UaeAppType!=0)
          {
            this.ChangeStatus=this.UaeAppType;
           /* if(activePath!='VisaApplications' && this.visaUserType != 'Accounts' && this.visaUserType != 'Cashier' && this.visaUserType != 'CreditControl'){
              this.getDubaiVisaApplication(this.filterData,1,1,function(){});
            }*/
   
          }
          else{
            this.ChangeStatus=0;
            if(activePath!='VisaApplications' && this.visaUserType != 'Accounts' && this.visaUserType != 'Cashier' && this.visaUserType != 'CreditControl'){
              this.getapplicationlist(1,1,1,0,0,0,0,'','',function(){});
            }
          }
          
       });

      
       
    }

  ngOnInit() {   
   
    this.parentMeassage="25"; 

        this.listobj.result= [
          {
            "id": 1,
            "name": "New Application",
            "count": 0
          },
          {
            "id": 2,
            "name": "Pending Applications",
            "count":0
          },
          {
            "id": 3,
            "name": "Submitted Applications",
            "count": 0
          },
          {
            "id": 4,
            "name": "Closed Applications",
            "count": 0
          }
        ] ;

        this.searchservice.CurrentFilterVal.subscribe(res=>{
          
          if(res!=0)
          {
          this.ChangeStatus=res;
            if(this.ChangeStatus==2)
            {
              this.location.replaceState(this.visaUserType+"/VisaApplications/Open");
            }
            else {
              this.location.replaceState(this.visaUserType+"/VisaApplications/New");
            }
            res=0;
          }
          });
     
    


      //this.getapplicationlist(this.id, this.page, this.paginationSize);

        // const source = interval(500000);  // ------- Timer
        // const subscribe = source.subscribe(
        //   val => 
        //   this.getapplicationcount()  
        //   );
      
       

    }


 getapplicationlist(id, page, paginationSize,countryid,visatypeid,paymentstatus,appstatus,textsearch,PassportNum,callbackfunc) {

    
    $("#visaLoader").show();
    
    console.info('getapplicationlist'+id);
    this._newappService.getApplicationList(id, page, paginationSize,countryid,visatypeid,paymentstatus,appstatus,textsearch,PassportNum)
      .subscribe(
        data => {

         

          this.listobj = data;
          console.info("data==",data);

          /*$('#newApplCount').text(this.listobj.allStatusCount.status_1);
          $('#pendingApplCount').text(this.listobj.allStatusCount.status_2);
          $('#submittedApplCount').text(this.listobj.allStatusCount.status_3);
          $('#closedApplCount').text(this.listobj.allStatusCount.status_4);*/

       
          callbackfunc(this.listobj);
         // $("#visaLoader").hide();
         
         
        },
        error => {
          $("#visaLoader").hide();
          this.err = error;
          // console.error(error);
          if(this.err.status==0||this.err.status==401){
            localStorage.clear();
            this.toastr.error("Session Expired! Please login to Continue..");
            this._router.navigate(['/']);
          }
          else{
             this.toastr.error('Error :' + this.err.message);
          }
        }
      );
  }


  getapplicationlistByRef(id, page, paginationSize,countryid,visatypeid,paymentstatus,appstatus,textsearch,PassportNum,callbackfunc) {

    
    $("#visaLoader").show();
    
    console.info('getapplicationlist'+id);
    this._newappService.getApplicationListByRef(id, page, paginationSize,countryid,visatypeid,paymentstatus,appstatus,textsearch,PassportNum)
      .subscribe(
        data => {

         

          this.listobj = data;
          console.info("data==",data);

          /*$('#newApplCount').text(this.listobj.allStatusCount.status_1);
          $('#pendingApplCount').text(this.listobj.allStatusCount.status_2);
          $('#submittedApplCount').text(this.listobj.allStatusCount.status_3);
          $('#closedApplCount').text(this.listobj.allStatusCount.status_4);*/

       
          callbackfunc(this.listobj);
         // $("#visaLoader").hide();
         
         
        },
        error => {
          $("#visaLoader").hide();
          this.err = error;
          // console.error(error);
          if(this.err.status==0||this.err.status==401){
            localStorage.clear();
            this.toastr.error("Session Expired! Please login to Continue..");
            this._router.navigate(['/']);
          }
          else{
             this.toastr.error('Error :' + this.err.message);
          }
        }
      );
  }


  ai_getapplicationlist(aiuserid,id, page, paginationSize,countryid,visatypeid,paymentstatus,appstatus,textsearch,PassportNum,callbackfunc) {

    console.info(aiuserid);
    $("#visaLoader").show();
    
    console.info('getapplicationlist'+id);
    this._newappService.AIgetApplicationList(aiuserid,id, page, paginationSize,countryid,visatypeid,paymentstatus,appstatus,textsearch,PassportNum)
      .subscribe(
        data => {

         

          this.listobj = data;
          console.info("data==",data);

          /*$('#newApplCount').text(this.listobj.allStatusCount.status_1);
          $('#pendingApplCount').text(this.listobj.allStatusCount.status_2);
          $('#submittedApplCount').text(this.listobj.allStatusCount.status_3);
          $('#closedApplCount').text(this.listobj.allStatusCount.status_4);*/

       
          callbackfunc(this.listobj);
         // $("#visaLoader").hide();
         
         
        },
        error => {
          $("#visaLoader").hide();
          this.err = error;
          // console.error(error);
          if(this.err.status==0||this.err.status==401){
            localStorage.clear();
            this.toastr.error("Session Expired! Please login to Continue..");
            this._router.navigate(['/']);
          }
          else{
             this.toastr.error('Error :' + this.err.message);
          }
        }
      );
  }



  // ---- Pagination Code Start here ----
   currentPageBind(currPage: any) {

    this.selectedIndex = currPage;
    this.page = currPage;
    $('ul.pagination li').removeClass('active');

    let obj={page:this.page,selectedIndex:this.selectedIndex};
    return obj;
    
  }


  nextPage(nextpageNumber: number,totalpages: number,pageshow:number) {

    this.page = nextpageNumber + 1;
    
    if (this.page <=totalpages) {
     // this.pageShow = pageshow + 10;
      this.pageShow = pageshow + 2;
    }

    let obj={page:this.page,pageShow:this.pageShow};
    return obj;
  }

  previousPage(prevPageNumber: number,pageshow:number) {
    
    if (prevPageNumber >= 0) {

      this.page = prevPageNumber + 1;
      //this.pageShow =pageshow - 10;
      this.pageShow =pageshow - 2;
    }

    let obj={page:this.page,pageShow:this.pageShow};
    return obj;
  }
  noOfRecordsToShow(val: number) {

    this.paginationSize = val;
    this.page = 1;

    let obj={page:this.page,paginationSize:this.paginationSize};
    return obj;
  }
  navigateTableRow(id: any,coutryid:any,visatype:any) {
   if(visatype!='OKTB')
   {
    if(coutryid!=1089)
      {
   
        this._router.navigate(['/' + this.visaUserType + '/ViewVisa'], { queryParams: { id: id } });
      }
   }
   else{
    
   
        this._router.navigate(['/' + this.visaUserType + '/ViewVisaOKTB'], { queryParams: { id: id } });
      
   }
  
    /*else{
      this._router.navigate(['/' + this.visaUserType + '/dubaiviewApplication'], { queryParams: { id: id,countryid:coutryid } });

    
    }*/

  }

  navigateApplicationRef(id: any,coutryid:any) {
   
    if(coutryid!=1089)
     {
  
       this._router.navigate(['/' + this.visaUserType + '/ViewVisaRef'], { queryParams: { id: id } });
     }
     /*else{
       this._router.navigate(['/' + this.visaUserType + '/dubaiviewApplication'], { queryParams: { id: id,countryid:coutryid } });
 
     
     }*/
 
   }

 getDubaiVisaApplication(FilterVal:any,page:number, paginationSize:number,callbackfunc:any)
 {
   
 
  $("#visaLoader").show();
  this._newappService.getDubaiApplicationList(FilterVal,page, paginationSize).subscribe(res=>{
    debugger;
    $("#visaLoader").hide();
    this.listobj = res;
    console.info("data==",res);

    $('#Statusopen').text(this.listobj.allStatusCount.status_1);
    $('#DocumentPending').text(this.listobj.allStatusCount.status_2);
    $('#documentCompeleted').text(this.listobj.allStatusCount.status_3);
    $('#NotPostedEmigration').text(this.listobj.allStatusCount.status_4);
    $('#PostedEmiragation').text(this.listobj.allStatusCount.status_5);
    $('#HoldHasResident').text(this.listobj.allStatusCount.status_6);
    $('#Hold_Has_Valid_Visa').text(this.listobj.allStatusCount.status_7);
    $('#Hold_Inside_Emir_by_Visa').text(this.listobj.allStatusCount.status_8);
    $('#Hold_Other').text(this.listobj.allStatusCount.status_9);
    $('#Uae_Approved').text(this.listobj.allStatusCount.status_10);
    $('#Close').text(this.listobj.allStatusCount.status_11);
    $('#Uae_Rejected').text(this.listobj.allStatusCount.status_12);
   
 
    callbackfunc(this.listobj);
  },
  err=>{
    $("#visaLoader").hide();
    this.err=err;
    if(this.err.status==0 || this.err.status==401)
    {
      localStorage.clear();
      this.toastr.error("Session Expired! Please login to Continue..");
      this._router.navigate(['/']);
    }
    else{
      this.toastr.error('Error :' + this.err.message);
    }
   
  })

 
 }

}
