import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AgentserviceService {

  ApiUrl: string = null;
  flag = 'True';
  private data: any;
  token: any;
  userId: any;
  

  constructor(private _http: HttpClient,private _router: Router) { 

    this.ApiUrl = environment.ApiUrl;
    this.data = JSON.parse(localStorage.getItem('currentUser'));
    //this.token = '123';
    //this.userId = '1';
    if(this.data!=null)
    {
      this.token = this.data.token;
    this.userId = this.data.userId;

   
    }
  }
  
  _registerAgent(agentObj) {
  
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    //headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    //return this._http.post(environment.ApiUrl + "/AgentPayment/Create", payObj, { headers: headers });
    return this._http.post(environment.ApiUrl + "/B2BUserDetails/RegisterAgent", agentObj, { headers: headers });
  
  
  }

  getRVISuser(activationcode) {
   
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.get(environment.ApiUrl + '/B2BUserDetails/GetRVISUser?activationCode='+activationcode);
  

  }

  

  checkagentbyemail(email) {
   
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.get(environment.ApiUrl + '/B2BUserDetails/CheckAgentExists?emailID='+email);
  

  }



  _getState() {

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
   
    return this._http.get(this.ApiUrl + "/StateCityMaster/GetStateMaster", { headers: headers });
  }

  _getCity(stateid) {

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
   
    
    return this._http.get(environment.ApiUrl + "/StateCityMaster/GetCityMaster?StateId=" + stateid, { headers: headers });
  }
}
