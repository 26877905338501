import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, Observer } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class SettingServiceService {
  ApiUrl: string = null;
  private data: any;
  token: any;
  userId: any;
  _settingOption= [
    { id: 1, Name: "Transfer Branch" },
    { id: 2, Name: "ERP PNR Push" },
    { id: 3, Name: "Reverse Process Error (UAE Automation)" },
    { id: 4, Name: "Change Vendor" },
    { id: 5, Name: "Allow Outside Access" },
  ];
  constructor(private _http: HttpClient) { 
    this.ApiUrl = environment.ApiUrl;
    this.data = JSON.parse(localStorage.getItem('currentUser'));
    this.token = this.data.token;
    this.userId = this.data.userId;
    
  }

  // getReligion() {
  //   let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  //   headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
  //   return this._http.get(environment.ApiUrl + "/VisaType/GetUAEReligions", { headers: headers });
  // }

}
