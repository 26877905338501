import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class VisaprocessingService {
  pagesize: number;
  page: number;
  flag: Boolean = true;
  pid: any;
  localData: any = {};
  token: string = null;


  constructor(private _http: HttpClient) {
    this.localData = JSON.parse(localStorage.getItem('currentUser'));
    this.token = this.localData.token;
  }
  getCountry() {

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.get(environment.ApiUrl + '/CountryMaster/GetAll', { headers: headers });
  }



  createUsers(ProccessingDaysupdatemodel) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.post(environment.ApiUrl + '/VisaProcessingDaysMst/Create', ProccessingDaysupdatemodel, { headers: headers });
  }

  getallVisaProcessing(page, pagesize,SearchKeyWord,countryid,visatypeid) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    this.page = page;
    this.pagesize = pagesize;
    this.flag = true;
    return this._http.get(environment.ApiUrl + '/VisaProcessingDaysMst/GetAll?page=' + this.page + '&pageSize=' + this.pagesize + '&Flag=' + this.flag + '&SearchKeyWord=' + SearchKeyWord+'&countryid='+countryid+'&visatypeid='+visatypeid, { headers: headers });

  }

  updateProccessingDays(ProccessingDaysupdatemodel) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.post(environment.ApiUrl + '/VisaProcessingDaysMst/Update', ProccessingDaysupdatemodel, { headers: headers });
  }

  deleteProccessingDays(id) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    this.pid = id;
    return this._http.post(environment.ApiUrl + '/VisaProcessingDaysMst/Delete?id=' + this.pid, '', { headers: headers });
    // return this._http.post('http://192.168.60.122:85/api/UserDetails/DeleteUser?id=12','');
  }
  _getVisaTypebyCountry(countryId, vendorId = 0) {

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.get(environment.ApiUrl + "/VisaType/GetVisaTypeName?CountryId=" + countryId+ "&vendorId=" + vendorId, { headers: headers });
  }

  // Get Visa Office

  Get_VisaOffice(CountryCode, LocationCode) {

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.get(environment.ApiUrl + '/ERPService/GetVendor?CountryCode=' + CountryCode + '&LocationCode=' + LocationCode + '', { headers: headers });
  }

  // Get Visa Office

  GetVendorsByCountryLocation(CountryCode) {

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.get(environment.ApiUrl + '/ERPService/GetVendorsByCountryLocation?CountryCode=' + CountryCode + '', { headers: headers });
  }

  // Get Vendor By Branches

  GetVendorBranches(CountryCode,VendorCode) {

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.get(environment.ApiUrl + '/ERPService/GetVendorBranches?CountryCode=' + CountryCode + "&VendorCode=" + VendorCode + '', { headers: headers });
  }

  // #Region Visa Type Master
  createVisaType(visatypeobj) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.post(environment.ApiUrl + '/VisaType/Create', visatypeobj, { headers: headers });
  }


  getall_Visatype(page, pagesize,SearchKeyWord,countryid) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    this.page = page;
    this.pagesize = pagesize;
    this.flag = true;
    return this._http.get(environment.ApiUrl + '/VisaType/GetAll?page=' + this.page + '&pageSize=' + this.pagesize + '&Flag=' + this.flag + '&SearchKeyWord=' + SearchKeyWord+'&countryid='+countryid, { headers: headers });

  }

  update_VisaType(UpdateVisaObj) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.post(environment.ApiUrl + '/VisaType/Update', UpdateVisaObj, { headers: headers });
  }

  // gey visa type by erp
  GetVisaTypeName(countryCode) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.get(environment.ApiUrl + '/ERPService/GetVisaType?CountryCode=' + countryCode + '', { headers: headers });
  }
  // #End Region

  // #Region Vendor Master
  createVendor(vendorobj) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.post(environment.ApiUrl + '/VisaVendorMaster/Create', vendorobj, { headers: headers });
  }

  getall_VendorList(page, pagesize,SearchKeyWord,countryid) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    this.page = page;
    this.pagesize = pagesize;
    this.flag = true;
    return this._http.get(environment.ApiUrl + '/VisaVendorMaster/GetAll?page=' + this.page + '&pageSize=' + this.pagesize + '&Flag=' + this.flag + '&SearchKeyWord='+SearchKeyWord+'&countryid='+countryid, { headers: headers });

  }

  _DeleteVendor(id) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    this.pid = id;
    return this._http.post(environment.ApiUrl + '/VisaVendorMaster/Delete?id=' + this.pid, '', { headers: headers });

  }
  createserviceChart(SrvcObj) {
    //console.info(JSON.stringify(SrvcObj));
    //
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.post(environment.ApiUrl + '/MstServiceCharge/Create', SrvcObj, { headers: headers });
  }

  Get_VendorbyCountryId(Id, typeId = 0) {
    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.get(environment.ApiUrl + '/VisaVendorMaster/GetVendorByCountryId?CountryId=' + Id + '&VisaTypeId=' + typeId, { headers: headers });

  }


  Get_EntriesbyCountryId(Id) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.get(environment.ApiUrl + '/VisaType/GetVisaTypeEntriess?VisaTypeId=' + Id + '', { headers: headers });

  }

  Get_ProcessingoptionbyCountryId(Id) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.post(environment.ApiUrl + '/MstProcessingOption/GetByCountryId?CountryId=' + Id + '', { headers: headers });

  }

  SearchbyCountry(value, page, pagesize) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    this.page = page;
    this.pagesize = pagesize;
    this.flag = true;
    return this._http.get(environment.ApiUrl + '/MstServiceCharge/SearchByCountryId?page=' + this.page + '&pageSize=' + this.pagesize + '&Flag=' + this.flag + '&CountryId=' + value + '', { headers: headers });

  }

  CountryAutocomplete(value) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.get(environment.ApiUrl + '/GlobalSearch/CountryIntelisence?SearchText=' + value + '', { headers: headers });

  }

  SearchbyCountry_vendor(value, page, pagesize) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    this.page = page;
    this.pagesize = pagesize;
    this.flag = true;
    return this._http.get(environment.ApiUrl + '/VisaVendorMaster/SearchByCountryId?page=' + this.page + '&pageSize=' + this.pagesize + '&Flag=' + this.flag + '&CountryId=' + value + '', { headers: headers });

  }
  SearchbyCountry_visaprocessing(value, page, pagesize) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    this.page = page;
    this.pagesize = pagesize;
    this.flag = true;
    return this._http.get(environment.ApiUrl + '/VisaFeesMst/SearchByCountryId?page=' + this.page + '&pageSize=' + this.pagesize + '&Flag=' + this.flag + '&CountryId=' + value + '', { headers: headers });

  }

  SearchbyCountry_visaprocessingday(value, page, pagesize) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    this.page = page;
    this.pagesize = pagesize;
    this.flag = true;
    return this._http.get(environment.ApiUrl + '/VisaProcessingDaysMst/SearchByCountryId?page=' + this.page + '&pageSize=' + this.pagesize + '&Flag=' + this.flag + '&CountryId=' + value + '', { headers: headers });

  }

  SearchbyCountry_visatype(value, page, pagesize) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    this.page = page;
    this.pagesize = pagesize;
    this.flag = true;
    return this._http.get(environment.ApiUrl + '/VisaType/SearchByCountryId?page=' + this.page + '&pageSize=' + this.pagesize + '&Flag=' + this.flag + '&CountryId=' + value + '', { headers: headers });

  }
  // Get Exist Vendor By Branches

  GetExistVendorBranches(id) {

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.get(environment.ApiUrl + '/VisaVendorMaster/GetExistVendorBranches?id=' + id + "&VendorCode=", { headers: headers });
  }

  getCategory() {

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.get(environment.ApiUrl + '/MstServiceCharge/GetCategory', { headers: headers });
  }

  // #End Region
}
