import { Component, OnInit } from '@angular/core';
import { BranchmanagementService } from '../../service/servicecall.service';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Newappservice } from '../../service/Newapplication_Service';

declare var $: any;
@Component({
  selector: 'app-branch-management',
  templateUrl: './branch-management.component.html',
  styleUrls: ['./branch-management.component.css'],
  providers: [BranchmanagementService]
})
export class BranchManagementComponent implements OnInit {
  branchData = [];
  brancharr = [];
  add_manage = [];
  registerForm: FormGroup;
  addbrnch: NgForm;
  Updatebranch: FormGroup;
  submitted = false;
  validForm: boolean;
  erp: object;
  err: any;
  id: any = {};
  deleteData: any = {};
  edibranchtdata: any = {};
  upadteuserdata = [];
  editObj: any = {};
  CategoryValue: number;
  selectedbranchDelete: any[];
  resultarr: any = [];
  autocompletearr: any = [];
  catFilter: string = null;
  searcharray: any = [];
  firstarray: any = [];
  flaginlist: boolean = false;

  // -- Pagination --
  paginationSize = 10;
  page: number = 1;
  pageShow = 0;
  totalNoofpages: number = 0;
  selectedIndex: number = 0;
  totalcount: any;
  Branchname: string = "";

  locationCode: string = "";
  locationref: string = "";
  dimension: string = "";
  data: any;
  bname: any = null;
  PageSizeArray: number[];
  constructor(private _http: HttpClient, private _service: BranchmanagementService, private toastr: ToastrService, private formbuilder: FormBuilder, public _newappService: Newappservice) { }

  ngOnInit() {
    this.PageSizeArray = this._newappService.PageSizeArray;
    // call function to display data on brancgh
    this.Get_allBranches(this.page, this.paginationSize);
    $("#add_ErpLoc").prop("disabled", true);
    $("#add_LocRef").prop("disabled", true);
    $("#add_dimension").prop("disabled", true);
    $("#ed_Loc").prop("disabled", true);
    $("#ed_LocRef").prop("disabled", true);
    $("#ed_Dim").prop("disabled", true);



    // Validation to Add Branch Form
    this.registerForm = this.formbuilder.group({
      brnchname: ['', [Validators.required, Validators.pattern("[a-zA-Z ]*"), Validators.maxLength(30)]],
      phoneNumber: ['', [Validators.required]],
      emailAddress: ['', [Validators.required]]
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.registerForm.controls; }


  // Add Branch Management
  addBranchModel() {
    //this.addbrnch.reset();
    this.submitted = false;
    this.Clear_Fields();
  }
  // call function to display data on brancgh
  Get_allBranches(page, paginationSize) {
    $("#visaLoader").show();
    this._service.getall_branches(page, paginationSize)
      .subscribe(
        data => {
          this.branchData = [];
          this.brancharr = [];
          this.branchData.push(data);
          this.brancharr.push(this.branchData[0].result);

          // -- for pagination
          this.totalcount = this.branchData[0].totalCount;
          this.totalNoofpages = Math.ceil(this.branchData[0].totalCount / this.paginationSize);
          $("#visaLoader").hide();
        },
        error => {
          this.err = error;
          this.toastr.error('Error :' + this.err.statusText);
          $("#visaLoader").hide();
        }
      );
  }

  Clear_Fields() {
    $('#Branch_txtbx').val('');
    $('#add_ErpLoc').val('');
    $('#add_LocRef').val('');
    $('#add_dimension').val('');
    $('#add_Email').val('');
    $('#add_Phone').val('');
  }


  AddManagement() {
    debugger;
    this.submitted = true;
    // stop here if form is invalid
    // if (this.registerForm.invalid) {
    //   return;
    // }
    $("#visaLoader").show();

    this.editObj = {
      "name": $('#Branch_txtbx').val(),
      "erpLocCode": $('#add_ErpLoc').val(),
      "locationRef": $('#add_LocRef').val(),
      "dimension": $('#add_dimension').val(),
      "email": $('#add_Email').val(),
      "phoneNo": $('#add_Phone').val(),
    };
    //console.log(JSON.stringify(this.editObj));
    this._service.Branch_Add(this.editObj)
      .subscribe(
        result => {
          this.toastr.info(result["messsage"]);
          this.Clear_Fields();
          $('#addPopupClose').click();
          this.Get_allBranches(this.page, this.paginationSize);
          this.resetAddMgm();
          $("#visaLoader").hide();
        },
        error => {
          this.err = error;
          this.toastr.error('Error :' + this.err.statusText);
          $("#visaLoader").hide();
        }
      );
  }
  resetAddMgm() {
    $('#Branch_txtbx').val('');
    $('#add_ErpLoc').val('');
    $('#add_LocRef').val('');
    $('#add_dimension').val('');
    $('#add_Email').val('');
    $('#add_Phone').val('');
  }

  checkValPhone() {
      $(".phone_req").fadeOut();
  }

  checkValEmail() {
    $(".email_req").fadeOut();
  }


  // UpdateBranch code is here
  updateBranch(editobj) {

    if($('#ed_phone').val() == '') {
      $(".phone_req").fadeIn();
      return false;
    }

    var x = $('#ed_Email').val();
    var atpos = x.indexOf("@");
    var dotpos = x.lastIndexOf(".");
    
    if (atpos<1 || dotpos<atpos+2 || dotpos+2>=x.length) {     
      $(".email_req").fadeIn(); 
      return false;
    }

    this.editObj = {
      "id": editobj.id,
      "name": editobj.name,
      "erpLocCode": editobj.erpLocCode,
      "locationRef": editobj.locationRef,
      "dimension": editobj.dimension,
      "email": $('#ed_Email').val(),
      "phoneNo": $('#ed_phone').val(),
    };
    //console.log(JSON.stringify(this.editObj));
    this._service.Branch_update(this.editObj)
      .subscribe(
        result => {
          this.toastr.success(result["messsage"]);
          $('#editPopupClose').click();
          this.Get_allBranches(this.page, this.paginationSize);
        },
        error => {
          this.err = error;
          this.toastr.error('Error :' + this.err.statusText);
        }
      );

  }

  // Delete Branch Management
  delete(dele) {
    this._service.deleteBranch(dele.id)
      .subscribe(data => {
        this.toastr.success(data["messsage"]);
        this.Get_allBranches(this.page, this.paginationSize);
        $('#closedelete').click();
        $('#Resetdelete').click();
      },
        error => {
          this.err = error;
          this.toastr.error('Error :' + this.err.statusText);
        }
      );
  }

  deleteBranch(dele) {
    this.selectedbranchDelete = [];
    this.selectedbranchDelete.push(dele);
  }

  // Edit Click - Branch Management
  geteditbranch(edit) {
    this.upadteuserdata = [];
    this.upadteuserdata.push(edit);
  }


  // ---- Pagination Code Start here ----
  noofRecordstoDisplay(args: any) {
    // tslint:disable-next-line:radix
    this.paginationSize = parseInt(args.target.value);
    this.page = 1;// setting to default value
    this.pageShow = 0;
  }
  currentPageBind(currPage: any) {
    this.selectedIndex = currPage;
    this.page = currPage;
    $('ul.pagination li').removeClass('active');
    this.ngOnInit();
  }
  nextPage(nextpageNumber: number) {
    this.page = nextpageNumber + 1;
    if (this.page <= this.totalNoofpages) {
      this.pageShow = this.pageShow + 10;
    }
  }
  previousPage(prevPageNumber: number) {
    if (prevPageNumber >= 0) {
      this.page = prevPageNumber + 1;
      this.pageShow = this.pageShow - 10;
    }
  }

  onBranchChange(searchValue: string) {
    if (searchValue !== "") {
      this._service
        ._globalsearchApi(searchValue)
        .subscribe(data => {
          this.autocompletearr = [];
          this.autocompletearr = data;
          for (let i = 0; i < this.autocompletearr.length; i++) {
            this.resultarr.push(this.autocompletearr);
          }
          $("#Branch_txtbx").autocomplete({   //  -------- Autocomplete populate --- 
            source: function (request, response) {
              response($.map(data, function (value, key) {
                return {
                  label: value.name,
                  value: value.code,
                  LOC: value.locationReference,
                  Dim: value.dimension
                };
              }));
            },
            focus: function (event, ui) {
              $('#Branch_txtbx').val(ui.item.label);
              return false;
            },
            // Once a value in the drop down list is selected, do the following:
            select: function (event, ui) {
              // place the person.given_name value into the textfield called 'select_origin'...
              $('#Branch_txtbx').val(ui.item.label);
              // and place the person.id into the hidden textfield called 'link_origin_id'. 
              $('#add_ErpLoc').val(ui.item.value);
              $('#add_LocRef').val(ui.item.LOC);
              $('#add_dimension').val(ui.item.Dim);
              
              return false;
            },
            appendTo: "#user-add",
          });
          
        },
          error => {
            this.err = error;
            this.toastr.error('Error :' + this.err.statusText);
          });
    }

  }
  noOfRecordsToShow(event: any) {
    this.paginationSize = parseInt(event.target.value);
    this.page = 1;
    this.Get_allBranches(this.page, this.paginationSize);
  }
}
