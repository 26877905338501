import { Component, OnInit, Input } from '@angular/core';
import { Newappservice } from '../service/Newapplication_Service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalsearchService } from '../service/GlobalsearchService';
import { NavMenuComponent } from '../nav-menu/nav-menu.component';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { DubaiManagerAuthGuardService } from '../AuthGuard/DubaiManagerAuthGuard.service';
import { environment } from 'src/environments/environment';
import { HttpErrorResponse } from '@angular/common/http/src/response';


declare var $: any;
@Component({
  selector: 'app-new-visa',
  templateUrl: './new-visa.component.html',
  styleUrls: ['./new-visa.component.css'],
  providers: [NavMenuComponent]
})
export class NewVisaComponent implements OnInit {
  token: any;
  visaName = '';
  id: number;
  listobj: any = {};
  applicationarry = [];
  userupdatemodel = [];
  err: any;
  paginationSize = 10;
  page: number = 1;
  pageShow = 0;
  totalNoofpages: number = 0;      // ---- pending due to api response is 0.
  totalRecords: number = 0;
  selectedIndex: number = 0;
  viewCorporate: any = null;
  newdata: any;
  visaUserType: string = null;
  private data: any;
  SearchTag_List = [];
  PageSizeArray: number[];
  @Input() childMessage;
  VisaListing: any = false;
  filterData: any = {
    PNRNo: '',
    PassportNo: '',
    FirstName: '',
    fromdateTime: '',
    todateTime: '',
    Status: 30,
    CIPV: '0',
    page: 1,
    Pagesize: 10,
    VisaCountry: 1089

  };
  Download: any = {
  };

  ChangeStatus: any = 0;
  empid: any = '';
  UaeAppType: any = 0;
  followupPathVisaCopy:any="";
  followupPathInsurenceCopy:any="";
  //pagininnation: any = [10, 20, 50, 100, 200];
  constructor(private _http: HttpClient, public _newappService: Newappservice, private _navMenu: NavMenuComponent, private _activeroute: ActivatedRoute, private searchservice: GlobalsearchService, private _toastr: ToastrService, private _router: Router) {
  
    _activeroute.params.subscribe(val => {
    debugger;
      this.PageSizeArray = this._newappService.PageSizeArray;
      $('#searchTag').hide();                                           // --- for global search tag remove --
      this.data = JSON.parse(localStorage.getItem('currentUser'));
      this.visaUserType = this.data.userType;
      this.empid = this.data.userId;
      // --------------------------- MENU ID HAS TO PASS------------

      // let applicationURL = { 'New': 1, 'Pending': 2, 'Submitted': 3, 'Closed': 4, 'Open': 30, 'Document_Pending': 31, 'Document_Complete': 32, 'Not_Posted_Emigration': 33, 'Posted_Emigration': 34, 'Hold_Has_Resident': 35, 'Hold_Has_Valid_Visa': 36, 'Hold_Inside_Emir_by_Visa': 37, 'Hold_Other': 38, 'Dubai_Approved': 39, 'Close': 40 };

      let applicationURL = { 'New': 1, 'Pending': 2, 'Submitted': 3, 'Closed': 4, 'Open': 30, 'Document_Pending': 31, 'Document_Complete': 32, 'Not_Posted_Emigration': 33, 'Posted_Emigration': 34, 'Hold_Has_Resident': 35, 'Hold_Has_Valid_Visa': 36, 'Hold_Inside_Emir_by_Visa': 37, 'Hold_Other': 38, 'Uae_Approved': 39, 'Close': 40, 'Uae_Rejected': 42 };

      this.visaName = this._activeroute.snapshot.params.id;

      this.id = applicationURL[this._activeroute.snapshot.params.id];

      this.paginationSize = 10;
      this.page = 1;
      this.pageShow = 0;

      this.filterData = {
        PNRNo: '',
        PassportNo: '',
        FirstName: '',
        fromdateTime: '',
        todateTime: '',
        Status: this.id,
        CIPV: '0',
        page: this.page,
        Pagesize: this.paginationSize,
        VisaCountry: 1089
      };

      $('ul.pagination li').removeClass('active');
      this.UaeAppType = JSON.parse(localStorage.getItem('uaeApptype'));
      if (this.UaeAppType != null) {
        this.ChangeStatus = this.UaeAppType;
        if (this.ChangeStatus == 2) {
          this.VisaListing = true;
          this.getDubaiVisaApplication(this.filterData, 'onload');
        }
        else {
          this.VisaListing = false;
          this.getApplData('onload');
        }
      }
      else {
        this.ChangeStatus = 0;
        this.VisaListing = false;
        this.getApplData('onload');
      }
    });
    $(".inner-content").css("padding", "92px 15px 0 195px");
    //$('#btnViewApp').hide();
    //$('#btnAddApp').show();
   
  }

  ngOnInit() {
    $('#pageSize').val(10);
  // this.followupPathVisaCopy=this._newappService.ApiUrl.split("/api")[0]+"/DubaiVisa/VisaCopy/";
  // this.followupPathInsurenceCopy=this._newappService.ApiUrl.split("/api")[0]+"/DubaiVisa/Insurance/";
   
    this.searchservice.getDubaiVisaStatus.subscribe(res => {
    

      if (Object.keys(res).length != 0) {
        if (res.Status != null && res.Status != undefined) {
          this.VisaListing = true;
          this.getDubaiVisaApplication(res, "onload");
        }
      }
    });

    //Other Service
    this.searchservice.CurrentFilterVal.subscribe(res => {
   
      if (res != 0) {
        this.ChangeStatus = res;
        if (this.ChangeStatus == 2) {
          this.VisaListing = true;
          this.filterData.Status = 30;
          this.getDubaiVisaApplication(this.filterData, "onload");
        }
        else {
          if (this.ChangeStatus == 1) {
            this.VisaListing = false;
            this.getApplData('onload');
          }
        }
      }
    });

    //  if(this.PageSizeArray.length==0)
    //  {
    //   this.PageSizeArray = [10, 20, 50, 100, 200];
    //  }

    
  }

  getApplData(methodname, number = 0, totalpages = 0, pageshow = 0) {
    
    this.VisaListing = false;
    this.listobj = {};
    this.applicationarry = [];
    let obj;
    switch (methodname) {
      case "onload":
       
        this.selectedIndex = 1;
        break;
      case "currentPageBind":
        obj = this._navMenu.currentPageBind(number);
        this.page = obj.page;
        this.selectedIndex = obj.selectedIndex;
        break;
      case "nextPage":
        obj = this._navMenu.nextPage(number, totalpages, pageshow);
        this.page = obj.page;
        this.pageShow = obj.pageShow;
       // return false;
        break;
      case "previousPage":
        obj = this._navMenu.previousPage(number, pageshow);
        this.page = obj.page;
        this.pageShow = obj.pageShow;
       // return false;
        break;
      case "noOfRecordsToShow":
        obj = this._navMenu.noOfRecordsToShow(number);
        this.page = obj.page;
        this.paginationSize = obj.paginationSize;
        break;
    }

    this._navMenu.getapplicationlist(this.id, this.page, this.paginationSize,0,0,0,0,'','',
      function (data) {
        debugger;
        this.listobj = data;
        this.applicationarry = this.listobj.result;
        console.info("this.applicationarry==", this.applicationarry);
        this.totalNoofpages = Math.ceil(this.listobj.totalCount / this.paginationSize);
        $(".left-nav a").removeClass('active');
        $('#' + this.visaName + '_leftNav').addClass('active');
        $('#li' + this.page).addClass('active');
      }.bind(this)
    )
  }
 
  getDubaiVisaApplication(Filter: any, methodname, number = 0, totalpages = 0, pageshow = 0) {

   
    this.listobj = {};
    this.applicationarry = [];
    this.filterData = Filter;
    if (Object.keys(Filter).length != 0) {
      let obj;
      this.VisaListing = true;
      switch (methodname) {
        case "currentPageBind":
          obj = this._navMenu.currentPageBind(number);
          this.page = obj.page;
          this.selectedIndex = obj.selectedIndex;
          break;
        case "nextPage":
          obj = this._navMenu.nextPage(number, totalpages, pageshow);
          this.page = obj.page;
          this.pageShow = obj.pageShow;
         // return false;
          break;
        case "previousPage":
          obj = this._navMenu.previousPage(number, pageshow);
          this.page = obj.page;
          this.pageShow = obj.pageShow;
         // return false;
          break;
        case "noOfRecordsToShow":
          obj = this._navMenu.noOfRecordsToShow(number);
          this.page = obj.page;
          this.paginationSize = obj.paginationSize;
          break;
      }
      // Call Function By Fateh
      debugger;
      this._navMenu.getDubaiVisaApplication(Filter, this.page, this.paginationSize,
        function (data) {
          debugger;
          this.listobj = data;
          this.applicationarry = this.listobj.result;
          console.info("this.applicationarry==", this.applicationarry);
          this.totalNoofpages = Math.ceil(this.listobj.totalCount / this.paginationSize);
          $(".left-nav a").removeClass('active');
          $('#' + this.visaName + '_leftNav').addClass('active');
          $('#li' + this.page).addClass('active');
        }.bind(this)

      )
    }

  }

  public AssignChnaged(appid, countryid, checktype) {
    console.log(appid);
    $("#visaLoader").show();
    this._newappService.getAssignByUser(appid, countryid, checktype).subscribe((res: any) => {
      $("#visaLoader").hide();
      if (res.status == true) {
        debugger;
        this._toastr.success(res.messsage);
      }
      else {
        this._toastr.error(res.messsage);
      }
    },
      err => {
        $("#visaLoader").hide();
        console.log(err);
      })
  }

  downloadVisaCopy(id:any,passportNo:string ,documentType:string){
    $("#visaLoader").show();
    debugger;
    let filename: string = passportNo+documentType+'.pdf';
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    this._http.get(this._newappService.ApiUrl+"/VisaApplDocument/DownloadVisaDocument?id="+id+"&passportNo="+passportNo+"&documentType="+documentType+"",
    { headers: headers , observe: 'response', responseType: 'arraybuffer'}, )
    .subscribe((res:any) => {
      debugger;
      if(res!=null)
      {
        $("#visaLoader").hide();
        var contentType = res.body.contentType;
        var blob = new Blob([res.body], { type:contentType});
  
        if (window.navigator && window.navigator.msSaveOrOpenBlob) { // IE workaround
         
  
          window.navigator.msSaveOrOpenBlob(blob);
          return;
      }else{
      
          let data = window.URL.createObjectURL(blob);
          let link = document.createElement('a');
          link.href = data;
          link.download = filename;
          link.click();
          setTimeout(() =>
          {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data);
          }, 100);
        }
  


      }
      else{
        $("#visaLoader").hide(); 
        this._toastr.error(documentType+" has not found");
      }
      
    },
    err=>{
      $("#visaLoader").hide();
      this._toastr.error(documentType+" has not found");
      console.error (err); 
    })
 }

}
