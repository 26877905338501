import { Component, OnInit } from '@angular/core';
import { BranchmanagementService } from "src/app/service/servicecall.service";
import { FormsModule, NgModel, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Message } from '@angular/compiler/src/i18n/i18n_ast';
declare var $: any;
@Component({
  selector: 'app-country-master',
  templateUrl: './country-master.component.html',
  styleUrls: ['./country-master.component.css']
})
export class CountryMasterComponent implements OnInit {

  paginationSize = 1000;
  page: number = 1;
  pageShow = 0;
  totalNoofpages: number = 0;
  selectedIndex: number = 0;

  totalcount: any;
  editarray: any=[];
  Createcountryobj: {};
  getallCountry: any = [];
  registerForm: FormGroup;
  submitted = false;
  err: any;
  CountryName: any;
  CountryCode: any;
  Data: any[];
  result: any;
  deleteobject: any;
  constructor(
    private _CountryService: BranchmanagementService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private _http: HttpClient) { }

  ngOnInit() {
    this.GetCountry();
    // Validation 
    this.registerForm = this.formBuilder.group({
      CountryName: ['', [Validators.required, Validators.pattern("[a-zA-Z ]*"), Validators.maxLength(30)]],
      CountryCode: ['', [Validators.required, Validators.pattern("[a-zA-Z ]*"), Validators.maxLength(30)]],
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.registerForm.controls; }

  AddCountryModel() {
    this.registerForm.reset();
    this.submitted = false;
  }

  // Add Country
  AddCountry() {
    if (this.CountryName !== "" || this.CountryCode !== "") {
      this.Createcountryobj = {
        "id": 0,
        "name": this.CountryName,
        "countryCode": this.CountryCode,
      };

      this._CountryService.CreateCountry(this.Createcountryobj)
        .subscribe(
          (data: any) => {
            this.Data = [];
            this.Createcountryobj = "";
            this.GetCountry();
            $('#adduserClose').click();
           
            if (data["exists"]==true) {
              this.toastr.warning(data["messsage"]);

            } else {
              this.toastr.success(data["messsage"]);
            }

          },
          (error: HttpErrorResponse) => {
            this.err = error;
            this.toastr.error("Error " + this.err.statusText);
          }
        );
    }
  }


  // Get All Country list
  GetCountry() {
    this._CountryService.getall_Country(this.page, this.paginationSize).subscribe(
      (data: any) => {
        const temparr = { data };
        // -- for pagination
        this.totalcount = temparr.data.totalCount;
        this.totalNoofpages = Math.ceil(temparr.data.totalCount / this.paginationSize);
        this.getallCountry = (temparr.data.result);
      },
      error => {
        this.err = error;
        this.toastr.error("Error :" + this.err.message);
      }
    );
  }


  deleteCountryMaster(obj) {
    this.deleteobject = [];
    this.deleteobject.push(obj);
  }

  // Delete Fee Master
  delete_feeMaster(deleteData) {
    this._CountryService.deleteCountry(deleteData.id)
      .subscribe(
        data => {
          const temp = data;

          $('#btnclose1').click();
          $('#btnclose').click();
          this.toastr.success(data["messsage"]);
          this.GetCountry();

        },
        error => {
          this.err = error;
          this.toastr.error('Error :' + this.err.statusText);
        }
      );
  }

  edit_Country(UpdateData)
  {

    this.editarray = {
      "id":UpdateData.id,
      "name": UpdateData.name,
      "countryCode": UpdateData.countryCode
    };
  }

  UpdateCountry(objUpdate){

   // console.info("Sending Data For update====",objUpdate);
    this._CountryService.UpdateCountry(this.editarray)
    .subscribe(
      data => {
     
        $('#editclose').click();

        if(data["exists"]==true){
          this.toastr.warning(data["messsage"]);
        }else{
          this.toastr.success(data["messsage"]);
        }

       
        this.GetCountry();

      },
      error => {
        this.err = error;
        this.toastr.error('Error :' + this.err.statusText);
      }
    );
  }

  // ---- Pagination Code Start here ----
  // noofRecordstoDisplay(args: any) {
  //   // tslint:disable-next-line:radix
  //   this.paginationSize = parseInt(args.target.value);
  //   this.page = 1;// setting to default value
  //   this.pageShow = 0;
  // }
  // currentPageBind(currPage: any) {
  //   this.selectedIndex = currPage;
  //   this.page = currPage;
  //   $('ul.pagination li').removeClass('active');
  //   this.ngOnInit();
  // }
  // nextPage(nextpageNumber: number) {
  //   this.page = nextpageNumber + 1;
  //   if (this.page <= this.totalNoofpages) {
  //     this.pageShow = this.pageShow + 10;
  //   }
  // }
  // previousPage(prevPageNumber: number) {
  //   if (prevPageNumber >= 0) {
  //     this.page = prevPageNumber + 1;
  //     this.pageShow = this.pageShow - 10;
  //   }
  // }


}
