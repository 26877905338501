import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, Observer } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class CheckPNRService {
  ApiUrl: string = null;
  pagesize: number = 0;
  page: number = 0;
  private data: any;
  token: any;
  userId: any;

  constructor(private _http: HttpClient) {
    this.ApiUrl = environment.ApiUrl;
    this.data = JSON.parse(localStorage.getItem('currentUser'));
    this.token = this.data.token;
    this.userId = this.data.userId;
  }

  GetRiyaQuotaVisaApplication(page, pagesize, SearchData) {

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    this.page = page;
    this.pagesize = pagesize;
    return this._http.get(environment.ApiUrl + '/DubaiAutomation/GetRiyaQuotaVisaApplication?page=' + this.page + '&pageSize=' + this.pagesize , { headers: headers });//+ '&SearchData=' + SearchData
  }
}
