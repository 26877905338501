import { Component, OnInit } from '@angular/core';
import { GlobalsearchService } from '../service/GlobalsearchService';
import { AddVisaService } from 'src/app/service/addVisaSerivce';
import { Newappservice } from '../service/Newapplication_Service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http/src/response';
import { NavMenuComponent } from '../nav-menu/nav-menu.component';
import { UpdateVisaService } from "src/app/service/updateVisaService";
declare var $: any;
@Component({
  selector: 'app-global-search',
  templateUrl: './global-search.component.html',
  styleUrls: ['./global-search.component.css'],
  providers: [NavMenuComponent]
})
export class GlobalSearchComponent implements OnInit {
  searcharr: any = {};
  searchapplication: any = [];
  searchTagList: any = [];
  searchresultlength: number;
  result: any;
  userType: any;
  globalsearchObj = {};
  SearchTag_List = [];
  applicationlist = [];
  allSearchData = [];
  // -- Pagination --
  paginationSize = 10;
  page: number = 1;
  pageShow = 0;
  totalNoofpages: number = 0;
  selectedIndex: number = 0;
  totalcount: any;
  pageSize: any;
  rowCount: any;
  currentPage: any;
   id: number;
  countryData=[];
  branchPassword:string="";
  isbranchvalid:boolean=false;
  transferid:any=0;
  branchid:any=0;
  branchlist: any=[]=[];
  brnlist:any;
  appid:any=0;
  countryid=0;
  PNRNo:any="";
  constructor(private router: Router, private _visaService:AddVisaService, private _navMenu: NavMenuComponent, private searchservice: GlobalsearchService, private toastr: ToastrService,public _newappService: Newappservice, private _ReportService: UpdateVisaService, private _toastr: ToastrService) 
  { 
    this.userType = this.searchservice.localData.userType

  }

  ngOnInit() {
    // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    
    $('.left-nav a').removeClass('active');
    this.searchapplications(0,this.paginationSize);
    $('#searchTag').show();
    this.globalsearchObj =
      {
        "page": 1,
        "pageSize": 10,
        "fromDate": "",
        "toDate": "",
        "lstVisaExecutiveId": [],
        "lstCurrentStatus": [],
        "lstVisaType": [],
        "lstVendorId": [],
        "lstApplicantName": [],
        "lstPassportNo": [],
        "lstVisaCountry": [],
        "lstBarcode": [],
        "lstBranch": [],
        "lstIcust": []
      };
    this.SearchTag_List = [];
    this.allSearchData = [];

    this.getBranches();

  }

  searchapplications(start,end) {

	
    this.searcharr = this.searchservice.getDataPageWise(start,end);
	

    this.totalcount = this.searchservice.gettotalcount();
    this.totalNoofpages = this.searchservice.gettotalNoofpages();
    this.pageSize = this.searchservice.getpageSize();
    this.rowCount = this.searchservice.getrowCount();
    this.currentPage = this.searchservice.getcurrentPage();
	
	
  }

  getCountryData(){

    this._visaService._getCountryList()
    .subscribe((successData:any)=>{
      // if(successData.status==true){
      //    this.countryData=successData.result;
      // }
      this.countryData=successData;
      //this.visaObj.visaCustomer.fK_VisaCountryMasterId=this.countryData.result.find(x=>x.countryCode).name;
      
    },
    (err: HttpErrorResponse) => {
      console.log (err);    // SHOW ERRORS IF ANY.
    });
  }

  // ---- Pagination Code Start here ----
  noofRecordstoDisplay(args: any) {
    // tslint:disable-next-line:radix
    this.paginationSize = parseInt(args.target.value);
    this.page = 1;// setting to default value
    this.pageShow = 0;
  }
  currentPageBind(currPage: any) {
	  
    this.selectedIndex = currPage;
    this.page = currPage;
	this.id=1;
    $('ul.pagination li').removeClass('active');
	

	let startArrayCnt=(this.selectedIndex*10)-10;
	let endArrayCnt=(this.selectedIndex*10);
	
	
	
	this.searchservice.currentPage=this.selectedIndex;


	this.searchapplications(startArrayCnt,endArrayCnt);
	
	
  }
  nextPage(nextpageNumber: number) {
    this.page = nextpageNumber + 1;
    if (this.page <= this.totalNoofpages) {
      this.pageShow = this.pageShow + 10;
    }
  }
  previousPage(prevPageNumber: number) {
    if (prevPageNumber >= 0) {
      this.page = prevPageNumber + 1;
      this.pageShow = this.pageShow - 10;
    }
  }

  getBranches()
  {
   this._ReportService.getBranches().subscribe(data=> {
     this.brnlist=data;
    if(this.brnlist.status==true)
    {
       this.branchlist=this.brnlist.result;
    } 
    else{
      console.info(this.brnlist.Messsage);
    }

   },
   err=>{
    console.log(err);
   }
   )

  }
  
  public BranchTransfers(id:any,coid:any,PNRNo:any)
  {
    
    //let userid=this.searchservice.localData.userId;
    this.appid=id; this.countryid=coid; this.PNRNo=PNRNo;
    $("#ChanngedTransferBranched").modal('show');

  }

  public passwordChecked()
  {
    if(this.branchPassword=="654321")
    {
      this.isbranchvalid=true;

    }
    else{
       this.isbranchvalid=false;
    }
  }

  public ChangedBranched()
  {

     console.warn(this.transferid+"Branch Id"+this.branchid);
    if(this.appid!=0 && this.countryid!=0)
    {

    this.searchservice.BranchChangedByUser(this.appid,this.countryid,this.transferid,this.branchid).subscribe((data:any)=> {

      if(data.status==false)
      {
       this._toastr.error(data.messsage);
      }
      else {

        if(this.transferid!=0 && this.branchid!=0)
        {
          this._toastr.success('Transfer Branch & Application Branch Changed Successfully!');
        }
        else if(this.transferid!=0)
        {
          this._toastr.success('Transfer Branch Changed Succesfull!');
        }
        else {
          this._toastr.success('Application Branch Changed Succesfull!');
        }
    
       $("#ChanngedTransferBranched").modal('hide');
      }

      },
      err=>{
      console.log("Error:"+err);
      })

     
    }
    else{
    alert("Please  PNR No Not Found then Again Try--");
    }
    

  }

}
