import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, Observer } from 'rxjs';
import { OpaqueViewState } from '@angular/core/src/render3/interfaces/view';

@Injectable({
  providedIn: 'root'
})
export class AdddubaivisaService {
  ApiUrl: string = null;

  pagesize: number;
  page: number;
  flag = 'True';
  private data: any;
  token: any;
  userId: any;
  _statusMaster = [
    { id: 30, Name: "Open" },
    { id: 31, Name: "Document_Pending" },
    { id: 32, Name: "Document_Complete" },
    { id: 33, Name: "Not_Posted_Emigration" },
    { id: 34, Name: "Posted_Emigration" },
    { id: 35, Name: "Hold_Has_Resident" },
    { id: 36, Name: "Hold_Has_Valid_Visa" },
    { id: 37, Name: "Hold_Inside_Emir_by_Visa" },
    { id: 38, Name: "Hold_Other" },
    { id: 39, Name: "Visa_Status" },
    { id: 40, Name: "Close" },
    { id: 41, Name: "FreeStructure" },
  ];
  _docList = [
    { Id: 1, docCode: 'PassportFrontSide', documentName: 'Passport Page-1' },
    { Id: 2, docCode: 'PassportBackSide', documentName: 'Passport Page-2' },
    { Id: 3, docCode: 'photo', documentName: 'Personal photo' },
    { Id: 4, docCode: 'pancard', documentName: 'Pan Card' },
    { Id: 5, docCode: 'Passport3', documentName: 'Dubai Passport Document 3 ' },
    { Id: 6, docCode: 'Passport4', documentName: 'Dubai Passport Document 4' },
    { Id: 7, docCode: 'Passport5', documentName: 'Dubai Passport Document 5' },
    { Id: 8, docCode: 'Passport6', documentName: 'Dubai Passport Document 6' },
    { Id: 9, docCode: 'Passport7', documentName: 'Dubai Passport Document 7' },
    { Id: 10, docCode: 'Passport8', documentName: 'Dubai Passport Document 8' },

  ];

  constructor(private _http: HttpClient) {
    this.ApiUrl = environment.ApiUrl;
    this.data = JSON.parse(localStorage.getItem('currentUser'));
    this.token = this.data.token;
    this.userId = this.data.userId;

  }
  _getProcessingOptionsByCountryid(countryId) {
    // let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    // headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.post(environment.ApiUrl + "/MstProcessingOption/GetByCountryId?CountryId=" + countryId, { headers: headers });
  }

  SChannelArabicConversion(name: any): Observable<object> {

    let arabicOptions: Object = {

      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      responseType: 'text'
    }
    return this._http.get("https://smart.gdrfad.gov.ae/SmartChannels/rest/AjaxHelpers/Translate?text=" + name + "", arabicOptions);
  }

  _getUAEVisaType() {

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.get(environment.ApiUrl + "/VisaType/GetUAEVisaType", { headers: headers });
  }
  getUAErofession() {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.get(environment.ApiUrl + "/VisaType/GetUAEProfession", { headers: headers });
  }
  getUAEPassportType() {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.get(environment.ApiUrl + "/VisaType/GetUAEPassportType", { headers: headers });
  }

  getReligion() {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.get(environment.ApiUrl + "/VisaType/GetUAEReligions", { headers: headers });
  }

  getFaithByReligionName(name: string) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.get(environment.ApiUrl + "/VisaType/GetFaithByReligionName/?name=" + name + "", { headers: headers });
  }

  getEducationlist() {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.get(environment.ApiUrl + "/VisaType/GETEducationlist", { headers: headers });
  }
  getAllLangauges() {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.get(environment.ApiUrl + "/VisaType/GetAllLangauges", { headers: headers });
  }
  isimgArray(array, word) {
    return array.indexOf(word.toLowerCase()) > -1;
  }

  _uploadDubaiVisaPassport(filePath) {
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.post(environment.ApiUrl + "/VisaApplDocument/UploadDubaiVisaPassport", filePath, { headers: headers });
  }
  _delUploadedDocument(docId, BucketName, FileName) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.post(environment.ApiUrl + "/VisaApplDocument/DeleteFile?id=" + docId + "&BucketName=" + BucketName + "&FileName=" + FileName, '', { headers: headers });
  }

  getVisaServiceType() {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.get(environment.ApiUrl + "/VisaType/getAllServiceCharges", { headers: headers });
  }

  saveDubaiVisaApplication(DubaivisaObj) {


    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.post(environment.ApiUrl + "/DubaiVisaApplication/InsertDuabiVisa", DubaivisaObj, { headers: headers });
  }
  saveDubaiVisaFeesAdd(visaobj) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.post(environment.ApiUrl + "/DubaiVisaApplication/DubaiVisaFeesAddOrUpdate", visaobj, { headers: headers });
  }

}
