import { Component, OnInit, Input } from '@angular/core';
import { Newappservice } from '../../service/Newapplication_Service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalsearchService } from '../../service/GlobalsearchService';
import { NavMenuComponent } from '../../nav-menu/nav-menu.component';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { DubaiManagerAuthGuardService } from '../../AuthGuard/DubaiManagerAuthGuard.service';
import { environment } from 'src/environments/environment';
import { HttpErrorResponse } from '@angular/common/http/src/response';
import { AddVisaService } from 'src/app/service/addVisaSerivce';

declare var $: any;
@Component({
  selector: 'app-list-app',
  templateUrl: './list-app.component.html',
  styleUrls: ['./list-app.component.css'],
  providers: [NavMenuComponent]
})

export class ListAppComponent implements OnInit {
  token: any;
  visaName = '';
  id: number;
  listobj: any = {};
  applicationarry = [];
  userupdatemodel = [];
  err: any;
  paginationSize = 10;
  page: number = 1;
  pageShow = 0;
  totalNoofpages: number = 0;      // ---- pending due to api response is 0.
  totalRecords: number = 0;
  selectedIndex: number = 0;
  viewCorporate: any = null;
  newdata: any;
  visaUserType: string = null;
  visaCountryData:any=[];
  private data: any;
  SearchTag_List = [];
  PageSizeArray: number[];
  @Input() childMessage;
  VisaListing: any = false;
  filterData: any = {
    PNRNo: '',
    PassportNo: '',
    FirstName: '',
    fromdateTime: '',
    todateTime: '',
    Status: 30,
    CIPV: '0',
    page: 1,
    Pagesize: 10,
    VisaCountry: 1089

  };
  Download: any = {
  };

  ChangeStatus: any = 0;
  empid: any = '';
  UaeAppType: any = 0;
  followupPathVisaCopy:any="";
  followupPathInsurenceCopy:any="";
  constructor(private _http: HttpClient, public _newappService: Newappservice, private _navMenu: NavMenuComponent, private _activeroute: ActivatedRoute, private searchservice: GlobalsearchService, private _toastr: ToastrService, private _router: Router,
    private _visaService: AddVisaService) {
  


    

    //$('#btnViewApp').hide();
    //$('#btnAddApp').show();
   
  }

  
  ngOnInit() {
    $('#pageSize').val(10);
    var self=this;
    $(document).on('change','#Country',function(event){
      this.page=1;
      this.pageShow = 0;
      self.searchlist('onload');
     
  
      });
      $(document).on('change','#PaymentStatus',function(event){
        this.page=1;
        this.pageShow = 0;
        self.searchlist('onload');
       
    
        });

        $(document).on('blur','#searchbox',function(event){
          this.page=1;
          this.pageShow = 0;
          self.searchlist('onload');
         
      
          });
          $(document).on('blur','#PassportNum',function(event){
            this.page=1;
            this.pageShow = 0;
            self.searchlist('onload');
           
        
            });

          

          $(document).on('change','#AppStatus',function(event){
            this.page=1;
            this.pageShow = 0;
            self.searchlist('onload');
           
        
            });

  // this.followupPathVisaCopy=this._newappService.ApiUrl.split("/api")[0]+"/DubaiVisa/VisaCopy/";
  // this.followupPathInsurenceCopy=this._newappService.ApiUrl.split("/api")[0]+"/DubaiVisa/Insurance/";
   
   /* this.searchservice.getDubaiVisaStatus.subscribe(res => {
    

      if (Object.keys(res).length != 0) {
        if (res.Status != null && res.Status != undefined) {
          this.VisaListing = true;
          this.getDubaiVisaApplication(res, "onload");
        }
      }
    });*/
    this.getCountryData();
    this.getApplData('onload');
    //Other Service
    this.searchservice.CurrentFilterVal.subscribe(res => {
   
      if (res != 0) {
        this.ChangeStatus = res;
      /*  if (this.ChangeStatus == 2) {
          this.VisaListing = true;
          this.filterData.Status = 30;
          this.getDubaiVisaApplication(this.filterData, "onload");
        }else*/
         {
          if (this.ChangeStatus == 1) {
            this.VisaListing = false;
            this.getApplData('onload');
          }
        }
      }
    });

    //  if(this.PageSizeArray.length==0)
    //  {
    //   this.PageSizeArray = [10, 20, 50, 100, 200];
    //  }

    
  }

  searchlist(methodname) {
    this.getApplData(methodname, 0, 0, 0) ;
    
  }

  getApplData(methodname, number = 0, totalpages = 0, pageshow = 0) {
    
    this.VisaListing = false;
    this.listobj = {};
    this.applicationarry = [];
    let obj;
    switch (methodname) {
      case "onload":
        this.page=1;
        this.selectedIndex = 1;
        break;
      case "currentPageBind":
        obj = this._navMenu.currentPageBind(number);
        this.page = obj.page;
        this.selectedIndex = obj.selectedIndex;
        break;
      case "nextPage":
        obj = this._navMenu.nextPage(number, totalpages, pageshow);
        //debugger;
        this.page = obj.page;
        this.pageShow = obj.pageShow;
       // return false;
        break;
      case "previousPage":
        obj = this._navMenu.previousPage(number, pageshow);
        this.page = obj.page;
        this.pageShow = obj.pageShow;
       // return false;
        break;
      case "noOfRecordsToShow":
        obj = this._navMenu.noOfRecordsToShow(number);
        this.page = obj.page;
        this.paginationSize = obj.paginationSize;
        break;
    }

    let countryid=$('#Country').val();
    let paystatus=$('#PaymentStatus').val();
    let searchbox=$('#searchbox').val();
    let AppStatus=$('#AppStatus').val();
    let PassportNum=$('#PassportNum').val();
    
    

//debugger;
    this._navMenu.getapplicationlist(this.id, this.page, this.paginationSize,countryid,0,paystatus,AppStatus,searchbox,PassportNum,
      function (data) {
      
        this.listobj = data;
        this.applicationarry = this.listobj.result;
        console.info("this.applicationarry==", this.applicationarry);

        this.totalNoofpages = Math.ceil(this.listobj.totalCount / this.paginationSize);
        $("#visaLoader").hide();
        $(".left-nav a").removeClass('active');
        $('#' + this.visaName + '_leftNav').addClass('active');
        $('#li' + this.page).addClass('active');
      }.bind(this)
    )
  }

  getCountryData()
  {
    this._visaService._getVisaFeesCountryList()
    .subscribe(result => {
      var data = { result };
      this.visaCountryData = data;
    },
      (err: HttpErrorResponse) => {
        console.error(err.message);    // SHOW ERRORS IF ANY.
      });
  }


  ExportVisaList()
  {
    
    let countryid=$('#Country').val();
    let paystatus=$('#PaymentStatus').val();
    let appstatus=$('#AppStatus').val();
    let searchbox=$('#searchbox').val();
    let PassportNum=$('#PassportNum').val();
    $('#visaLoader').show();
    this._newappService
		.getApplicationListDownload(this.id, countryid,0,paystatus,appstatus,searchbox,PassportNum)
		.subscribe(
        (data: any) => {
          console.info(data);
        //  console.info(this.selectedCustType);
          //window.location.href=data["result"];
          if( data["result"]!=null){

            window.open(
              data["result"],
              '_blank' // <- This is what makes it open in a new window.
            );
  

          }
          $('#visaLoader').hide();
          this._toastr.info(data["messsage"]);
        },
        (error: HttpErrorResponse) => {
          $('#visaLoader').hide();
          this.err = error;
          this._toastr.error("Error " + this.err.statusText);
        }
       
      );
  }

  getStatusClass(val)
  {
    if (val =="For Review") {
      return 'status-btn dot2';
    } else if (val =="Draft") {
      return 'status-btn dot4';
    } else if(val=="Ready for Submission") {
      return 'status-btn dot1'
    }
    else{
      return 'status-btn dot3'
    }
  }

}
