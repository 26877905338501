import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from "../service/login-service.service";
import { UsermanagementService } from '../service/userService';
declare var $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  localData: any = {};
  profileimagepath: string = null;
  visaUserType: string = null;
  profiledata: any = {};
isAI :boolean=false;
  constructor(private _router: Router,private _loginService:LoginService,private _userService:UsermanagementService) { }

  ngOnInit() {
    this.localData = JSON.parse(localStorage.getItem('currentUser'));
     
    //console.info(localStorage.getItem('UserImage'));
   let uimage  = localStorage.getItem('UserImage');
   this.visaUserType = this.localData.userType;
   //console.info(uimage);
   if(this.visaUserType=='AI')
   {
    this.isAI=true;
   }
   //console.info(uimage);

   if (window.location.href.indexOf('userdashboard') > -1)
   {
$('.left-box').hide();
   }

   debugger;
    if (uimage== null) {     
     
      debugger;
     this.getUserProfile();

    }  
    else{
      debugger;
      this.profileimagepath = uimage;
    }    
   
  }


  
  profile() {
   
    this._router.navigate([this.localData.userType+'/agentprofile']);
  }

  Logout() {
    $("#visaLoader").show();
    this._loginService._logoutUser();
    $("#visaLoader").hide();
    // this._compiler.clearCache();
    // location.reload();
    // location.reload(true);
    // localStorage.clear();
    // this._router.navigate(['/']);
  }

  viewlistclick()
  {
    this._router.navigate([this.localData.userType+'/Visalist']);
      if($("#menu-item4").hasClass('active')) {
        $("#menu-item4").removeClass('active');
        $("#menu-item3").addClass('active');
            }

  }


  addappclick()
  {
    this._router.navigate([this.localData.userType+'/addApplication']);
    if($("#menu-item3").hasClass('active')) {
      $("#menu-item3").removeClass('active');
      $("#menu-item4").addClass('active');
          }
  }


  
  getUserProfile() {
    // ---- Call all user data for grid binding ---
    //debugger;
    $('#visaLoader').show();
    this._userService.getAgentbyId().subscribe(
      (data: any)  => {
       // this.resultdate=data;
       console.info(data);
        this.profiledata=data.result;
        
        console.info(this.profiledata.profileImage);
if(this.profiledata.profileImage!=null && this.profiledata.profileImage!="")
{
  let objectURL = 'data:image/jpeg;base64,' + this.profiledata.profileImage;

  this.profileimagepath = objectURL;
  localStorage.setItem('UserImage',objectURL);

}else{
  //this.profileimagepath='assets/images/profile-pic.jpg';
  //localStorage.setItem('UserImage',"assets/images/profile-pic.jpg");

  this.profileimagepath='assets/images/visa-service-logo.png';
  localStorage.setItem('UserImage',"assets/images/visa-service-logo.png");
  

}

        

        $('#visaLoader').hide();
      },
      error => {
        $('#visaLoader').hide();
        //this.err = error;
       // this._toastr.error('Error :' + this.err.statusText);
      }
    );
  };


}
