import { Component, OnInit } from '@angular/core';
declare var $: any;
@Component({
  selector: 'app-branch-reports',
  templateUrl: './branch-reports.component.html',
  styleUrls: ['./branch-reports.component.css']
})
export class BranchReportsComponent implements OnInit {
  drpdwnPageSizes = [50, 100, 200, 500];
  paginationSize = 10;
  page = 1;
  pageShow = 0;
  totalNoofpages: number = 22;
  totalRecords: number = 0;
  selectedIndex: number = 0;
  viewCorporate: any = null;
  constructor() { }

  ngOnInit() {
   // this.totalNoofpages = Math.ceil(this.totalRecords / this.paginationSize);
  }
  noofRecordstoDisplay(args: any) {
    this.paginationSize = parseInt(args.target.value);
    this.page = 1;// setting to default value
    this.pageShow = 0;
 
  }
  currentPageBind(currPage: any) {
    this.selectedIndex = currPage;
    this.page = currPage;   
    $("ul.pagination li").removeClass("active");
  }
  nextPage(nextpageNumber: number) {
    this.page = nextpageNumber + 1;
    if (this.page <= this.totalNoofpages) {
      this.pageShow = this.pageShow + 10;   

    //  $("ul.pagination li").removeClass("active");
    //  $("ul.pagination #li" + this.page).addClass("active");
     // this.bindUserCorporatesList(this.page, this.paginationSize);
    }
  }
  previousPage(prevPageNumber: number) {
    if (prevPageNumber >= 0) {
      this.page = prevPageNumber + 1;
      this.pageShow = this.pageShow - 10;    
    }
  }
} 

