import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { VisaprocessingService } from "src/app/service/visaProcessingService";
import { FormsModule, NgModel, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { Newappservice } from '../../service/Newapplication_Service';
declare var $: any;
@Component({
  selector: 'app-processing-days',
  templateUrl: './Visatype.component.html',
  styleUrls: ['./Visatype.component.css']
})
export class VisatypeComponent implements OnInit {
  getallCountry: any = [];
  err: any = {};
  visatypeObj: any = {};
  temparray = [];
  entriesArray = [];
  Data: any = [];
  Visatypelist: any = [];
  visaListarray: any = [];
  registerForm: FormGroup;
  submitted = false;
  updatevisatypeObj: any = {};
  UpdateArray: any = [];
  editfee: any=[];
  CountryId: number = 0;
  // -- Pagination --
  paginationSize = 10;
  page: number = 1;
  pageShow = 0;
  totalNoofpages: number = 0;
  selectedIndex: number = 0;
  totalcount: any;
  editarray: any[];
  tempobj: {};
  temparray1: any = [];
  visatypelist: any = [];
  productDimension: any;
  Visatypearray_bycountry: any = [];
  countryarray: any = [];
  searchString: string = '';
  getFeeMaster: any = {};
  PageSizeArray: number[];
  SearchKeyWord: string = '';
  fk_Countryid:any=0;
  visatypeid:any="";
  constructor(
    private _http: HttpClient,
    private _visaprocessing: VisaprocessingService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,public _newappService: Newappservice) { }

  ngOnInit() {
    this.GetCountry();
    
    this.temparray = [];
    this.GetVisaType_List(this.page, this.paginationSize,this.SearchKeyWord);
    this.visatypeObj.fk_CountryId = null;
    this.visatypeObj.visaType = null;
    this.PageSizeArray = this._newappService.PageSizeArray;
  }

  // Get All Country list
  GetCountry() {
    this._visaprocessing.getCountry().subscribe(
      data => {
        this.getallCountry = { data };
        this.getallCountry = this.getallCountry.data;
      },
      error => {
        this.err = error;
        this.toastr.error("Error :" + this.err.message);
      }
    );
  }

  // Get All Country list
  GetVisaType_List(page,paginationSize,SearchKeyWord) {
    this._visaprocessing.getall_Visatype(page,paginationSize,SearchKeyWord,this.fk_Countryid).subscribe(
      data => {
        this.temparray1 = { data };
        this.visatypelist = this.temparray1.data.result;

        

        // -- for pagination
        this.totalcount = this.temparray1.data.totalCount;
        this.totalNoofpages = Math.ceil(this.temparray1.data.totalCount / this.paginationSize);
      },
      error => {
        this.err = error;
        this.toastr.error("Error :" + this.err.message);
      }
    );
  }
  // Add Visa type master
  AddMaster(visatypeObj) {
    this.entriesArray = [];
    for (let index = 0; index < this.temparray.length; index++) {
      const element = this.temparray[index];
      const obj = { "id": 0, "entries": element, "fk_VisaTypeId": 0 };
      this.entriesArray.push(obj);
    }

    this.CountryId = this.getallCountry.find(x => x.countryCode === visatypeObj.fk_CountryId).id;
    this.productDimension = this.Visatypearray_bycountry.find(x => x.visaType === visatypeObj.visaType).productDimension;
    this.visatypeObj = {
      "id": 0,
      "name": visatypeObj.visaType,
      "fk_CountryId": this.CountryId,
      "lstVisaTypeEntries": this.entriesArray,
      "productDimension": this.productDimension
    };
    //console.log(JSON.stringify(this.visatypeObj));
    this.temparray = []; // clear checkbox array
    this._visaprocessing
      .createVisaType(this.visatypeObj)
      .subscribe(
        (data: any) => {
          this.visatypeObj.fk_CountryId = null;
          $('#adduserClose').click();
          this.GetVisaType_List(this.page, this.paginationSize,this.SearchKeyWord);
          this.toastr.success(data["messsage"]);
          this.visatypeObj.visaType = null;
          
        },
        (error: HttpErrorResponse) => {
          this.err = error;
          this.toastr.error("Error " + this.err.statusText);
        }
      );
  }

  getCountry_changeonCreate(CountryCode) {
    

    //console.info("tthis.CountryId= i am here");
   // debugger;
    //this.CountryId = event;

    this.CountryId = CountryCode;

    //console.info("tthis.CountryId=", this.CountryId);

    if (this.CountryId !== null) {
      this.Getall_VisatypebyCountryCode(this.CountryId); // Geting Visa Type
    } else {
      this.editfee.countryCode = null;
    }
  }
  // Get visaType by ERP by countrycode -- first time visa type create
  Getall_VisatypebyCountryCode(CountryId) {

    this._visaprocessing.GetVisaTypeName(CountryId).subscribe(
      (data: any) => {
        this.visaListarray = [];
        this.visaListarray.push(data);
        this.Visatypearray_bycountry = this.visaListarray[0].result;
      },
      error => {
        this.err = error;
        this.toastr.error("Error :" + this.err.message);
      }
    );
  }


  // Storing checked data in array
  selectBadge(e) {
    if (e.target.checked) {
      this.temparray.push(e.target.value);
    } else {
      this.temparray.splice(this.temparray.indexOf(e.target.value), 1);
    }

  }

  // Edit Button Click
  VisatypeMasteredit(vtypobj) {
    this.editarray = [];
    this.tempobj = {};
    var Single: any;
    var Double: any;
    var Triple: any;
    var Quad: any;
    var Multiple: any;

    this.editfee = vtypobj;

   console.info(" this.editfee", this.editfee)


    this.getCountry_changeonCreate(this.editfee.countryCode);

    
    

    const A = vtypobj.entries.split(", ");
    for (let index = 0; index < A.length; index++) {
      const element = A[index];
      if (element === 'Single') {
        Single = element;
      } else if (element === 'Double') {
        Double = element;
      } else if (element === 'Triple') {
        Triple = element;
      } else if (element === 'Quad') {
        Quad = element;
      } else if (element === 'Multiple') {
        Multiple = element;
      }
    }

    this.tempobj = {
      "id": vtypobj.id,
      "countryId": vtypobj.countryId,
      "countryName": vtypobj.countryName,
      "countryCode": vtypobj.countryCode,
      "name": vtypobj.name,
      "Single": Single,
      "Double": Double,
      "Triple": Triple,
      "Quad": Quad,
      "Multiple": Multiple,
    };
    this.editarray.push(this.tempobj);
    console.info("this.tempobj",this.tempobj);
  }

  update_Visatype(updateobj) {
  
    this.UpdateArray = [];
    //console.log(updateobj);
    if (updateobj.Double === true || updateobj.Double === 'Double') {
      const obj = { "id": 0, "entries": "Double", "fk_VisaTypeId": 0 };
      this.UpdateArray.push(obj);
    } if (updateobj.Multiple === true || updateobj.Double === 'Multiple') {
      const obj = { "id": 0, "entries": "Multiple", "fk_VisaTypeId": 0 };
      this.UpdateArray.push(obj);
    } if (updateobj.Quad === true || updateobj.Quad === 'Quad') {
      const obj = { "id": 0, "entries": "Quad", "fk_VisaTypeId": 0 };
      this.UpdateArray.push(obj);
    } if (updateobj.Single === true || updateobj.Single === 'Single') {
      const obj = { "id": 0, "entries": "Single", "fk_VisaTypeId": 0 };
      this.UpdateArray.push(obj);
    } if (updateobj.Triple === true || updateobj.Triple === 'Triple') {
      const obj = { "id": 0, "entries": "Triple", "fk_VisaTypeId": 0 };
      this.UpdateArray.push(obj);
    }
    this.updatevisatypeObj = {
      "id": updateobj.id,
      "name": updateobj.name,
      "fk_CountryId": updateobj.countryId,
      "lstVisaTypeEntries": this.UpdateArray
    };
    //console.info(JSON.stringify(this.updatevisatypeObj));

    this._visaprocessing
      .update_VisaType(this.updatevisatypeObj)
      .subscribe(
        (data: any) => {
          this.Data = [];
          $('#editclose').click();
          this.GetVisaType_List(this.page, this.paginationSize,this.SearchKeyWord);
          var a = (data["messsage"]);
          if (a === "Record already exist!") {
            this.toastr.info(data["messsage"]);
          } else {
            this.toastr.success(data["messsage"]);
          }

        },
        (error: HttpErrorResponse) => {
          this.err = error;
          this.toastr.error("Error " + this.err.statusText);
        }
      );
  }
  openaddpopup() {
    this.visatypeObj.fk_CountryId = null;
    this.visatypeObj.visaType = null;
  }

  //#region -- search autocomplete --
  onSearch_autocomplete(values) {
    if (values !== "") {
      this._visaprocessing.CountryAutocomplete(values)
        .subscribe(
          (data: any) => {
            this.countryarray = [];
            this.countryarray.push(data.result);
            $("#countrysearch").autocomplete({   //  -------- Autocomplete populate --- 
              source: function (request, response) {
                response($.map(data.result, function (value, key) {
                  return {
                    label: value.name,
                    value: value.id,
                  };
                }));
              },
              focus: function (event, ui) {
                $('#countrysearch').val(ui.item.label);
                return false;
              },
              // Once a value in the drop down list is selected, do the following:
              select: function (event, ui) {
                // place the person.given_name value into the textfield called 'select_origin'...
                $('#countrysearch').val(ui.item.label);
                $('#selectedSearchId').val(ui.item.value);
                return false;
              },
            });
          },
          error => {
            this.err = error;
            this.toastr.error('Error :' + this.err.statusText);
          });
    }

  }
  //#endregion

  // #region --Search By country
  SearchbyCountry() {

    this.page=1;
    this.GetVisaType_List(this.page, this.paginationSize,this.SearchKeyWord);

    // this.searchString = $('#selectedSearchId').val();

    // if(this.searchString==""||this.searchString==null){

    //   this.toastr.error('Error : Country Not Found.');
    //   $("#visaLoader").hide();
    //   return false;
    // }


    // this.visatypelist = [];
    // $("#visaLoader").show();
    // this._visaprocessing.SearchbyCountry_visatype(this.searchString, this.page, this.paginationSize)
    //   .subscribe(
    //     data => {
    //       this.getFeeMaster = { data };
    //       this.visatypelist = this.getFeeMaster.data.result;
    //       // -- for pagination
    //       this.totalcount = this.getFeeMaster.data.totalCount;
    //       this.totalNoofpages = Math.ceil(this.getFeeMaster.data.totalCount / this.paginationSize);
    //       $("#visaLoader").hide();
    //       $('#selectedSearchId').val("");
    //     },
    //     error => {
    //       this.err = error;
    //       this.toastr.error('Error :' + this.err.statusText);
    //       $("#visaLoader").hide();
    //     }
    //   );
  }
  // #endregion
  myFunction() {
    this.searchString = "";
    $('#countrysearch').val("");
  }
  Reload() {
    this.SearchKeyWord='';
    this.ngOnInit();
  }

  //#region "--Pagination Code--"
  noofRecordstoDisplay(args: any) {
    // tslint:disable-next-line:radix
    this.paginationSize = parseInt(args.target.value);
    this.page = 1;// setting to default value
    this.pageShow = 0;
  }
  currentPageBind(currPage: any) {
    this.selectedIndex = currPage;
    this.page = currPage;
    $('ul.pagination li').removeClass('active');
    this.ngOnInit();
  }
  nextPage(nextpageNumber: number) {
    this.page = nextpageNumber + 1;
    if (this.page <= this.totalNoofpages) {
      this.pageShow = this.pageShow + 10;
    }
  }
  previousPage(prevPageNumber: number) {
    if (prevPageNumber >= 0) {
      this.page = prevPageNumber + 1;
      this.pageShow = this.pageShow - 10;
    }
  }
  noOfRecordsToShow(event: any) {
    this.paginationSize = parseInt(event.target.value);
    this.page = 1;
    this.GetVisaType_List(this.page, this.paginationSize,this.SearchKeyWord);
  }
  //#endregion

 getvisaTypeonChange($event)
{
  
   if($event!=null)
   {
          this.GetVisaType_List(this.page, this.paginationSize,this.SearchKeyWord);
          this.Getall_VisatypebyCountryCode($event.target.value);
        
        
   }
   
}

getvisaTypesearch()
{
  this.SearchKeyWord=this.visatypeid;
  this.GetVisaType_List(this.page, this.paginationSize,this.SearchKeyWord);
}

}

