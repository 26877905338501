import { Component, OnInit,ViewChild,ElementRef } from '@angular/core';
import { Router ,ActivationEnd} from '@angular/router';

import {GlobalServiceService} from '../../service/global-service.service';
import { AirindiaserviceService } from 'src/app/service/airindiaservice.service';
import { HttpErrorResponse } from '@angular/common/http/src/response';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
declare var $: any;
@Component({
  selector: 'app-dashboardview',
  templateUrl: './dashboardview.component.html',
  styleUrls: ['./dashboardview.component.css']
})

export class DashboardviewComponent implements OnInit {
  draftApps:string='';
  totalApps:string='';
  registrationCount:string='';
  totalLogins:string='';
  uniqueLogins:string='';
  totalOKTBApps:string='';
  

  durationfrom:string='';
  durationto:string='';
 SearchToday=false;
  constructor(private _router: Router, 
    private _toaster: ToastrService,private _airindiaService: AirindiaserviceService,public _globle:GlobalServiceService)  { }

  ngOnInit() {

  let  fromdate =new Date();
   let todate =new Date();
   this.SearchToday=true;
    this.getReport(this.dateFormatChange(fromdate),this.dateFormatChange(todate))
    $('#fromdate').hide();
    $('#todate').hide(); 
    $('#searchbyduration').hide(); 
    
    $('#quarterselection').hide();


    $('#fromdatecal').calendar({
      type: 'date',
      monthFirst: false,
      formatter: {
      date: function (date, settings) {
        if (!date) return '';
        var day = date.getDate();
        var month = date.getMonth() + 1;
        var year = date.getFullYear();
        return day + '/' + month + '/' + year;
      }
      }, maxDate: new Date()
      });

      $('#todatecal').calendar({
        type: 'date',
        monthFirst: false,
        formatter: {
        date: function (date, settings) {
          if (!date) return '';
          var day = date.getDate();
          var month = date.getMonth() + 1;
          var year = date.getFullYear();
          return day + '/' + month + '/' + year;
        }
        }, maxDate: new Date()
        });

  }

  onfilterquarter()
  {
 //   this.getReport('01-Jan-2023','01-Oct-2024')
    let fromdate ,todate,currdate;
    currdate=new Date();
    var quarter = (Math.floor(currdate.getMonth()/3))+1;
    debugger;
    if($('#filterquarter').val()=='Jan-Mar')
      {
        fromdate='01/01/YYYY';
        todate='03/31/YYYY';

if(quarter==1)
{
  //previous year
  let d ;
  d= new Date();
d.setFullYear(d.getFullYear() - 1);
  fromdate=fromdate.replace('YYYY',d.getFullYear());
  todate=todate.replace('YYYY',d.getFullYear());
}
else{
  //current year
  fromdate=fromdate.replace('YYYY',currdate.getFullYear());
  todate=todate.replace('YYYY',currdate.getFullYear());
}

this.getReport(fromdate,todate)

      }
      else if($('#filterquarter').val()=='Apr-Jun')
        {
          fromdate='04/01/YYYY';
          todate='06/30/YYYY';
  
  if(quarter<=2)
  {
    //previous year
    let d ;
    d= new Date();
  d.setFullYear(d.getFullYear() - 1);
    fromdate=fromdate.replace('YYYY',d.getFullYear());
    todate=todate.replace('YYYY',d.getFullYear());
  }
  else{
    //current year
    fromdate=fromdate.replace('YYYY',currdate.getFullYear());
    todate=todate.replace('YYYY',currdate.getFullYear());
  }
  
  this.getReport(fromdate,todate)
  
        }
        else if($('#filterquarter').val()=='Jul-Sep')
          {
            //fromdate='01-Jul-YYYY';
            //todate='30-Sep-YYYY';
            fromdate='07/01/YYYY';
            todate='09/30/YYYY';
    
    if(quarter<=3)
    {
      //previous year
      let d ;
      d= new Date();
    d.setFullYear(d.getFullYear() - 1);
      fromdate=fromdate.replace('YYYY',d.getFullYear());
      todate=todate.replace('YYYY',d.getFullYear());
    }
    else{
      //current year
      fromdate=fromdate.replace('YYYY',currdate.getFullYear());
      todate=todate.replace('YYYY',currdate.getFullYear());
    }
    
    this.getReport(fromdate,todate)
    
          }
          else if($('#filterquarter').val()=='Oct-Dec')
            {
             // fromdate='01-Oct-YYYY';
              //todate='31-Dec-YYYY';
              fromdate='10/01/YYYY';
              todate='12/31/YYYY';
      
      if(quarter<=4)
      {
        //previous year
        let d ;
        d= new Date();
      d.setFullYear(d.getFullYear() - 1);
        fromdate=fromdate.replace('YYYY',d.getFullYear());
        todate=todate.replace('YYYY',d.getFullYear());
      }
      else{
        //current year
        fromdate=fromdate.replace('YYYY',currdate.getFullYear());
        todate=todate.replace('YYYY',currdate.getFullYear());
      }
      
      this.getReport(fromdate,todate)
      
            }



  }
  

  onfilterchange()
  {
    this.SearchToday=false;
    $("#visaLoader").show();
    $('#quarterselection').hide();
    if($('#filtercriteria').val()=='DateRange')
    {
      $('#fromdate').show();
      $('#todate').show();
      $('#searchbyduration').show(); 

      $('#fromdatecal').calendar({
        type: 'date',
        monthFirst: false,
        formatter: {
        date: function (date, settings) {
          if (!date) return '';
          var day = date.getDate();
          var month = date.getMonth() + 1;
          var year = date.getFullYear();
          return day + '/' + month + '/' + year;
        }
        }, maxDate: new Date()
        });
  
        $('#todatecal').calendar({
          type: 'date',
          monthFirst: false,
          formatter: {
          date: function (date, settings) {
            if (!date) return '';
            var day = date.getDate();
            var month = date.getMonth() + 1;
            var year = date.getFullYear();
            return day + '/' + month + '/' + year;
          }
          }, maxDate: new Date()
          });

    }
else{
 // this.getReport('01-Jan-2023','01-Oct-2024')
let fromdate ,todate,currdate;
currdate =new Date();
  if($('#filtercriteria').val()=='Today')
  {
    this.SearchToday=true;
    fromdate =new Date();
    todate =new Date();
    this.getReport(this.dateFormatChange(fromdate),this.dateFormatChange(todate))
  }else if($('#filtercriteria').val()=='LastWeek')
  {
   // var diff = currdate.getDate() - currdate.getDay() + (currdate.getDay() === 0 ? -6 : 1);
    var diff = currdate.getDate() - currdate.getDay() + (currdate.getDay() === 0 ? -12 : -6);  
    debugger;
    fromdate=new Date(currdate.setDate(diff)); 
    var lastday = currdate.getDate() - (currdate.getDay() - 1) + 6;  
    todate=new Date(currdate.setDate(lastday));  
  
    this.getReport(this.dateFormatChange(fromdate),this.dateFormatChange(todate));
  }
  else if($('#filtercriteria').val()=='LastMonth')
    {      
       currdate.setDate(1);
       todate=new Date( currdate.getTime()- (1 * 24 * 60 * 60 * 1000));
       fromdate=new Date( currdate.setMonth(currdate.getMonth()-1));
debugger;
     this.getReport(this.dateFormatChange(fromdate),this.dateFormatChange(todate));
    }
    else if($('#filtercriteria').val()=='LastQuarter')
      {
        $('#quarterselection').show();
        this.onfilterquarter();
        
      }


  $('#fromdate').hide();
  $('#todate').hide(); 
  $('#searchbyduration').hide(); 
}
$("#visaLoader").hide();

  }


  searchbyduration()
  {
    let fromdate ,todate;
    fromdate=new Date(this.dateFormatChangeddmmyyyy($('#dtfromdate').val()));
    todate=new Date(this.dateFormatChangeddmmyyyy($('#dttodate').val()));
    this.getReport(this.dateFormatChange(fromdate),this.dateFormatChange(todate));

  }

  dateFormatChangeddmmyyyy(dateData) {
    if (dateData!="" && dateData!="Invalid date" && dateData!=null) {
      return dateData.split('/')[1] + '/' + dateData.split('/')[0] + '/' + dateData.split('/')[2];
    }
  }

  dateFormatChange(dateData) {
  
console.info(dateData);
    var day = dateData.getDate();
    var month = dateData.getMonth() + 1;
    var year = dateData.getFullYear();
    return month + '/' + day + '/' + year;
  }


  getReport(fromdate,todate)
  {
this.durationfrom=fromdate;
this.durationto=todate;
    this._airindiaService._aixdashboardreport(fromdate,todate)  
      .subscribe(
      (SuccesData:any) => { 
        let data=SuccesData.result;
        this.draftApps=data.draftApps;
        this.registrationCount=data.registrationCount;
        this.totalApps=data.totalApps;
        this.totalLogins=data.totalLogins;
        this.uniqueLogins=data.uniqueLogins;
this.totalOKTBApps=data.totalOKTBApps;
       
        console.info(SuccesData);
        
      },
    (error: HttpErrorResponse) => {
    
    });

  }


}
