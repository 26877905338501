import { Component, OnInit } from '@angular/core';
import { Route, ActivatedRoute, Router,RouterEvent, NavigationEnd} from '@angular/router';
import { AddVisaService } from '../../service/addVisaSerivce';
import { HttpClient, HttpHeaders , HttpRequest,HttpResponse,HttpHeaderResponse} from '@angular/common/http';
import {formatDate, Location} from '@angular/common';
import { forEach } from '@angular/router/src/utils/collection';

@Component({
  selector: 'app-taxinvoice',
  templateUrl: './taxinvoice.component.html',
  styleUrls: ['./taxinvoice.component.css']
})
export class TaxinvoiceComponent implements OnInit {
  printContents: any;
  popupWin: any;

  printVisaData: any=[];
  objDisplayData: any=[];
  visaFee:any;
  MiscFee:any;
  TotalFee:any;
  SGst:any;
  CGST:any;
  ServiceFee:any;
  discountSrvchrge:any;
  GTotal:any;
  GrandTotal: any;
  print:any;
  vfsFees:any;
  currentUrl:any;
  previousUrl:any;
  currentDate:any;
  TotalDiscount: any;
  TotalDiscount1:any;
  localData:any;
  userName:any;
  PassangerData:any;
  PassTotalFee:any;
  PassServiceFee:any;
  lstVisaVFSVASFees:any=[];
  markupbasefire:any=0;
  constructor(private _http: HttpClient, private _visaService:AddVisaService, private route: ActivatedRoute,private _location:Location) {

  }


  ngOnInit() {


    // console.info('This is invoice data1==',this.route);
    this.route.queryParams.subscribe(params => {
      let id = JSON.parse(params["id"]);

      console.info('This is invoice data1==',id);

      this._visaService.GetPassangerData(id)
      .subscribe(data=>{
        this.PassangerData= data;
        console.info("datapass==",this.PassangerData.result);
      });

      this._visaService._getPrintVisaById(id)
      .subscribe(data=>{
          this.printVisaData= data;
          this.objDisplayData= this.printVisaData.result;

         // console.info('this.objDisplayData.lstVisaPassangerDetail=',this.objDisplayData);
          this.lstVisaVFSVASFees = this.objDisplayData.lstVisaVFSVASFees;

        let value = this.objDisplayData.lstApplicationStatus;
        value.forEach(childObj=> {
         console.info('fee===',childObj);

         if(childObj.fK_StatusMasterId==1 || childObj.fK_StatusMasterId==15)
         {
          this.markupbasefire=childObj.markupOnBasefare!=null ? childObj.markupOnBasefare: 0;
          this.visaFee=childObj.visaFees+parseInt(this.markupbasefire);
          this.vfsFees=childObj.vfsFees;
          this.ServiceFee=childObj.serviceCharges;
          this.discountSrvchrge=childObj.discountedServCharge;
          

          if(childObj.discountedServVal>0){
            this.TotalDiscount=parseInt(childObj.discountedServVal)*this.PassangerData.result.length;
          }
          else{
            this.TotalDiscount=0;
          }

          this.TotalFee= parseInt(this.visaFee) + parseInt(this.vfsFees) ;

          let totalvfsvasfees=0;
          let totalvfsvasfeesAr = [];
          totalvfsvasfeesAr = this.lstVisaVFSVASFees.map(function(row) {
            return row["totalVFSVASAmt"];
         });
         if(totalvfsvasfeesAr.length>0)
           totalvfsvasfees=eval(totalvfsvasfeesAr.join("+"));

          let totalmiscfees=0;
          let totalmiscfeesAr=this.objDisplayData.lstVisaMiscFees.map(function(row) {
            return row["feeAmount"];
         });
         if(totalmiscfeesAr.length>0)
           totalmiscfees=eval(totalmiscfeesAr.join("+"));

          if(!this.objDisplayData.customerSEZ){
            this.SGst= ((((this.ServiceFee)*this.PassangerData.result.length)+totalmiscfees-((this.TotalDiscount)))*9)/ 100;
            this.CGST= ((((this.ServiceFee)*this.PassangerData.result.length)+totalmiscfees-((this.TotalDiscount)))*9)/ 100;
          }else{
            this.SGst = 0;
            this.CGST = 0;
          }

          var Passtotalmiscfees=totalmiscfees;
          this.PassTotalFee=this.TotalFee*this.PassangerData.result.length;
          this.PassServiceFee=this.ServiceFee*this.PassangerData.result.length;

          //this.GrandTotal= (totalmiscfees+parseInt(this.TotalFee) + parseInt(this.ServiceFee +parseInt(this.SGst) + parseInt(this.CGST))- parseInt(this.TotalDiscount));
          this.GrandTotal= Math.round((totalvfsvasfees + Passtotalmiscfees+this.PassTotalFee + this.PassServiceFee +this.SGst + this.CGST- this.TotalDiscount));

          this.ConvertString(this.GrandTotal);
         }
       })



      })




      var month= ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

      let date = new Date();
      let dd=date.getDate();
      let mm=date.getMonth();
      let yy=date.getFullYear();

      this.currentDate=dd+"-"+month[mm]+"-"+yy;

      this.localData = JSON.parse(localStorage.getItem('currentUser'));
      this.userName=this.localData.userName;
    // console.info("userId = "+this.userName);



    })
    //});TextInvoiceGetById

  }




 printTaxInvoice()
{

    this.printContents, this.popupWin;
    this.printContents = document.getElementById('TaxInvoice_Print').innerHTML;
    this.popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    this.popupWin.document.open();
    this.popupWin.document.write(`
      <html>
        <head>
          <title>Print tab</title>
          <meta charset="utf-8">
          <meta name="viewport" content="width=device-width, initial-scale=1">
          <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.0/css/bootstrap.min.css">
          <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js"></script>
          <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.0/js/bootstrap.min.js"></script>
          <style>
          .table>tbody>tr>td, .table>tbody>tr>th, .table>tfoot>tr>td, .table>tfoot>tr>th, .table>thead>tr>td, .table>thead>tr>th{
            border-top: 1px solid grey;
          }
          </style>
        </head>
    <body onload="window.print();window.close();">${this.printContents}</body>
      </html>`
    );
    this.popupWin.document.close();
}





//Convert Amount in Word
numberToEnglish( n ) {

  var string = n.toString(), units, tens, scales, start, end, chunks, chunksLen, chunk, ints, i, word, words, and = 'and';

  /* Remove spaces and commas */
  string = string.replace(/[, ]/g,"");

  /* Is number zero? */
  if( parseInt( string ) === 0 ) {
    return 'zero';
  }

  /* Array of units as words */
  units = [ '', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen' ];

  /* Array of tens as words */
  tens = [ '', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety' ];

  /* Array of scales as words */
  scales = [ '', 'thousand', 'million', 'billion', 'trillion', 'quadrillion', 'quintillion', 'sextillion', 'septillion', 'octillion', 'nonillion', 'decillion', 'undecillion', 'duodecillion', 'tredecillion', 'quatttuor-decillion', 'quindecillion', 'sexdecillion', 'septen-decillion', 'octodecillion', 'novemdecillion', 'vigintillion', 'centillion' ];

  /* Split user arguemnt into 3 digit chunks from right to left */
  start = string.length;
  chunks = [];
  while( start > 0 ) {
    end = start;
    chunks.push( string.slice( ( start = Math.max( 0, start - 3 ) ), end ) );
  }

  /* Check if function has enough scale words to be able to stringify the user argument */
  chunksLen = chunks.length;
  if( chunksLen > scales.length ) {
    return '';
  }

  /* Stringify each integer in each chunk */
  words = [];
  for( i = 0; i < chunksLen; i++ ) {

    chunk = parseInt( chunks[i] );

    if( chunk ) {

      /* Split chunk into array of individual integers */
      ints = chunks[i].split( '' ).reverse().map( parseFloat );

      /* If tens integer is 1, i.e. 10, then add 10 to units integer */
      if( ints[1] === 1 ) {
        ints[0] += 10;
      }

      /* Add scale word if chunk is not zero and array item exists */
      if( ( word = scales[i] ) ) {
        words.push( word );
      }

      /* Add unit word if array item exists */
      if( ( word = units[ ints[0] ] ) ) {
        words.push( word );
      }

      /* Add tens word if array item exists */
      if( ( word = tens[ ints[1] ] ) ) {
        words.push( word );
      }

      /* Add 'and' string after units or tens integer if: */
      if( ints[0] || ints[1] ) {

        /* Chunk has a hundreds integer or chunk is the first of multiple chunks */
        if( ints[2] || ! i && chunksLen ) {
          words.push( and );
        }

      }

      /* Add hundreds word if array item exists */
      if( ( word = units[ ints[2] ] ) ) {
        words.push( word + ' hundred' );
      }

    }

  }

  return words.reverse().join( ' ' );

}



 ConvertString(v) {
var sep = ('string'==typeof v)?'"':'';
 this.print= this.numberToEnglish(v).toUpperCase();

}

backPage(){
  this._location.back();
}


}

