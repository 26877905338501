import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})

export class UsermanagementService {
  pagesize: number = 0;
  page: number = 0;
  flag: boolean = true;
  uid: any;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    })
  };
  localData: any = {};
  token: string = null;
  userid: string = null;
  constructor(private _http: HttpClient) {
    this.localData = JSON.parse(localStorage.getItem('currentUser'));
    this.token = this.localData.token;
    this.userid = this.localData.userid;
  }

  // ----> Get user by Id -- Created By : Prashant
  getUserbyId(id) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    if (id != null) {
      return this._http.get(environment.ApiUrl + '/UserDetails/GetAll?page=' + this.page + '&pageSize=' + this.pagesize + '&Flag=' + this.flag + '');
    } else {
      return ('Error while getting userId.');
    }

  }
  // ---> For  -- Created By : Prashant
  getallusers(page, pagesize, SearchData) {

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    this.page = page;
    this.pagesize = pagesize;
    this.flag = true;
    return this._http.get(environment.ApiUrl + '/UserDetails/GetAll?page=' + this.page + '&pageSize=' + this.pagesize + '&Flag=' + this.flag + '&SearchData='+SearchData, { headers: headers });
  }

  getAPIusers() {

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    this.page = 0;
    this.pagesize = 0;
    this.flag = false;
    return this._http.get(environment.ApiUrl + '/UserDetails/GetAll?page=' + this.page + '&pageSize=' + this.pagesize + '&Flag=' + this.flag + '&SearchData='+"" + '&getOnlyAPIUser='+true, { headers: headers });
  }

  // ---> For reset password of users  -- Created By : Prashant
  resetusers(userdetail,url) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    this.uid = null;
    //this.uid = userid;
    return this._http.post(environment.ApiUrl + '/UserDetails/ResetPassward?url='+url, userdetail, { headers: headers });
  }


  // ---> For delete password of users  -- Created By : Prashant
  deleteusers(userid) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    this.uid = null;
    this.uid = userid;
    return this._http.post(environment.ApiUrl + '/UserDetails/DeleteUser?id=' + this.uid, '', { headers: headers });
    // return this._http.post('http://192.168.60.122:85/api/UserDetails/DeleteUser?id=12','');
  }

  updateusers(userupdatemodel) {

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.post(environment.ApiUrl + '/UserDetails/Update', userupdatemodel, { headers: headers });
    // put(this.apiUrl + '/' + us.id, us)
    // return this._http.post('https://localhost:44375/api/UserDetails/Update',userupdatemodel,{ headers: headers});
  }

  createUsers(userupdatemodel,url) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.post(environment.ApiUrl + '/UserDetails/Create?url='+url, userupdatemodel, { headers: headers });
    // return this._http.post('https://localhost:44375/api/UserDetails/Create',userupdatemodel,{ headers: headers});
  }

  // ---> For Branch List -- Created By : Prashant
  getallbranch() {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    this.flag = false;
    return this._http.get(environment.ApiUrl + '/BranchMstr/GetAll?page=' + this.page + '&pageSize=' + this.pagesize + '&Flag=' + this.flag, { headers: headers });
  }
  // ---> For userType List -- Created By : Anil
  getallUserType() {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.get(environment.ApiUrl + '/UserDetails/GetAllUserType', { headers: headers });
  }


  UnlockUserApi(userid) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.get(environment.ApiUrl + '/UserDetails/UnlockUser?userid='+userid, { headers: headers });
  }


  getAgentbyId() {
   
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.get(environment.ApiUrl + '/B2BUserDetails/GetByusername?username='+this.userid);
  

  }


}
