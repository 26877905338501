import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})

export class ApplicationcountService { 
  private data: any;
  token: any;
  username: any;
  constructor(private _http: HttpClient) {
    // this.data = JSON.parse(localStorage.getItem('currentUser'));
    // if(this.data!=null){
    //   this.token = this.data.token;
    //   this.username = this.data.username;
    // }
   }

  getapplicationCountsapi() { 
    return false;
    this.data = JSON.parse(localStorage.getItem('currentUser'));
    if(this.data!=null){
      this.token = this.data.token;
      this.username = this.data.username;
    }  
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.get(environment.ApiUrl+ '/StatusMaster/CountMaster',{ headers: headers});
  }
}
