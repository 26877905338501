import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AirindiaserviceService {

  ApiUrl: string = null;
  flag = 'True';
  private data: any;
  token: any;
  userId: any;
  
  constructor(private _http: HttpClient,private _router: Router) { 

    this.ApiUrl = environment.ApiUrl;
    this.data = JSON.parse(localStorage.getItem('currentUser'));
    //this.token = '123';
    //this.userId = '1';
    if(this.data!=null)
    {
      this.token = this.data.token;
    this.userId = this.data.userId;

   
    }
  }

  _generateOTP(userObj) {
  
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    //headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    //return this._http.post(environment.ApiUrl + "/AgentPayment/Create", payObj, { headers: headers });

    
    return this._http.get(environment.ApiUrl + "/AI/GenerateOTP?userName="+userObj.userName+"&emailID="+userObj.emailID+"&mobileNo="+userObj.mobileNo+"&otpMode="+userObj.otpMode+"&loginID="+userObj.loginID);
  
  
  }
  _generateOTPLogin(userObj) {
  
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    //headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    //return this._http.post(environment.ApiUrl + "/AgentPayment/Create", payObj, { headers: headers });

    
    return this._http.get(environment.ApiUrl + "/AI/GenerateOTPLogin?emailID="+userObj.emailID);
  
  
  }


  

  _AuthenticateUser(userObj) {
  
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    //headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    //return this._http.post(environment.ApiUrl + "/AgentPayment/Create", payObj, { headers: headers });

    
    return this._http.get(environment.ApiUrl + "/AI/AuthenticateUser?userID="+userObj.userID+"&otp="+userObj.otp);
  
  
  }

  
  _AuthenticateUserLogin(userObj) {
  
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    //headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    //return this._http.post(environment.ApiUrl + "/AgentPayment/Create", payObj, { headers: headers });

    
    return this._http.get(environment.ApiUrl + "/AI/AuthenticateUserLogin?userID="+userObj.userID+"&otp="+userObj.otp);
  
  
  }

  _InsertB2BUserLogs(LogType,Action, UserID,UserType){
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);  

    return this._http.post(environment.ApiUrl + "/B2BUserDetails/InsertUserLog?LogType="+LogType+"&Action="+Action+"&UserID="+UserID+"&UserType="+UserType, { headers:headers});
  }

  _aixdashboardreport(fromdate,todate) {
  
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    //headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    //return this._http.post(environment.ApiUrl + "/AgentPayment/Create", payObj, { headers: headers });    
    return this._http.get(environment.ApiUrl + "/B2BVisaApplication/AIDashboardReport?AIuserid=1&FromDate="+fromdate+"&ToDate="+todate);
  
  
  }

}
