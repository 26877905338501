import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ClientsService } from '../service/clients.service';
import { Client, ClientDetails, CountryVendorMap, VisaCountryDocuments } from '../models/client';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { AddVisaService } from '../service/addVisaSerivce';
import { HttpErrorResponse, HttpHeaders, HttpClient } from '@angular/common/http';
import { GlobalServiceService } from '../service/global-service.service';
import { UsermanagementService } from '../service/userService';
import { Router } from '@angular/router';
import { VisaprocessingService } from '../service/visaProcessingService';

declare var $: any;

@Component({
  selector: 'app-client-form',
  templateUrl: './client-form.component.html',
  styleUrls: ['./client-form.component.css']
})

export class ClientFormComponent implements OnInit {

  usersObject = [];
  userlistarr = [];
  getCust: any = {};
  locationData: any = {};
  customerDetailsData: any = { result: {} };
  token: any;
  private localData: any;
  selectedUser: number;
  visaTypeData: any = {};
  visatypeArray: any = [];


  visaCountryData: any = [];
  visaOfficeData: any = [];
  clientName: string = "";
  CVMaps: number[] = [0];
  extraDocuments: string;
  clientsToDisplay: any[] = [];
  countryDocToDisplay: any[] = [];
  countryDocAll: any[] = [];

  countryForDocument: number;



  countryVendorMappingArray: CountryVendorMap[] = [
    {
      countryOptions: [],
      selectedCountry: null,
      selectedVendor: null,
      vendorOptions: [],
      visaTypes: null
    }

  ];

  visaObj: any = {
    visaCustomer: {},
    lstApplicationStatus: [],
    lstVisaMiscFees: [],
    lstVisaApplBranch: [],
    lstVisaPassangerDetail: []
  };


  getallCountry: any = [];
  countries: any = [];
  err: any = {};

  form: FormGroup

  constructor(
    private _visaService: AddVisaService,
    private _clientService: ClientsService,
    private _toastr: ToastrService,
    private formBuilder: FormBuilder,
    public _globle: GlobalServiceService,
    private _http: HttpClient,
    private _userService: UsermanagementService,
    private _visaprocessing: VisaprocessingService,

    private r: Router
  ) {
    this.localData = JSON.parse(localStorage.getItem('currentUser'));
    this.token = this.localData.token;
  }

  ngOnInit() {
    this.GetCountry();
    this.getLocationData();
    this.getAPIUsers();
  }

  getCustomerDeatilsPopUp() {
    this.getCust.pattern = $("#searchCustPattern").val();
  }

  getLocationData() {
    this._visaService._getLocationList()
      .subscribe(result => {
        this.locationData = result;
      },
        (err: HttpErrorResponse) => {
          console.error(err.message);    // SHOW ERRORS IF ANY.
        });
  }

  getCustDetailsById() {
    $("#btnGetICUSTDetails").prop("disable", true);
    $('#loaderGetDetails').show();
    this.getCust.locationName = this.locationData.result.find(x => x.id === this.getCust.locationId).erpLocCode;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    this._http.get(this._visaService.ApiUrl + "/VisaApplication/GetCustomer?Loc=" + this.getCust.locationName + "&pattern=" + this.getCust.pattern, { headers: headers })
      .subscribe((successData: any) => {
        $("#btnGetICUSTDetails").prop("disable", false);
        if (successData.status == true) {
          if (successData.result.responseData.length > 0) {
            $('#custDetailsTable').show();

            successData.result.responseData.forEach(element => {

              switch (element.customerGroup) {
                case "RI":
                  element.customerGroup = "RI";
                  break;
                case "RC":
                  element.customerGroup = "Riya Corporate";
                  break;
                case "RS":
                  element.customerGroup = "Riya Sub Agent";
                  break;
                case "RE":
                  element.customerGroup = "Riya SME";
                  break;
                case "RA":
                  element.customerGroup = "Riya Agent";
                  break;
                default:
                  element.customerGroup = "New Type";
                  break;

              }

            });
            this.customerDetailsData = successData.result.responseData;


          }
          else {
            this._toastr.warning('No Data Found for Given Search Result');
          }
          //console.log(successData);
        } else {
          this._toastr.warning(successData.messsage);
        }
        $('#loaderGetDetails').hide();
      },
        (err: HttpErrorResponse) => {
          $("#btnGetICUSTDetails").prop("disable", true);
          $('#loaderGetDetails').hide();
          if (err.status == 400) {
            this._toastr.error(err.statusText);
          } else {
            this._toastr.error(err.message);
          }
          console.error(err);    // SHOW ERRORS IF ANY.
        });
  }



  bindCustomerDetails(cust) {

    $('#cust-loc').val(this.getCust.locationName);
    this.visaObj.customerName = cust.name;
    this.visaObj.icust = cust.no_;
    this.visaObj.customerType = cust.customerType;
    this.visaObj.customerLocation = cust.location;
    this.visaObj.customerPhoneNumber = cust.phone;
    this.visaObj.customerEmail = cust.email;
    this.visaObj.customerGroup = cust.customerGroup;


    $('#transferBranch option[value="1101"]').prop('disabled', true);

    // if(this.visaObj.customerLocation=="BOM"){
    //   $('#transferBranch option[value="1101"]').prop('disabled',true);
    // }

    $('#CloseGetCustPop').click();
    //  $(".cancel-email").click(); // making empty of email ids textbox
    //  var emails=cust.email.split(";");
    //  for(var i=0;i<emails.length;i++){
    //   var getValue = emails[i];
    //   $('.all-mail').append('<span class="email-ids">' + getValue + ' <span class="cancel-email">x</span></span>');
    //  }

  }



  getAPIUsers() {
    // ---- Call all user data for grid binding ---
    //debugger;
    this._userService.getAPIusers().subscribe(
      data => {
        this.usersObject = [];
        this.userlistarr = [];
        console.log(data);
        this.usersObject.push(data);
        this.userlistarr = this.usersObject[0].result;                // --- Used in edit update delete -----userlistarr-----
      },
      error => {
        this.err = error;
        this._toastr.error('Error :' + this.err.statusText);
      }
    );
  };






  resetVariables(): void {
    this.clientName = "";
    this.CVMaps = [0];
    this.extraDocuments = "";
    this.countryVendorMappingArray = [
      {
        countryOptions: [],
        selectedCountry: null,
        selectedVendor: null,
        vendorOptions: [],
        visaTypes: ''
      }

    ];
  }

  addCountryVendorMap() {
    this.countryVendorMappingArray.push({ countryOptions: [], selectedCountry: null, selectedVendor: null, vendorOptions: [],visaTypes: null });
    this.CVMaps.push(this.CVMaps.length);
  }

  GetCountry() {
    this._visaService._getVisaFeesCountryList()
      .subscribe(result => {
        var data = { result };
        this.visaCountryData = data;
      },
        (err: HttpErrorResponse) => {
          console.error(err.message);    // SHOW ERRORS IF ANY.
        });
  }

  getVisaOfficeData(index: number) {
    this.countryVendorMappingArray[index].vendorOptions = [];
    this.countryVendorMappingArray[index].visaTypes ="";
    if(this.countryVendorMappingArray[index].selectedCountry != null){
    this._visaService._getVisaOfficeData(this.countryVendorMappingArray[index].selectedCountry, this.visaObj.fK_TransferBranchId, null)
      .subscribe((successData: any) => {
        if (successData.status == true) {
          this.countryVendorMappingArray[index].vendorOptions = successData.result;
        }
      },
        (err: HttpErrorResponse) => {
          console.error(err.message);    // SHOW ERRORS IF ANY.
        });
      }
  }



  saveClient() {
    let isValid = true;
    if(this.clientName == ""  || this.selectedUser == null ||  this.selectedUser == undefined
     || this.visaObj.icust == null ||  this.visaObj.icust == undefined){
      this._toastr.error("FIll in the form.");
      return;
     }
    const detailsObj: ClientDetails[] = [];
    this.countryVendorMappingArray.forEach(d => {
      if(d.selectedCountry == null || d.selectedVendor == null){
        isValid = false;
      }
      detailsObj.push({
        CountryId: d.selectedCountry,
        VendorId: d.selectedVendor,
        ClientsId: 0,
        Id: 0
      })
    });
    const obj = {
      Name: this.clientName,
      Details: detailsObj,
      Id: 0,
      Icust: this.visaObj.icust,
      UserDetailId: this.selectedUser
    }
    if(detailsObj.length == 0){
      this._toastr.error('Please add details.');
      return;
    }
    if(!isValid){
      this._toastr.error('Country and vendor should be mapped.');
      return;
    }
    this._clientService.save(obj).subscribe(d => {
      if (d.status == true) {
        this.r.navigate(['../Admin/Clients']);
      }else{
        this._toastr.error(d.message);
      }
    },
        (err: HttpErrorResponse) => {
        this._toastr.error(err.message);
          console.error(err.message);    // SHOW ERRORS IF ANY.
        });
  }



  getVisaTypeDetails(countryId, vendorId, map){
    this.countryVendorMappingArray[map].visaTypes ="";
    if(vendorId != null && countryId != null){
    this._visaprocessing._getVisaTypebyCountry(countryId, vendorId).subscribe(
      (data: any) => {
        if(data.success)
        this.visaTypeData = {};
        this.visaTypeData = data.result;
        this.countryVendorMappingArray[map].visaTypes = this.visaTypeData.map(q=>q.name).join(", ");
      },
      err => {
        this._toastr.error("Error :" + this.err.message);
      }
    );
    }
  }



  navigateBack(){
    this.r.navigate(['../Admin/Clients']);
  }
}