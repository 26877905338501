import { Component, OnInit } from "@angular/core";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpResponse
} from "@angular/common/http";
import { VisaprocessingService } from "src/app/service/visaProcessingService";
import { UsermanagementService } from 'src/app/service/userService';
import {
  FormsModule,
  NgModel,
  FormBuilder,
  FormGroup,
  Validators
} from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { Newappservice } from '../../service/Newapplication_Service';

declare var $: any;

declare var $: any;
@Component({
  selector: 'app-visa-office',
  templateUrl: './visa-office.component.html',
  styleUrls: ['./visa-office.component.css']
})
export class VisaOfficeComponent implements OnInit {
  VendorObject = [];
  getallCountry: any = [];
  countryList: any = {};
  OfficeList: any = {};
  err: any = {};
  branchlistarr = [];
  VendorListarr: any = [];
  newProccessingDays: any = {};
  SearchKeyWord:string='';
  selectedProcessing: any = [];
  VisaProcessingmodel = [];
  VisaProcessingobj = {};
  countryId: any;
  registerForm: FormGroup;
  submitted = false;
  visaTypeData: any = {};
  VisaTypecountryList: any = {};
  visatypeArray: any = [];
  onentriesChange: any;
  vendorarr: any = [];
  createvendorobj: { "id": number; "vendorCode": string; "vendorName": string; "fK_CountryMasterId": number; "lstVendorBranch":[],"old_vendor_code": string};
  VendorId: any;
  totalcount: any;
  servicechargearr: any = [];
  Entries: any;
  vendorArray: any = [];
  vendorBranchArray: any = [];
  messsage: any;
  countryarray; any = [];
  searchString: any;
  searchKeyWord:any;
  getFeeMaster: any = {};
    PageSizeArray: number[];
    fk_Countryid:any=0;
  constructor(
    private _http: HttpClient,
    private _visaprocessing: VisaprocessingService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    public _newappService: Newappservice,
    private _userService: UsermanagementService
  ) { }
  // -- Pagination --
  paginationSize = 10;
  page: number = 1;
  pageShow = 0;
  totalNoofpages: number = 0;
  selectedIndex: number = 0;
  
  ngOnInit() {
    
    this.allVendorList(this.page, this.paginationSize,this.SearchKeyWord);
    this.GetCountry();
    this.branchlistfn();

    this.newProccessingDays.countryCode = null;
    this.newProccessingDays.no_ = null;
    this.newProccessingDays.branchid=null;
    this.PageSizeArray = this._newappService.PageSizeArray;
  }
  // convenience getter for easy access to form fields
  get f() {
    return this.registerForm.controls;
  }

  addprocessing = function () {
    //this.registerForm.reset();
    this.submitted = false;
  };

  // All Vendor List
  allVendorList = function (page, paginationSize,SearchKeyWord) {
    this._visaprocessing
      .getall_VendorList(page, paginationSize,SearchKeyWord,this.fk_Countryid)
      .subscribe(
        data => {
          this.VendorObject = [];
          this.VendorObject.push(data);
          // --- Used in edit update delete -----VendorListarr-----
          this.VendorListarr = this.VendorObject[0].result;
          //addedd
          console.info(" this.VendorListarr===", this.VendorListarr);
          
          
          /////
          // -- for pagination
          this.totalcount = this.VendorObject[0].totalCount;
          this.totalNoofpages = Math.ceil(
            this.VendorObject[0].totalCount / this.paginationSize
          );
        },
        error => {
          this.err = error;
          this.toastr.error("Error :" + this.err.status);
        }
      );
  };

  // Get All Country list
  GetCountry() {
    this.newProccessingDays.no_ = null;
    this._visaprocessing.getCountry().subscribe(
      data => {
        this.getallCountry = { data };
        this.getallCountry = this.getallCountry.data;
      },
      error => {
        this.err = error;
        this.toastr.error("Error :" + this.err.message);
      }
    );
  }

  // Create Vendor
  CreateVendor() {
   // console.log(this.newProccessingDays);
    const VendorName = this.vendorArray.find(x => x.no_ === this.newProccessingDays.no_).name;
    const old_vendor_code = this.vendorArray.find(x => x.no_ === this.newProccessingDays.no_).old_vendor_code;
    const countryId = this.getallCountry.find(x => x.countryCode === this.newProccessingDays.countryCode).id;

    this.createvendorobj = {
      "id": 0,
      "vendorCode": this.newProccessingDays.no_,
      "vendorName": VendorName,
      "fK_CountryMasterId": countryId,
      "old_vendor_code": old_vendor_code,
      "lstVendorBranch" :this.vendorBranchArray
    };
    //console.log(JSON.stringify(this.createvendorobj));

    this._visaprocessing
      .createVendor(this.createvendorobj)
      .subscribe(
        data => {
          //const tempvendor_response = { data };
          this.allVendorList(this.page, this.paginationSize,this.SearchKeyWord);

          $('#addOfficeClose').click();
          var a = (data["messsage"]);
          if (a === "Record already exist!") {
            this.toastr.info(data["messsage"]);
          } else {
            this.toastr.success(data["messsage"]);
          }

          this.newProccessingDays.countryCode = null;
          this.newProccessingDays.no_ = null;
          this.newProccessingDays.VendorCode = null;
        },
        error => {
          this.err = error;
          this.toastr.error("Error :" + this.err.status);
        }
      );
  }

  // Get Country Code on change on country change
  //getCountry_changeonCreate = function (CountryCode) {
 
   
    //this.CountryCode = CountryCode;
   // this.getBranch_changeonCreate(CountryCode)
    


  //};
  
  
  // getBranch_changeonCreate = function (CountryCode) {
 
  //   console.info('Selected Country1====',CountryCode);

  //   if (CountryCode !== null) {
  //     //console.info('this.newProccessingDays.countryCode=='+CountryCode);
  //    // this.GetCountry();
  //     // this.getVisatypeByCountry(this.CountryCode); // Geting Visa Type
  //     console.info('Selected Country2====',CountryCode);
  //     this.getVendorByCountry(this.CountryCode); // Geting Visa office
  //   } else {
  //     this.newProccessingDays.visaType = null;
  //     this.newProccessingDays.no_ = null;
  //   }
  // };
  
  


  getVendor_changeonCreate() {

    this.newProccessingDays.VendorCode = this.newProccessingDays.no_;
    this.GetVendorBranches(this.newProccessingDays.countryCode,this.newProccessingDays.VendorCode)

    
  }

  // Get Visa office/Vendor by Country Code by ERP - first time create vendor
  getVendorByCountry  = function(countryCode) {
    this.vendorArray = [];

    //console.info('this.newProccessingDays.countryCode==22'+countryCode);


    if (countryCode != null) {
      console.log('vendor based on Country3==='+this.vendorArray);
      //console.info('this.newProccessingDays.countryCode==11'+countryCode);
       // Sending Blank String
      this._visaprocessing.GetVendorsByCountryLocation(countryCode).subscribe(
        (data: any) => {
          const vendor = data.result;
          this.vendorArray = vendor;
          console.log('vendor based on Country==='+this.vendorArray);
        },
        error => {
          this.err = error;
          this.toastr.error('Error :' + error.error.messsage);
        }
      );
    } else {
      this.newProccessingDays.visaType = null;
      this.newProccessingDays.no_ = null;
    }
  }
  
  // Get Vendor Branches by Country Code & Vendor Code by ERP 
  GetVendorBranches  = function(countryCode,VendorCode) {
    this.vendorBranchArray = [];
  
    //console.info('this.newProccessingDays.countryCode==22'+countryCode);


    if (countryCode != null && VendorCode != null) {
      this._visaprocessing.GetVendorBranches(countryCode,VendorCode).subscribe(
        (data: any) => {
          this.vendorBranchArray = data.result;
          console.log('vendor based on Country==='+this.vendorBranchArray);
        },
        error => {
          this.err = error;
          this.toastr.error('Error :' + error.error.messsage);
        }
      );
    } else {
      this.newProccessingDays.visaType = null;
      this.newProccessingDays.no_ = null;
    }
  }


  // on edit load on country change
  getCountry_changeonedit = function (countryID) {
    // passing to change on create function - getCountry_changeonCreate
    let CountryCode = null;    // For Country Id
    if (countryID !== "null") {
      CountryCode = this.getallCountry.find(x => x.id === countryID).countryCode;
      this.newProccessingDays.countryCode = CountryCode;
    } else {
      this.newProccessingDays.countryCode = null;
      this.newProccessingDays.no_ = null;
      this.editfee.vendorName = null;
      this.editfee.visaType = null;
    }

    // this.getCountry_changeonCreate();
  };


  // -- Delete Pop up ---
  deletevendor = function (vendorobj) {
 
    this.vendorarr = [];
    this.vendorarr.push(vendorobj);
  };

  // --Delete Confirm
  deleteConfirm = function (vendor) {
    this._visaprocessing._DeleteVendor(vendor.id)
      .subscribe(
        data => {
          if (data.status = true) {
            this.toastr.success(data["messsage"]);
            $('#closedelete1').click();
            $('#closedelete').click();
            // if (vendor.isActive === true) {
            //   $('#closedelete').click();
            //   $('.modal, .modal-backdrop').fadeOut();
            //   this.toastr.success('Delete Sucessfully');
            // }
            // if (vendor.isActive === false) {
            //   $('#closedelete1').click();
            //   $('.modal, .modal-backdrop').fadeOut();
            //   this.toastr.success('Restore successfully');
            // }
            this.allVendorList(this.page, this.paginationSize);
          }
        },
        (error: HttpErrorResponse) => {
          this.err = error;
          this.toastr.error('Error :' + this.err.statusText);
        }
      );
  };

  // ---- Pagination Code Start here ----
  noofRecordstoDisplay(args: any) {
    // tslint:disable-next-line:radix
    this.paginationSize = parseInt(args.target.value);
    this.page = 1;// setting to default value
    this.pageShow = 0;
  }
  currentPageBind(currPage: any) {
    this.selectedIndex = currPage;
    this.page = currPage;
    $('ul.pagination li').removeClass('active');
    this.ngOnInit();
  }
  nextPage(nextpageNumber: number) {
    this.page = nextpageNumber + 1;
    if (this.page <= this.totalNoofpages) {
      this.pageShow = this.pageShow + 10;
    }
  }
  previousPage(prevPageNumber: number) {
    if (prevPageNumber >= 0) {
      this.page = prevPageNumber + 1;
      this.pageShow = this.pageShow - 10;
    }
  }

  //#region -- search autocomplete --
  onSearch_autocomplete(values) {
    if (values !== "") {
      this._visaprocessing.CountryAutocomplete(values)
        .subscribe(
          (data: any) => {
            this.countryarray = [];
            this.countryarray.push(data.result);
            $("#countrysearch").autocomplete({   //  -------- Autocomplete populate --- 
              source: function (request, response) {
                response($.map(data.result, function (value, key) {
                  return {
                    label: value.name,
                    value: value.id,
                  };
                }));
              },
              focus: function (event, ui) {
                $('#countrysearch').val(ui.item.label);
                return false;
              },
              // Once a value in the drop down list is selected, do the following:
              select: function (event, ui) {
                // place the person.given_name value into the textfield called 'select_origin'...
                $('#countrysearch').val(ui.item.label);
                $('#selectedSearchId').val(ui.item.value);
                return false;
              },
            });
          },
          error => {
            this.err = error;
            this.toastr.error('Error :' + this.err.statusText);
          });
    }

  }
  //#endregion

  // #region --Search By country
  SearchbyCountry() {

    //this.SearchKeyWord = $('#countrysearch').val();
    //this.paginationSize = parseInt(event.target.value);
    this.page = 1;
    this.allVendorList(this.page, this.paginationSize,this.SearchKeyWord);

    // this.searchString = $('#selectedSearchId').val();
    // this.searchKeyWord
    // this.VendorListarr = [];
    // $("#visaLoader").show();
    // this._visaprocessing.SearchbyCountry_vendor(this.searchString, this.page, this.paginationSize)
    //   .subscribe(
    //     data => {
    //       this.getFeeMaster = { data };
    //       this.VendorListarr = this.getFeeMaster.data.result;
    //       // -- for pagination
    //       this.totalcount = this.getFeeMaster.data.totalCount;
    //       this.totalNoofpages = Math.ceil(this.getFeeMaster.data.totalCount / this.paginationSize);
    //       $("#visaLoader").hide();
    //     },
    //     error => {
    //       this.err = error;
    //       this.toastr.error('Error :' + this.err.statusText);
    //       $("#visaLoader").hide();
    //     }
    //   );
  }
  // #endregion
  myFunction() {
    this.searchString = "";
    $('#countrysearch').val("");
  }
  Reload() {
    this.SearchKeyWord='';
    this.ngOnInit();
  }
  noOfRecordsToShow(event: any) {
    this.paginationSize = parseInt(event.target.value);
    this.page = 1;
    this.allVendorList(this.page, this.paginationSize,this.SearchKeyWord);
  }


  branchlistfn = function () {
    // ---- Call all user data for grid binding ---
    this._userService.getallbranch()
      .subscribe(
        data => {
          this.branchlist = [];
          this.branchlist.push(data);
          // --- Used in edit update delete -----userlistarr-----
          this.branchlistarr = this.branchlist[0].result;
		  
		  //console.info('branchlistarr',this.branchlistarr);

        },
        (error: HttpErrorResponse) => {
          this.err = error;
          this.toastr.error('Error :' + this.err.statusText);
        }
      );
  };

  
  // -- Show vendor branch ---
  showvendorbranch = function (vendorobj) {
    this.GetExistVendorBranches(vendorobj.id)
 
  };

  // Get Vendor Branches by Country Code & Vendor Code by VisaVendor 
  GetExistVendorBranches  = function(id) {
    this.vendorBranchArray = [];
  
    if (id != null) {
      this._visaprocessing.GetExistVendorBranches(id).subscribe(
        (data: any) => {
          this.vendorBranchArray = data.result;
        },
        error => {
          this.err = error;
          this.toastr.error('Error :' + error.error.messsage);
        }
      );
    } else {
      this.newProccessingDays.visaType = null;
      this.newProccessingDays.no_ = null;
    }
  }

  getvisaTypeonChange($event)
  {
   
     if($event!=null)
     {
       this.fk_Countryid=$event.target.value;
      this.allVendorList(this.page, this.paginationSize,this.SearchKeyWord);
          
     }
    
     
  }

}
