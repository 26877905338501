import { Component, OnInit } from '@angular/core';
import { AddVisaService } from 'src/app/service/addVisaSerivce';
import { Route, ActivatedRoute, Router, NavigationExtras } from '@angular/router';
declare var $: any;
@Component({
  selector: 'app-b2b-app-success',
  templateUrl: './b2b-app-success.component.html',
  styleUrls: ['./b2b-app-success.component.css']
})
export class B2bAppSuccessComponent implements OnInit {

  private localData: any;
  token: any;
  
  appNo = '';
  status:any;
  successflag:any;
  draftflag:any;
  editVisaData:any={};
  visaUserType: string = null;
   pnrstring='';
   visa='';
  visaObj: any = {
    visaCustomer: {},
    lstApplicationStatus: [],
    lstVisaMiscFees: [],
    lstVisaApplBranch: [],
    lstVisaPassangerDetail:[],
    lstVisaVFSVASFees: [],
    VisaAppType: 'Other Application'
  };
  constructor(private _route: ActivatedRoute,private _visaService: AddVisaService,private _router: Router) { 
    //this.appNo = this._route.snapshot.queryParams.id;
    //this.status=this._route.snapshot.queryParams.status;
    this.appNo = localStorage.getItem('Appid');
    this.status=localStorage.getItem('PayStatus');
    this.visa=localStorage.getItem('visa');
    console.info(this.visa);
     if(this._route.snapshot.queryParams.visa=='oktb')
     {
      this.getoktbVisaById(this.appNo);
     }
     else{
      this.getEditVisaById(this.appNo);
     }
    
    $(".inner-content").css("padding", "92px 15px 0 15px");

  }

  ngOnInit() {
    this.localData = JSON.parse(localStorage.getItem('currentUser'));
    if(this.localData==null)
    {
      this._router.navigate(['/']);
    }
    else{
    this.token = this.localData.token;
    this.visaUserType = this.localData.userType;
    }
  }


  
  viewlistclick()
  {
    this._router.navigate([ this.visaUserType +'/Visalist']);
    

  }


  getoktbVisaById(editID){
    console.info("getoktbVisaById");
   
    // debugger;
    this._visaService._getOKTBVisaByRefId(editID)
    .subscribe(data=>{
     this.editVisaData = data;
     console.info(this.editVisaData)
      //  this.location =  this.editVisaData.result[0].customerLocation;
        debugger;
        this.visaObj =  this.editVisaData.result;

        for(var i=0;i<this.visaObj.length;i++)
        {
         if(this.pnrstring=='')
         {
           this.pnrstring =this.visaObj[i].riyaVisaPNR;
         }
         else{
           this.pnrstring =this.pnrstring+' , ' +this.visaObj[i].riyaVisaPNR;
         }
         
        }
        console.info( this.visaObj[0]);
if(this.visaObj[0].fK_VisaCountryMasterId=='1089'){
  this.successflag=true;
}else{

 if(this.status.toLowerCase()=='success')
 {
   this.successflag=true;
 }else{
   this.successflag=false;
   if(this.status.toLowerCase()=='draft')
   {
     this.draftflag=true;
   }
   else{
     this.draftflag=false;
   }
 }
}
        //debugger;
    })
  }


  getEditVisaById(editID){


    console.info("getEditVisaById");
   
     // debugger;
     this._visaService._getEditVisaByRefId(editID)
     .subscribe(data=>{
      this.editVisaData = data;
      console.info(this.editVisaData)
       //  this.location =  this.editVisaData.result[0].customerLocation;
         debugger;
         this.visaObj =  this.editVisaData.result;

         for(var i=0;i<this.visaObj.length;i++)
         {
          if(this.pnrstring=='')
          {
            this.pnrstring =this.visaObj[i].riyaVisaPNR;
          }
          else{
            this.pnrstring =this.pnrstring+' , ' +this.visaObj[i].riyaVisaPNR;
          }
          
         }
         console.info( this.visaObj[0]);
if(this.visaObj[0].fK_VisaCountryMasterId=='1089'){
   this.successflag=true;
}else{

  if(this.status.toLowerCase()=='success')
  {
    this.successflag=true;
  }else{
    this.successflag=false;
    if(this.status.toLowerCase()=='draft')
    {
      this.draftflag=true;
    }
    else{
      this.draftflag=false;
    }
  }
}
         //debugger;
     })
    }

}
