import { VFSVASFeesService } from './../../service/vfs-vfs.service';
import { Component, OnInit  } from '@angular/core';
// import { FormsModule } from '@angular/forms';
import { HttpClient, HttpHeaders , HttpRequest,HttpResponse,HttpHeaderResponse} from '@angular/common/http';
import { AddVisaService } from 'src/app/service/addVisaSerivce';
import {GlobalServiceService} from '../../service/global-service.service';
//import {CommonVisa} from 'src/app/CommonVisa2';

import { HttpErrorResponse } from '@angular/common/http/src/response';
//import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { Route, ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { IfStmt } from '@angular/compiler';
import { debug } from 'util';
import { TestBed } from '@angular/core/testing';
import { QueryValueType } from '@angular/core/src/view';

declare var $: any;
export enum visaStatusMaster {
  ReadyforSubmission=1,
  DocumentPending,
  ForReview,
  Submitted,
  InterviewScheduled,
  Approved,
  Rejected,
  Canceled,
  Collected,
  PendingatVisaConsolate,
  Deliver,
  ScheduleSubmission,
  Hold,
  Posted,
  NotPosted
}
@Component({
  selector: 'app-b2b-view-visa',
  templateUrl: './b2b-view-visa.component.html',
  styleUrls: ['./b2b-view-visa.component.css']
})
export class B2bViewVisaComponent implements OnInit {
  maxDate: Date;
  minDate: Date;
  nationality='';
  passportExipryMaxDate:Date;
  dobMinDate:Date;
  travellingMaxDate:Date;
  editVisaData:any={};
  PassangerData:any={};
  lstPassangerDetails:any={};
  getCust:any={};
  locationData:any={};
  countryData:any=[];
  countryDataedit:any=[];
  resData:any={};
  visaTypeData:any=[];
  visaOfficeData:any=[];
  visaMiscChargesData:any={};
  filteredVisaMiscChargesData:any={};
  visaVfsVasChargesData:any[]=[];
  visaOfficeBoyData:any={};
  customerDetailsData:any={result:{}};
  transferBranchData:any=[];
  containerClass:string;
  DiscountedServCharge:any;
  rbtDiscountedServCharge:any;
  misChagresAddedObj:any=[];
  visaProcessingOptData=[];
  professionList:any=[];
  profession='';
  visaCountry='';
  totalMiscCharges:number=0;
  totalVfsVasCharges:number=0;
  imageadd: any;
  location: string;
  CurrentApplicationStatus:string;
  riyaVisaPNR: string;
  lstVisaMiscFees:any={
    mscAmount1:null,
    mscAmount2:null,
    mscAmount3:null,
    mscAmount4:null,
    mscAmount5:null,
    mscAmount6:null
  };
  vfsVasFeesAdded:{
    id: number,
    vfsVasFeesId: number,
    quantity: number,
    amount: number
  }[]=[];
  addDoc:any=[
   { docName:"Document1"},
   { docName:"Document2"},
   { docName:"Document3"},
   { docName:"Document4"},
   { docName:"Document5"}
];
// scheduleInterview:Date=new Date();
  myDocFilesType:any={};
  myFiles:any=[];
  myBindFiles:any=[{}];
  visaEntriesData = [];

  addNewdocCount:number=0;
  dynamicDocCount=1;
  //public dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();
  visaObj:any={
    visaCustomer:{},
    lstApplicationStatus:[],
    lstVisaMiscFees:[],
    lstVisaApplBranch:[],
    lstVisaDocument:[],
    lstVisaVFSVASFees: []
  };
  tempVisaObj:any={};
  dateOfBirth:any={};
  imageShow: any= '';
  addNewMiscCount:number=0;
  addNewVfsVasCount:number=0;
  FK_StatusMasterId:number=0;
  DiscountedServiceCharges=0;
  public localData: any;
  token: any;
  visaUserType:string=null;
  resultarr = [];
  autocompletearr = [];
  deliveryPartnerData=[];
   Profession:any=[];
   Profession_auto: any=[];
  _statusMaster = visaStatusMaster;
  DiscountedServVal: any;
  visaCountryData: any=[];
  Cust_travelDate: any;
  Cust_dateOfBirth: any;
  Cust_passportExpiry: any;

  miscInfo:any = {
    feesDetails:[],
    scheduledSubmissionDetails:[],
    interviewScheduledDetails:[]
  };

  isDiscountedServValPercentage : boolean = true;
  formatedScheduleDate:any;
  scheduleDate : any;
  scheduleSubmissionLocation : string;
  scheduleInterviewDate : any;
  scheduleInterviewLocation : string;
  islocation : boolean = false;
  isdate : boolean = false;

  showServiceCategory:boolean = false;
  lstServiceCharge : any = [];
  categoryMasterId : any ;
  selectedservicecharge:any;
  creditLimit: number = null;

  constructor(private _http: HttpClient, private _visaService:AddVisaService,public _globle:GlobalServiceService, private _route: ActivatedRoute,private _toastr: ToastrService, private _router:Router
    ,private _VFSVASFeesService: VFSVASFeesService) {
    this.localData = JSON.parse(localStorage.getItem('currentUser'));
    this.token = this.localData.token;
    this.visaUserType=this.localData.userType;
    $(".inner-content").css("padding", "92px 15px 0 15px");
  }

  ngOnInit() {
   // this.getBranchData();
    this.getCountryData();
    this.GetAll_Profession();
  //this.getLocationData();
   //this.getTransferBranchData();
  // this.visaObj.fK_AppBranchId=1079;
   //this.getvisaMiscChargesData();
   //this.getOfficeBoyData();
   
    this.getEditVisaById(this._route.snapshot.queryParams.id);
  }

  GetAll_Profession()
  {
    this.professionList=[];
    this._visaService._getProfessionList('')
    .subscribe((professionList:any)=>{
      if(professionList.status==true){
       
        this.professionList=professionList.result;
      }})
  }
  getCountryData() {
    this._visaService._getCountryList()
      .subscribe(result => {
        var data = { result };
        console.info(data)
        this.countryData = data;
        //this.visaObj.visaCustomer.fK_NationalityMasterId=this.countryData.result.find(x=>x.countryCode=="IN").id; // for deafault country India
      },
        (err: HttpErrorResponse) => {
          console.error(err.message);    // SHOW ERRORS IF ANY.
        });

        
        /*this._visaService._getVisaFeesCountryList()
        .subscribe(result => {
          var data = { result };
          this.visaCountryData = data;
          this.visaObj.visaCustomer.fK_NationalityMasterId=this.countryData.result.find(x=>x.countryCode=="IN").id; // for deafault country India
        },
          (err: HttpErrorResponse) => {
            console.error(err.message);    // SHOW ERRORS IF ANY.
          });*/
  }

  getVisatypeByCountry() {
    this._visaService._getVisaTypebyCountry(this.visaObj.fK_VisaCountryMasterId)
      .subscribe((successData: any) => {
        //  this.visaTypeData={resData};
        if (successData.status == true) {
          this.visaTypeData = successData.result;
        }
      },
        (err: HttpErrorResponse) => {
          this.visaTypeData = [];
          console.log(err.message);    // SHOW ERRORS IF ANY.
        });
    // to get visa office  data based on country and branch selected
    // this.tempVisaObj.CountryCode=this.visaObj.visaCustomer.fK_NationalityMasterId;
    // this.getVisaOfficeData(this.tempVisaObj.CountryCode,this.tempVisaObj.fK_BranchMasterId);

  }


  getLocationData() {
    this._visaService._getLocationList()
      .subscribe(result => {
        this.locationData = result;
      },
        (err: HttpErrorResponse) => {
          console.error(err.message);    // SHOW ERRORS IF ANY.
        });
  }

  

  getTransferBranchData() {
    //this._visaService._getTransferBranchList()
    
    this._visaService._getBranchesByUser()
      .subscribe((successData: any) => {
        if (successData.status == true) {
           this.transferBranchData = successData.result;
           console.info(this.transferBranchData);
        }
        
      },
        (err: HttpErrorResponse) => {
          console.error(err.message);    // SHOW ERRORS IF ANY.
        });
  }

  getVisaOfficeData() {
   // debugger;
 /*
console.info("test for erp==",this.visaObj.fK_TransferBranchId);
   //$('#transferBranch').val(this.visaObj.FK_TransferBranchId);

   //this.visaObj.fK_TransferBranchId=$('#transferBranch').val();
   
   if((this.visaObj.fK_VisaTypeId==null || this.visaObj.fK_VisaTypeId==0)  && this.visaObj.fK_TransferBranchId>0) {
     this._toastr.error("Please select Visa Type First");
     this.visaObj.fK_TransferBranchId= {id:-1,Name:'Select Branch'};

     return false;
   }
   this.visaOfficeData=[];
   $('#visaLoader').show();

    this._visaService._getVisaOfficeData(this.visaObj.fK_VisaCountryMasterId,this.visaObj.fK_TransferBranchId,this.visaObj.fK_VisaTypeId)
      .subscribe((successData: any) => {
        $('#visaLoader').hide();
        if (successData.status == true) {
          this.visaOfficeData = successData.result;
        }
      },
        (err: HttpErrorResponse) => {
          $('#visaLoader').hide();
          console.error(err.message);    // SHOW ERRORS IF ANY.
        });*/
  }

  delUploadedDoc(documentName, documentCode){



    var delDocObj =  this.myFiles.find(x=>x.URL==documentCode);
 
    var delIndexPosition=this.myFiles.findIndex(x=>x.URL==documentCode);
 
    //console.info('delUploadedDoc====',delDocObj);
     if(delDocObj!=undefined){
 
     if(delIndexPosition>-1){
         this._visaService._delUploadedDocument(delDocObj.id,delDocObj.bucketName,delDocObj.fileName)
         .subscribe((successData:any)=>{
             if(successData.status==true){
                this._toastr.success(successData.messsage);
                $("input[name="+documentCode+"]").prop('checked', false);
                $('#imgDel-'+documentCode).hide();
                $('#imgUpload-'+documentCode).hide();
                $("#fileimg-" + documentCode).val('');
                $("#file-"+ documentCode).val(null);
                $("#fileimg-" + documentCode).hide();
                $("#file-"+ documentCode).parent().find('.fas').addClass('fa-upload');
                this.myFiles.splice(delIndexPosition, 1);  // delete   uploaded doc from object
               // console.log(this.myFiles);
             }
         },
         (err: HttpErrorResponse) => {
           this._toastr.error('Error in Deleting the Document!');
           console.log (err);    // SHOW ERRORS IF ANY.
         })
     }
 
 
   }else{
 
     this._toastr.success("Document Deleted Succssfully!");
 
 
     $("#file-"+ documentCode).parent().find('.fas').addClass('fa-upload');
 
 
     $('#imgUpload-'+documentCode).hide();
     $("#fileimg-" + documentCode).hide();
     $("#fileimg-" + documentCode).val('');
     $("#file-"+ documentCode).val(null);
 
     this.myFiles.splice(delIndexPosition, 1);  // delete  current  uploaded doc from object
   }
 
   $('.'+documentCode).hide();
 
   }

  getEditVisaById(editID){


    $('#visaLoader').show();
     // debugger;
     this._visaService._getEditVisaById(editID)
     .subscribe(data=>{
        $('#visaLoader').hide();
         //console.info("full data:",data);
         this.editVisaData = data;
         //this.riyaVisaPNR="123";
 
         this.location = this.editVisaData.result.customerLocation;
 
         this.visaObj = this.editVisaData.result;
        // this.countryData= this.visaObj.countryMaster;
  
  this.visaCountry= this.visaObj.countryMaster.name;
        //  console.info("visainfo===",   this.visaObj );
         this.miscInfo = this.editVisaData.result.miscInfo;
         //console.info(this.miscInfo);
         //console.info("locatn===",this.visaObj.customerLocation);
         $('#visaTransferBranch option[value="1101"]').prop('disabled',true);
 
         // if(this.visaObj.customerLocation=="BOM"){
         //   $('#visaTransferBranch option[value="1101"]').prop('disabled',true);
         // }
  //console.info("this.visaObj.lstApplicationStatus.statusName==",this.visaObj.lstApplicationStatus[0].statusName);
 
 
         this.getVisaProcessingOptions(1);
 
         //$("#riyaVisaPNR").text(this.visaObj.riyaVisaPNR);
 
 
         if(this.visaObj.visaCustomer.gender=="Male"){
           $('#visaCustMale').prop("checked", true);
         }
         else if(this.visaObj.visaCustomer.gender=="Female") {
           $('#visaCustFemale').prop("checked", true);
         }
        if(this.visaObj.impCase!=null)
        {
         if(this.visaObj.impCase=="No"){
           $('#IMPNo').prop("checked", true);
         }
         else if(this.visaObj.impCase.trim()=="CIP/VIP") {
           $('#IMPCIP_VIP').prop("checked", true);
         }
       }
       else {
         $('#IMPNo').prop("checked", true);
       }
 
        /* if(this.visaObj.fK_VisaCountryMasterId!=null){
          // this.getVisatypeByCountry()
            this.callCountryRelatedVisaAPIs(1);
         }
         if(this.visaObj.fK_VisaTypeId!=null){
           this.getVisaEntries();
         }
         debugger;*/

         this.getVisatypeByCountry() ;
         this.getVisaEntries();
         this.getVisaProcessingOptions()

         console.info("this.visaObj.lstVisaDocument==",this.visaObj.lstVisaDocument);
         if(this.visaObj.lstVisaDocument.length>0){
           this.bindVisaDocumets();
         }

if(this.visaObj.lstVisaPassangerDetail.length>0){

  
  debugger;
  this.nationality=this.countryData.result.find(x=>x.id==this.visaObj.lstVisaPassangerDetail[0].fk_NationalityId).nationality; 
//console.info(this.nationality);

if(this.visaObj.lstVisaPassangerDetail[0].fk_ProfessionId!=null)
{
  this.profession=this.professionList.find(x=>x.id==this.visaObj.lstVisaPassangerDetail[0].fk_ProfessionId).name; 
  //console.info(this.profession);
}


}



debugger;

         if(this.visaObj.lstApplicationStatus.length>0){
          
           this.CurrentApplicationStatus=this.visaObj.lstApplicationStatus[0].statusName;
         
           this.FK_StatusMasterId= this.visaObj.lstApplicationStatus.find(x=>x.currentStatus==true).fK_StatusMasterId;
          // if(this.FK_StatusMasterId==this._statusMaster.ForReview)
            var rbtDisCharges=this.visaObj.lstApplicationStatus.find(x=>x.fK_StatusMasterId==this._statusMaster.ForReview);
            if(rbtDisCharges!=undefined){
             this.rbtDiscountedServCharge=rbtDisCharges.discountedServCharge;
            }
           if((this.FK_StatusMasterId!= this._statusMaster.ForReview) && (this.FK_StatusMasterId!=this._statusMaster.DocumentPending)){
 
             $(document).ready(function() {
               //$(".input__field.input__field--custom").prop('readonly',true);
               $('.input__field--custom,.select-option select').attr("disabled", true);
               $('#dubaistatus').removeAttr('disabled');
               // $(':radio:not(:checked)').attr('disabled', true);
               $('input[name="Gender"]').attr('disabled', true);
               $('input[name="IMPCase"]').attr('disabled', true);
               $('#addPassanger').css('display',"none");
               $('.RemovePass').css('display',"none");
               $('.dynamic-Pax').attr('disabled', true);
             });
             if(this.FK_StatusMasterId==this._statusMaster.ScheduleSubmission|| this.FK_StatusMasterId==this._statusMaster.ReadyforSubmission){
               $(document).ready(function() {
               $("#office-boy").attr("disabled",false); // for enalble to edit office boy
               });
             }
           }
        //   this.tempVisaObj.isInterviewScheduleBeforeSubmit=this.visaObj.isInterviewScheduleBeforeSubmit;
           // var test={abc:this.visaObj.lstApplicationStatus};
           // if( this.FK_StatusMasterId==this._statusMaster.InterviewScheduled && (this.visaObj.lstApplicationStatus.find(x=>x.fK_StatusMasterId!=this._statusMaster.Submitted)!=undefined))
           // {
           //   this.tempVisaObj.isInterviewScheduleBeforeSubmit=true;
           // }
         }
          this.visaObj.travelDate = this._globle.changeDateFormat(this.visaObj.travelDate,"mm/dd/yyyy","dd/mm/yyyy");
          //this.visaObj.visaCustomer.dateOfBirth = this._globle.changeDateFormat(this.visaObj.visaCustomer.dateOfBirth,"mm/dd/yyyy","dd/mm/yyyy");
          //this.visaObj.visaCustomer.passportExpiry = this._globle.changeDateFormat(this.visaObj.visaCustomer.passportExpiry,"mm/dd/yyyy","dd/mm/yyyy");
 
         this.Cust_travelDate=this.visaObj.travelDate;
         //this.Cust_dateOfBirth=this.visaObj.visaCustomer.dateOfBirth;
         //this.Cust_passportExpiry=this.visaObj.visaCustomer.passportExpiry;
 
         $('#costcenter').val(this.visaObj.costCenter);
         $('#empcode').val(this.visaObj.empCode);
         $('#location').val(this.visaObj.location);
         $('#department').val(this.visaObj.department);
 
         $('#bpcode').val(this.visaObj.bpCode);
         $('#companycode').val(this.visaObj.companyCode);
 
          // if(this.visaObj.lstVisaApplBranch.length>0){
         //   fk_BranchMasterId
         // }
        
        this.tempVisaObj.fK_TransferBranchId = this.visaObj.fK_TransferBranchId;
         //this.visaObj.visaCustomer.dateOfBirth="02/02/2019";
         // alert(this.visaObj.visaCustomer.dateOfBirth);
       //  this.visaObj.visaCustomer.dateOfBirth=null;
       //  $('#dob').val(this.visaObj.visaCustomer.dateOfBirth.split('T')[0]);
         if(this.visaObj.visaCustomer==null){
           this.visaObj.visaCustomer={};
         }
         // this.visaAppDelivery.courierPartner = this.visaObj.courierPartner;
         // this.visaAppDelivery.deliveryRefNo = this.visaObj.deliveryReferenceNo;
    
         
         // else{
         //   $('#markuponbaseFees').val(this.miscInfo.feesDetails.markupOnBasefare);
         // }
         if(this.FK_StatusMasterId==12 || this.FK_StatusMasterId==5){
           if(this.miscInfo.feesDetails.serviceCharges == null){
             this.miscInfo.feesDetails.serviceCharges = 0;
           }
           if(this.miscInfo.feesDetails.discountedServVal == null){
             this.miscInfo.feesDetails.discountedServVal = 0;
           }
           var totalservcharge = this.miscInfo.feesDetails.serviceCharges - this.miscInfo.feesDetails.discountedServVal;
           if( totalservcharge == this.miscInfo.feesDetails.discountedServCharge){
             this.isDiscountedServValPercentage = true;
           }
           else{
             this.isDiscountedServValPercentage = false;
           }
 
           if(this.miscInfo.feesDetails.markupOnBasefare == null || this.miscInfo.feesDetails.markupOnBasefare==undefined){
             this.miscInfo.feesDetails.markupOnBasefare = 0;
           }
 
         }
     })
 
   }

   getVisaEntries() {
	  $(".visaType.required").fadeOut();
    if (this.visaObj.fK_VisaTypeId != null) {
      this._visaService._getEntriesbyCountryId(this.visaObj.fK_VisaTypeId)
        .subscribe((successData: any) => {
          if (successData.status == true) {
            this.visaEntriesData = successData.result;

           
            if(this.visaEntriesData.length==0){
              $("#visaEntries").attr('disabled',true);
            }else{
              $("#visaEntries").attr('disabled',false);
            }
          }
        },
          (err: HttpErrorResponse) => {
            $("#visaEntries").attr('disabled',false);
            this.visaEntriesData = [];
            console.log(err.message);    // SHOW ERRORS IF ANY.
          });
    }
    else {
      this.visaEntriesData = [];
    }
  }

  getVisaProcessingOptions(onload=0) {

    if(onload==0){
      this.visaObj.fK_MstProcessingOptionsId = '';

    }

    if(this.visaObj.fK_VisaCountryMasterId!=null && this.visaObj.entries!=null && this.visaObj.fK_VisaTypeId!=null && this.visaObj.fK_VendorId!=null){


    this._visaService._getVisaProcessingOptions(this.visaObj.fK_VisaCountryMasterId,this.visaObj.entries,this.visaObj.fK_VisaTypeId,this.visaObj.fK_VendorId)
      .subscribe((successData: any) => {
        if (successData.status == true) {

          this.visaProcessingOptData = successData.result;


          if(this.visaProcessingOptData.length==0){

            $("#mstprocessingOptions").attr('disabled',true);

          }else{

            // non-editable after readyforsubmission
                if((this.FK_StatusMasterId!= this._statusMaster.ForReview) && (this.FK_StatusMasterId!=this._statusMaster.DocumentPending)){
                  // $(':radio:not(:checked)').attr('disabled', true);

                  $("#mstprocessingOptions").attr('disabled',true);
                }else{

                  $("#mstprocessingOptions").attr('disabled',false);
                }
          }

        }else{

          this.visaProcessingOptData = [];
          $("#mstprocessingOptions").attr('disabled',true);
        }
      },
        (err: HttpErrorResponse) => {
          $("#mstprocessingOptions").attr('disabled',true);
          this.visaProcessingOptData = [];
          console.log(err);    // SHOW ERRORS IF ANY.
        });
      }
  }

  downloadDoc(documentCode){

    var downloadVisaDocument=this.visaObj.lstVisaDocument.find(x=>x.url==documentCode);
    if(downloadVisaDocument!=null){
       ;
      let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    // VisaApplDocument/DownloadVisaDoc?id=403 //DownloadSample //responseType 'blob'
        this._http.get(this._visaService.ApiUrl+"/VisaApplDocument/DownloadDocument?id="+downloadVisaDocument.id,
        { headers: headers , observe: 'response', responseType: 'arraybuffer'}, )
        .subscribe(res => {
           ;
          var filename=downloadVisaDocument.fileName.split('.')[0];
          var contentType = res.headers.get('content-type');
          if(contentType=="application/vnd.openxmlformatsofficedocument.spreadsheetml.sheet"){
            contentType="application/vnd.ms-excel";
          }
          var blob = new Blob([res.body], { type:contentType});
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob, filename);
          } else {
          var a = document.createElement('a');
          a.href = URL.createObjectURL(blob);
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          }
        },
         (err: HttpErrorResponse) => {
           this._toastr.error("Internal Server Error.")
          //this._toastr.error(err.message);
          console.error (err);    // SHOW ERRORS IF ANY.
        });
     }
  }
 
   bindVisaDocumets(){
     try{
 
           this.myFiles=[];
           var dynamicDOcChecking;
           for(var i=0;i<this.visaObj.lstVisaDocument.length;i++){
             var tempObj=this.visaObj.lstVisaDocument[i];
 
                 var obj={
                   id:tempObj.id,
                   URL:tempObj.url,
                   documentName:tempObj.documentName,
                   availability:tempObj.availability,
                   fileName:tempObj.fileName,
                   bucketName:tempObj.bucketName,
                   contentType:tempObj.contentType
            }
 
             this.myFiles.push(obj);
 //console.info(this._visaService._docList);
             if(this._visaService._docList.find(x=>x.docCode==tempObj.url)==undefined){
               dynamicDOcChecking=this.myFiles[i].documentName;
               //$("#file-"+tempObj.url).val(this.myFiles[i].documentName.remove('_'));
             }
             else{
               dynamicDOcChecking= this._visaService._docList.find(x=>x.docCode==tempObj.url).documentName;
             }
 console.info(dynamicDOcChecking)
             if(dynamicDOcChecking==undefined){
                 //  this.addDoc[this.addNewdocCount].documentName=tempObj.documentName.split('@@')[1];
                     this.addNewdocCount= this.addNewdocCount+1;
                     $('.'+tempObj.url).show();
                     $("#"+tempObj.url).val(tempObj.documentName);
                     if(tempObj.fileName!=null){
                      // tempObj.documentName=tempObj.documentName.split('@@')[0]+this.addNewdocCount;
 
                       $("#file-"+tempObj.url).parent().find('.fa-upload').removeClass('fa-upload').addClass('fa-file');
                       $("#imgDel-"+tempObj.url).show();
                       $("#imgUpload-"+tempObj.url).show();
                     }
                     $("input[name="+tempObj.url+"][value="+tempObj.availability+"]").attr('checked', true);
             }else{
               if(tempObj.fileName!=null){
                 // var abc= tempObj.fileName.split('.'); tempObj.contentType=='image/jpeg'
                 var string2='image';
 
                 $("#file-"+tempObj.url).parent().find('.fa-upload').removeClass('fa-upload').addClass('fa-file');
 
                 if(tempObj.contentType.indexOf(string2) != -1){
 
                  // console.info(string2 + " found");
 
                   $('#fileimg-'+tempObj.url).show();
                   $("#file-"+tempObj.url).parent().find('.fa-upload').removeClass('fa-upload');
                   //$('#fileimg-'+tempObj.url).attr('src', this._visaService.ApiUrl.split("/api")[0]+"/Upload/document/"+tempObj.bucketName+"/"+tempObj.fileName);
                   $('#fileimg-'+tempObj.url).attr('src', this._visaService.ApiUrl.split("/api")[0]+"/Upload/document/"+tempObj.bucketName+"/"+tempObj.fileName);
                 }else{
 
                   $('#fileimg-'+tempObj.url).hide();
 
                 }
 
                 $("#imgDel-"+tempObj.url).show();
                 $("#imgUpload-"+tempObj.url).show();
 
 
                 //console.info(" I am here for ==imgDel-"+tempObj.url);
               }
               $("input[name="+tempObj.url+"][value="+tempObj.availability+"]").attr('checked', true);
             }
 
 
             // $("input[name="+tempObj.documentName+"][value="+tempObj.availability+"]").prop("checked",true);
             // $("input[name=passport][value=Photocopy]").prop('checked', true);
             // $("#img-"+tempObj.documentName).attr('src', 'http://employees.riya.travel/Payroll/RTTIPL\Employeephoto\313406.jpg');
            //console.log(this.myFiles);
           }
 
       }catch(exception){
            console.error("exception log:"+exception);
        }
   }

}
