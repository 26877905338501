import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})

export class VFSVASFeesService {
    pagesize: number;
    page: number;
    uid: any;

    barcode: string;
    localData: any = {};
    token: string = null;
    flag: boolean = true;

    constructor(private _http: HttpClient) {
        this.localData = JSON.parse(localStorage.getItem('currentUser'));
        this.token = this.localData.token;
        // this.userId = this.data.userId;  
    }


    updateVFSVASFees(vfsVas) {
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
        return this._http.post(environment.ApiUrl + '/VfsVasFees/UpdateVfsVas',vfsVas, { headers: headers });
    }

    get(page, pagesize, SearchKeyWord,countryid) {
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
        this.page = page;
        this.pagesize = pagesize;
        return this._http.get(environment.ApiUrl + '/VfsVasFees/Get?page=' + this.page + '&pageSize=' + this.pagesize + '&Flag=' + this.flag + '' + '&searchKeyWord='+SearchKeyWord+'&countryid='+countryid, { headers: headers });
      }

      toggleVFSVASFees(id: number) {
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
        return this._http.get(environment.ApiUrl + '/VfsVasFees/ToggleVFSVASFees?id='+id, { headers: headers });
    }

    getForCountry(id) {
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
        return this._http.get(environment.ApiUrl + '/VfsVasFees/GetForCountry?id='+id, { headers: headers });
      }
}
