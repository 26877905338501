import { Component, OnInit } from '@angular/core';
import { Router ,ActivatedRoute} from '@angular/router';
import { AgentserviceService } from 'src/app/service/agentservice.service';
import { HttpErrorResponse } from '@angular/common/http/src/response';
import { ToastrService } from 'ngx-toastr';

import { HttpClient, HttpHeaders, HttpRequest, HttpResponse, } from '@angular/common/http';
declare var $: any;
@Component({
  selector: 'app-agent-reg',
  templateUrl: './agent-reg.component.html',
  styleUrls: ['./agent-reg.component.css']
})

export class AgentRegComponent implements OnInit {

  customerDetailsData: any = { result: {} };
  emailMsg: any;
  token: any;
  RVISUserFlag:boolean=false;
  
  RVISUserPwd:any;
  emailValidationStatus: boolean;
  icustValidationStatus: boolean;
  StateData: any = [];
  CityData: any = [];
  rvisagent:any=[];
  checkuser:any=[];
  agentFile=[];
  constructor(private _http: HttpClient,private _router: Router,  private _toastr: ToastrService,private _agentService: AgentserviceService ,
    private _route: ActivatedRoute) { }

  ngOnInit() {
    console.info('registration')
    this.getState();
    this.checkRVISUser();
    var self=this;

    $(".pan_number_star").fadeOut();
    $(".gstin_number_star").fadeOut();
    $(".pan_doc_star").fadeOut();
    $(".gstin_doc_star").fadeOut();


    $(document).on('change','.panocpath',function(event){
     
     // let this_id=$(this).attr('id');
      //console.info('upload');
      self.uploadpandoc(event);
     
  
      });

      $(document).on('change','.gstdocpath',function(event){
     
        // let this_id=$(this).attr('id');
         //console.info('upload');
         self.uploadGSTdoc(event);
        
     
         });

      
  }


  uploadpandoc(e)
  {

    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    var formData = new FormData();
    formData.append("PAN", e.target.files[0]);
    var docname="PAN";
    this._http.post(this._agentService.ApiUrl + "/VisaApplDocument/UploadAgentDocument", formData, { headers: headers } )
    .subscribe((successData:any) => {
      console.info(successData);
      if(successData.status==true)
      {
        var tempObj = {       
        
          documentName:docname,
         
          fileName:successData.result.fileName,
          bucketName:successData.result.bucketName,
          encryptionKey:successData.result.encryptionKey,
          contentType:successData.result.contentType,
        }
       
        this.agentFile.push(tempObj);
        console.info(this.agentFile);
      }
      else{
        //console.error(successData.message);  
        this._toastr.error(successData.messsage); 
        $('#pan_doc').val('');
      }
     
     // $('#view-doc_'+docid).show();
      
debugger;
    },   (err: HttpErrorResponse) => {
      console.error(err);    // SHOW ERRORS IF ANY.
    });

  }


  
  uploadGSTdoc(e)
  {

    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    var formData = new FormData();
    formData.append("GST", e.target.files[0]);
    var docname="GST";
    this._http.post(this._agentService.ApiUrl + "/VisaApplDocument/UploadAgentDocument", formData, { headers: headers } )
    .subscribe((successData:any) => {
      console.info(successData);
      if(successData.status==true)
      {
        var tempObj = {       
        
          documentName:docname,
         
          fileName:successData.result.fileName,
          bucketName:successData.result.bucketName,
          encryptionKey:successData.result.encryptionKey,
          contentType:successData.result.contentType,
        }
       
        this.agentFile.push(tempObj);
        console.info(this.agentFile);
      }
      else{
        //console.error(successData.message);  
        this._toastr.error(successData.messsage); 
        $('#gst_doc').val('');
      }
     
     // $('#view-doc_'+docid).show();
      
debugger;
    },   (err: HttpErrorResponse) => {
      console.error(err);    // SHOW ERRORS IF ANY.
    });

  }

checkRVISUser()
{
 
let code=this._route.snapshot.queryParams.actcode;
$('#visaLoader').show();
if(code!="" && code!=undefined )
{
  this._agentService.getRVISuser(code)
  .subscribe(result => {
    var data = { result };

    console.info(data.result)
debugger;
    if(data.result!=null )
    {
      this.rvisagent=data.result;
      console.info('rvisagent'+this.rvisagent)
      debugger;
if(this.rvisagent.result!=null)
{
  this._agentService.checkagentbyemail(this.rvisagent.result.emailID)
      .subscribe(checkresult => {
        this.checkuser = { checkresult };
        console.info(this.checkuser.checkresult.result)
if(this.checkuser.checkresult.result)
{
  //agent exists . can login
  $('#visaLoader').hide();
  this._router.navigate(['/']);
}
else{
  $("#name").val(this.rvisagent.result.firstName);
  $('#emailid').val(this.rvisagent.result.emailID);
 
  $('#designation').val(this.rvisagent.result.designation);
  
  $('#phoneno').val(this.rvisagent.result.mobileNo);
 
  $('#company').val(this.rvisagent.result.companyName);
  $('#gstin_number').val(this.rvisagent.result.gstNo);
  $('#refempcode').val(this.rvisagent.result.referralEmp);
  $('#refby').val(this.rvisagent.result.referredBy);
 $('#address').val(this.rvisagent.result.address);
 $('#emailid').prop('disabled',true );
 this.RVISUserPwd=this.rvisagent.result.rVkey
this.RVISUserFlag=true;

 $('#visaLoader').hide();

}
$('#visaLoader').hide();
      })
}
    else{
      $('#visaLoader').hide();
    }
    
    }
   
  },
    (err: HttpErrorResponse) => {
      console.error(err.message);    // SHOW ERRORS IF ANY.
    });
}
else{
  $('#visaLoader').hide();
}

//$('#visaLoader').hide();
}

loginlink()
{
  this._router.navigate(['/']);
}

getState() {
  this._agentService._getState()
    .subscribe(result => {
      var data = { result };
      console.info(data)
      this.StateData = data;
     
    },
      (err: HttpErrorResponse) => {
        console.error(err.message);    // SHOW ERRORS IF ANY.
      });


    }


    
getCity() {

  if($('#state').val()!="0")
  {
    this._agentService._getCity($('#state').val())
    .subscribe(result => {
      var data = { result };
      console.info(data)
      this.CityData = data;
     
    },
      (err: HttpErrorResponse) => {
        console.error(err.message);    // SHOW ERRORS IF ANY.
      });


  }
  else{
    this.CityData = [];
  }
  
    }


    loginidsameas()
    {
      if($('input[name=chkloginid]:checked').val()=="Yes")
      {
      $('#loginid').val($('#emailid').val())
      }
      else{
        $('#loginid').val('')
      }
    }

registeragent()
{
  //console.info('RVISUserFlag'+this.RVISUserFlag)
  //console.info('RVISUserPwd'+this.RVISUserPwd)
  var validcheck=true;

    $('.required').fadeOut();

    if($('input[name=radioicust]:checked').val()=="Yes" && $('#icust').val()=="")
    {
      $(".icust.required").fadeIn();
      validcheck = false;

    }

    if ($("#name").val() == "") {
      $(".name.required").fadeIn();
      validcheck = false;
    }
    if ($("#emailid").val() == "") {
      $(".emailid").fadeIn();
      validcheck = false;
    }
    if ($("#loginid").val() == "") {
      $(".loginid").fadeIn();
      validcheck = false;
    }


    
    if(this.emailValidationStatus==false)
    {
      $(".invalidemail.required").fadeIn();
      
      validcheck = false;
    }


    if ($("#phoneno").val() == "") {
      $(".phoneno").fadeIn();
      validcheck = false;
    }
    if ($("#company").val() == "") {
      $(".company").fadeIn();
      validcheck = false;
    }

    let approvalstatus=1;

    if($('input[name=radioicust]:checked').val()=="Yes")
    {

      if($('#icust').val()!="")
      {
        if( this.icustValidationStatus==true)
        {
          approvalstatus=2
        }
        else{
          $(".icustinvalid.required").fadeIn();
        
          validcheck = false;
        }
      }

    }
    else{
///Not registered customer
      if ($("#pan_number").val() == "") {
        $(".pan_number").fadeIn();
        $(".pan_number_star").fadeIn();
        validcheck = false;
      }

     
      if ($("#gstin_number").val() == "") {
        $(".gstin_number").fadeIn();
        $(".gstin_number_star").fadeIn();
        validcheck = false;
      }
  

      var docpanindex=this.agentFile.findIndex(x=>x.documentName=='PAN');

      if(docpanindex==-1)
      {
        $(".pan_doc").fadeIn();
        $(".pan_doc_star").fadeIn();
        validcheck = false; 
      }


      var docgstindex=this.agentFile.findIndex(x=>x.documentName=='GST');
      debugger;
      if(docgstindex==-1)
      {
        $(".gstin_doc").fadeIn();
        $(".gstin_doc_star").fadeIn();
        validcheck = false; 
      }


      approvalstatus=1
    }

    if(validcheck)
    {
      let stateid,cityid;
    
    if($('#state').val()!="0" && $('#state').val()!="")
    {
      stateid=$('#state').val();
    }

    if($('#city').val()!="0" && $('#city').val()!="")
    {
      cityid=$('#city').val();
    }

    $('#visaLoader').show();

let RVISuser=0;
if(this.RVISUserFlag==true)
{
  RVISuser=1;
}

let lstAgentDoc=[]

if(this.agentFile!=null )
{
  lstAgentDoc=this.agentFile
}

    var tempObj={
      "id": 0,
      "empCode": "12345",
      loginID:$('#loginid').val(),
      userName:$('#name').val(),
      loginAttempt:0,
      designation:$('#designation').val(),
      email:$('#emailid').val(),
      mobNo:$('#phoneno').val(),
      createdOn: new Date(),
      fK_UserTypeMasterId:2,
      isActive:0,
      customerName:$('#company').val(),
      gSTIN_Number:$('#gstin_number').val(),
      referralEmpcode:$('#refempcode').val(),
      referredBy:$('#refby').val(),
      address:$('#address').val(),
      approvalStatus:approvalstatus,
      fk_stateid:stateid,
      fk_cityid:cityid,
      icust:$('#icust').val(),
      panNumber:$('#pan_number').val(),
      RVISUser:RVISuser,
      lstAgentDocument:lstAgentDoc,
      password:this.RVISUserPwd
    }

    $('#btnregister').prop('disabled',true );

    this._agentService._registerAgent(tempObj)
    .subscribe((successData: any) => {
     
      $('#btnregister').prop('disabled', false);  

      //var temp = { successData };

      if(successData.status==true){
        this._toastr.info(successData.messsage);
        this.clearregistrationfields();
        this._router.navigate(['/']);
      }
      else{
        this._toastr.error(successData.messsage);   
      }

      $('#visaLoader').hide();

     
    },
    (err: HttpErrorResponse) => {
      $('#visaLoader').hide();
      console.error(err.message);    // SHOW ERRORS IF ANY.
    });



  }

}

clearregistrationfields()
{
  $('#emailid').val('');
  $('#name').val('');
  $('#loginid').val('');
  $('#icust').val('');
  $('#designation').val('');
  
  $('#phoneno').val('');
 
  $('#company').val('');
  $('#gstin_number').val('');
  $('#pan_number').val('');
  $('#refempcode').val('');
  $('#refby').val('');
 $('#address').val('');

 $('#address').val('');
 $('#state option:eq(0)').prop('selected', true)
 $('#city option:eq(0)').prop('selected', true)
}


getCustDetailsById() {
  
  this.icustValidationStatus=false;


  if($('#icust').val().length<=6)
  {
    this.icustValidationStatus=false;
    return;
  }

if($('#icust').val()!="")
{
   //$("#btnGetICUSTDetails").prop("disable",true);
   $('#visaLoader').show();
   //this.getCust.locationName=this.locationData.result.find(x=>x.id ===this.getCust.locationId).erpLocCode;
   let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
   headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
   this._http.get(this._agentService.ApiUrl + "/B2BVisaApplication/GetCustomer?Loc=" + $('#icust').val() + "&pattern=" + $('#icust').val(), { headers: headers })
     .subscribe((successData: any) => {
     // $("#btnGetICUSTDetails").prop("disable",false);
       if (successData.status == true) {
         if (successData.result.responseData.length > 0) {
           
          
           this.customerDetailsData = successData.result.responseData;
          // console.info(this.customerDetailsData);
 //console.info(this.customerDetailsData.email)
 debugger;
 if( this.RVISUserFlag==false)
  {
    $('#emailid').val(this.customerDetailsData[0].email);
    $('#phoneno').val(this.customerDetailsData[0].phone);

  }
         
           $('#gstin_number').val(this.customerDetailsData[0].gstNo);
           $('#company').val(this.customerDetailsData[0].name);
       
           this.icustValidationStatus=true;
         }
         else {
           this._toastr.warning('Invalid Customer Code');//No data found
           this.icustValidationStatus=false;
           if( this.RVISUserFlag==false)
           {
            $('#emailid').val('');
            $('#gstin_number').val('');
            $('#company').val('');
            $('#phoneno').val('');
           }

          
          
         }
         //console.log(successData);
       }else{        
         this._toastr.warning(successData.messsage);
         this.icustValidationStatus=false;
         if( this.RVISUserFlag==false)
         {
          $('#emailid').val('');
          $('#gstin_number').val('');
          $('#company').val('');
          $('#phoneno').val('');
         }
       }
       $('#visaLoader').hide();
     },
       (err: HttpErrorResponse) => {
         this.icustValidationStatus=false;
         //$("#btnGetICUSTDetails").prop("disable",true);
         $('#visaLoader').hide();
         if(err.status==400){
           this._toastr.error(err.statusText);
         }else{
           this._toastr.error(err.message);
         }
         console.error(err);    // SHOW ERRORS IF ANY.
       });

}
 
}


Customerdiv(flag)
{
  if(flag=='y')
  {
    $('#icustdiv').show();
    $(".pan_number_star").fadeOut();
    $(".gstin_number_star").fadeOut();
    $(".pan_doc_star").fadeOut();
    $(".gstin_doc_star").fadeOut();

  }
 else{
  $('#icustdiv').hide();
  $('#icust').val('');

  if( this.RVISUserFlag==false)
  {
    $('#emailid').val('');
    $('#gstin_number').val('');
    $('#company').val('');
    $('#phoneno').val('');
  }

  $(".pan_number_star").fadeIn();
  $(".gstin_number_star").fadeIn();
  $(".pan_doc_star").fadeIn();
  $(".gstin_doc_star").fadeIn();


 
 }
  
}
validateEmail(e)
{
  //var regExp = /^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$/;
  var regExp = /^(([^<>()\[\]\\.:\s@"]+(\.[^<>()\[\]\\.:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  //var regExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var emails =e.target.value;
  if(regExp.test(emails)){
    this.emailMsg = "";
    this.emailValidationStatus = true;
    //console.log(this.emailError);
  }else{
    this.emailMsg = "Invalid Email ID";
    this.emailValidationStatus = false;
  }
  //debugger;
}

}
