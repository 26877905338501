import { Component, OnInit ,Compiler } from '@angular/core';
import { Router } from '@angular/router';
//import { LocaleData } from 'ngx-bootstrap/chronos/public_api';
import { LoginService } from "../../service/login-service.service";
declare var $: any;
@Component({
  selector: 'app-header-menu',
  templateUrl: './header-menu.component.html',
  styleUrls: ['./header-menu.component.css']
})
export class HeaderMenuComponent implements OnInit {
  public localData: any;
  visaAdminUser = false;
  visaBranchManagerUser = false;
  visaDubaiManager = false;
  accountsUser = false;
  cashierUser:boolean = false;
  creditconrole:boolean = false;

  constructor(private _router: Router,private _compiler: Compiler,private _loginService:LoginService) {
  
    if (localStorage.getItem('adminUser')) {
      this.visaAdminUser = true;
    } else if (localStorage.getItem('branchManagerUser')) {
      this.visaBranchManagerUser = true;
    }else if(localStorage.getItem('DubaiManagerUser')){
      this.visaDubaiManager = true;
    }
    else if(localStorage.getItem('DubaiExecutiveUser')){
      this.visaDubaiManager = true;
    }
    else if(localStorage.getItem('AccountsUser')){
      this.accountsUser = true;
    }
    else if(localStorage.getItem('Cashier')){
      this.cashierUser = true;
    }
    else if(localStorage.getItem('CreditControl')){
      this.creditconrole = true;
    }
    this.localData = JSON.parse(localStorage.getItem('currentUser'));
    // this.token = this.localData.token;
    // this.visaUserType=this.localData.userType;  
  }

  ngOnInit() {
    // debugger;
    //     if (localStorage.getItem('adminUser')) {
    //       this.visaUserType=true;  
    //     }   

  }
  ngAfterViewInit() {
    $(document).ready(function () {
      // Menu Add Class Left
      $(".hamburger-icon").click(function () {
        $(".slide-menu").toggleClass("slide-left");
      });
      //Menu Add Class Close
      $('.slide-close-button button, .overlay-menu, .menu li').click(function () {
        $(".slide-menu").removeClass("slide-left");
      });

      $(".menu li a").click(function () {
        $(".left-nav a").removeClass('active');
      })
    });
  }
  Logout() {
    $("#visaLoader").show();
    this._loginService._logoutUser();
    $("#visaLoader").hide();
    // this._compiler.clearCache();
    // location.reload();
    // location.reload(true);
    // localStorage.clear();
    // this._router.navigate(['/']);
  }
}
