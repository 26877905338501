import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FeeMasterServiceService {
  // ApiUrl = 'http://192.168.60.101:83/api'; 
  pagesize: number;
  page: number;
  flag: Boolean = true;
  pid: any;
  localData: any = {};
  token: string = null;
  constructor(private _http: HttpClient) {
    this.localData = JSON.parse(localStorage.getItem('currentUser'));
    this.token = this.localData.token;
  }
  // Gete All Fee Master Data
  Getall_FeeMaster(page, pagesize,SearchKeyWord,countryid,visatypeid,Searchwithid,searchtype,vendorid) {

    // var searchid=0;
    // if(typeof search != 'undefined'){
    //   searchid=search;
    // }
   debugger;
    this.page = page;
    this.pagesize = pagesize;
    this.flag = true;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.get(environment.ApiUrl + '/VisaFeesMst/GetAll?page=' + this.page + '&pageSize=' + this.pagesize + '&Flag=' + this.flag + '&search='+SearchKeyWord+'&countryid='+countryid+'&visatypeid='+visatypeid+'&vendorid='+vendorid+'&Searchwithid='+Searchwithid+'&searchtype='+searchtype , { headers: headers });
  }

  // Delete Fee Master

  deleteServiceChargemaster(delId) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.post(environment.ApiUrl + '/MstServiceCharge/Delete?id=' + delId, '', { headers: headers });
  }

  // Edit Fees Master

  FeeMster_update() {
    // return this._http.post(environment.ApiUrl+'/VisaFeesMst/Update');
  }

  Getall_Country() {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.get(environment.ApiUrl + '/CountryMaster/GetAll', { headers: headers });
  }

  //Get All Service Charge Master
  Getall_ServiceCharge(page, pagesize,SearchKeyWord,countryid) {

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    this.page = page;
    this.pagesize = pagesize;
    this.flag = true;
    // http://visaapi.parikshan.net/api/MstServiceCharge/GetAll?page=1&pageSize=1&Flag=true
    return this._http.get(environment.ApiUrl + '/MstServiceCharge/GetAll?page=' + this.page + '&pageSize=' + this.pagesize + '&Flag=' + this.flag + '&SearchKeyWord='+SearchKeyWord+'&countryid='+countryid, { headers: headers });

  }

  // Update Service Charge
  _updteServiceCharge(svceupdate) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.post(environment.ApiUrl + '/MstServiceCharge/Update', svceupdate, { headers: headers });
  }


  // Get Visa Office

  Get_VisaOffice(CountryCode, LocationCode) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.get(environment.ApiUrl + '/ERPService/GetVendor?CountryCode=' + CountryCode + '&LocationCode=' + LocationCode + '', { headers: headers });
  }

  // Add Visa Office
  Create_FeeMaster(addFeeVisa) {
    debugger;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.post(environment.ApiUrl + '/VisaFeesMst/Create', addFeeVisa, { headers: headers });
  }

  Update_FeeMaster(Visaobj) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.post(environment.ApiUrl + '/VisaFeesMst/Update', Visaobj, { headers: headers });
  }

  _getVisaTypebyCountry(countryId) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.get(environment.ApiUrl + "/VisaType/GetVisaTypeName?CountryId=" + countryId, { headers: headers });
  }
  // Add Visa ProcessingOptions
  createProcessingOption(VisaProcessing) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.post(environment.ApiUrl + '/MstProcessingOption/Create', VisaProcessing, { headers: headers });
  }
  // Get all Options
  GetallOption(page, pagesize,countryid) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    this.page = page;
    this.pagesize = pagesize;
    this.flag = true;
    return this._http.get(environment.ApiUrl + '/MstProcessingOption/GetAll?page=' + this.page + '&pageSize=' + this.pagesize + '&Flag=' + this.flag + '&countryid='+countryid, { headers: headers });
  }
  // visa option update

  visaopt_update(updateObj) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.post(environment.ApiUrl + '/MstProcessingOption/Update', updateObj, { headers: headers });
  }

  // Get All Service Charge
  GetAllService(page, pagesize) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    this.page = page;
    this.pagesize = pagesize;
    this.flag = true;
    return this._http.get(environment.ApiUrl + '/MstServiceCharge/GetAll?page=' + this.page + '&pageSize=' + this.pagesize + '&Flag=' + this.flag + '', { headers: headers });
  }
  // Delete Fee Master

  deleteFeemaster(delId) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.post(environment.ApiUrl + '/VisaFeesMst/Delete?id=' + delId, '', { headers: headers });
  }
  SearchbyCountry(value, page, pagesize) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    this.page = page;
    this.pagesize = pagesize;
    this.flag = true;
    return this._http.get(environment.ApiUrl + '/MstProcessingOption/SearchByCountryId?page=' + this.page + '&pageSize=' + this.pagesize + '&Flag=' + this.flag + '&CountryId=' + value + '', { headers: headers });
  }

  CountryAutocomplete(value) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.get(environment.ApiUrl + '/GlobalSearch/CountryIntelisence?SearchText=' + value + '', { headers: headers });

  }

  // This method Used To display DeliveryMaster
  GetAll_Delivery() {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.get(environment.ApiUrl + '/DeliveryPartners/GetAll', { headers: headers });
  }
  Create_Adddelivery(objdelivery){

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.post(environment.ApiUrl + '/DeliveryPartners/Create', objdelivery, { headers: headers });
  }
  Update_DeliveryMaster(Deliupdate)
  {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.post(environment.ApiUrl + '/DeliveryPartners/Update', Deliupdate, { headers: headers });
  }
 
  deleteDeliverymaster(deliid) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.post(environment.ApiUrl + '/DeliveryPartners/Delete?id=' + deliid, '', { headers: headers });
  }
  getAutoSearch(SearchKeyWord,countryid,visatypeid) {

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.get(environment.ApiUrl + '/VisaFeesMst/getAutoSearchValue?countryid='+countryid+'&visatypeid='+visatypeid+'&SearchKeyword='+SearchKeyWord, { headers: headers });
  }
}


