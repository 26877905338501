import { Component, OnInit } from "@angular/core";
import { VisaFeesAutomationService } from 'src/app/service/visaFeesAutomation.service';
import { VisaprocessingService } from 'src/app/service/visaProcessingService';
import { ToastrService } from 'ngx-toastr';
import { Newappservice } from 'src/app/service/Newapplication_Service';


declare var $: any;

 @Component({
    selector: "app-visa-FeesAutomation",
    templateUrl: "./visa_fees_automation.component.html",
    styleUrls: ["./visa_fees_automation.component.css"],
  })
export class VisaFeesAutomationComponent implements OnInit {
    visaFeesAutomationData:any=[];
    VisaFeesDetails:any=[];
    getallCountry: any = [];
    selectedIndex: number = 0;
    getVisaTypeByCountryId: any = [];
    fk_Countryid:any=0;
    fk_VisaTypeid:any=0;
    err:any={};
    VisaFees:any=0;
    VisaFeeId:any=0;
    visaTypeData:any={};
    visatypeArray: any = [];
    PageSizeArray: number[];
    totalNoofpages: number=0;
    paginationSize = 10;
    page: number = 1;
    pageShow = 0;
    totalcount : number=0;
    totalNoofdetailspages: number=0;
    dPageShow= 0;
    dPage: number = 1;
    dPaginationSize= 10;
    dTotalcount: number=0;
    constructor (private toastr: ToastrService,private feesAutomationservice:VisaFeesAutomationService,private _visaprocessing: VisaprocessingService, public _newappService: Newappservice){}

    ngOnInit() {      
        this.PageSizeArray = this._newappService.PageSizeArray;
        this.clearmodal();
        this.GetFeesAutomation(this.page,this.paginationSize);
        this.GetCountry();
    }
    GetFeesAutomation(page,paginationSize)
    {
      debugger;
        this.feesAutomationservice.GetAllVisaFeesAutomation(this.fk_Countryid,this.fk_VisaTypeid,page,paginationSize).subscribe((data:any)=> {
            if(data.status==true)
            {
              this.visaFeesAutomationData=data.result;  
              this.totalcount = data.totalCount ;           
              this.totalNoofpages = Math.ceil(this.totalcount / this.paginationSize);
            } 
            else{
              console.info(data.Messsage);
            }
        
           },
           error => {
            this.err = error;
            this.toastr.error("Error :" + this.err.message);
          }
           )
    }
    clearmodal = function () {
      this.getallCountry=null;
      this.VisaFeesDetails=null;
      this.visatypeArray=null;
      this.visaFeesAutomationData=null;
      this.getVisaTypeByCountryId=null;
      this.fk_Countryid=0;
      this.fk_VisaTypeid=0;
      this.err=null;
      this.VisaFees=null;
      this.visaTypeData=null;
      
    }
    closeModel=function(){
      this.clearDetailsmodal();      
      $("#View-Fees-modal").modal("hide");
    }
    clearDetailsmodal = function () {
     this.VisaFeeId  =0;
     this.VisaFees=0;
     this.totalNoofdetailspages  =0;  
     this.dPageShow=0;
     this.dPaginationSize= 10;
     this.dTotalcount=0;
     this.dPage=1;
    }
    Reload() {
      this.ngOnInit();      
    }
    GetCountry() {
      this.feesAutomationservice.GetCountryByVisaFees().subscribe(
             data => {
          this.getallCountry = { data };
          this.getallCountry = this.getallCountry.data.result;
        },
        error => {
          this.err = error;
          this.toastr.error("Error :" + this.err.message);
        }
      );
    }
getvisaTypeonChange($event)
{
   if($event!=null)
   {
    $('ul.pagination li').removeClass('active')
    this.page = 1;
    this.GetFeesAutomation(this.page,this.paginationSize);
          this.getVisatypeByCountry($event.target.value);
   }
}
    getVisatypeByCountry(countryCode) {
      this.visatypeArray = [];
      if (countryCode != null) {
        this.feesAutomationservice.GetVisaTypeByVisaFees(countryCode).subscribe(
          (data: any) => {
            this.visaTypeData = {};
            this.visaTypeData = data.result;
            this.visatypeArray = this.visaTypeData;
            //console.log(this.visatypeArray);
          },
          err => {
            this.toastr.error("Error :" + this.err.message);
          }
        );
      }
    }
    getvisaTypesearch()
    {   
      $('ul.pagination li').removeClass('active')  
       this.page = 1;
       this.GetFeesAutomation(this.page,this.paginationSize);
    }

    ViewFeeDetails(id,Fees)
    {
      debugger;
      this.VisaFeeId=id;
      this.VisaFees=Fees;
      this.feesAutomationservice.GetVisaFeesAutomationDetails(id,this.dPage).subscribe((data:any)=> {
        if(data.status==true)
        {
          $("#View-Fees-modal").modal("show");
          this.VisaFeesDetails=data.result;
          this.dTotalcount = data.totalCount ;           
          this.totalNoofdetailspages = Math.ceil(this.dTotalcount / this.paginationSize);
        } 
        else{
          this.toastr.success(data.messsage);
        }
    
       },
       error => {
        this.err = error;
        this.toastr.error("Error :" + this.err.message);
      }
       )
    }
   public UpdateVisaFeeAutomationDetails(obj:any){
    debugger;
    this.feesAutomationservice.UpdateVisaFeeAutomationDetails(obj,this.VisaFees).subscribe((data:any)=> {
      $("#visaLoader").show();
        if(data.status==true)
        {
          $("#View-Fees-modal").modal("hide");
          $("#visaLoader").hide();
          this.toastr.success('Visa Fees Updated Succesfully!');
          this.VisaFees=null;
          this.VisaFeesDetails=null;

        } 
        else{
          this.toastr.error(data.Messsage);
        }
    
       },
       error => {
        this.err = error;
        this.toastr.error("Error :" + this.err.message);
      }
       )
    }
    
    currentPageBind(currPage: any) {
      this.selectedIndex = currPage;
      this.page = currPage;
      $('ul.pagination li').removeClass('active');      
      this.GetFeesAutomation(this.page,this.paginationSize);
    }
    nextPage(nextpageNumber: number) {
      this.page = nextpageNumber + 1;
      if (this.page <= this.totalNoofpages) {
        this.pageShow = this.pageShow + 10;
      }
      
      this.GetFeesAutomation(this.page,this.paginationSize);
    }
    previousPage(prevPageNumber: number) {
      if (prevPageNumber >= 0) {
        this.page = prevPageNumber + 1;
        this.pageShow = this.pageShow - 10;
      }
      
      this.GetFeesAutomation(this.page,this.paginationSize);
    }
    noOfRecordsToShow(event: any) {
      this.paginationSize = parseInt(event.target.value);
      this.page = 1;
      this.GetFeesAutomation(this.page,this.paginationSize);
    }

    previousDPage(prevPageNumber: number) {
      if (prevPageNumber >= 0) {
        this.dPage = prevPageNumber + 1;
      }      
      this.ViewFeeDetails(this.VisaFeeId,this.VisaFees);
    }
    currentDPageBind(currPage: any) {
      debugger;
      this.selectedIndex = currPage;
      this.dPage = currPage;
      $('ul.pagination li').removeClass('active');      
      this.ViewFeeDetails(this.VisaFeeId,this.VisaFees);
    }
    nextDPage(nextpageNumber: number) {
      this.dPage = nextpageNumber + 1;
      if (this.dPage <= this.totalNoofdetailspages) {
        this.dPageShow = this.dPageShow + 5;
      }
      
      this.ViewFeeDetails(this.VisaFeeId,this.VisaFees);
    }
}