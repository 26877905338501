import { Component, OnInit,ViewChild,ElementRef } from '@angular/core';
import { Router ,ActivationEnd} from '@angular/router';
import { LoginModel } from '../../service/LoginModel';
import { AiloginserviceService } from '../../service/ailoginservice.service';
import { AirindiaserviceService } from 'src/app/service/airindiaservice.service';
import { HttpErrorResponse } from '@angular/common/http/src/response';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';

//import { LocaleData } from 'ngx-bootstrap/chronos/public_api';
declare var $: any;
@Component({
  selector: 'app-ailogin',
  templateUrl: './ailogin.component.html',
  styleUrls: ['./ailogin.component.css']
})

export class AiloginComponent implements OnInit {
  id: number;
  OTPdiv: any;
  TimeLeftDiv:any;
  Pwddiv: any;
  AccessedByMobileMsg:any;
  localData: any = {};
  LoginButn:any;
  divLogin:any;
  TimeLeft:any;
  AccessedByMobile:any;
  GenerateOTP:number;
  userpassworddata:any={};
  forgotpwd_email='';
  @ViewChild('closeemailModal') closeemailModal: ElementRef;
  @ViewChild('closeresetpwdModal') closeresetpwdModal: ElementRef;
  
  otpmessage='';
  OTP_emailmobile='';
  emailMsg: any;
  emailValidationStatus: boolean;
  userID =0;
  

  constructor(private _router: Router, private _loginservice: AiloginserviceService, private _toaster: ToastrService,private _airindiaService: AirindiaserviceService) { 

    this._router.events.subscribe((ev) => {
      //debugger;
      if (ev instanceof ActivationEnd)
       {
    
$(document).ready(function() {


 // console.info('loadslick')
 /*$('.form-slider').slick({
  dots: true,
  autoplay: true,
  arrow:true,
  autoplaySpeed: 2000,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll:1,
  responsive: [
    {
    breakpoint: 1200,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: true,
      dots: true
    }
    },
    {
    breakpoint:900,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1
    }
    },
    {
    breakpoint: 480,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1
    }
    }
  ]
  })*/
  $('.hero').slick({
    dots: false,
    infinite: true,
    speed: 1000,
    fade: !0,
    cssEase: 'linear',
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    draggable: true,
    arrows: false,
    responsive: [
    {
    breakpoint: 1024,
    settings: {
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true
        }
    },
    {
    breakpoint: 768,
    settings: {
    draggable: true,
        }
    },
    {
    breakpoint: 600,
    settings: {
    slidesToShow: 1,
    draggable: true,
    slidesToScroll: 1
        }
    },
    {
    breakpoint: 480,
    settings: {
    slidesToShow: 1,
    draggable: true,
    slidesToScroll: 1
        }
    }
  
        ]
        });

	});
    }
    
    });
   
    $(document).ready(function() {
      
 //password show hide
 /*$(".toggle-password").click(function() {
  $(this).toggleClass("fa-eye fa-eye-slash");
 let input = $(this).parent().find("input");
  if (input.attr("type") == "password") {
      input.attr("type", "text");
  } else {
      input.attr("type", "password");
  }
});*/

});

    if(!(window.location.href.indexOf( environment.AirIndiaURL)>-1 )  &&  !(window.location.href.indexOf( "localhost")>-1 )  )
    {
      this._router.navigate(['/']);   
    }

  }

  ngOnInit(): void {

    this.TimeLeftDiv=false;
    this.TimeLeft=0;

    //this.setTimer();

    //this.checkMobileAccess();
    this.divLogin=true;
    this.AccessedByMobile=false;
    this.OTPdiv=false;
    this.Pwddiv=true;
    this.LoginButn="Login";
    this.GenerateOTP=0;
    this.localData = JSON.parse(localStorage.getItem('currentUser'));
    
    /*if (this.localData !== null) {     
     
      this._router.navigate([this.localData.userType+'/addApplication']);
     // this._router.navigate(['branchExecutive'+'/addApplication']);

    }   */
    
  /*  if(window.location.href.indexOf( environment.AirIndiaURL)>-1   )
    {
      this._router.navigate(['/register-me']);   
    }
*/



  }
  output: any;
  LoginModel: LoginModel = new LoginModel();

    
  

  register() {
    //console.info('register')
    this._router.navigate(['/register-me']);
  }


  forgotEmailSubmit()
  {
    $("#visaLoader").show();
  
let mystring=$('#fpwdEmailID').val();

var regexp = new RegExp("^[a-zA-Z0-9_!#$%&'*+/=?`{|}~^.-]+@[a-zA-Z0-9.-]+$");
let testpwdformat = regexp.test(mystring);
console.info(testpwdformat);
if(!testpwdformat)
{
this._toaster.error("Invalid Email ID."); 
$("#visaLoader").hide();
return false;
}


    this.forgotpwd_email='******'+mystring.substring(mystring.indexOf('@')-2); 
      //$('#forgotpopup').modal('hide');
     
      this._loginservice._forgotPassword(mystring)
      .subscribe((successData: any) => {   
       console.info(successData);
        if (successData.status == true) {        
           
          $('#otpmodal').modal('show');
          this.closeemailModal.nativeElement.click();
          $("#visaLoader").hide();
     
          }
          else{
            this._toaster.error(successData.messsage);
            $("#visaLoader").hide();
          }
        });
       // $("#visaLoader").hide();
  }

resetpassword()
  {
    $("#visaLoader").show();

    if($('#txtOTP').val()==null || $('#txtOTP').val()=='' ){
     
      this._toaster.error("OTP Cant be empty!"); 
      //$("#changePwdBtn").prop('disabled', false);  
      $("#visaLoader").hide();   
      return false;
     }
   

    let emailid=$('#fpwdEmailID').val();
    let otp=$('#txtOTP').val();
    let newpwd=$('#newpwd').val();
    let confirmpwd=$('#txtConfirmPassword').val();

    if(newpwd==undefined||newpwd==""||newpwd==null){
     
      this._toaster.error("New Password Cant be empty!"); 
      //$("#changePwdBtn").prop('disabled', false);  
      $("#visaLoader").hide();   
      return false;
     }
   
     //var regexp = new RegExp('(?=^.{9,15}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$');
     let regexp = new RegExp('(?=^.{9,15}$)(?=.*[A-Z])(?=.*[a-z])(?=.*[@$!%*?&]).*$');
    
    var testpwdformat = regexp.test(newpwd);

 if(!testpwdformat)
 {
   this._toaster.error("Invalid Password (1 upper case, 1 lowercase,1 special character and minimum nine character)"); 
   $("#visaLoader").hide();   
   //this._toaster.error("Invalid Password (1 upper case, 1 lowercase, 1 special character and 1 digit, minimum nine character)"); 
   // $("#changePwdBtn").prop('disabled', false);     
    return false;
 }

 
     if(newpwd!==confirmpwd){
      //debugger;
      this._toaster.error("New Password and Confirm password should be same."); 
     $("#visaLoader").hide();   
     // $("#changePwdBtn").prop('disabled', false);     
      return false;
    }

    
    

//debugger;
    this._loginservice._resetUserPassword(emailid,otp,newpwd,confirmpwd)
    .subscribe((successData: any) => {   
     console.info(successData);
      if (successData.status == true) {        
         
        this.closeresetpwdModal.nativeElement.click();
        this._toaster.success(successData.messsage);
        $("#visaLoader").hide();   
        }
        else{
          this._toaster.warning(successData.messsage);
          $("#visaLoader").hide();   
        }
      });
     // $("#visaLoader").hide();   
  }

 off() {
    document.getElementById("overlay").style.display = "none";
}




getotp()
{
  var validcheck=true;
  $("#btngetotp").prop('disabled', true);
  $("#visaLoader").show();
  $('.required').fadeOut();
  
  if ($("#empId").val() == "" ) { //&& $("#chkEmail").is(':checked')
    $(".emailid").fadeIn();
    validcheck = false;
  }
if($("#empId").val() != "")
{
var regexp = new RegExp("^[a-zA-Z0-9_!#$%&'*+/=?`{|}~^.-]+@[a-zA-Z0-9.-]+$");
let testpwdformat = regexp.test($("#empId").val());
console.info(testpwdformat);
console.info($("#empId").val());
if(!testpwdformat)
{
this._toaster.error("Invalid Email ID."); 
$("#btngetotp").prop('disabled', false);
$("#visaLoader").hide();
return false;
}
}
 
  if(!validcheck)
  {
    $("#btngetotp").prop('disabled', false);
    $('#visaLoader').hide();
    return;
  }

var otpmode='';

  
  this.otpmessage='We have sent an OTP on your Email ID : '; //& Mobile No
  this.OTP_emailmobile=$("#empId").val();
  otpmode='Both'
  var tempObj = {       
      
   
    emailID:$("#empId").val()
   
  }
  console.info(tempObj);
  this._airindiaService._generateOTPLogin(tempObj)
  .subscribe((successData: any) => {
   console.info(successData.result)
   
    if(successData.status==true)
    {
      $('#btnregister').prop('disabled', false);  
      $('#otpmodallogin').modal('show');

      this.userID=successData.result;
      $("#btngetotp").prop('disabled', false);
      $('#visaLoader').hide();
    }
  else{
    $("#btngetotp").prop('disabled', false);
    $('#visaLoader').hide();
    this._toaster.error(successData.messsage);
  }
   
  },
  (err: HttpErrorResponse) => {
    $("#btngetotp").prop('disabled', false);
    $('#visaLoader').hide();
    console.error(err.message);    // SHOW ERRORS IF ANY.
  });


 
}



airindia_login()
{
  $("#visaLoader").show();
  
  
  if($("#txtOTPLogin").val()==null || $("#txtOTPLogin").val()=="")
  {
    this._toaster.error("Please enter OTP");
    $("#visaLoader").hide();
    return;
  }


  var tempObj = {       
      
    userID:this.userID,     
    otp:$("#txtOTPLogin").val(),
   
  }

  

  this._airindiaService._AuthenticateUserLogin(tempObj)
  .subscribe((successData: any) => {
   console.info(successData.result)
    $('#btnregister').prop('disabled', false);  
    $('#otpmodallogin').modal('hide');
    if(successData.status==true)
    {
      localStorage.setItem('currentUser', JSON.stringify({ userId: successData.empCode, token: successData.toKen, userType: successData.userType, userName: successData.userName, userBranchId: successData.userBranchId }));

     // localStorage.setItem('currentUser', JSON.stringify({ userId:this.userID, token: successData.result, userType: 'AI', userName:successData.userName, userBranchId: '' }));
      localStorage.setItem('branchExecutiveUser', JSON.stringify({ userId: 'Username', token: successData.result }));
      localStorage.setItem('uaeApptype',"1");
      localStorage.setItem('AIUserID',this.userID+"");
      
      this._router.navigate(['/AI/addApplication'], { queryParams: { id: 0 } });
      //this._toaster.info("Registered successfully ! You will receive the credentials on the registered email address.");
      //this._router.navigate(['/ailogin']);
      $("#txtOTPLogin").val('');
      $("#visaLoader").hide();
    }
    
else{
this._toaster.error(successData.result); 
$("#txtOTPLogin").val('')
}
   //var temp = { successData };    

    $('#visaLoader').hide();     
  },
  (err: HttpErrorResponse) => {
    $('#visaLoader').hide();
    console.error(err.message);    // SHOW ERRORS IF ANY.
  });




}

/*CheckMobileAccess(){
    try {
      this._loginservice.CheckMobileAccess().subscribe((successData: any) => { 

        if(successData.status==true){
          this.AccessedByMobile=false;
          this.divLogin=true;
        }
        else{
         
         this._toaster.error(successData.msg);
          this.AccessedByMobile=true;
          this.divLogin=false;
          this.AccessedByMobileMsg=successData.msg;
        }
      },
      
      
      
      (error) => {
       
           this._toaster.error("We are facing technical issue at the moment!");
        
       }
      
      
      ); 
      
    } catch (error) {
      
    }
   
  }

  setTimer(callbacksettime){

    this.TimeLeftDiv=true;
    var timeleft = 1;


    var downloadTimer = setInterval(function(){

      
    this.TimeLeftDiv=false;
    this.LoginButn="Re-Generate OTP";

      this.TimeLeft=61-timeleft;

      document.getElementById("TimeLeft").innerHTML=this.TimeLeft;
      
      timeleft += 1;

      console.info("tiumeleft==",timeleft);

      if(timeleft>= 62){

        clearInterval(downloadTimer);
        callbacksettime();
        // this.TimeLeftDiv=false;
        // this.LoginButn="Re-Generate OTP";



      }
    }, 1000);
     
  }
*/
      
}
