import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class BranchManagerAuthGuardService implements CanActivate  {
  constructor(private _router: Router) { }
  canActivate()
    {
        if (localStorage.getItem('branchManagerUser'))
        {
            // logged in so return true
            // alert('branchManagerUser');

            return true;
        }

        // not logged in so redirect to login page
        this._router.navigate(['/']);
        return false;
    }
}
