import { Component, OnInit } from '@angular/core';
import { SettingServiceService } from 'src/app/service/setting-service.service';
import { UpdateVisaService } from "src/app/service/updateVisaService";

declare var $: any;
@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.css']
 

})
export class SettingComponent implements OnInit {
  settingOption:any="";
  settingHeading:String="";
  setting_option:any=[];
  PNRNo:any="";
  branchlist: any=[]=[];
  brnlist:any;
  empcode:any="";
  option_status:boolean=false;
  isActive:any=false;
  pos:string='No';
  pos2:string='Yes';
  options=['No','Yes'];
  alldevicedata=[{"id": 11,  "alertonoff": "1"}];
  toggleValue:string="0";
  constructor(private _setting:SettingServiceService,private _ReportService: UpdateVisaService) { }

  ngOnInit() {
    this.setting_option=this._setting._settingOption;
    this.getBranches();
    
  }

  public settingFetch(id:any)
  {
     if(id!=undefined)
     {
      this.settingHeading=this.setting_option.find(o=>o.id==id).Name;
     }
     else{
      this.settingHeading="";
     }
  }

  getBranches()
  {
   this._ReportService.getBranches().subscribe(data=> {
     this.brnlist=data;
    if(this.brnlist.status==true)
    {
       this.branchlist=this.brnlist.result;
    } 
    else{
      console.info(this.brnlist.Messsage);
    }

   },
   err=>{
    console.log(err);
   }
   )

  }
}
