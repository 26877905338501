import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';

declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class GlobalServiceService {

  constructor() { }

  AddCountryVendor(){
    var clonedDiv = $('#CountryVendor1').clone();
    $('.CountryVendor:last').after(clonedDiv);


  }


  


  AddPass(){
    var clonedDiv = $('#PassDetail_1').clone(); 
    var clonedDivDoc = $('#PassengerDoc_1').clone();
   
    clonedDivDoc.appendTo(clonedDiv);
    $('.passangernew:last').after(clonedDiv);
  
    let j=$('.passangernew').length;
    console.info('passenger cnt'+j)

    


    
  }


  AddPass1(){
 
    var genderVal=$('input[name=Gender_1]:checked').val();


 // var clonedDiv = $('#PassDetail_1').clone();
 
  var clonedDiv = $('#PassDetail_1').clone().find("input:text").val("").end();
  

  $('.PassangerDetails:last').after(clonedDiv);



  let j=$('.PassangerDetails').length;

  
  $('.PassangerDetails:last').find('.dynamic-Pax').each(function(){

        //let id=$(this).attr('id');

        let ids=$(this).attr('id');
          
        const lastIndex = ids.lastIndexOf('_');

        let id= ids.substring(0, lastIndex);
debugger;
        if(id=="visaCustMale"||id=="visaCustFemale"){
          let name=$(this).attr('name');
          $(this).attr('name',name+'_'+j);
        }

       

        $(this).attr('id',id+'_'+j);


    });
    //$('input[name=Gender]').filter('[value=Male]').prop('checked', true);

  this.ResetNumber();
  $('input[name=Gender_1]').filter('[value='+genderVal+']').prop('checked', true);
  //$('input[type=email]').val("");


  }


  ResetNumberDoc(){
    let i=1;
    
    $('.PassengerDoc').each(function(){
      debugger;
      if(i>1){
        
        $(this).attr('id', 'PassengerDoc_'+i);


        $(this).find('.dynamic-Pax').each(function(){

          // let id=$(this).attr('id').split('_')[0];
           //let ids=$(this).attr('id').split('_');
           
        let ids=$(this).attr('id');          
           const lastIndex = ids.lastIndexOf('_');
           let id= ids.substring(0, lastIndex);
           debugger;
           $(this).attr('id',id+'_'+i);

        })

      } 
      i++;
    })
  }

  ResetNumber(){
   
    let i=1;
    
    $('.PassangerDetails').each(function(){
      debugger;
      if(i>1){
        
        $(this).attr('id', 'PassDetail_'+i);
        $(this).find('.passportpage11').attr('id','passportfrontside_'+i);
        $(this).find('.passportpage11').attr('name','passportfrontside_'+i);

        var fronpassportimg= $('.PassangerDetails').length;
        if(i==fronpassportimg)
        {
          $('#passportfrontside_'+i).val(''); 
        }
        
     
        //$(document).on('change','#file-passport_'+i,'getFileDetailsPass($event,"passport","passport",'+i+')');
       
    
        $(this).find('.passoscrimg1').attr('id', 'passportfront-page1_'+i);
    
         if(i==fronpassportimg)
         {
         
          $('#passportfront-page1_'+i).attr('src','');
         }
        

        $(this).find('.imgcsrpassport').attr('id','addPassportimage_'+i);

       


        $(this).find('.RemovePass').attr('id','RemovePass-'+i);


        $(this).find('.dynamic-doc').each(function(){

          let id=$(this).attr('id').split('_')[0];

         

        });
      
        $(this).find('.dynamic-Pax').each(function(){

         // let id=$(this).attr('id').split('_')[0];
          //let ids=$(this).attr('id').split('_');
          
       let ids=$(this).attr('id');          
          const lastIndex = ids.lastIndexOf('_');
          let id= ids.substring(0, lastIndex);
        
          /*   let beforeid = ids.slice(lastIndex + 1);*/

          if(id=="passport" || id=="photo" || id=="travelTicket" || id=="accommodation" || id=="forex_Credit_Card"
          || id=="bankStatement" || id=="incomeTax" || id=="selfEmployment" || id=="travelInsurance" || id=="coveringLetter"
          || id=="invitationLetter" || id=="NOCDocument" 
          ){
            //let forAtt=$(this).attr('for').split('_')[0];
            $(this).attr('for','file-'+id+'_'+i);
            $(this).attr('id',id+'_'+i);
          }

         
          
          if(id=="visaCustMale"||id=="visaCustFemale"){
            let name=$(this).attr('name').split('_')[0];
            console.info('name===',name);
            $(this).attr('name',name+'_'+i);
          }
          if(id=="CustMale"||id=="CustFemale"){
            //let forAtt=$(this).attr('for').split('_')[0];
            $(this).attr('for','visa'+id+'_'+i);
          }
          else{

              let type=$(this).attr('type');

              let proalt=$(this).attr('alt');
              if(typeof proalt != 'undefined'){
                $(this).attr('alt',proalt.split('_')[0]+'_'+i);
              }
              var minDate = new Date();

            /*  $('#dob_'+i).datepicker({
                changeMonth: true,
            changeYear: true,
            dateFormat:'dd/mm/yy',
            minDate:   minDate.getDate()-36500 ,
            maxDate:0 ,
            yearRange: '1950:'+new Date().getFullYear(),
              });*/

              if(i==fronpassportimg)
              {
                $('#dob_'+i).val(''); 
              }
            

              /*$('#pass-exp_'+i).datepicker({
                changeMonth: true,
            changeYear: true,
            dateFormat:'dd/mm/yy',
            minDate:   minDate.getDate() ,
          maxDate:minDate.getDate() + 3650
                });
*/
                if(i==fronpassportimg)
                {
                  $('#pass-exp_'+i).val('');
                }
                
                $('#ui-datepicker-div').addClass('theme-red');
                $('.ui-datepicker-header').addClass('bs-datepicker-head');

                
        }
       
      


        //$(this).attr('id',id+'_'+i);
       // $("#profession_"+i).autocomplete("destroy");
       if ($("#profession_"+i).hasClass('ui-autocomplete')) {
           $("#profession_"+i).autocomplete('destroy')
           }

      });
     
      debugger;
      //document.getElementById ('#file-passport_'+i).addEventListener("change",)

      
      //$(this).find('#file-passport_'+i).attr('change','getFileDetailsPass($event,\'passport\',\'passport\','+i+')');
      //console.info($(this).find('#file-passport_'+i).attr('change'));

        $('#RemovePass-'+i).show();
        $(this).find('.PaxHeading').text('Passenger '+i);
       
      }
      i++;

    });
    /*pooja 31-Oct-2022*/
    $('#emailId_'+(i-1)).val('');  
    $('#mob-num_'+(i-1)).val('');  
   
  }


  changeDateFormat(userdate,old_format,new_format){


    var newDate='';

    var dateAr = userdate.split('/');

    if(new_format=="dd/mm/yyyy" && old_format=="mm/dd/yyyy"){

      var newDate = dateAr[1] + '/' + dateAr[0] + '/' + dateAr[2];
    }

    else if(new_format=="mm/dd/yyyy" && old_format=="dd/mm/yyyy"){

      var newDate = dateAr[1] + '/' + dateAr[0] + '/' + dateAr[2];
    }

    return newDate;


  }


stringToDate(_date,_format,_delimiter)
{

            console.info('formated date==',_date);

            var formatLowerCase=_format.toLowerCase();

            var formatItems=_date.split(_delimiter);



           var new_date=formatItems[1]+'/'+formatItems[0]+'/'+formatItems[2];

            console.info('formatItems==',formatItems);


            console.info('formated date1==',new_date);

            return new_date;


}


validatevisainfouae(imtcase:boolean)
{

  var ueavalidate=true;

  $('.required').fadeOut();
  if ($("#travel-date").val() == "") {
    $(".travel-date").fadeIn();
    ueavalidate = false;
  }

  if ($("#mstprocessingOptions").val() == 0) {
   
    $(".mstprocessingOptions.required").fadeIn();
     ueavalidate = false;
  }
  if ($("#visaType").val() == 0) {
   
    $(".visaType.required").fadeIn();
     ueavalidate = false;
  }

  var impCase = $('input[name=IMPCase]:checked').val();
   if (impCase == "" || impCase==null || impCase==undefined) {
    $(".impcasechk.required").fadeIn();
    ueavalidate = false;
    }

  /*if(imtcase==false)
  {
   
    $(".imtcase.required").fadeIn();
     ueavalidate = false; 
  }*/
  return ueavalidate;
}


validateVisainfo()
{
  var validcheck=true;

  $('.required').fadeOut();


  if( $("#visaType option:selected" ).text()!='OKTB')
    {
        if($('#visaEntries').is(':enabled')) {
          if ($("#visaEntries").val() == "0: null") {
            $(".visaEntries.required").fadeIn();
            validcheck = false;
          }
        }
      
        if ($("#mstprocessingOptions").val() == "0: null" || $("#mstprocessingOptions").val() == null) {
          $(".mstprocessingOptions.required").fadeIn();
          validcheck = false;
        }
  }
else{
  if($('#visaAirline').is(':enabled')) {
    if ($("#visaAirline").val() == "0: null") {
      $(".visaAirline.required").fadeIn();
      validcheck = false;
    }
  }

}
 
  if ($("#visaType").val() == "0: null" || $("#visaType").val() == null) {
    $(".visaType.required").fadeIn();
    validcheck = false;
  }
console.info($("#visaCountry").val());
  if ($("#visaCountry").val() == "0: null" || $("#visaCountry").val() == null) {
    $(".visaCountry.required").fadeIn();
    validcheck = false;
  }

  if ($("#travel-date").val() == "") {
    $(".travel-date").fadeIn();
    validcheck = false;
  }


  return validcheck;

}

  validateVisaForm(){

    var validcheck=true;

    $('.required').fadeOut();

    if ($("#cust-id").val() == "") {
      $(".cust-id.required").fadeIn();
      validcheck = false;
    }

    if($('#visaRemark').val()=="")
    {
      $(".visaRemark.required").fadeIn();
      validcheck = false;
    }

    if ($("#pass-exp").val() == "") {
      $(".pass-exp.required").fadeIn();
      validcheck = false;
    }
    if ($("#poi").val() == "") {
      $(".poi").fadeIn();
      validcheck = false;
    }
    if ($("#dob").val() == "") {
      $(".dob").fadeIn();
      validcheck = false;
    }

    if ($("#pass-num").val() == "") {
      $(".pass-num.required").fadeIn();
      validcheck = false;
    }

    if ($("#given-name").val() == "") {
      $(".given-name.required").fadeIn();
      validcheck = false;
    }

    if ($("#surname").val() == "") {
      $(".surname.required").fadeIn();
      validcheck = false;
    }

    if($('#visaEntries').is(':enabled')) {
      if ($("#visaEntries").val() == "0: null") {
        $(".visaEntries.required").fadeIn();
        validcheck = false;
      }
    }
    if($('#mstprocessingOptions').is(':enabled')) {
      if ($("#mstprocessingOptions").val() == "0: null") {
        $(".mstprocessingOptions.required").fadeIn();
        validcheck = false;
      }
    }

    if ($("#nationality").val() == "0: null") {
      $(".nationality.required").fadeIn();
      validcheck = false;
    }

    if ($("#visaType").val() == "0: null") {
      $(".visaType.required").fadeIn();
      validcheck = false;
    }

    if ($("#visaCountry").val() == "0: null") {
      $(".visaCountry.required").fadeIn();
      validcheck = false;
    }

    if ($("#paxType_1").val() == "0: null") {
      $(".paxType.required").fadeIn();
      validcheck = false;
    }

    if ($("#travel-date").val() == "") {
      $(".travel-date").fadeIn();
      validcheck = false;
    }

    $('.dynamic-Pax-require').each(function(){
      let this_value=$(this).val();

      if(this_value==""){
        console.info(" this_value==", this_value);
        $(this).nextAll('.required:first').fadeIn();
        // $(this).next().find('.required').fadeIn();
        // $(this).closest().find('.required').fadeIn();
        // $(this).closest('.input__label').next().find('.required').fadeIn();
        validcheck = false;
      }
      else if(this_value=="null"){
        console.info(" this_value==", this_value);
        $(this).nextAll('.required:first').fadeIn();
        // $(this).next().find('.required').fadeIn();
        // $(this).closest().find('.required').fadeIn();
        // $(this).closest('.input__label').next().find('.required').fadeIn();
        validcheck = false;
      }

    });


    console.info("testing==", $("#visaOffice").val());

    if ($("#visaOffice").val() == "0: null" || $("#visaOffice").val()=="" || $("#visaOffice").val()=="null"|| $("#visaOffice").val()==null) {

      $(".visaOffice.required").fadeIn();
      validcheck = false;
    }
    if ($("#mob-num").val() == "") {
      $(".mob-num.required").fadeIn();
      validcheck = false;
    }
    if ($("#emailId").val() == "") {
      $(".emailId.required").fadeIn();
      validcheck = false;
    }
    debugger;
   var impCase = $('input[name=IMPCase]:checked').val();
   if (impCase == "" || impCase==null || impCase==undefined) {
    $(".impcasechk.required").fadeIn();
    validcheck = false;
    }

    return validcheck;
  }

  validateUaeVisaForm(fronside:any,secondside:any,imtcase:boolean,mtstatus:string,panNoval:boolean)
  {
    var ueavalidate=true;
    $('.required').fadeOut();

    if ($("#cust-id").val() == "") {
      $(".cust-id.required").fadeIn();
      ueavalidate = false;
    }

    if($('#visaRemark').val()=="")
    {
      $(".visaRemark.required").fadeIn();
      ueavalidate = false;
    }
    if ($("#mob-num").val() == "") {
      $(".mob-num.required").fadeIn();
      ueavalidate = false;
    }
    if ($("#emailId").val() == "") {
       $(".email-id.required").fadeIn();
       ueavalidate = false;
    }
    if($("#file-PassportFrontSide").val() =="" && fronside==null)
    {
      $(".file-PassportFrontSide.required").fadeIn();
      ueavalidate = false; 
    }
    if($("#file-PassportBackSide").val() =="" && secondside==null)
    {
  
    $(".file-PassportBackSide.required").fadeIn();
      ueavalidate = false; 
    }
    if ($("#mstprocessingOptions").val() == 0) {
      $(".mstprocessingOptions.required").fadeIn();
       ueavalidate = false;
    }
    if ($("#visaType").val() == 0) {
      $(".visaType.required").fadeIn();
       ueavalidate = false;
    }
    if(imtcase==false)
    {
      $(".imtcase.required").fadeIn();
       ueavalidate = false; 
    }

    if ($("#passportNo").val() == "") {
      $(".passportNo.required").fadeIn();
       ueavalidate = false;
    }
    if ($("#issuedateTime").val() == "") {
      $(".issuedateTime.required").fadeIn();
       ueavalidate = false;
    }
    if ($("#expirdateTime").val() == "") {
      $(".expirdateTime.required").fadeIn();
       ueavalidate = false;
    }
    if ($("#PassportIssuePlace").val() == "") {
      $(".PassportIssuePlace.required").fadeIn();
       ueavalidate = false;
    }
    if ($("#Ecrtype").val() ==0) {
      $(".Ecrtype.required").fadeIn();
       ueavalidate = false;
    }
    if ($("#Sign").val() ==0) {
      $(".Sign.required").fadeIn();
       ueavalidate = false;
    }
    if ($("#Pantype").val() ==0) {
      $(".PanNo.required").fadeIn();
       ueavalidate = false;
    }
    if ($("#Insurance").val() ==0) {
      $(".Insurance.required").fadeIn();
       ueavalidate = false;
    }
    if ($("#PersonalFirstName").val() =="") {
      $(".PersonalFirstName.required").fadeIn();
       ueavalidate = false;
    }
    if ($("#PersonalLastName").val() =="") {
      $(".PersonalLastName.required").fadeIn();
       ueavalidate = false;
    }
    if ($("#gender").val() ==0) {
      $(".gender.required").fadeIn();
       ueavalidate = false;
    }
    if ($("#dob").val() =="") {
      $(".dob.required").fadeIn();
       ueavalidate = false;
    }
    if ($("#fk_PassIssueCountryid").val() ==0) {
      $(".PassIssueCountryid.required").fadeIn();
       ueavalidate = false;
    }
    if ($("#fk_birthCountryId").val() ==0) {
      $(".birthCountryId.required").fadeIn();
       ueavalidate = false;
    }
    if ($("#fK_NationalityMasterId").val() ==0) {
      $(".NationalityMasterId.required").fadeIn();
       ueavalidate = false;
    }
    if ($("#PaxType").val() ==0) {
      $(".PaxType.required").fadeIn();
       ueavalidate = false;
    }
    if ($("#fatherName").val() ==0) {
      $(".fatherName.required").fadeIn();
       ueavalidate = false;
    }

    if ($("#fatherName").val() ==0) {
      $(".fatherName.required").fadeIn();
       ueavalidate = false;
    }
    if ($("#PersonalMotherName").val() ==0) {
      $(".PersonalMotherName.required").fadeIn();
       ueavalidate = false;
    }
    if(mtstatus=="MARRIED" && $('#gender').val()=='Female' && $('#HusbandName').val()=="")
    {
      $(".HusbandName.required").fadeIn();
      ueavalidate = false;
    }
    if ($("#MaritalStatus").val() ==0) {
      $(".MaritalStatus.required").fadeIn();
       ueavalidate = false;
    }
    if ($("#PersonalBirthPlace").val() =="") {
      $(".PersonalBirthPlace.required").fadeIn();
       ueavalidate = false;
    }
    if($('#Pantype').val()=="With PAN" && $('#PancardNo').val()=="")
    {
     
      $(".PancardNo.required").fadeIn();
      ueavalidate = false;
    }
    else{
     
      if(panNoval==true)
      {
        $(".PancardNo.required").fadeIn();
         ueavalidate = false; 
      }
    }
    if ($("#ProfessionId").val() ==0) {
      $(".ProfessionId.required").fadeIn();
       ueavalidate = false;
    }
    if ($("#travelDate").val() =="") {
      $(".travel-date.required").fadeIn();
       ueavalidate = false;
    }
    if ($("#fk_comingCountryId").val() =="") {
      $(".comingCountryId.required").fadeIn();
       ueavalidate = false;
    }
    if ($("#fk_Personalnationalid").val() ==0) {
       $(".Personalnationalid.required").fadeIn();
       ueavalidate = false;
    }
    if ($("#fk_PersonalPRVnationalid").val() ==0) {
      $(".PersonalPRVnationalid.required").fadeIn();
      ueavalidate = false;
   }
   if ($("#fK_ReligionId").val() =="0") {
    $(".fK_ReligionId.required").fadeIn();
    ueavalidate = false;
    }
    if ($("#fK_FaithName").val() ==0) {
      $(".FaithName.required").fadeIn();
      ueavalidate = false;
      }
    if($("#fK_educationid").val() ==0) {
        $(".educationid.required").fadeIn();
        ueavalidate = false;
      }
    if($("#fK_langid").val() ==0) {
        $(".langid.required").fadeIn();
        ueavalidate = false;
     }
     if($("#file-photo").val() =="") {
      $(".file-photo.required").fadeIn();
      ueavalidate = false;
     }
     if($("#sChannelArabicFirstName").val() =="") {
      $(".sChannelArabicFirstName.required").fadeIn();
      ueavalidate = false;
     }

     if($("#sChannelArabicFirstName").val() =="") {
      $(".sChannelArabicFirstName.required").fadeIn();
      ueavalidate = false;
     }

    //  if($("#sChannelArabicMiddleName").val() =="") {
    //   $(".sChannelArabicMiddleName.required").fadeIn();
    //   ueavalidate = false;
    //  }

     if($("#sChannelArabicLastName").val() =="") {
      $(".sChannelArabicLastName.required").fadeIn();
      ueavalidate = false;
     }
     if($("#sChannelArabicMotherName").val() =="") {
      $(".sChannelArabicMotherName.required").fadeIn();
      ueavalidate = false;
     }
     if($("#sChannelArabicBirthPlace").val() =="") {
      $(".sChannelArabicBirthPlace.required").fadeIn();
      ueavalidate = false;
     }
     if($("#sChannelArabicPassportIssuePlace").val() =="") {
      $(".sChannelArabicPassportIssuePlace.required").fadeIn();
      ueavalidate = false;
     }

    return ueavalidate;
  }


  validateUaeVisaFormPassenger(fronside:any,secondside:any,imtcase:boolean,mtstatus:string,panNoval:boolean)
  {
    var ueavalidate=true;
    $('.required').fadeOut();

    

    if ($("#mob-num").val() == "") {
      $(".mob-num.required").fadeIn();
      ueavalidate = false;
    }
    if ($("#emailId").val() == "") {
       $(".email-id.required").fadeIn();
       ueavalidate = false;
    }
    if($("#file-PassportFrontSide").val() =="" && fronside==null)
    {
      $(".file-PassportFrontSide.required").fadeIn();
      ueavalidate = false; 
    }
    if($("#file-PassportBackSide").val() =="" && secondside==null)
    {
  
    $(".file-PassportBackSide.required").fadeIn();
      ueavalidate = false; 
    }
    
    if ($("#passportNo").val() == "") {
      $(".passportNo.required").fadeIn();
       ueavalidate = false;
    }
    if ($("#issuedateTime").val() == "") {
      $(".issuedateTime.required").fadeIn();
       ueavalidate = false;
    }
    if ($("#expirdateTime").val() == "") {
      $(".expirdateTime.required").fadeIn();
       ueavalidate = false;
    }
    if ($("#PassportIssuePlace").val() == "") {
      $(".PassportIssuePlace.required").fadeIn();
       ueavalidate = false;
    }
    if ($("#Ecrtype").val() ==0) {
      $(".Ecrtype.required").fadeIn();
       ueavalidate = false;
    }
    if ($("#Sign").val() ==0) {
      $(".Sign.required").fadeIn();
       ueavalidate = false;
    }
    if ($("#Pantype").val() ==0) {
      $(".PanNo.required").fadeIn();
       ueavalidate = false;
    }
    if ($("#Insurance").val() ==0) {
      $(".Insurance.required").fadeIn();
       ueavalidate = false;
    }
    if ($("#PersonalFirstName").val() =="") {
      $(".PersonalFirstName.required").fadeIn();
       ueavalidate = false;
    }
    if ($("#PersonalLastName").val() =="") {
      $(".PersonalLastName.required").fadeIn();
       ueavalidate = false;
    }
    if ($("#gender").val() ==0) {
      $(".gender.required").fadeIn();
       ueavalidate = false;
    }
    if ($("#dob").val() =="") {
      $(".dob.required").fadeIn();
       ueavalidate = false;
    }
    if ($("#fk_PassIssueCountryid").val() ==0) {
      $(".PassIssueCountryid.required").fadeIn();
       ueavalidate = false;
    }
    if ($("#fk_birthCountryId").val() ==0) {
      $(".birthCountryId.required").fadeIn();
       ueavalidate = false;
    }
    if ($("#fK_NationalityMasterId").val() ==0) {
      $(".NationalityMasterId.required").fadeIn();
       ueavalidate = false;
    }
    if ($("#PaxType").val() ==0) {
      $(".PaxType.required").fadeIn();
       ueavalidate = false;
    }
    if ($("#fatherName").val() ==0) {
      $(".fatherName.required").fadeIn();
       ueavalidate = false;
    }

    if ($("#fatherName").val() ==0) {
      $(".fatherName.required").fadeIn();
       ueavalidate = false;
    }
    if ($("#PersonalMotherName").val() ==0) {
      $(".PersonalMotherName.required").fadeIn();
       ueavalidate = false;
    }
    if(mtstatus=="MARRIED" && $('#gender').val()=='Female' && $('#HusbandName').val()=="")
    {
      $(".HusbandName.required").fadeIn();
      ueavalidate = false;
    }
    if ($("#MaritalStatus").val() ==0) {
      $(".MaritalStatus.required").fadeIn();
       ueavalidate = false;
    }
    if ($("#PersonalBirthPlace").val() =="") {
      $(".PersonalBirthPlace.required").fadeIn();
       ueavalidate = false;
    }
    if($('#Pantype').val()=="With PAN" && $('#PancardNo').val()=="")
    {
     
      $(".PancardNo.required").fadeIn();
      ueavalidate = false;
    }
    else{
     
      if(panNoval==true)
      {
        $(".PancardNo.required").fadeIn();
         ueavalidate = false; 
      }
    }
    if ($("#ProfessionId").val() ==0) {
      $(".ProfessionId.required").fadeIn();
       ueavalidate = false;
    }
    if ($("#travelDate").val() =="") {
      $(".travel-date.required").fadeIn();
       ueavalidate = false;
    }
    if ($("#fk_comingCountryId").val() =="") {
      $(".comingCountryId.required").fadeIn();
       ueavalidate = false;
    }
    if ($("#fk_Personalnationalid").val() ==0) {
       $(".Personalnationalid.required").fadeIn();
       ueavalidate = false;
    }
    if ($("#fk_PersonalPRVnationalid").val() ==0) {
      $(".PersonalPRVnationalid.required").fadeIn();
      ueavalidate = false;
   }
   if ($("#fK_ReligionId").val() =="0") {
    $(".fK_ReligionId.required").fadeIn();
    ueavalidate = false;
    }
    if ($("#fK_FaithName").val() ==0) {
      $(".FaithName.required").fadeIn();
      ueavalidate = false;
      }
    if($("#fK_educationid").val() ==0) {
        $(".educationid.required").fadeIn();
        ueavalidate = false;
      }
    if($("#fK_langid").val() ==0) {
        $(".langid.required").fadeIn();
        ueavalidate = false;
     }
     if($("#file-photo").val() =="") {
      $(".file-photo.required").fadeIn();
      ueavalidate = false;
     }
     if($("#sChannelArabicFirstName").val() =="") {
      $(".sChannelArabicFirstName.required").fadeIn();
      ueavalidate = false;
     }

     if($("#sChannelArabicFirstName").val() =="") {
      $(".sChannelArabicFirstName.required").fadeIn();
      ueavalidate = false;
     }

    //  if($("#sChannelArabicMiddleName").val() =="") {
    //   $(".sChannelArabicMiddleName.required").fadeIn();
    //   ueavalidate = false;
    //  }

     if($("#sChannelArabicLastName").val() =="") {
      $(".sChannelArabicLastName.required").fadeIn();
      ueavalidate = false;
     }
     if($("#sChannelArabicMotherName").val() =="") {
      $(".sChannelArabicMotherName.required").fadeIn();
      ueavalidate = false;
     }
     if($("#sChannelArabicBirthPlace").val() =="") {
      $(".sChannelArabicBirthPlace.required").fadeIn();
      ueavalidate = false;
     }
     if($("#sChannelArabicPassportIssuePlace").val() =="") {
      $(".sChannelArabicPassportIssuePlace.required").fadeIn();
      ueavalidate = false;
     }

    return ueavalidate;
  }



  validateAddPassenger(){

    var validcheck=true;

    $('.required').fadeOut();


    if ($("#pass-exp_1").val() == "") {
      $(".pass-exp.required").fadeIn();
      validcheck = false;
    }
    if ($("#poi_1").val() == "") {
      $(".poi").fadeIn();
      validcheck = false;
    }
    if ($("#dob_1").val() == "") {
      $(".dob").fadeIn();
      validcheck = false;
    }

    if ($("#pass-num_1").val() == "") {
      $(".pass-num.required").fadeIn();
      validcheck = false;
    }


   
  


    if ($("#given-name_1").val() == "") {
      $(".given-name.required").fadeIn();
      validcheck = false;
    }

    if ($("#surname_1").val() == "") {
      $(".surname.required").fadeIn();
      validcheck = false;
    }
    if ($("#mob-num_1").val() == "") {
      $(".mob-num_1.required").fadeIn();
      validcheck = false;
    }
    if ($("#emailId_1").val() == "") {
      $(".emailId_1.required").fadeIn();
      validcheck = false;
    }
    if ($("#mob-num_1").val() != "") {
    if ($("#mob-num_1").val().length != 10) {
      $(".mob-num_invalid.required").fadeIn();
      validcheck = false;
    }
  }
    

    console.info(validcheck)

    
   /*Pooja if($('#mstprocessingOptions').is(':enabled')) {
      if ($("#mstprocessingOptions").val() == "0: null") {
        $(".mstprocessingOptions.required").fadeIn();
        validcheck = false;
      }
    }*/

    if ($("#nationality_1").val() == "null") {
      $(".nationality.required").fadeIn();
      validcheck = false;
    }
console.info('paxType' + $("#paxType_1").val() );
    

    if ($("#paxType_1").val() == "null") {
      $(".paxType.required").fadeIn();
      validcheck = false;
    }

   
    return validcheck;

  }


  validateVisaDetails()
  {
    var validcheck=true;

    $('.required').fadeOut();

    if ($("#visaType").val() == "0: null") {
      $(".visaType.required").fadeIn();
      validcheck = false;
    }

    if ($("#visaCountry").val() == "0: null") {
      $(".visaCountry.required").fadeIn();
      validcheck = false;
    }
    if($('#visaEntries').is(':enabled')) {
      if ($("#visaEntries").val() == "0: null") {
        $(".visaEntries.required").fadeIn();
        validcheck = false;
      }
    }
    if ($("#travel-date").val() == "") {
      $(".travel-date").fadeIn();
      validcheck = false;
    }
    return validcheck;
  }
  
  validateB2BVisaForm(){

    var validcheck=true;

    $('.required').fadeOut();

    /*Pooja  if ($("#cust-id").val() == "") {
      $(".cust-id.required").fadeIn();
      validcheck = false;
    }*/

    if($('#visaRemark').val()=="")
    {
      $(".visaRemark.required").fadeIn();
      validcheck = false;
    }

    if ($("#pass-exp").val() == "") {
      $(".pass-exp.required").fadeIn();
      validcheck = false;
    }
    if ($("#poi").val() == "") {
      $(".poi").fadeIn();
      validcheck = false;
    }
    if ($("#dob").val() == "") {
      $(".dob").fadeIn();
      validcheck = false;
    }

    if ($("#pass-num").val() == "") {
      $(".pass-num.required").fadeIn();
      validcheck = false;
    }

    if ($("#given-name").val() == "") {
      $(".given-name.required").fadeIn();
      validcheck = false;
    }

    if ($("#surname").val() == "") {
      $(".surname.required").fadeIn();
      validcheck = false;
    }
    console.info(validcheck)

    if($('#visaEntries').is(':enabled')) {
      if ($("#visaEntries").val() == "0: null") {
        $(".visaEntries.required").fadeIn();
        validcheck = false;
      }
    }
   /*Pooja if($('#mstprocessingOptions').is(':enabled')) {
      if ($("#mstprocessingOptions").val() == "0: null") {
        $(".mstprocessingOptions.required").fadeIn();
        validcheck = false;
      }
    }*/

    if ($("#nationality").val() == "0: null") {
      $(".nationality.required").fadeIn();
      validcheck = false;
    }

    if ($("#visaType").val() == "0: null") {
      $(".visaType.required").fadeIn();
      validcheck = false;
    }

    if ($("#visaCountry").val() == "0: null") {
      $(".visaCountry.required").fadeIn();
      validcheck = false;
    }

    if ($("#paxType_1").val() == "0: null") {
      $(".paxType.required").fadeIn();
      validcheck = false;
    }

    if ($("#travel-date").val() == "") {
      $(".travel-date").fadeIn();
      validcheck = false;
    }
    console.info(validcheck)
    $('.dynamic-Pax-require').each(function(){
      let this_value=$(this).val();
      let this_id=$(this).id;
      debugger;
      if(this_value==""){
        console.info(" this_value==", this_value);
        $(this).nextAll('.required:first').fadeIn();
        // $(this).next().find('.required').fadeIn();
        // $(this).closest().find('.required').fadeIn();
        // $(this).closest('.input__label').next().find('.required').fadeIn();
        validcheck = false;
      }
      else if(this_value=="null"){
        debugger;
        console.info(" this_value==", this_value);
        $(this).nextAll('.required:first').fadeIn();
        // $(this).next().find('.required').fadeIn();
        // $(this).closest().find('.required').fadeIn();
        // $(this).closest('.input__label').next().find('.required').fadeIn();
        validcheck = false;
      }

    });
    console.info(validcheck)
/*
    console.info("testing==", $("#visaOffice").val());

    if ($("#visaOffice").val() == "0: null" || $("#visaOffice").val()=="" || $("#visaOffice").val()=="null"|| $("#visaOffice").val()==null) {

      $(".visaOffice.required").fadeIn();
      validcheck = false;
    }
    if ($("#mob-num").val() == "") {
      $(".mob-num.required").fadeIn();
      validcheck = false;
    }
    if ($("#emailId").val() == "") {
      $(".emailId.required").fadeIn();
      validcheck = false;
    }
    debugger;
   var impCase = $('input[name=IMPCase]:checked').val();
   if (impCase == "" || impCase==null || impCase==undefined) {
    $(".impcasechk.required").fadeIn();
    validcheck = false;
    }
*/
    return validcheck;
  }

}
