import { Component, OnInit } from '@angular/core';
import { FeeMasterServiceService } from '../../service/fee-master-service.service';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators } from '@angular/forms'
// import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
declare var $: any;
@Component({
  selector: 'app-delivery-partners',
  templateUrl: './delivery-partners.component.html',
  styleUrls: ['./delivery-partners.component.css']
})
export class DeliveryPartnersComponent implements OnInit {
   deliveryPartner: FormGroup;

  constructor(private fb: FormBuilder, private _getservie: FeeMasterServiceService, private _http: HttpClient, private toastr: ToastrService) { this.createForm(); }

  getalldelivery: any = [];
  delivery: any = {};
  DeliveryMasterObj: any = [];
  VisaDeliveryObj: {};
  err: any = {};
  deleteData: any = {};
  
  ngOnInit() {
    this.GetAllDelivery();

  }

 // Getter for easy access
 get deliveryTxturl() { return this.deliveryPartner.get('deliveryTxturl') };
 get deliveryTxtname() { return this.deliveryPartner.get('deliveryTxtname') };

  createForm() {
    const reg = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
    this.deliveryPartner = this.fb.group({
      deliveryTxtname: ['', [Validators.required, Validators.pattern("[a-zA-Z ]*"), Validators.maxLength(30)]],
      deliveryTxturl: ['', [Validators.required, Validators.pattern(reg)]],
    });
  }

  GetAllDelivery() {
    this._getservie.GetAll_Delivery().subscribe(
      (data: any) => {
        this.getalldelivery = data.result;
        //  this.getalldelivery = this.getalldelivery.data;
      },
      error => {
        this.err = error;
        this.toastr.error("Error :" + this.err.message);
      }
    );
  }
  // Add Delivery method
  AddDelivery() {
  if(this.delivery.txtname.trim()==""||this.delivery.txturl.trim()==""){
    this.toastr.warning("Name (or) URL can't be empty!");
    return true;
  }

    this.VisaDeliveryObj =
      {
        "id": 0,
        "name": this.delivery.txtname.trim(),
        "url": this.delivery.txturl,
      };

    //console.log(JSON.stringify(this.VisaDeliveryObj));
    this._getservie.Create_Adddelivery(this.VisaDeliveryObj)
      .subscribe(
        (data: any) => {
          this.toastr.success(data["messsage"]);
          $('#adduserClose').click();
          this.clearmodal();
          this.GetAllDelivery();
        },
        error => {
          this.err = error;
          this.toastr.error('Error :' + this.err.statusText);
        }
      );
  }

  urlvalidation(a) {
    debugger;
    const reg = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
    // return reg.test(a);
  }
  Edit_DeliveryMaster(deliveryedit) {

    this.DeliveryMasterObj = [];
   // console.log(deliveryedit);
    this.DeliveryMasterObj = deliveryedit;
  }

  //// Update Visa Fee Master
  updateDelivery = function (DeliveryModel) {

    if(DeliveryModel.name.trim()===""||DeliveryModel.url.trim()===""){
      this.toastr.warning("Name (or) URL can't be empty!");
      return true;
    }
    this.VisaDeliveryObj = {};
    this.VisaDeliveryObj =
      {
        'id': DeliveryModel.id,
        'name': DeliveryModel.name,
        'url': DeliveryModel.url
      };
    //console.log(JSON.stringify(this.VisaDeliveryObj));
    this._getservie.Update_DeliveryMaster(this.VisaDeliveryObj)
      .subscribe(
        (data: any) => {
          if (data.status === true) {
            this.toastr.success(data["messsage"]);
            this.GetAllDelivery();
            $('#aupdateClose').click();
          }
        },
        error => {
          this.err = error;
          this.toastr.error('Error :' + this.err.statusText);
        });

  };

  // Delete Or Ative Deactive Delivery Master
  // Delete Fee Master
  deleteDeliveryMaster(dele) {

    this.deleteData = [];
    this.deleteData.push(dele);

  }


  delete_Master(deleteData) {

    this._getservie.deleteDeliverymaster(deleteData.id).subscribe(
      data => {
        const temp = data;

        $('#btnclose1').click();
        $('#btnclose').click();
        this.toastr.success(data["messsage"]);
        this.GetAllDelivery();

      },
      error => {
        this.err = error;
        this.toastr.error('Error :' + this.err.statusText);
      }
    );
  }

  // Setting NULL for dropdown
  clearmodal = function () {
    this.delivery.txtname = null;
    this.delivery.txturl = null;
  }


}


