import { Component, OnInit } from '@angular/core';
import { FeeMasterServiceService } from '../../service/fee-master-service.service';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { debug } from 'util';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { VisaprocessingService } from 'src/app/service/visaProcessingService';
import { AddVisaService } from 'src/app/service/addVisaSerivce';
import { Newappservice } from '../../service/Newapplication_Service';

declare var $: any;

@Component({
  selector: 'app-servicecharge-master',
  templateUrl: './servicecharge-master.component.html',
  styleUrls: ['./servicecharge-master.component.css']
})
export class ServicechargeMasterComponent implements OnInit {
  AllServiceChMasterdata: any = [];
  AllFeeMasterdata: any = [];
  getFeeMaster: any = {};
  getallCountry: any = [];
  getVisaOffices: any = [];
  HttpErrorResponse: any = {};
  err: any = {};
  objdisplayedit: any = {};
  editfeeMaster: any = {};
  visaUpdateModel: any = [];
  deleteData: any = [];
  visaFeeMasterObj: any = {};
  visObj: any = {};
  visaFees: string = '';
  brancharr = [];
  editFeeMasterObj: any = {};
  editfee: any;
  edit_CountryCode: string = '';
  UpdateFeeMasterObj: any = [];
  registerForm: FormGroup;
  submitted = false;
  visaTypeData: any = {};
  visatypeArray: any = [];
  vendorArray: any = [];
  serviceCharges: any;
  VisaFeeObject: {};
  updatechrgemaster: any = {};
  visatype: any;
  ServiceChargeObj: any = {};
  chargeupdateobj: any = {};
  countryarray: any = [];
  searchString: string = '';
  locationData: any = {};
  customerDetailsData: any = {};
  // -- Pagination --
  paginationSize = 10;
  page: number = 1;
  pageShow = 0;
  totalNoofpages: number = 0;
  selectedIndex: number = 0;
  totalcount: any;
  servicechargearr: any = [];
  custlength: number = 0;
  disabled: boolean = true;
  PageSizeArray: number[];
  SearchKeyWord: string = '';
  getallCategory: any = [];
  fk_Countryid:any=0;
  
  constructor(private _service: FeeMasterServiceService, private _visaService: AddVisaService,
    private _http: HttpClient, private toastr: ToastrService,
    private formbuilder: FormBuilder, private _visaprocessing: VisaprocessingService, public _newappService: Newappservice ) { }

  ngOnInit() {
    $('#custDetailsTable').hide();
    this.disabled = true;
    //this.SearchKeyWord='';
    this.GetAllServiceMaster(this.page, this.paginationSize,this.SearchKeyWord);
    this.GetCountry();
    this.getLocationData();
    this.GetCategory();
    this.ServiceChargeObj.iCust = null;
    this.ServiceChargeObj.fK_CountryId = null;
    this.ServiceChargeObj.fK_VisaTypeId = null;
    this.ServiceChargeObj.fK_VendorId = null;
    this.ServiceChargeObj.serviceCharge = null;
    this.ServiceChargeObj.pattern = null;
    this.ServiceChargeObj.locationName = null;
    this.ServiceChargeObj.lstServiceCharges_VisaTypeMapper = [];
    this.ServiceChargeObj.CategoryMasterId = null;
    this.customerDetailsData = [];
    this.PageSizeArray = this._newappService.PageSizeArray;
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.registerForm.controls;
  }

  addservice() {
    $('#custDetailsTable').hide();
    this.disabled = true;
  }
  
  // All Fees Master List
  GetAllServiceMaster(page, paginationSize,SearchKeyWord) {
    
    $("#visaLoader").show();
    this._service.Getall_ServiceCharge(page, paginationSize,SearchKeyWord,this.fk_Countryid)
      .subscribe(
        data => {
          this.getFeeMaster = { data };
          this.AllServiceChMasterdata = this.getFeeMaster.data.result;

         
          console.info('this.AllServiceChMasterdata==',this.AllServiceChMasterdata);
         
          // -- for pagination
          this.totalcount = this.getFeeMaster.data.totalCount;
          this.totalNoofpages = Math.ceil(
            this.getFeeMaster.data.totalCount / this.paginationSize
          );
          $("#visaLoader").hide();
        },

        error => {
          this.err = error;
          this.toastr.error('Error :' + this.err.statusText);
          $("#visaLoader").hide();
        }

      );
  }

  // ---- Pagination Code Start here ----
  noofRecordstoDisplay(args: any) {
    // tslint:disable-next-line:radix
    this.paginationSize = parseInt(args.target.value);
    this.page = 1;// setting to default value
    this.pageShow = 0;
  }
  currentPageBind(currPage: any) {
    this.selectedIndex = currPage;
    this.page = currPage;
    $('ul.pagination li').removeClass('active');
    this.ngOnInit();
  }
  nextPage(nextpageNumber: number) {
    this.page = nextpageNumber + 1;
    if (this.page <= this.totalNoofpages) {
      this.pageShow = this.pageShow + 10;
    }
  }
  previousPage(prevPageNumber: number) {
    if (prevPageNumber >= 0) {
      this.page = prevPageNumber + 1;
      this.pageShow = this.pageShow - 10;
    }
  }



  Addmodal = function () {
    this.ServiceChargeObj.FK_CountryId = null;
    this.ServiceChargeObj.Vistypeid = null;
    this.ServiceChargeObj.vendorid = null;
  }
  // Get All Country list
  GetCountry() {
    this._visaprocessing.getCountry().subscribe(
      data => {
        this.getallCountry = { data };
        this.getallCountry = this.getallCountry.data;
      },
      error => {
        this.err = error;
        this.toastr.error("Error :" + this.err.message);
      }
    );
  }
  // AddServiceChargeMaster

  AddServiceChargeMaster() {
    debugger;

    console.info(JSON.stringify(this.ServiceChargeObj));
    this._visaprocessing
      .createserviceChart(this.ServiceChargeObj)
      .subscribe(
        (data: any) => {
          debugger;
          this.toastr.success(data["messsage"]);
          this.GetAllServiceMaster(this.page, this.paginationSize,this.SearchKeyWord);
          $('#addPopupClose').click();
          this.closeadd();
        },
        (error: HttpErrorResponse) => {
          this.err = error;
          this.toastr.error("Error " + this.err.statusText);
        }
      );
  }


  //Bind Edit Controler inside popup for update
  ServiceCharteredit(objdisplayedit) {
    console.info(objdisplayedit);
    this.servicechargearr = objdisplayedit;
  }

  // update popup entry data
  ServiceObjupdate(chargeupdateobj) {
    debugger;
    this.updatechrgemaster = {};
    this.updatechrgemaster =
      {
        'Id': chargeupdateobj.id,
        'Icust': chargeupdateobj.icust,
        'serviceCharge': chargeupdateobj.serviceCharge,
        'vendorName': chargeupdateobj.vendorName,
        'FK_VisaTypeId': chargeupdateobj.fK_VisaTypeId,
        'counrtyName': chargeupdateobj.counrtyName,
        'fK_CountryId': chargeupdateobj.fK_CountryId
      }
    //console.log(JSON.stringify(this.updatechrgemaster));
    this._service._updteServiceCharge(this.updatechrgemaster)
      .subscribe(
        (data: any) => {
          if (data.status === true) {
            this.toastr.success(data['messsage']);
            this.GetAllServiceMaster(this.page, this.paginationSize,this.SearchKeyWord);
            $('#editclose').click();
          }
        },
        error => {
          this.err = error;
          this.toastr.error('Error :' + this.err.statusText);
        });

  }

  // Get Country Code on change on country change
  getCountry_changeonCreate = function () {
    this.CountryCode = this.ServiceChargeObj.fK_CountryId;

    if (this.CountryCode !== null) {
      this.GetCountry();
      this.getVisatypeByCountry(this.CountryCode); // Geting Visa Type
      this.getVendorByCountry(this.CountryCode); // Geting Visa office
    } else {
      this.Addmodal();
    }
  };
  // Get Visa Type by Country Code
  getVisatypeByCountry(countryCode) {
    this.visatypeArray = [];
    if (countryCode != null) {
      this._visaprocessing._getVisaTypebyCountry(countryCode).subscribe(
        (data: any) => {
          this.visaTypeData = {};
          this.visaTypeData = data.result;
          this.visatypeArray = this.visaTypeData;
          //console.log(this.visatypeArray);
        },
        err => {
          this.toastr.error("Error :" + this.err.message);
        }
      );
    } else {
      this.Addmodal();
    }
  }



  // Get Visa office/Vendor by Country Code
  getVendorByCountry(countryCode) {
    this.vendorArray = [];
    if (countryCode != null) {
      this._visaprocessing.Get_VendorbyCountryId(countryCode).subscribe(
        (data: any) => {
          const tempobj = [];
          tempobj.push(data.result);
          this.vendorArray = tempobj[0];
          //console.log(this.vendorArray);
        },
        err => {
          this.toastr.error("Error :" + this.err.message);
        }
      );
    } else {
      this.ServiceChargeObj.visaType = null;
      // this.ServiceChargeObj.no_ = null;
    }
  }

  
  getVisatype_changeonCreate (visa) {
    
    
    // this.ServiceChargeObj.multipleVisaTypeId.push(visa.id);
    // console.info("sss -" + this.ServiceChargeObj.multipleVisaTypeId);

    let obj ={Fk_VisaTypeId:visa.id};
    
    this.ServiceChargeObj.lstServiceCharges_VisaTypeMapper.push(obj);


    console.info("sss -" + this.ServiceChargeObj);

    console.info("obj -" + obj);
    
  };


  updateVisaMaster = function (VisaModel) {
    let CountryId = null;    // For Country Id
    if (VisaModel.countryID != null) {
      CountryId = this.getallCountry.find(x => x.countryCode === VisaModel.countryID).id;
    }
    this.VisaFeeObject = {};
    this.VisaFeeObject =
      {
        'id': VisaModel.id,
        'iCust': VisaModel.iCust,
        'travellerType': VisaModel.travellerType,
        'visaType': VisaModel.visaType,
        'visaFees': VisaModel.visaFees,
        'vfsFees': VisaModel.vfsFees,
        'serviceCharges': VisaModel.serviceCharges,
        'fK_CountryMasterId': CountryId, // this.visaFeeMasterObj.fK_CountryMasterId,
        'vendorCode': VisaModel.vendorCode,
        'vendorName': VisaModel.vendorName,
      };
    //console.log(JSON.stringify(this.VisaFeeObject));
    this._service.FeeVisaMaster_update(this.VisaFeeObject)
      .subscribe(
        data => {
          if (data.status === true) {
            this.toastr.success(data["messsage"]);
            this.GetAllServiceMaster();
            $('#editclose').click();
          }
        },
        error => {
          this.err = error;
          this.toastr.error('Error :' + this.err.statusText);
        });

  };
  // Delete Fee Master
  delete_MServiceMaster(deleteData) {
    debugger;
    this._service.deleteServiceChargemaster(deleteData.id)
      .subscribe(
        data => {
          const temp = data;
          this.toastr.success(data["messsage"]);

          $('#btnclose').click();

          $('#btnclose1').click();


          this.GetAllServiceMaster(this.page, this.paginationSize,this.SearchKeyWord);
        },
        error => {
          this.err = error;
          this.toastr.error('Error :' + this.err.statusText);
        }
      );
  }

  getLocationData() {
    this._visaService._getLocationList()
      .subscribe(result => {
        this.locationData = result;
      },
        (err: HttpErrorResponse) => {
          console.error(err.message);    // SHOW ERRORS IF ANY.
        });
  }

  getCustDetailsById() {
    const pattern = this.ServiceChargeObj.pattern;
    const locationname = this.ServiceChargeObj.locationName;
    this._visaService._getCustDetailsListById(locationname, pattern)
      .subscribe((successData: any) => {
        if (successData.status == true) {
          if (successData.result.responseData.length > 0) {
            $('#custDetailsTable').show();
            this.disabled = true;
            this.customerDetailsData = successData.result.responseData;
          }
          else {
            this.toastr.warning('No Data Found for Given Search Result');
          }
          //console.log(successData);
        }
      },
        (err: HttpErrorResponse) => {
          console.error(err.message);    // SHOW ERRORS IF ANY.
        });
  }

  bindCustomerDetails(cust) {
    this.ServiceChargeObj.iCust = cust.no_;
    if(this.ServiceChargeObj.fK_CountryId === null ||
   this.ServiceChargeObj.fK_VendorId === null ||
    this.ServiceChargeObj.fK_VisaTypeId === null ||
    this.ServiceChargeObj.iCust === null ||
      this.ServiceChargeObj.locationName === null ||
      this.ServiceChargeObj.pattern === null ||
    this.ServiceChargeObj.serviceCharge === null){
    debugger;
    this.disabled = true;
    } else {
      this.disabled = false;
    }
    
  }

  delete_visaCharge(dele) {

    this.deleteData = [];
    this.deleteData.push(dele);

  }

  //#region -- search autocomplete --
  onSearch_autocomplete(values) {
    if (values !== "") {
      this._visaprocessing.CountryAutocomplete(values)
        .subscribe(
          (data: any) => {
            this.countryarray = [];
            this.countryarray.push(data.result);
            $("#countrysearch").autocomplete({   //  -------- Autocomplete populate --- 
              source: function (request, response) {
                response($.map(data.result, function (value, key) {
                  return {
                    label: value.name,
                    value: value.id,
                  };
                }));
              },
              focus: function (event, ui) {
                $('#countrysearch').val(ui.item.label);
                return false;
              },
              // Once a value in the drop down list is selected, do the following:
              select: function (event, ui) {
                // place the person.given_name value into the textfield called 'select_origin'...
                $('#countrysearch').val(ui.item.label);
                $('#selectedSearchId').val(ui.item.value);
                return false;
              },
            });
          },
          error => {
            this.err = error;
            this.toastr.error('Error :' + this.err.statusText);
          });
    }

  }
  //#endregion

  // #region --Search By country
  SearchbyCountry() {
    this.page = 1;
    this.GetAllServiceMaster(this.page, this.paginationSize,this.SearchKeyWord);

    // this.searchString = $('#selectedSearchId').val();

    // if(this.searchString==""||this.searchString==null){

    //   this.toastr.error('Error : Country Not Found.');
    //   $("#visaLoader").hide();
    //   return false;
    // }
    
    // this.AllServiceChMasterdata = [];
    // $("#visaLoader").show();
    // this._visaprocessing.SearchbyCountry(this.searchString, this.page, this.paginationSize)
    //   .subscribe(
    //     data => {
    //       this.getFeeMaster = { data };
    //       this.AllServiceChMasterdata = this.getFeeMaster.data.result;
    //       // -- for pagination
    //       this.totalcount = this.getFeeMaster.data.totalCount;
    //       this.totalNoofpages = Math.ceil(this.getFeeMaster.data.totalCount / this.paginationSize);
    //       $("#visaLoader").hide();
    //       $('#selectedSearchId').val("");
    //     },
    //     error => {
    //       this.err = error;
    //       this.toastr.error('Error :' + this.err.statusText);
    //       $("#visaLoader").hide();
    //     }
    //   );
  }
  // #endregion
  myFunction() {
    this.searchString = "";
    $('#countrysearch').val("");
  }
  Reload() {
    this.SearchKeyWord='';
    this.ngOnInit();
  }
  closeadd() {
    this.disabled = true;
    this.ServiceChargeObj.iCust = null;
    this.ServiceChargeObj.fK_CountryId = null;
    this.ServiceChargeObj.fK_VisaTypeId = null;
    this.ServiceChargeObj.fK_VendorId = null;
    this.ServiceChargeObj.serviceCharge = null;
    this.ServiceChargeObj.pattern = null;
    this.ServiceChargeObj.locationName = null;
    this.ServiceChargeObj.lstServiceCharges_VisaTypeMapper = [];
    this.customerDetailsData = [];
  }
  noOfRecordsToShow(event: any) {
    this.paginationSize = parseInt(event.target.value);
    this.page = 1;
    this.GetAllServiceMaster(this.page, this.paginationSize,this.SearchKeyWord);
  }

  // Get All Country list
  GetCategory() {
    debugger;
    this._visaprocessing.getCategory().subscribe(
      data => {
        debugger;
        this.getallCategory = { data };
        this.getallCategory = this.getallCategory.data;
      },
      error => {
        this.err = error;
        this.toastr.error("Error :" + this.err.message);
      }
    );
  }

getvisaTypeonChange($event)
{
 
   if($event!=null)
   {
     this.fk_Countryid=$event.target.value
    this.GetAllServiceMaster(this.page, this.paginationSize,this.SearchKeyWord);
        
   }
  
   
}




}
