import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ClientsService } from '../service/clients.service';
import { Client, ClientDetails, CountryVendorMap, VisaCountryDocuments, VisaCountryDocumentsEdit } from '../models/client';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { AddVisaService } from '../service/addVisaSerivce';
import { HttpErrorResponse } from '@angular/common/http';
import { GlobalServiceService } from '../service/global-service.service';
import { Router } from '@angular/router';
import { VisaprocessingService } from '../service/visaProcessingService';

declare var $: any;

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.css']
})
export class ClientsComponent implements OnInit {

  visaCountryData: any = [];
  visaOfficeData: any = [];
  clientName: string;
  uneditableClientInfo: string;
  currentClient: Client;
  CVMaps: number[] = [0];
  extraDocuments: string;
  clientsToDisplay: any[] = [];
  countryDocToDisplay: any[] = [];
  countryDocAll: any[] = [];
  dropdownSettings: any = {};
  multiSelectOptions: any[] = [];
  multiOptionsSelected: any[] = [];
  documentToEdit: VisaCountryDocumentsEdit;
  visaTypeData: any = {};
  visatypeArray: any = [];
  countryForDocument: number;
  countryDocumentOptions: any[] = [
    {
      Name: "Passport"
    },
    {
      Name: "Photos"
    },
    {
      Name: "Travel Tickets"
    },
    {
      Name: "Accommodation/Hotel Confirmation"
    },
    {
      Name: "Forex/Credit Card"
    },
    {
      Name: "Bank Statement"
    },
    {
      Name: "Income Tax Papers"
    },
    {
      Name: "Proof of Self-employment"
    },
    {
      Name: "Travel Insurance"
    },
    {
      Name: "Covering Letter"
    },
    {
      Name: "Invitation Letter"
    },
    {
      Name: "NOC Document"
    }
  ];

  countryVendorMappingArray: CountryVendorMap[] = [
    {
      countryOptions: [],
      selectedCountry: null,
      selectedVendor: null,
      vendorOptions: [],
      visaTypes: ""
    }

  ];

  visaObj: any = {
    visaCustomer: {},
    lstApplicationStatus: [],
    lstVisaMiscFees: [],
    lstVisaApplBranch: [],
    lstVisaPassangerDetail: []
  };


  getallCountry: any = [];
  countries: any = [];
  err: any = {};

  form: FormGroup

  constructor(
    private _visaService: AddVisaService,
    private _clientService: ClientsService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    public _globle: GlobalServiceService,
    private _visaprocessing: VisaprocessingService,
    private r: Router
  ) { }

  ngOnInit() {
    this.GetCountry();
    this.GetClients();
    this.GetCountryWiseDocuments();
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 5,
      allowSearchFilter: true,
      enableCheckAll: false
    };
  }

  resetVariables(): void {
    this.clientName = "";
    this.uneditableClientInfo = "";
    this.CVMaps = [0];
    this.extraDocuments = "";
    this.countryVendorMappingArray = [
      {
        countryOptions: [],
        selectedCountry: null,
        selectedVendor: null,
        vendorOptions: [],
        visaTypes: null
      }

    ];
    this.multiOptionsSelected = []; 
    $.each($("input[name='docReq']"), function () {
      $(this).parent().parent().removeClass("active");
      $(this).prop("checked", false);
      $(this).removeAttr("checked");
    });
    // $.each($("input[name='docReq']:checked"), function () {
      
    // });
    this.extraDocuments = null;
    this.documentToEdit = {} as VisaCountryDocumentsEdit;
    this.documentToEdit.Documents = [];
    this.documentToEdit.ExtraDocuments = "";
  }

  redirectToAddPage() {
    this.r.navigate(['../Admin/ClientForm']);
  }


  toggleClient(c) {
    this.currentClient = c;
  }


  toggleClientConfirm() {
    this._clientService.toggleClient(this.currentClient["id"]).subscribe(c => {
      if (c.status == true) {
        this.toastr.success("Success");
        this.GetClients();
        $('.closeModalBoxClass').click();
      }
    },
      err => {
        this.toastr.error("Error :" + this.err.message);
      });
  }

  editClient(c): void {
    this.resetVariables();
    this.currentClient = c;
    this.clientName = c.name;
    this.uneditableClientInfo = c.icust + "(" + c.userInfo + ")";
    let i = 0;
    c.details.forEach(d => {
      if (i == 0) {
        this.countryVendorMappingArray = [{
          countryOptions: [],
          selectedCountry: d.countryId,
          selectedVendor: d.vendorId,
          vendorOptions: [],
          visaTypes: null
        }];
        this.getVisaOfficeData(i);
      } else {
        this.countryVendorMappingArray.push({
          countryOptions: [],
          selectedCountry: d.countryId,
          selectedVendor: d.vendorId,
          vendorOptions: [],
          visaTypes: null
        });
        this.getVisaOfficeData(i);
        this.CVMaps.push(i);
      }
      i++;
    });
  }
  editDocument(documentToEdit: any) {
  this.resetVariables();
  setTimeout(() => {
    this.documentToEdit.CountryId = documentToEdit.value[0].countryId;
    this.documentToEdit.CountryName = documentToEdit.key;
    documentToEdit.value.forEach(e => {
      if (this.countryDocumentOptions.map(d => d.Name).indexOf(e.documentName) > -1) {
        this.documentToEdit.Documents.push(e.documentName);
      } else {
        this.documentToEdit.ExtraDocuments = this.documentToEdit.ExtraDocuments + ";" + e.documentName;
      }
    });
    if (this.documentToEdit.ExtraDocuments && this.documentToEdit.ExtraDocuments.length > 0) {
      this.documentToEdit.ExtraDocuments = this.documentToEdit.ExtraDocuments.substring(1);
    }
    $("#edit-country-doc").modal("show");
  }, 100);
  }



  addCountryVendorMap() {
    this.countryVendorMappingArray.push({ countryOptions: [], selectedCountry: null, selectedVendor: null, vendorOptions: [], visaTypes: null });
    this.CVMaps.push(this.CVMaps.length);
  }

  deleteDetail(index) {
    this.CVMaps.splice(this.CVMaps.length - 1, 1);
    this.countryVendorMappingArray.splice(index, 1);
  }

  GetCountry() {
    this._visaService._getVisaFeesCountryList()
      .subscribe(result => {
        var data = { result };
        this.visaCountryData = data;
        // this.multiSelectOptions = this.visaCountryData.result.map((d) => ({ id: d.id, name: d.name }));

      },
        (err: HttpErrorResponse) => {
          console.error(err.message);    // SHOW ERRORS IF ANY.
        });
  }

  // getFilteredCountriesForCLientDD(){
  //   if(this.countryVendorMappingArray.length > 1){
  //   var b= this.visaCountryData.result;
  //  var selectedCountries = this.countryVendorMappingArray.map(d=>d.selectedCountry);
  //  var a = this.visaCountryData.result.filter(d=>selectedCountries.indexOf(d.id) == -1);
  //  debugger;
  //  return this.visaCountryData.result.filter(d=>selectedCountries.indexOf(d.id) == -1);
  //   }
  //  return this.visaCountryData.result;
  // }

  getVisaOfficeData(index: number) {
    this.countryVendorMappingArray[index].vendorOptions = [];
    this.countryVendorMappingArray[index].visaTypes ="";
    if(this.countryVendorMappingArray[index].selectedCountry != null){
    this._visaService._getVisaOfficeData(this.countryVendorMappingArray[index].selectedCountry, this.visaObj.fK_TransferBranchId, null)
      .subscribe((successData: any) => {
        if (successData.status == true) {
          this.countryVendorMappingArray[index].vendorOptions = successData.result;
          this.getVisaTypeDetails(this.countryVendorMappingArray[index].selectedCountry, this.visaObj.fK_TransferBranchId, index);
        }
      },
        (err: HttpErrorResponse) => {
          console.error(err.message);    // SHOW ERRORS IF ANY.
        });
      }
  }


  GetCountryWiseDocuments() {
    this._clientService.getCountryWiseDoc().subscribe(
      data => {
        if (data != null) {
          this.countryDocAll = data;
          this.countryDocToDisplay = data;
        }
      },
      error => {
        this.err = error;
        this.toastr.error("Error :" + this.err.message);
      }
    );
  }

  getCountrieForDocuments() {
    this.resetVariables();
    setTimeout(() => {
      this._visaService._getVisaFeesCountryList(true)
      .subscribe(result => {
        this.multiSelectOptions = result.map((d) => ({ id: d.id, name: d.name }));
      },
        (err: HttpErrorResponse) => {
          console.error(err.message);    // SHOW ERRORS IF ANY.
        });
        $("#add-country-doc").modal("show");
    }, 100);
  }

  GetClients() {
    this._clientService.get().subscribe(
      data => {
        if (data != null)
          this.clientsToDisplay = data;
      },
      error => {
        this.err = error;
        this.toastr.error("Error :" + this.err.message);
      }
    );
  }

  saveEditedClient() {
    let isValid = true;
    const detailsObj: ClientDetails[] = [];
    this.countryVendorMappingArray.forEach(d => {
      if(d.selectedCountry == null || d.selectedVendor == null){
        isValid = false;
      }
      detailsObj.push({
        CountryId: d.selectedCountry,
        VendorId: d.selectedVendor,
        ClientsId: 0,
        Id: 0
      })
    });
    if(detailsObj.length == 0){
      this.toastr.error('Please add details.');
      return;
    }
    if(!isValid){
      this.toastr.error('Country and vendor should be mapped.');
      return;
    }
    this.currentClient.Details = detailsObj;
    this.currentClient.Name = this.clientName;
    this.currentClient["details"] = null;
    this._clientService.save(this.currentClient).subscribe(d => {
      if (d.status) {
        this.toastr.success("Saved");
        $('.closeModalBoxClass').click();
        this.GetClients();
      }
    },
      err => {
        this.toastr.error("Error :" + this.err.message);
      })
  }


  public saveCountryDocument(fromEdit: boolean) {
    const countryReqDocs: VisaCountryDocuments[] = [];
    let docsSelected: string[] = [];
    $.each($("input[name='docReq']:checked"), function () {
      docsSelected.push($(this).val());
    });

    if (this.extraDocuments && fromEdit == false) {
      docsSelected = docsSelected.concat(this.extraDocuments.split(';'));
    }
    if (fromEdit == true && this.documentToEdit.ExtraDocuments && this.documentToEdit.ExtraDocuments.length > 0) {
      docsSelected = docsSelected.concat(this.documentToEdit.ExtraDocuments.split(';'));
    }

    docsSelected.forEach(d => {
      if (fromEdit) {
        countryReqDocs.push(
          {
            CountryId: this.documentToEdit.CountryId,
            Id: 0,
            DocumentName: d
          });
      } else {
        this.multiOptionsSelected.forEach(q => {
          countryReqDocs.push(
            {
              CountryId: q.id,
              Id: 0,
              DocumentName: d
            })
        });
      }
    });
    if(docsSelected.length == 0){
      this.toastr.error("Select/enter documents");
      return;
    }
    if(this.multiOptionsSelected.length == 0 && fromEdit == false){
      this.toastr.error("Select countries");
      return;
    }
    this._clientService.addCountryDocument(countryReqDocs).subscribe(d => {
      this.toastr.success("Saved");
      $('.closeModalBoxClass').click();
      this.GetCountryWiseDocuments();
    },
      err => {
        this.toastr.error("Error :" + this.err.message);
      })
  }


  getVisaTypeDetails(countryId, vendorId, map) {
    this.countryVendorMappingArray[map].visaTypes ="";
    if(vendorId != null && countryId != null){
      this._visaprocessing._getVisaTypebyCountry(countryId, vendorId).subscribe(
        (data: any) => {
          if (data.success)
            this.visaTypeData = {};
          this.visaTypeData = data.result;
          this.countryVendorMappingArray[map].visaTypes = this.visaTypeData.map(q => q.name).join(", ");
        },
        err => {
          this.toastr.error("Error :" + this.err.message);
        }
      );
    }
  }
}