import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class CashierCreditService {
  ApiUrl: string = null;

  pagesize: number;
  page: number;
  flag = 'True';
  private data: any;
  token: any;
  userId: any;
 
  constructor(private _http: HttpClient,private _router: Router,private datePipe: DatePipe) { 
    debugger;
    this.ApiUrl = environment.ApiUrl;
    this.data = JSON.parse(localStorage.getItem('currentUser'));
    if(this.data==null)
    {
      this._router.navigate(['/']);
    }
    else{
    this.token = this.data.token;
    this.userId = this.data.userId;
    }

  }

  getCashierApplicationList(obj: any, page, pagesize) {          // --Changes required parameter has to be sent after login------------
   debugger;
    obj.page = page;
    obj.Pagesize = pagesize;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
   
    return this._http.post(this.ApiUrl + '/CashierCredit/GetCashierVisaApplications', obj, { headers: headers });

  }

  _getEditVisaById(ID: string) {

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.get(this.ApiUrl + "/CashierCredit/VisaAppGetById?id=" + ID, { headers: headers });
  }

  VoucherInsert(Obj:any) {


    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.post(this.ApiUrl + "/CashierCredit/VoucherInsert", Obj, { headers: headers });
  }

  OutStandingReport(OutStandingReport:any){
  
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
  //  OutStandingReport.frmdt = this.datePipe.transform(OutStandingReport.frmdt, 'M/d/yyyy');
   // OutStandingReport.todt = this.datePipe.transform(OutStandingReport.todt, 'M/d/yyyy');
    
    console.warn("report"+OutStandingReport);
     return this._http.post(environment.ApiUrl + '/CashierCredit/OutStandingReportExcel',OutStandingReport, { headers: headers} );
   // return this._http.get(environment.ApiUrl + '/Reports/ReportsDownload?ReportName='+status+ '&FromDate='+txtfrom+ '&Todate='+txtTodate+'&brachArr='+brachArr+'&icustarr='+icustarr, { headers: headers} );
  }
  _InvoiceUploadVisaTracker(Obj):Observable<any> {
     console.log(Obj);
     debugger;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.post(environment.ApiUrl + "/CashierCredit/UploadExcelInvoiceData",Obj, { headers: headers });
  }
  getInvoicesSampleXls(){
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.get( environment.ApiUrl + '/VisaApplDocument/InvoiceFormatUploadSampleFile',{ headers:headers} );
  }
  _UploadInvoiceFile(fileName, dataKey) {   

    //let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.get(environment.ApiUrl + "/CashierCredit/UploadInoviceCreditControlExcel?key="+dataKey+"&filename="+fileName, { headers: headers });
  }

  GetInvoiceList(obj: any, page, pagesize) {          // --Changes required parameter has to be sent after login------------
    debugger;
     obj.page = page;
     obj.Pagesize = pagesize;
     let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
     headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    
     return this._http.post(this.ApiUrl + '/CashierCredit/GetInvoiceList', obj, { headers: headers });
 
   }

   _getBranchesByUser() {

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.get(this.ApiUrl + "/BranchMstr/getBranchesByUser", { headers: headers });
  }

  GetVoucherDetails(id) {

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.get(this.ApiUrl + "/CashierCredit/GetVoucherDataById/?id="+id, { headers: headers });
  }

}
