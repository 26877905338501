import { Component, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from 'src/app/service/login-service.service';
import { Router,Route,ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http/src/response';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';
import { LoginModel } from '../service/LoginModel';
declare var $: any;

@Component({
  selector: 'app-reset-default-password',
  templateUrl: './reset-default-password.component.html',
  styleUrls: ['./reset-default-password.component.css']
})
export class ResetDefaultPasswordComponent implements OnInit {
  public userpassworddata:any={};
  public passMatch:boolean;
  EmployeeCode:any;
  key:any;
  HideForm:boolean;
  subscription: Subscription;
  @ViewChild('chngpass') form;
  // message: any = {};
  public LoginModelDetails: LoginModel = new LoginModel();
  
  constructor(private _router: Router, private _toaster: ToastrService, private _loginService:LoginService, private route: ActivatedRoute) { 
    //this.subscription = this._loginService.getMessage().subscribe(message => { this.message = message; });
    this.LoginModelDetails = this._loginService.getLoginDetials(); 
  }

  ngOnInit() {
    if(this.LoginModelDetails.Username==""||null){
      //this._router.navigate(['/']);
    }
   this.HideForm=true;

    //added by bhavika to get encrypted employee code to reset password
    this.route.queryParams.subscribe(params => {
      console.info('params==',params);
      this.key =params["key"];

      
      this._loginService._getEncryptedEmailID(this.key)
      .subscribe(data=>{
       // console.info("iddd",data);
        this.EmployeeCode= data;
        this.LoginModelDetails.Username=this.EmployeeCode.result;
      },
      
      (error: HttpErrorResponse) => {

        $("#changePwdBtn").prop('disabled', true);
        this.userpassworddata={};          
        // console.error(error);
        if(error.status==500){
            this._toaster.error('Invalid or Expired URL');
            this.HideForm=false;
        }else{
          this._toaster.error('Invalid or Expired URL!');
          this.HideForm=false;
        }
      }
      
      
      
      )
      
    })
  }

  checkPassword(event) {
    const password = this.userpassworddata.newPwd;
    const confirm_new_password = event.target.value;

    if (password !== undefined) {
      if (confirm_new_password !== password) {
        this.passMatch = true;
      } else {
        this.passMatch = false;
      }
    }
  }

  createChangepass() {     

    $("#changePwdBtn").prop('disabled', true);
    this.LoginModelDetails.OTP= this.userpassworddata.OTP;
    this.LoginModelDetails.newPassword= this.userpassworddata.newPassword;
    this.LoginModelDetails.cnfrmNewPassword=this.userpassworddata.cnfrmNewPassword; 

    $('.required').fadeOut();
if(this.userpassworddata.OTP=="" || this.userpassworddata.OTP==undefined)
{
  
  $(".otp.required").fadeIn();
  return false;
}


if(this.userpassworddata.newPassword=="" || this.userpassworddata.newPassword==undefined)
{
 
  $(".newpwd.required").fadeIn();
  return false;
}
if(this.userpassworddata.cnfrmNewPassword=="" || this.userpassworddata.cnfrmNewPassword==undefined)
{
  
  $(".cnfrmNewPassword.required").fadeIn();
  return false;
}

let regexp = new RegExp('(?=^.{9,15}$)(?=.*[A-Z])(?=.*[a-z])(?=.*[@$!%*?&]).*$');
    
var testpwdformat = regexp.test(this.userpassworddata.newPassword);

if(!testpwdformat)
{
this._toaster.error("Invalid Password (1 upper case, 1 lowercase,1 special character and minimum nine character)"); 
$("#visaLoader").hide();   
//this._toaster.error("Invalid Password (1 upper case, 1 lowercase, 1 special character and 1 digit, minimum nine character)"); 
// $("#changePwdBtn").prop('disabled', false);     
return false;
}

  
    if(this.LoginModelDetails.newPassword !== this.LoginModelDetails.cnfrmNewPassword){      
        //this._toaster.error("New Password and Confirm password should be same."); 
        this._toaster.error("Re-Type password does not match."); 
        
        $("#changePwdBtn").prop('disabled', false);     
        return false;
    }
    else{
      debugger;
     // this.userpassworddata.oldPwd = 'Test@12345';
      this._loginService._changePassword(this.LoginModelDetails,this.key)          
        .subscribe(
          (SuccesData:any) => { 
            debugger;
            $("#changePwdBtn").prop('disabled', false);
            //console.log(SuccesData);
            if(SuccesData.status == true){
              this.userpassworddata={};
              this._toaster.success(SuccesData.messsage);
              localStorage.clear();
              this._router.navigate(['/']);
            }
            else{
              this._toaster.error(SuccesData.messsage);
            }
          },
        (error: HttpErrorResponse) => {
          $("#changePwdBtn").prop('disabled', false);
          this.userpassworddata={};          
          // console.error(error);
          if(error.status==500){
              this._toaster.error(error.error.messsage);
          }else{
            this._toaster.error('Password Updation Failed!');
          }
        });
      } 
   }
}
