import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class BranchExecutiveAuthGuardService implements CanActivate {

  constructor(private _router: Router) { }
  canActivate()
    {
        if (localStorage.getItem('branchExecutiveUser'))
        {
            // logged in so return true
            // alert('branchExecutiveUser');

            return true;
        }

        // not logged in so redirect to login page
        this._router.navigate(['/']);
        return false;
    }
}
