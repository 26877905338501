import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Newappservice } from '../service/Newapplication_Service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';


declare var $: any;
@Component({
  selector: 'app-closed-app',
  templateUrl: './closed-app.component.html',
  styleUrls: ['./closed-app.component.css']
})
export class ClosedAPPComponent implements OnInit {
  visaName = 'Closed ';
  id: number = 4;

  listobj: any = {};
  applicationarry = [];
  userupdatemodel = [];
  err: any;
  paginationSize = 10;
  page: number = 1;
  pageShow = 0;
  totalNoofpages: number = 0;      // ---- pending due to api response is 0.
  totalRecords: number = 0;
  selectedIndex: number = 0;
  viewCorporate: any = null;
  newdata: any;
  visaUserType: string = null;
  private data: any;
  PageSizeArray: number[];
  constructor(private _http: HttpClient, private _newappService: Newappservice, private toastr: ToastrService, private _router: Router) { }
  ngOnInit() {
    this.PageSizeArray = this._newappService.PageSizeArray;
    $('#searchTag').hide();
    this.data = JSON.parse(localStorage.getItem('currentUser'));
    this.visaUserType = this.data.userType;
    // --------------------------- MENU ID HAS TO PASS------------
    // this.id =3;
    this.getapplicationlist(this.id, this.page, this.paginationSize);
  }
  getapplicationlist(id, page, paginationSize) {
    
    $("#visaLoader").show();
    this._newappService.getApplicationList(id, page, paginationSize,0,0,0,0,'','')
      .subscribe(
        data => {
          $("#visaLoader").hide();
          this.listobj = data;

          $('#newApplCount').text(this.listobj.allStatusCount.status_1);
          $('#pendingApplCount').text(this.listobj.allStatusCount.status_2);
          $('#submittedApplCount').text(this.listobj.allStatusCount.status_3);
          $('#closedApplCount').text(this.listobj.allStatusCount.status_4);
          
          this.applicationarry = this.listobj.result;
          this.totalNoofpages = Math.ceil(this.listobj.totalCount / this.paginationSize);
        },
        error => {
          $("#visaLoader").hide();
          this.err = error;
          // console.error(error);
          if(this.err.status==0||this.err.status==401){
            localStorage.clear();
            this.toastr.error("Session Expired! Please login to Continue..");
            this._router.navigate(['/']);
          }
          else{
             this.toastr.error('Error :' + this.err.message);
          }
        }
      );
  }


  // ---- Pagination Code Start here ---- 
  currentPageBind(currPage: any) {
    this.selectedIndex = currPage;
    this.page = currPage;
    $('ul.pagination li').removeClass('active');
    this.ngOnInit();
  }
  nextPage(nextpageNumber: number) {
    this.page = nextpageNumber + 1;
    console.info("this.page==",this.page);
    console.info("this.totalNoofpages==",this.totalNoofpages);
    console.info("this.pageShow==",this.pageShow);
    if (this.page >= this.totalNoofpages) {
      this.pageShow = this.pageShow + 10;
    }
  }
  previousPage(prevPageNumber: number) {
    if (prevPageNumber >= 0) {
      this.page = prevPageNumber + 1;
      this.pageShow = this.pageShow - 10;
    }
  }
  noOfRecordsToShow(event: any) {
    this.paginationSize = parseInt(event.target.value);
    this.page = 1;
    this.getapplicationlist(this.id, this.page, this.paginationSize);
  }
  navigateTableRow(id: any) {
    this._router.navigate(['/' + this.visaUserType + '/editVisaApplication'], { queryParams: { id: id } });
  }
}
