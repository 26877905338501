import { Component, OnInit } from '@angular/core';
import { AddVisaService } from 'src/app/service/addVisaSerivce';
import { Route, ActivatedRoute, Router, NavigationExtras } from '@angular/router';
declare var $: any;
@Component({
  selector: 'app-b2b-rpgresponse',
  templateUrl: './b2b-rpgresponse.component.html',
  styleUrls: ['./b2b-rpgresponse.component.css']
})

export class B2bRPGResponseComponent implements OnInit {
  token: any;
  orderID: any;
  status:any;
  ed:any;
  txnID:any;
  failureMsg:any;
  applicationRef:any;
  private localData: any;
  visaUserType: string = null;
  Payment: any = {};
  orderdata:any;
  constructor(private _router: Router,private _route: ActivatedRoute,private _visaService: AddVisaService) { 
    this.localData = JSON.parse(localStorage.getItem('currentUser'));
    if(this.localData==null)
    {
      this._router.navigate(['/']);
    }
    else{
    this.token = this.localData.token;
    this.visaUserType = this.localData.userType;
    }
    $(".inner-content").css("padding", "92px 15px 0 15px");
  }

  ngOnInit() {

     this.orderID=this._route.snapshot.queryParams.orderid;
     this.status=this._route.snapshot.queryParams.status;
     this.ed=this._route.snapshot.queryParams.ed;
     this.txnID=this._route.snapshot.queryParams.txnID;
     this.failureMsg=this._route.snapshot.queryParams.failureMsg;


     this.Payment.applicationRef =this.orderID;
     this.Payment.orderID =this.orderID;
     this.Payment.status =this.status
     this.Payment.isMatched =''
     this.Payment.txnID =this.txnID;
     this.Payment.failureMessage=this.failureMsg; 
     this.Payment.responseString='';
     this.Payment.ed =this.ed;
     $('#visaLoader').show();
     debugger;
     this._visaService._payStatusUpdation(this.Payment)
    .subscribe(data=>{
      this.orderdata=data;
      $('#visaLoader').hide();
      this.applicationRef=this.orderdata.result;

      localStorage.setItem('PayStatus',this.status);
      localStorage.setItem('Appid',this.applicationRef);

      if(this.status.toLowerCase()=='success')
      {

        this.insertUserLog("Payment Successful")
        this._router.navigate([this.visaUserType + '/B2bAppSuccess'], { queryParams: { id:this.applicationRef,status:this.status}});
             
      }
      else{
        this.insertUserLog("Payment Failed")
        this._router.navigate([this.visaUserType + '/B2bAppSuccess'], { queryParams: { id:this.applicationRef,status:this.status}});
        
      }
    });




  }

  
insertUserLog(stepname)
{
  let aiUserID=localStorage.getItem('AIUserID');
  let usertype="Agent";
//console.info(aiUserID);

  if(aiUserID !=null && aiUserID !="")
  {
    usertype="Airindia";
  }

  this._visaService._InsertB2BUserLogs("Application",stepname,aiUserID,usertype)
  .subscribe((successData: any) => {
console.info(successData);

  });
}



  

}
