import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ApplicationcountService } from './CountService';
@Injectable({
  providedIn: 'root'
})
export class Newappservice {
  pagesize: number;
  page: number;
  flag: number;
  private data: any;
  token: any;
  username: any;
  public PageSizeArray = [10, 20, 50, 100, 200];
  // Download: any = {
  // };
  ApiUrl: string = "";
  constructor(private _http: HttpClient, private _getapplicationCountsapi: ApplicationcountService) {
    this.data = JSON.parse(localStorage.getItem('currentUser'));
    this.token = this.data.token;
    this.username = this.data.username;
    this.ApiUrl=environment.ApiUrl;
  }

  // ----> Get user by Id -- Created By : Pooja
  getApplicationList(id, page, pagesize,countryid,visatypeid,paymentstatus,appstatus,searchtext,PassportNum) {          // --Changes required parameter has to be sent after login------------
    this.page = page;
    this.pagesize = pagesize;
    this.flag = id;

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    //  this._getapplicationCountsapi.getapplicationCountsapi();
  //  return this._http.get(environment.ApiUrl + '/VisaApplication/GetVisaApplicationByStatus?page=' + this.page + '&Pagesize=' + this.pagesize + '&Status=' + this.flag + '', { headers: headers });
   // proc Call 
   
  // return this._http.get(environment.ApiUrl + '/B2BVisaApplication/GetVisaApplicationList?page=' + this.page + '&Pagesize=' + this.pagesize + '&Status=0', { headers: headers });
  
   return this._http.get(environment.ApiUrl + '/B2BVisaApplication/GetVisaApplicationSearch?page=' + this.page + '&Pagesize=' + this.pagesize + '&Status=0'+'&CountryID='+countryid+'&VisaTypeID='+visatypeid+'&PaymentStatus='+paymentstatus+'&AppStatus='+appstatus+'&SearchText='+searchtext+'&PassportNum='+PassportNum, { headers: headers });
  
   console.log(environment.ApiUrl);
    
  }

   // ----> Get user by Id -- Created By : Pooja
   getApplicationListByRef(id, page, pagesize,countryid,visatypeid,paymentstatus,appstatus,searchtext,PassportNum) {          // --Changes required parameter has to be sent after login------------
    this.page = page;
    this.pagesize = pagesize;
    this.flag = id;

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    //  this._getapplicationCountsapi.getapplicationCountsapi();
  //  return this._http.get(environment.ApiUrl + '/VisaApplication/GetVisaApplicationByStatus?page=' + this.page + '&Pagesize=' + this.pagesize + '&Status=' + this.flag + '', { headers: headers });
   // proc Call 
   
  // return this._http.get(environment.ApiUrl + '/B2BVisaApplication/GetVisaApplicationList?page=' + this.page + '&Pagesize=' + this.pagesize + '&Status=0', { headers: headers });
  
   return this._http.get(environment.ApiUrl + '/B2BVisaApplication/GetVisaApplicationSearchByRef?page=' + this.page + '&Pagesize=' + this.pagesize + '&Status=0'+'&CountryID='+countryid+'&VisaTypeID='+visatypeid+'&PaymentStatus='+paymentstatus+'&AppStatus='+appstatus+'&SearchText='+searchtext+'&PassportNum='+PassportNum, { headers: headers });
  
   console.log(environment.ApiUrl);
    
  }

  getApplicationListDownload(id, countryid,visatypeid,paymentstatus,appstatus,searchtext,PassportNum) {          // --Changes required parameter has to be sent after login------------
   // this.page = page;
    //this.pagesize = pagesize;
    this.flag = id;


    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    var ReportBody={
      VStatus:id,
      CountryID:countryid,
      VisaTypeID:visatypeid,
      PaymentStatus:paymentstatus,
      AppStatus:appstatus,
      SearchText:searchtext,
      PassportNum:PassportNum
    };
    console.warn("report"+ReportBody);
     return this._http.post(environment.ApiUrl + '/B2BVisaApplication/VisaApplicationDownload',ReportBody, { headers: headers} );
   

   
    
  }


  AIgetApplicationListDownload(aiuserid,id, countryid,visatypeid,paymentstatus,appstatus,searchtext,PassportNum) {          // --Changes required parameter has to be sent after login------------
    // this.page = page;
     //this.pagesize = pagesize;
     this.flag = id;
 
 
     let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
     headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
     var ReportBody={
       VStatus:id,
       CountryID:countryid,
       VisaTypeID:visatypeid,
       PaymentStatus:paymentstatus,
       AppStatus:appstatus,
       SearchText:searchtext,
       PassportNum:PassportNum,
       AIUserID:aiuserid
     };
     console.warn("report"+ReportBody);
      return this._http.post(environment.ApiUrl + '/B2BVisaApplication/AIVisaApplicationDownload',ReportBody, { headers: headers} );
    
 
    
     
   }
 

  AIgetApplicationList(aiuserid,id, page, pagesize,countryid,visatypeid,paymentstatus,appstatus,searchtext,PassportNum) {          // --Changes required parameter has to be sent after login------------
    this.page = page;
    this.pagesize = pagesize;
    this.flag = id;

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    //  this._getapplicationCountsapi.getapplicationCountsapi();
  //  return this._http.get(environment.ApiUrl + '/VisaApplication/GetVisaApplicationByStatus?page=' + this.page + '&Pagesize=' + this.pagesize + '&Status=' + this.flag + '', { headers: headers });
   // proc Call 
   
  // return this._http.get(environment.ApiUrl + '/B2BVisaApplication/GetVisaApplicationList?page=' + this.page + '&Pagesize=' + this.pagesize + '&Status=0', { headers: headers });
  
   return this._http.get(environment.ApiUrl + '/B2BVisaApplication/AIGetVisaApplicationSearch?AIuserid='+aiuserid+'&page=' + this.page + '&Pagesize=' + this.pagesize + '&Status=0'+'&CountryID='+countryid+'&VisaTypeID='+visatypeid+'&PaymentStatus='+paymentstatus+'&AppStatus='+appstatus+'&SearchText='+searchtext+'&PassportNum='+PassportNum, { headers: headers });
  
   console.log(environment.ApiUrl);
    
  }


  getDubaiApplicationList(obj: any, page, pagesize) {          // --Changes required parameter has to be sent after login------------
    debugger;
    obj.page = page;
    obj.Pagesize = pagesize;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    //  this._getapplicationCountsapi.getapplicationCountsapi();
    // Frameworke Api B
    //return this._http.post(environment.ApiUrl + '/DubaiVisaApplication/GetDubaiVisaApplicationByStatus', obj, { headers: headers });
    //Stored Proced
    return this._http.post(environment.ApiUrl + '/DubaiVisaApplication/GetDubaiVisaApplicationByStatusProc', obj, { headers: headers });

  }
  getAssignByUser(id, countryid, checkType) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    //  this._getapplicationCountsapi.getapplicationCountsapi();
    return this._http.get(environment.ApiUrl + '/DubaiVisaApplication/GetAssignDubaiApplicationByUser?id=' + id + '&coutryid=' + countryid + '&checktype=' + checkType + '', { headers: headers });

  }
}
