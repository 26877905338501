import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})

export class AddVisaService {

  ApiUrl: string = null;

  pagesize: number;
  page: number;
  flag = 'True';
  private data: any;
  token: any;
  userId: any;
 

  _statusMaster = [
    { id: 1, Name: "Invoice Raised" },
    { id: 2, Name: "Document Pending" },
    { id: 3, Name: "For Review" },
    { id: 4, Name: "Submitted" },
    { id: 5, Name: "Interview Scheduled" },
    { id: 6, Name: "Approved" },
    { id: 7, Name: "Rejected" },
    { id: 8, Name: "Canceled" },
    { id: 9, Name: "Collected" },
    { id: 10, Name: "Pending at VisaConsolate" },
    { id: 43, Name: "Draft" }
   
  ];
  _docList = [
    { Id: 1, docCode: 'passport',documentName:'Passport' },
    { Id: 2, docCode: 'photo',documentName:'Photo' },
    { Id: 3, docCode: 'travelTicket' ,documentName:'Travel Ticket'},
    { Id: 4, docCode: 'accommodation',documentName:'Accommodation/Hotel Confirmation' },
    { Id: 5, docCode: 'forex_Credit_Card',documentName:'Forex/Credit Card ' },
    { Id: 6, docCode: 'bankStatement',documentName:'Bank Statement' },
    { Id: 7, docCode: 'incomeTax' ,documentName:'Income Tax Papers'},
    { Id: 8, docCode: 'selfEmployment',documentName:'Proof of Self-employment' },
    { Id: 9, docCode: 'travelInsurance',documentName:'Travel Insurance' },
    { Id: 10, docCode: 'coveringLetter' ,documentName:'Covering Letter'},
    { Id: 11, docCode: 'invitationLetter',documentName:'Invitation Letter' },
    { Id: 12, docCode: 'NOCDocument',documentName:'NOC Document' },
    { Id: 13, docCode: 'Visa_CopyRejection',documentName:'Visa Copy/Rejection' },
    { Id: 14, docCode: 'addNewdoc1' },
    { Id: 15, docCode: 'addNewdoc2' },
    { Id: 16, docCode: 'addNewdoc3' },
    { Id: 17, docCode: 'addNewdoc4' },
    { Id: 18, docCode: 'addNewdoc5' }
  ];

  _fileDownLoadContenTyep = [
    { name: "application/vnd.openxmlformatsofficedocument.spreadsheetml.sheet", type: "application/vnd.ms-excel" },
    { name: "application/pdf", type: "application/pdf" }
  ]
  constructor(private _http: HttpClient,private _router: Router,) {
    this.ApiUrl = environment.ApiUrl;
    this.data = JSON.parse(localStorage.getItem('currentUser'));
    //this.token = '123';
    //this.userId = '1';
    debugger;
    if(this.data==null)
    {
      console.info('empty currentUser')
      this._router.navigate(['/']);
      
    }
    else{
    this.token = this.data.token;
    this.userId = this.data.userId;

    }
    
  }
/*
  _getAPIAuth() {
    console.info('api call')
    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    let method="ApiUser";
    console.info(environment.ApiUrl + "/UserAuthonticate/AuthonticateUser"+"?userName=313241&password=super_admin@987");
    console.info(headers);
    return this._http.get<any>(environment.ApiUrl + "/UserAuthonticate/AuthonticateUser"+"?userName=313241&password=super_admin@987&OTP=123", { headers: headers });
   
  
  }
*/
_saveAgentPayment(payObj) {
  console.info('visaObj11==',payObj);
  let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
  //return this._http.post(environment.ApiUrl + "/AgentPayment/Create", payObj, { headers: headers });
  return this._http.post(environment.ApiUrl + "/AgentPayment/Create", payObj, { headers: headers });


}

_saveOKTBPayment(payObj) {
  console.info('visaObj11==',payObj);
  let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
  //return this._http.post(environment.ApiUrl + "/AgentPayment/Create", payObj, { headers: headers });
  return this._http.post(environment.ApiUrl + "/AgentPayment/CreateOKTB", payObj, { headers: headers });


}

_UploadVisaDocument(formData,) {
  console.info('formData==',formData);
  let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
  //return this._http.post(environment.ApiUrl + "/AgentPayment/Create", payObj, { headers: headers });
  return this._http.post(environment.ApiUrl + "/VisaApplDocument/UploadDocument", formData, { headers: headers });

  
}


  // ---> For all users list
  _getCustDetailsListById(locationName: any, pattern: any) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.get(environment.ApiUrl + "/VisaApplication/GetCustomer?Loc=" + locationName + "&pattern=" + pattern, { headers: headers })
  }
  // ---> For all users list
  _getCustDetailsByUser() {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.get(environment.ApiUrl + "/B2BVisaApplication/GetCustomerByUser" , { headers: headers })
  }
  _getCountryList() {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    console.info(headers)
    return this._http.get(environment.ApiUrl + "/CountryMaster/GetAll", { headers: headers });
  }

  _getVisaFeesCountryList(getForDocuments: boolean = false):any {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.get(environment.ApiUrl + "/CountryMaster/GetVisaFeesCountryList?getForDocuments=" + getForDocuments, { headers: headers });
  }
  _getLocationList() {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.get(environment.ApiUrl + "/VisaApplication/GetLocation", { headers: headers });
  }
  _getTransferBranchList() {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.get(environment.ApiUrl + "/BranchMstr/GetAll?page=1&pageSize=1&Flag=false", { headers: headers });
  }
  _getEditVisaById(ID: string) {

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.get(environment.ApiUrl + "/B2BVisaApplication/VisaApplicationGetById?id=" + ID, { headers: headers });
  }

  _getAgentPaymentList(ID: string,Fk_VisaAppId:string) {

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.get(environment.ApiUrl + "/AgentPayment/GetAgentPayment?id=" + ID +"&Fk_VisaAppId="+Fk_VisaAppId, { headers: headers });
  }

  _getApplicationPaymentList(ID: string) {

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.get(environment.ApiUrl + "/AgentPayment/GetApplicationPayment?id=" + ID , { headers: headers });
  }

  _getEditVisaByRefId(ID: string) {

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.get(environment.ApiUrl + "/B2BVisaApplication/VisaApplicationGetByRefId?id=" + ID, { headers: headers });
  }

  _payGenerateToken(payment) {

    /*let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.get(this.ApiUrl + "/AgentPayment/GenerateToken?data="+payment, { headers: headers });
*/
    console.info('visaObj11==',payment);
  let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
  //return this._http.post(environment.ApiUrl + "/AgentPayment/Create", payObj, { headers: headers });
  return this._http.post(environment.ApiUrl + "/AgentPayment/GenerateToken", payment, { headers: headers });

  }

  _payStatusUpdation(payment) {

   
 //   console.info('visaObj11==',payment);
  let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
  //return this._http.post(environment.ApiUrl + "/AgentPayment/Create", payObj, { headers: headers });
  return this._http.post(environment.ApiUrl + "/AgentPayment/UpdatePaymentStatus", payment, { headers: headers });

  }

  _payGenerateOrderID(Orderdetails) {

    console.info('visaObj11==',Orderdetails);
  let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
  //return this._http.post(environment.ApiUrl + "/AgentPayment/Create", payObj, { headers: headers });
  return this._http.post(environment.ApiUrl + "/AgentPayment/GenerateOrderID", Orderdetails, { headers: headers });

  }

  _getBranchesByUser() {

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.get(this.ApiUrl + "/BranchMstr/getB2BBranchesByUser", { headers: headers });
  }

  _getPrintVisaById(ID: string) {

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.get(environment.ApiUrl + "/VisaApplication/TextInvoiceGetById?id=" + ID, { headers: headers });
  }

  _getVisaTypebyCountry(countryId) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.get(environment.ApiUrl + "/VisaType/GetVisaTypeName?CountryId=" + countryId, { headers: headers });
    // return this._http.get(environment.ApiUrl+"/ERPService/GetVisaType?CountryCode="+countryCode,{ headers: headers});
  }
  _getEntriesbyCountryId(visaTypeId) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.get(environment.ApiUrl + '/VisaType/GetVisaTypeEntriess?VisaTypeId=' + visaTypeId + '', { headers: headers });
  }
  Get_VendorbyCountryId(Id) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.get(environment.ApiUrl + '/VisaVendorMaster/GetVendorByCountryId?CountryId=' + Id + '', { headers: headers });

  }
  _getVisaOfficeData(countryId,trasnferbranchid,VisaTypeId) {
    if(countryId==1089){
      trasnferbranchid=0
    }
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);

    console.info("trasnferbranchid==",trasnferbranchid);
    if(trasnferbranchid ==  null || trasnferbranchid ==  "null")
    {
      
      trasnferbranchid = 0
      console.info("trasnferbranchid1==",trasnferbranchid);
    }
    if(VisaTypeId ==  null)
    {
      VisaTypeId = 0
    }

    return this._http.get(environment.ApiUrl + "/VisaVendorMaster/GetVendorByCountryId?CountryId=" + countryId + "&trasnferbranchid=" + trasnferbranchid + "&VisaTypeId=" + VisaTypeId + "&getVendorsWithFees=" + true, { headers: headers });
    // return this._http.get(environment.ApiUrl+"/VisaOfficeMst/GetAll?page=1&pageSize=10&Flag=false",{ headers: headers});
  }

  _getVisaProcessingOptions(countryId,Entries,VisaTypeId,VendorId) {
    // let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    // headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.get(environment.ApiUrl + "/MstProcessingOption/GetPRocessOptionByCountryId?CountryId=" + countryId+'&Entries='+Entries+'&VisaTypeId='+VisaTypeId+'&VendorId='+0, { headers: headers });
  }
  _getvisaMiscChargesData() {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.get(environment.ApiUrl + "/MiscChargesMst/GetAll?page=1&pageSize=1000&Flag=false", { headers: headers });
  }
  _getOfficeBoyData() {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.get(environment.ApiUrl + "/B2BUserDetails/GetByUserType?UserType=4", { headers: headers });
  }
  _delUploadedDocument(docId,BucketName,FileName) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.post(environment.ApiUrl + "/VisaApplDocument/DeleteFile?id="+docId+"&BucketName="+BucketName+"&FileName="+FileName, '', { headers: headers });
  }

  _updateVisaApplication(visaObj) {

    console.info('visaObj11==',visaObj);
 
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.post(environment.ApiUrl + "/VisaApplStatus/UpdateVisaAppWithStatus", visaObj, { headers: headers });
  }
  _saveVisaApplication(visaObj) {
    console.info('visaObj11==',visaObj);
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.post(environment.ApiUrl + "/VisaApplication/Insert", visaObj, { headers: headers });
  }
  
  _saveb2bVisaApplication(visaObj) {
    console.info('visaObj11==',visaObj);
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.post(environment.ApiUrl + "/B2BVisaApplication/Insert", visaObj, { headers: headers });
  }

  _saveb2bVisaApplicationOKTB(visaObj) {
    console.info('visaObj11==',visaObj);
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.post(environment.ApiUrl + "/B2BVisaApplication/InsertOKTB", visaObj, { headers: headers });
  }
  
 
  

  _uploadPassport(filePath) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.post(environment.ApiUrl + "/VisaApplication/UploadPassport", filePath, { headers: headers });
  }

  _uploadExcelVisaApplication(formData, dataKey) {   

    //let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.post(environment.ApiUrl + "/VisaApplDocument/Upload?key="+dataKey, formData, { headers: headers });
  }
  _bulkUploadVisaApplication(visaObj):Observable<any> {
     
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.post(environment.ApiUrl + "/VisaApplDocument/UploadBulkData", visaObj, { headers: headers });
  }


  // _getVisaServiceCharges(icust){
  //   return this._http.get(environment.ApiUrl+"/VisaFeesMst/GetByPara?ICUST=211&TravellerType=213&VisaCountry=213&Visatype=123&VisaOfficeId=123")
  // }


  // other componet service ......
  _changePassword(oldPwd, newPwd) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    console.info("B2BUserDetails");
    debugger;
    return this._http.post(environment.ApiUrl + "/B2BUserDetails/ChangeUserPassword?EmpCode=" + this.userId + "&OldPass=" + oldPwd + '&NewPass=' + newPwd, '', { headers: headers });

  }


   // other componet service ......
   _changePasswordAfterLogin(oldPwd, newPwd) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    console.info("B2BUserDetails");
    debugger;
    return this._http.post(environment.ApiUrl + "/B2BUserDetails/ChangeUserPasswordAfterLogin?EmpCode=" + this.userId + "&OldPass=" + oldPwd + '&NewPass=' + newPwd, '', { headers: headers });

  }

  _Passportplace_Api(place) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.get(environment.ApiUrl + '//GlobalSearch/GetPassportPOI?SearchText=' + place + '', { headers: headers });

  }


  _getVisaServiceCharges(fk_countryId,fk_visaTypeId,fk_VendorId,fk_ProcessingOption,Entries,ICUST) {
     
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.get(environment.ApiUrl + "/VisaFeesMst/GetByPara?CountryId="+fk_countryId+"&VisaTypeId="+ fk_visaTypeId+"&VendorId="+fk_VendorId+"&Entries="+Entries+"&ProcessingOption="+fk_ProcessingOption+"&ICUST="+ICUST, { headers: headers });

  }

  _setVisaDeliveryDetails(deliveryObject) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.post(environment.ApiUrl + "/VisaApplication/UpdateDeliveryDetails?id="+deliveryObject.id +"&visaAppId="+deliveryObject.visaAppId+ "&TrackingNo="+deliveryObject.trackingNo + "&DeliveryPartnerName="+deliveryObject.deliveryPartnerName+"&Url="+deliveryObject.url,'', { headers: headers });

  }
  _getDeliveryPartner(){
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);  
    return this._http.get(environment.ApiUrl + "/DeliveryPartners/GetAll", { headers: headers });
  }
  _getVisaCustomerByPassportNo(passportNumber){
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);  
   // return this._http.get(environment.ApiUrl + "/VisaApplication/GetVisaCustomerByPassportNo?PassportNo="+passportNumber, { headers: headers });
    return this._http.get(environment.ApiUrl + "/VisaApplication/GetVisaPassengerByPassportNo?PassportNo="+passportNumber, { headers: headers });
  }

  _getdubaidata()
  {
     
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);  
    return this._http.get(environment.ApiUrl + "/HoldStatusDubai", { headers: headers });
  }

  getSampleXls(){
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.get( environment.ApiUrl + '/VisaApplDocument/BulkUploadSampleFile',{ headers:headers} );
  }

  _getProfessionList(SearchValue){
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);  

    console.info('SearchValue==',SearchValue);
    return this._http.get(environment.ApiUrl + "/B2BVisaApplication/GetProfessionList?SearchValue="+SearchValue, { headers:headers});
  }

  GetPassangerData(Id) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.get(environment.ApiUrl + '/VisaApplication/GetPassangerData?VisaAppId=' + Id + '', { headers: headers });

  }

  _GetUserBranchList(){
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);  

    return this._http.get(environment.ApiUrl + "/B2BUserDetails/Getuserbranch", { headers:headers});
  }

  _LogVisaApplicationEditError(data){
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);  

    return this._http.post(environment.ApiUrl + "/VisaApplication/LogVisaApplicationEditError", data, { headers:headers});
  }

  _GetErpPostStatus(pnr: string){
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);  

    return this._http.get(environment.ApiUrl + "/VisaApplication/GetErpPostStatus?pnr="+pnr, { headers:headers});
  }

  _updateInterviewSubmissionDates(visaObj) {
    console.info('visaObj11==',visaObj);
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.post(environment.ApiUrl + "/VisaApplStatus/UpdateDates", visaObj, { headers: headers });
  }

  _getCreditLimit(iCust) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);  
    return this._http.get(environment.ApiUrl + "/ERPService/GetRemainingCreditLimit?Icust="+iCust, { headers:headers});
  }

  isimgArray(array, word) {
    return array.indexOf(word.toLowerCase()) > -1;
  }

  _uploadDubaiVisaPassport(filePath) {
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.post(environment.ApiUrl + "/VisaApplDocument/ScanVisaPassport", filePath, { headers: headers });
  }


  _bulkUploadExcelFiles(Obj):Observable<any> {
     
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.post(environment.ApiUrl + "/VisaApplDocument/UploadBulkDataByExcel", Obj, { headers: headers });
  }

  _UploadExcelVisaApplicationByFiles(formData, dataKey) {   

    //let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.post(environment.ApiUrl + "/VisaApplDocument/UploadVisaApplicationBulk?key="+dataKey, formData, { headers: headers });
  }


  _GetRVISChecklist(CountryName,Branchid, VisaTypeName){
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);  

    return this._http.get(environment.ApiUrl + "/VisaApplication/GetRVISCheckListDetail?CountryName="+CountryName+"&Branchid="+Branchid+"&VisaTypeName="+VisaTypeName, { headers:headers});
  }

  _InsertB2BUserLogs(LogType,Action, UserID,UserType){
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);  

    return this._http.post(environment.ApiUrl + "/B2BUserDetails/InsertUserLog?LogType="+LogType+"&Action="+Action+"&UserID="+UserID+"&UserType="+UserType, { headers:headers});
  }

  _getAirlinebyCountryId(countryId,UserType) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.get(environment.ApiUrl + '/MstProcessingOption/GetAirlineByCountryId?CountryId=' + countryId  + '&UserType='+UserType, { headers: headers });
  }

  _getOKTBServiceCharges(fk_countryId,fk_airlineId,UserType) {
     
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.get(environment.ApiUrl + "/VisaFeesMst/GetByParaOKTB?CountryId="+fk_countryId+"&AirlineId="+ fk_airlineId+"&UserType="+UserType, { headers: headers });

  }

  _getOKTBVisaByRefId(ID: string) {

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.get(environment.ApiUrl + "/B2BVisaApplication/OKTBApplicationGetByRefId?id=" + ID, { headers: headers });
  }

  _getOKTBVisaById(ID: string) {

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.get(environment.ApiUrl + "/B2BVisaApplication/OKTBVisaApplicationGetById?id=" + ID, { headers: headers });
  }

  _oktbpayStatusUpdation(payment) {

   
    //   console.info('visaObj11==',payment);
     let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
     headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
     //return this._http.post(environment.ApiUrl + "/AgentPayment/Create", payObj, { headers: headers });
     return this._http.post(environment.ApiUrl + "/AgentPayment/UpdateOKTBPaymentStatus", payment, { headers: headers });
   
     }
     _getOKTBPaymentList(ID: string,Fk_VisaAppId:string) {

      let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
      return this._http.get(environment.ApiUrl + "/AgentPayment/GetOKTBPayment?id=" + ID +"&Fk_VisaAppId="+Fk_VisaAppId, { headers: headers });
    }

}
