import { Component, OnInit} from '@angular/core';
// import { FormsModule } from '@angular/forms';
import { HttpClient, HttpHeaders, HttpRequest, HttpResponse, } from '@angular/common/http';
import { AddVisaService } from 'src/app/service/addVisaSerivce';
import { HttpErrorResponse } from '@angular/common/http/src/response';
//import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { Route, ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import {GlobalServiceService} from '../../service/global-service.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { VFSVASFeesService } from 'src/app/service/vfs-vfs.service';
import { environment ,infocontent} from 'src/environments/environment';
declare var $: any;
@Component({
  selector: 'app-oktbpayment',
  templateUrl: './oktbpayment.component.html',
  styleUrls: ['./oktbpayment.component.css']
})

export class OktbpaymentComponent implements OnInit {
  isAI :boolean=false;
  DiscountedServCharge: any;
  private localData: any;
  location: string;
  token: any;
  orderID: any;
  RPGSiteJSURL:any;
  tokendata:any;
  orderdata:any;
  editVisaData:any={};
  visaUserType: string = null;
 
  visaObj: any = {
    visaCustomer: {},
    lstApplicationStatus: [],
    lstVisaMiscFees: [],
    lstVisaApplBranch: [],
    lstVisaPassangerDetail:[],
    lstVisaVFSVASFees: [],
    VisaAppType: 'Other Application'
  };
  visaagentpayment: any = {};
  Payment: any = {};
  PaymentOrder: any = {};
  showServiceCategory:boolean = false;
  lstServiceCharge : any = [];
  categoryMasterId : any ;
  currentCust:any;
  creditLimit: number = null;
  paxcount:number=0;
  selectedservicecharge:any; 
  dis_discountedservicecharge:any;
  applist : any = [];
  url = environment.RPGSiteJSURL;

  constructor(private _toastr: ToastrService,private _visaService: AddVisaService,private _router: Router,private _route: ActivatedRoute) { 

    this.localData = JSON.parse(localStorage.getItem('currentUser'));
    if(this.localData==null)
    {
      this._router.navigate(['/']);
    }
    else{
    this.token = this.localData.token;
    this.visaUserType = this.localData.userType;
    }

    this.loadScript();
    $(".inner-content").css("padding", "92px 15px 0 15px");



    if(window.location.href.indexOf( environment.AirIndiaURL)>-1   )
      { this.isAI=true;
      }
   

    $(document).ready(function () {
      $('img#toolgst').data('liteTooltip',infocontent.servicegst);    
      
      });

     
  }

  ngOnInit() {
    this.getEditVisaById(this._route.snapshot.queryParams.id);
    this.getCustomerData();
    this.RPGSiteJSURL=environment.RPGSiteJSURL;
  }

  
  public loadScript() {
    //console.info('preparing to load...')
    let node = document.createElement('script');
    node.src = this.url;
    node.type = 'text/javascript';
   // node.async = true;
    //node.charset = 'utf-8';
    var ele=document.getElementsByTagName('head')[0];
    //console.info( ele)
    ele.appendChild(node);
    //console.info( node)
    //console.info( document.getElementsByTagName('head')[0].children)
}


  getVisaServiceCharges(){  
       
        this.visaObj.VisaAppType='Other Application';
        this.showServiceCategory = false;
       // this._visaService._getVisaServiceCharges(this.visaObj.fK_VisaCountryMasterId,this.visaObj.fK_VisaTypeId,0,this.visaObj.fK_MstProcessingOptionsId,this.visaObj.entries,this.visaObj.icust)
        this._visaService._getOKTBServiceCharges(this.visaObj.fK_VisaCountryMasterId,this.visaObj.fK_AirlineId ,'B2B')
          .subscribe( (successData:any)=>{
            
           console.info(successData);
            if(successData.status==true){
              //$("#Raise-Invoice").modal('show');
           // this.visaServicesData=successData;
              //var visaFees=successData.result[0].visaFees;
              //var vfsFees=successData.result[0].vfsFees;
              var serviceCharges=successData.result.serviceFee;
              var gstval=0;
             // debugger;
             {
              $("#serviceCharges").val(serviceCharges);
              

 gstval=18*serviceCharges/100;
$("#gst").val(gstval);

$("#totalamountperpax").val((serviceCharges +gstval));
$("#totalamount").val((serviceCharges +gstval)*this.paxcount);
//this.dis_discountedservicecharge=(serviceCharges*10.00/8);
            }
          } else{
            // $("#raiseInvoicePopUp").click();
            this._toastr.warning(" Visa charges are not available, contact admin");
           // $("#visaFees").val("");
            //$("#vfsFees").val("");
            $("#serviceCharges").val("");
          }
           //console.log(successData);
          },
          (err: HttpErrorResponse) => {
            // $("Raise-Invoice").hide();
           // $("#visaFees").val("");
           // $("#vfsFees").val("");
            $("#serviceCharges").val("");
            console.error (err.message);    // SHOW ERRORS IF ANY.
          });
        }

  getCustomerData(){
    
    this._visaService._getCustDetailsByUser()
    .subscribe((successData: any) => {
        var temp = { successData };
      // console.info(successData.result.responseData[0]);
      var CustomerData=successData.result.responseData[0];
      /*$('#cust-name').val(this.CustomerData.name);
      $('#cust-id').val(this.CustomerData.custNo);
      $('#cust-type').val(this.CustomerData.customerType);
      $('#cust-loc').val(this.CustomerData.location);
      $('#phone-num').val(this.CustomerData.phone);*/
      this.bindCustomerDetails(CustomerData);
     // $('#phone-num').val(this.CustomerData.location);
   
      },
        (err: HttpErrorResponse) => {
          console.error(err.message);    // SHOW ERRORS IF ANY.
        });
  }

  bindCustomerDetails(cust) {
    debugger; 
    this.creditLimit = null;
    this.currentCust = cust;
    this._visaService._getCreditLimit(cust.no_).subscribe((z:any)=>{
     // console.info(z);
      if(z.status && z.result && z.result.length > 0){
        this.creditLimit = +z.result.split('-')[0];

        if(this.creditLimit==0)
        {
          this.visaagentpayment.paymentMode=2;
        }

        /*if (this.creditLimit <= 0) {
          $('#CloseGetCustPop').click();
          $('#creditLimitAv').modal({backdrop: 'static', keyboard: false});
         $("#creditLimitAv").modal('show');
          // if(window.confirm("Available credit limit for: "+ cust.no_ + " is " + this.creditLimit + 
          // ". Do you wish to continue ?")){
          //   this.bindCustData(cust);
          //  }
        }else{
          this.bindCustData(cust);
        }*/
      }else{
        //this.bindCustData(cust);
      }
    })
  }

  savepaymentdetails()
  {
   
    $('#visaLoader').show();
    this.visaagentpayment.fk_VisaAppId=0;
    this.visaagentpayment.fk_VisaAppRef=this._route.snapshot.queryParams.id;
    //this.visaagentpayment.paymentMode=1;
    if(this.visaagentpayment.paymentMode==1)
    {
      this.visaagentpayment.paymentModeText='Credit Limit';
      this.visaagentpayment.paymentStatus=2;
      this.visaagentpayment.paymentStatusText='Pending';
    }
    else{
      this.visaagentpayment.paymentModeText='Make Payment';
      this.visaagentpayment.paymentStatus=2;
      this.visaagentpayment.paymentStatusText='Pending';
    }
    
   
    //this.visaagentpayment.createdBy=1;
    //this.visaagentpayment.createdOn=new Date();
    //this.visaagentpayment.updatedBy=1;
    //this.visaagentpayment.updatedOn=new Date();
    this.visaagentpayment.visaFees=0;
    this.visaagentpayment.vFSFees=0;
    this.visaagentpayment.serviceCharges=  $("#serviceCharges").val();
    this.visaagentpayment.totalAmount= $("#totalamount").val();
    this.visaagentpayment.gST= $("#gst").val();
    this.visaagentpayment.paxCount= this.paxcount;
    //  $("#totalamount").val((visaFees+vfsFees+serviceCharges +gstval)*this.paxcount);
    debugger;
    this.applist.forEach(app =>
      {
        this.visaagentpayment.fk_VisaAppId=app.id;
  this._visaService._saveOKTBPayment(this.visaagentpayment)
    .subscribe((successData: any)=> {

      //this._router.navigate([this.visaUserType + '/B2bAppSuccess'], { queryParams: { id:this.visaObj.applicationRef}});
                
      console.info(successData);
     
    });
  });
    this.PaymentOrder.amount=this.visaagentpayment.totalAmount;
    this.PaymentOrder.Fk_VisaAppRef=this.visaagentpayment.fk_VisaAppRef;
          
   

    this._visaService._payGenerateOrderID(this.PaymentOrder)
    .subscribe(data=>{
      this.orderdata=data;
      debugger;
//console.info(this.orderdata);
this.orderID=this.orderdata.result;
let passenger= this.visaObj.lstVisaPassangerDetail[0];
$('#visaLoader').hide();
this.SetPaymentRPG( this.orderID,  this.visaagentpayment.totalAmount,  passenger.givenName+ ' '+ passenger.surname,passenger.phoneNumber, passenger.emailID);

    });

  



    //this.SetPaymentRPG('891829182','120','Pooja','123','pooja@g.com');

  }

 paymenttemplate()
  {
      var strTemplate = '';
     // var currency = $('#currency').val();
     strTemplate=strTemplate+"function callpayment() {   var configJson = { ";
     strTemplate=strTemplate+"'Country': '@Country',  'Department': '@Department', 'Branch': '@Branch', 'UserType': '@UserType',";
     strTemplate=strTemplate+"'Product': '@Product', 'AgentName': '@AgentName', 'TransactionId': '@TransactionId',";
     strTemplate=strTemplate+"'Currency': 'INR','Amount': '@Amount', 'CustFirstName': '@CustFirstName', 'CustLastName': '@CustLastName',";
     strTemplate=strTemplate+"'CustMobileNo': '@CustMobileNo', 'CustEmailId': '@CustEmailId', 'ClientReturnURL': '@ClientReturnURL',";
     strTemplate=strTemplate+"'SaltKey': '@SaltKey', 'Token': '@Token','CreditCardNo': '',";
     strTemplate=strTemplate+"'ExpirationDate': null, 'Address': '', 'City': '','State': '',";
      strTemplate=strTemplate+"'Zip': '', 'ICUS': ''}; PaymentGateway(configJson);} callpayment();";

      return strTemplate;
  }
  SetPaymentRPG( OrderId,  amount,  PassangeName, contactno,  email)
  {

    //Payment opayment = new Payment();
    this.Payment.Amount = amount;
    this.Payment.CustFirstName  = PassangeName;
    this.Payment.CustLastName  = "";
    //this.Payment.CustMobileNo  = contactno ;
    this.Payment.CustMobileNo  = "" ;
    this.Payment.CustEmailId  = email ;
    this.Payment.TransactionId  = OrderId;
    this.Payment.SaltKey  = environment.SaltKey;
debugger;
    //var token = GenerateToken(opayment);

    
    //console.info(this.Payment);
    this._visaService._payGenerateToken(this.Payment)
    .subscribe(data=>{
      this.tokendata=data;
      debugger;
//console.info(this.tokendata);
this.token=this.tokendata.result;

var template= this.paymenttemplate();
    template = template.replace("@Country", "IN");
    template = template.replace("@Department", "Department");
    template = template.replace("@Branch", "Branch");
    template = template.replace("@UserType", "UserType");
    template = template.replace("@Product", "B2B");
    template = template.replace("@AgentName", "");
    template = template.replace("@TransactionId", OrderId);
    template = template.replace("@Amount", amount);
    template = template.replace("@CustFirstName", PassangeName);
    template = template.replace("@CustLastName", "");
    //template = template.replace("@CustMobileNo", contactno);
    template = template.replace("@CustMobileNo", "");
    template = template.replace("@CustEmailId", email);
//console.info(window.location.href.indexOf( environment.AirIndiaURL));
    //template = template.Replace("@ClientReturnURL", Request .Url.Authority+ "/SaveRPGResponse.aspx");
   
    if(window.location.href.indexOf( environment.AirIndiaURL)>-1   )
    {
      template = template.replace("@ClientReturnURL", environment.AIRRPGReturnURL.replace('B2bRPGResponse','OktbRPGResponse'));
    }
   else{
    template = template.replace("@ClientReturnURL", environment.RPGReturnURL.replace('B2bRPGResponse','OktbRPGResponse'));
   }
    
    template = template.replace("@SaltKey", environment.SaltKey);
    template = template.replace("@Token", this.token);
   // console.info(template);
    var F=new Function (template);
//debugger;
    return(F());

    });

    

  }

  

  getEditVisaById(editID){

    $('#visaLoader').show();
    //console.info("i am here");
     // debugger;
     this._visaService._getOKTBVisaByRefId(editID)
     .subscribe(data=>{
        
       //  console.info("full data:",data);
        // this.editVisaData = data;
         //this.riyaVisaPNR="123";
         this.editVisaData = data;
         this.location =  this.editVisaData.result[0].customerLocation;
         //debugger;
         this.visaObj =  this.editVisaData.result[0];
         this.paxcount=  this.editVisaData.result.length;
         this.applist=this.editVisaData.result
        //console.info( this.applist);
         if(this.visaObj.visaCustomer==null){
           this.visaObj.visaCustomer={};
         }
        
         this.getVisaServiceCharges();
         
         $('#visaLoader').hide();
     })
 
   }

}

