import { debug } from 'util';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})

export class BranchmanagementService {

  pagesize: number = 0;
  page: number = 0;
  flag: boolean = true;
  Mid: any;
  managementrid: number;
  localData: any = {};
  token: string = null;
  constructor(private _http: HttpClient) {
    this.localData = JSON.parse(localStorage.getItem('currentUser'));
    this.token = this.localData.token;
  }

  //#region "Branches Management API CALLS"

  // for All BranchesManagement list
  getall_branches(page, pagesize) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    this.page = page;
    this.pagesize = pagesize;
    return this._http.get(environment.ApiUrl + '/BranchMstr/GetAll?page=' + this.page + '&pageSize=' + this.pagesize + '&Flag=' + this.flag + '', { headers: headers });
  }

  // Insert Branch From
  Branch_Add(addObj) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.post(environment.ApiUrl + '/BranchMstr/Create', addObj, { headers: headers });
  }

  // Update Branch
  Branch_update(editObj) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.post(environment.ApiUrl + '/BranchMstr/Update', editObj, { headers: headers });
  }

  // ---> For delete Branch
  deleteBranch(managementrid) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    this.Mid = null;
    this.Mid = managementrid;
    return this._http.post(environment.ApiUrl + '/BranchMstr/Delete?id=' + this.Mid, '', { headers: headers });

  }

  // show data after click on Edit button
  showeditdatabyid(branchid) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    this.Mid = null;
    this.Mid = branchid;
    return this._http.get(environment.ApiUrl + '/BranchMstr/GetById?id=' + branchid, { headers: headers });
  }
  //#endregion

  //#region "Visa fees Master API CALLS "

  // for All visa office list
  getall_VisaofficeAPI(page, pagesize) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    this.page = page;
    this.pagesize = pagesize;
    this.flag = true;
    return this._http.get(environment.ApiUrl + '/VisaOfficeMst/GetAll?page=' + this.page + '&pageSize=' + this.pagesize + '&Flag=' + this.flag + '', { headers: headers });
  }


  deletevisaOffice(id) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.post(environment.ApiUrl + '/VisaOfficeMst/Delete?id=' + id, '', { headers: headers });
  }

  updatevisaoffice(officeobj) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.post(environment.ApiUrl + '/VisaOfficeMst/Update', officeobj, { headers: headers });
  }

  createvisaoffice(officeobj) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.post(environment.ApiUrl + '/VisaOfficeMst/Create', officeobj, { headers: headers });
  }

  //#endregion  

  //#region "Miscelleneous Fees Charges API CALLS"

  getall_MissFessCharges(page, pagesize, SearchKeyWord) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    this.page = page;
    this.pagesize = pagesize;
    this.flag = true;
    return this._http.get(environment.ApiUrl + '/MiscChargesMst/GetAll?page=' + this.page + '&pageSize=' + this.pagesize + '&Flag=' + this.flag + '' + '&searchKeyWord='+SearchKeyWord, { headers: headers });
  }

  MissFess_Add(feeobj) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.post(environment.ApiUrl + '/MiscChargesMst/Create', feeobj, { headers: headers });
  }

  deleteFeeCharges(feeobj1) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    debugger;
    return this._http.post(environment.ApiUrl + '/MiscChargesMst/Delete?id=' + feeobj1, '', { headers: headers });
  }

  updateFessCharges(editobj) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.post(environment.ApiUrl + '/MiscChargesMst/Update', editobj, { headers: headers });
  }
  //#endregion "Miscelleneous Fees Charges API CALLS"

  //#region "Country & Visa Type Common API CALLS" 

  // For country list
  _getCountryList() {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.get(environment.ApiUrl + "/CountryMaster/GetAll", { headers: headers });
  }

  // For Visa type list
  // _getVisaTypebyCountry(countryCode) {
  //   let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  //   headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
  //   return this._http.get(environment.ApiUrl + "/ERPService/GetVisaType?CountryCode=" + countryCode, { headers: headers });
  // }
  _globalsearchApi(text: string) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.get(environment.ApiUrl + "/BranchMstr/GetERPBranch?BranchName=" + text + '', { headers: headers });
  }

  //#endregion

  //#region "Country Master API CALLS "

  // for All visa office list
  CreateCountry(CountryObj) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);

    return this._http.post(environment.ApiUrl + '/CountryMaster/Create', CountryObj, { headers: headers });
  }
  UpdateCountry(CountryObj) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);

    return this._http.post(environment.ApiUrl + '/CountryMaster/Update', CountryObj, { headers: headers });
  }

  getall_Country(page, pagesize) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    this.page = page;
    this.pagesize = pagesize;
    this.flag = true;
    return this._http.get(environment.ApiUrl + '/CountryMaster/GetAllWithPagination?page=' + this.page + '&pageSize=' + this.pagesize + '&Flag=' + this.flag + '', { headers: headers });
  }

  deleteCountry(id) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.post(environment.ApiUrl + '/CountryMaster/Delete?id=' + id, '', { headers: headers });

  }
  //#endregion

  //For Branch IP
  getall_BranchIp(page, pagesize) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    this.page = page;
    this.pagesize = pagesize;
    this.flag = true;
    return this._http.get(environment.ApiUrl + '/BranchMstr/GetAllBranchIp?page=' + this.page + '&pageSize=' + this.pagesize + '&Flag=' + this.flag + '', { headers: headers });
  }

  updateBranchIp(editobj) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.post(environment.ApiUrl + '/BranchMstr/UpdateBranchIp', editobj, { headers: headers });
  }

  BranchIp_Add(feeobj) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.post(environment.ApiUrl + '/BranchMstr/CreateBranchIp', feeobj, { headers: headers });
  }
  /////
}
