import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, Observer } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class DubaivisaupdateserviceService {
  ApiUrl: string = null;
  LocationCode=[
    { id:1101,locationcode:'BOM',name:'Mumbai-Main Branch'}
  ];
  pagesize: number;
  page: number;
  flag = 'True';
  private data: any;
  token: any;
  userId: any;
  _docList = [
    { Id: 1, docCode: 'PassportFrontSide',documentName:'Passport Front Page' },
    { Id: 2, docCode: 'PassportBackSide',documentName:'Passport Back Page' },
    { Id: 3, docCode: 'photo' ,documentName:'Personal Photo'},
    { Id: 4, docCode: 'pancard',documentName:'PAN Card' },
    { Id: 5, docCode: 'Passport3',documentName:'Passport Page 3' },
    { Id: 6, docCode: 'Passport4',documentName:'Passport Page 4' },
    { Id: 7, docCode: 'Passport5',documentName:'Passport Page 5'},
    { Id: 8, docCode: 'Passport6',documentName:'Passport Page 6' },
    { Id: 9, docCode: 'Passport7',documentName:'Passport Page 7' },
    { Id: 10, docCode: 'Passport8',documentName:'Passport Page 8'},
  
  ];
  constructor(private _http: HttpClient) { 

    this.ApiUrl = environment.ApiUrl;
    this.data = JSON.parse(localStorage.getItem('currentUser'));
    this.token = this.data.token;
    this.userId = this.data.userId;
  }
  _getCountryList() {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.get(environment.ApiUrl + "/CountryMaster/GetAll", { headers: headers });
  }
  _getDubaiVisaStatus() {
   
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.get(environment.ApiUrl + "/StatusMaster/GetDubaiStatus", { headers: headers });
  }
  getBranches(){
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.get(environment.ApiUrl + '/BranchMstr/getAllBranches',{ headers: headers});
  }
  GetDubaiVisaVendors(CountryCode, LocationCode) {

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.get(environment.ApiUrl + '/ERPService/GetDubaiVisaVendor?CountryCode=' + CountryCode + '&LocationCode=' + LocationCode + '', { headers: headers });
  }
  _getProcessingOptionsByCountryid(countryId) {
    // let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    // headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.post(environment.ApiUrl + "/MstProcessingOption/GetByCountryId?CountryId=" + countryId, { headers: headers });
  }

  SChannelArabicConversion(name:any):Observable<object>
  {
   
    let arabicOptions:Object = {

      headers: new HttpHeaders({
          'Content-Type': 'application/json'
      }),
      responseType: 'text'
   }
    return this._http.get("https://smart.gdrfad.gov.ae/SmartChannels/rest/AjaxHelpers/Translate?text=" + name + "",arabicOptions);
  }

  _getUAEVisaType() {
   
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.get(environment.ApiUrl + "/VisaType/GetUAEVisaType", { headers: headers });
  }
  getUAErofession()
  {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.get(environment.ApiUrl + "/VisaType/GetUAEProfession", { headers: headers });
  }
  getUAEPassportType()
  {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.get(environment.ApiUrl + "/VisaType/GetUAEPassportType", { headers: headers });
  }

  getReligion()
  {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.get(environment.ApiUrl + "/VisaType/GetUAEReligions", { headers: headers });
  }

  getFaithByReligionName(name:string)
  {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.get(environment.ApiUrl + "/VisaType/GetFaithByReligionName/?name="+name+"", { headers: headers });
  }

  getEducationlist()
  {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.get(environment.ApiUrl + "/VisaType/GETEducationlist", { headers: headers });
  }
  getAllLangauges()
  {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.get(environment.ApiUrl + "/VisaType/GetAllLangauges", { headers: headers });
  }
  isimgArray(array,word)
  {
    return array.indexOf(word.toLowerCase()) > -1;
  }
  
  _uploadDubaiVisaPassport(filePath) {
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.post(environment.ApiUrl + "/VisaApplDocument/UploadDubaiVisaPassport", filePath, { headers: headers });
  }
  _delUploadedDocument(docId,BucketName,FileName) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.post(environment.ApiUrl + "/VisaApplDocument/DeleteFile?id="+docId+"&BucketName="+BucketName+"&FileName="+FileName, '', { headers: headers });
  }

  getVisaServiceType()
  {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.get(environment.ApiUrl + "/VisaType/getAllServiceCharges", { headers: headers });
  }
  _getVisaCustomerByPassportNo(passportNumber){
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);  
    return this._http.get(environment.ApiUrl + "/VisaApplication/GetVisaCustomerByPassportNo?PassportNo="+passportNumber, { headers: headers });

  }
  _getDubaiVisaEditVisaById(appid:number,coutryid) {

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.get(environment.ApiUrl + "/DubaiVisaApplication/DubaiVisaApplicationGetById?id=" + appid+"&coutryid="+coutryid+"", { headers: headers });
  }
  saveDubaiVisaFeesAdd(visaobj)
  {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.post(environment.ApiUrl + "/DubaiVisaApplication/DubaiVisaFeesAddOrUpdate", visaobj, { headers: headers });
  }
  DocumentStatus(appstatus)
  {
   
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.post(environment.ApiUrl + "/VisaApplStatus/DubaiVisaUpdateStatus", appstatus, { headers: headers });
  }
  UpdateDubaiVisaApplication(DubaivisaObj) {
    
   
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.post(environment.ApiUrl + "/DubaiVisaApplication/UpdateDubaiVisaApp", DubaivisaObj, { headers: headers });
  }
  ClosedDubaiVisaApplication(DubaivisaObj) {
    
   
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.post(environment.ApiUrl + "/DubaiVisaApplication/ClosedDubaiVisaApp", DubaivisaObj, { headers: headers });
  }
  GetdDubaiVisaFeesWithVendor(id) {
    
   
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.get(environment.ApiUrl + "/DubaiVisaApplication/GetDubaiVisaFeesVendorDetails?id="+id+"&coutryid=1089", { headers: headers });
  }

  AddOrUpdatedVendored(obj)
  {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.post(environment.ApiUrl + "/DubaiVisaApplication/AddOrUpdatedVendor",obj, { headers: headers });

  }

}
