import { Component, OnInit, Input } from '@angular/core';
import { Newappservice } from '../../service/Newapplication_Service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalsearchService } from '../../service/GlobalsearchService';
import { NavMenuComponent } from '../../nav-menu/nav-menu.component';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
//import { DubaiManagerAuthGuardService } from '../../AuthGuard/DubaiManagerAuthGuard.service';
import { environment } from 'src/environments/environment';
//import { HttpErrorResponse } from '@angular/common/http/src/response';

declare var $: any;

@Component({
  selector: 'app-viewlisting-app',
  templateUrl: './viewlisting-app.component.html',
  styleUrls: ['./viewlisting-app.component.css']
})
export class ViewlistingAppComponent implements OnInit {
  options = {}
  data = [];
  //columns: any = {};

  optionsBasicNoData = {}
  dataBasicNoData = [];
  columnsBasicNoData: any = {};

  optionsWithCaption = {}
  dataWithCaption = [];
  columnsWithCaption: any = {};

  columnsWithFeatures:any
  optionsWithFeatures: any
  dataWithFeatures: any
  
  optionsWithoutClass = {}
  dataWithoutClass = [];
  columnsWithoutClass: any = {};
  listobj: any = {};
  id: number;
  paginationSize = 10;
  page: number = 1;


  rows = [];
    selected: any[] = [];
    
    loadingIndicator = true;
    reorderable = true;
  
    columns = [{ prop: 'applicationRef',name:"Applicant Ref." }, { prop: 'riyaVisaPNR',name: 'PNR No' }, { prop: 'passportNo',name: 'Passport No.', sortable: false },
    { prop: 'name',name: 'Country' }, { prop: 'visaType',name: 'Visa Type' }
  ];

    
  constructor(private _http: HttpClient, private _activeroute: ActivatedRoute,private _newappService: Newappservice, private searchservice: GlobalsearchService, private _toastr: ToastrService, private _router: Router) { }

  ngOnInit() {


    this._newappService.getApplicationList(this.id, this.page, this.paginationSize,0,0,0,0,'','')
    .subscribe(
      data => {

        $("#visaLoader").hide();

        this.listobj = data;
        console.info(this.listobj)
        this.rows = this.listobj.result;
      });

/*

    //this.data = this.appService.getData();
    this.columns = [
        { key: 'riyaVisaPNR', title: "riyaVisaPNR" },
        { key: 'applicationRef', title: 'applicationRef' },
       
    ]

    this.optionsBasicNoData = {
        emptyDataMessage : 'No data available in table'
    }
    this.dataBasicNoData = [];
    this.columnsBasicNoData = [
        { key: 'id', title: "ID" },
        { key: 'name', title: 'Name' },
        { key: 'phone', title: 'Phone' },
        { key: 'company', title: 'Company'},
        { key: 'date', title: 'Date' },
        { key: 'phone', title: 'Phone' },
    ]

    //this.dataWithCaption = this.appService.getData();
    this.columnsWithCaption = [
        { key: 'id', title: "ID" },
        { key: 'name', title: 'Name' },
        { key: 'phone', title: 'Phone' },
        { key: 'company', title: 'Company'},
        { key: 'date', title: 'Date' },
        { key: 'phone', title: 'Phone' }
    ]


    this.optionsWithFeatures = {
        rowClickEvent: true,
        rowPerPageMenu: [5, 10, 20, 30],
        rowPerPage : 5
    }
   // this.dataWithFeatures = this.appService.getData();
    this.columnsWithFeatures = [
        { key: 'id', title: "ID", width: 50, sorting: true },
        { key: 'name', title: 'Name', width: 100, },
        { key: 'phone', title: 'Phone', align: { head: 'center' }, width: 100, sorting: false },
        { key: 'company', title: 'Company', width: 300, sorting: true, align: { head: 'right', body: 'right' }, noWrap: { head: true, body: true } },
        { key: 'date', title: 'Date', sorting: false, },
        { key: 'zip', title: 'ZIP Title with text wrap', sorting: true, width: 80, noWrap: { head: true, body: true } },
        { key: 'company', title: 'Company', width: 200, noWrap: { head: true, body: true } },
        { key: 'zip', title: 'ZIP', sorting: false },
        { key: 'date', title: 'Date', sorting: false },
        { key: 'zip', title: 'ZIP', sorting: false }
    ];

    //this.dataWithoutClass = this.appService.getData();
    this.columnsWithoutClass = [
        { key: 'id', title: "ID" },
        { key: 'name', title: 'Name' },
        { key: 'phone', title: 'Phone' },
        { key: 'company', title: 'Company'},
        { key: 'date', title: 'Date' },
        { key: 'phone', title: 'Phone' }
    ]*/

  }

}
