import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { VisaCountryDocuments, Client } from '../models/client';


@Injectable({
  providedIn: 'root'
})
export class ClientsService {
  
  pagesize: number = 0;
  page: number = 0;
  flag: boolean = true;
  uid: any;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    })
  };
  localData: any = {};
  token: string = null;
  constructor(private _http: HttpClient) {
    this.localData = JSON.parse(localStorage.getItem('currentUser'));
    this.token = this.localData.token;
  }

  getById(id:number) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    if (id != null) {
      return this._http.get(environment.ApiUrl + '/Clients/GetAll?page=' + this.page + '&pageSize=' + this.pagesize, { headers: headers });
    } else {
      return ('Error');
    }
  }

  get():any {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.get(environment.ApiUrl + '/Clients/Get', { headers: headers });
  }

  getCountryWiseDoc():any {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.get(environment.ApiUrl + '/Clients/GetCountryWiseDoc', { headers: headers });
  }



  save(client:any):any {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.post(environment.ApiUrl + '/Clients/Add', client, { headers: headers });
  }

  
  addCountryDocument(visaCountryDocuments:VisaCountryDocuments[]) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.post(environment.ApiUrl + '/Clients/AddCountryDocuments', visaCountryDocuments, { headers: headers });
  }

  
  toggleClient(clientId:number):any {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.get(environment.ApiUrl + '/Clients/ToggleClient?id='+clientId, { headers: headers });
  }

  getCountriesForVisa
}
