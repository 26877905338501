import { Component, OnInit } from '@angular/core';
import { Newappservice } from '../service/Newapplication_Service';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

declare var $: any;
@Component({
  selector: 'app-pending-visa',
  templateUrl: './pending-visa.component.html',
  styleUrls: ['./pending-visa.component.css']
})


export class PendingVisaComponent implements OnInit {
  visaName = 'Pending ';
  id: number;

  // pagesize =2;
  listobj: any = {};
  applicationarry = [];
  userupdatemodel = [];
  err: any;
  paginationSize = 10;
  page: number = 1;
  pageShow = 0;
  currentpagerecord: number;
  totalNoofpages: number = 0;
  totalRecords: number = 0;
  selectedIndex: number = 0;
  viewCorporate: any = null;
  visaUserType: string = null;
  private data: any;
  PageSizeArray: number[];
  constructor(private _http: HttpClient, private _newappService: Newappservice, private toastr: ToastrService, private _router: Router) { }
  ngOnInit() {
    this.PageSizeArray = this._newappService.PageSizeArray;
    $('#searchTag').hide();
    this.data = JSON.parse(localStorage.getItem('currentUser'));
    this.visaUserType = this.data.userType;
    // --------------------------- MENU ID HAS TO PASS------------
    this.id = 2;
    //  alert(this.page);
    //  alert(this.paginationSize);
    this.getapplicationlist(this.id, this.page, this.paginationSize);
  }
  getapplicationlist(id, page, paginationSize) {
    $("#visaLoader").show();
    this._newappService.getApplicationList(id, page, paginationSize,0,0,0,0,'','')
      .subscribe(
        data => {
          $("#visaLoader").hide();
          this.listobj = data;
          $('#newApplCount').text(this.listobj.allStatusCount.status_1);
          $('#pendingApplCount').text(this.listobj.allStatusCount.status_2);
          $('#submittedApplCount').text(this.listobj.allStatusCount.status_3);
          $('#closedApplCount').text(this.listobj.allStatusCount.status_4);
          // --- Used in edit update delete -----userlistarr-----
          //this.applicationarry = this.listobj.result;
          this.applicationarry = this.listobj.result;
          this.totalNoofpages = Math.ceil(this.listobj.totalCount / this.paginationSize);
        },
        error => {
          $("#visaLoader").hide();
          this.err = error;
          //console.error(error);
          if(this.err.status==0||this.err.status==401){
            localStorage.clear();
            this.toastr.error("Session Expired! Please login to Continue..");
            this._router.navigate(['/']);
          }
          else{
            this.toastr.error('Error :' + this.err.message);
         }
        }
      );
  }

  // ---- Pagination Code Start here ---- 
  currentPageBind(currPage: any) {
    this.selectedIndex = currPage;
    this.page = currPage;
    this.getapplicationlist(this.id, this.page, this.paginationSize);
    // alert('current page'+this.page);
    // this.bindUserCorporatesList(currPage, this.paginationSize);
    $('ul.pagination li').removeClass('active');
    //  this.ngOnInit();
  }
  nextPage(nextpageNumber: number) {
    this.page = nextpageNumber + 1;
    if (this.page <= this.totalNoofpages) {
      this.pageShow = this.pageShow + 10;
      //   alert('Next page'+this.page);

      //  $("ul.pagination li").removeClass("active");
      //  $("ul.pagination #li" + this.page).addClass("active");
      // this.bindUserCorporatesList(this.page, this.paginationSize);
    }
  }
  previousPage(prevPageNumber: number) {
    if (prevPageNumber >= 0) {
      this.page = prevPageNumber + 1;
      this.pageShow = this.pageShow - 10;
      // alert('Previous page'+this.page);
      //  $("ul.pagination li").removeClass("active");
      //  $("ul.pagination #li" + this.page).addClass("active");

      // this.bindUserCorporatesList(this.pageShow + 1, this.paginationSize);
    }
  }
  noOfRecordsToShow(event: any) {
    this.paginationSize = parseInt(event.target.value);
    this.page = 1;
    this.getapplicationlist(this.id, this.page, this.paginationSize);
  }
  navigateTableRow(id: any) {
    this._router.navigate(['/' + this.visaUserType + '/editVisaApplication'], { queryParams: { id: id } });
  }
}
