import { Component, OnInit,ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CheckPNRService } from 'src/app/service/CheckPNR.service';
import { FormsModule, NgModel, FormBuilder, FormGroup, Validators,NgForm } from "@angular/forms";
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from "@angular/router";
import { HttpErrorResponse } from '@angular/common/http/src/response';
import { environment } from 'src/environments/environment';
import { Newappservice } from '../../service/Newapplication_Service';
import { DubaivisaupdateserviceService } from "src/app/service/dubaivisaupdateservice.service";

declare var $: any;
@Component({
  selector: 'app-CheckPNR',
  templateUrl: './CheckPNR.component.html',
  styleUrls: ['./CheckPNR.component.css']
})

export class CheckPNRComponent implements OnInit {
  token: any;
  private localData: any;
  visaUserType: string = null;
  ServiceResponse: any;
  ReyaQuotaResponse: any;
  RiyaQuotaData: any;
  PageSizeArray: number[];
  paginationSize = 10;
  page: number = 1;
  SearchKeyWord: string = '';
  usersObject = [];
  totalRecords: any;
  totalPage: number = 0;
  pageShow = 0;
  selectedIndex: number = 0;
  userlistarr: any;
  SearchObject:any={
  };
  lstdubaiVisaFeeApplicationStatus:any={
    processing_Fee:null,
    serviceCharges:null,
    visaFees:null,
    fK_Chargeid1:0,
    fK_Chargeid2:0,
    fK_Chargeid3:0,
    charge_Amount1:null,
    charge_Amount2:null,
    charge_Amount3:null,
    markupOnBasefare:0,
    remark:'',
    CurrentStatus:false,
    FK_StatusMasterId:41,
    FK_VisaApplicationId:0
    };
    brnlist: any;
    vendorList:any=[];
    VendorName:any=null;
    fK_BranchId:any=0;
    vendorcode:any="";
    dubaiProcessByName:any="";
    serviseTypes: any;
    vendorcolor:any='red';
    visafeescolor:any='red';
    @ViewChild('dubaiVisaFees') dubaiVisaFees: NgForm;
    @ViewChild('dubaiVendors') dubaiVendors: NgForm;
    apid:number=0;
    PnrNo:string="";
    vendorId:any=0;
  constructor(private _http: HttpClient, private CheckPNR: CheckPNRService, private _toastr: ToastrService, private _router: Router, public _newappService: Newappservice,private dubaiupdateservice: DubaivisaupdateserviceService) {
   
    this.localData = JSON.parse(localStorage.getItem('currentUser'));
    if (this.localData == null) {
      this._router.navigate(['/']);
    }
    else {
      this.token = this.localData.token;
      this.visaUserType = this.localData.userType;
    }
  }

  ngOnInit() {
    this.PageSizeArray = this._newappService.PageSizeArray;
    this.GetCheckPNRData(this.page, this.paginationSize);
    this.getBranches();
    this.getVisaServiceType();
  }

  ShowRecord(event: any) {
  
    this.paginationSize = parseInt(event.target.value);
    this.page = 1;
    this.GetCheckPNRData(this.page, this.paginationSize);
  }

  GetCheckPNRData(page, paginationSize) {
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);

   
    this.SearchObject.page = page;
    this.SearchObject.pageSize = paginationSize;
    this.SearchObject.fromDate = $("#fromDate").val();
    this.SearchObject.toDate = $("#toDate").val();
    this.SearchObject.ERPStatus = $("#ERPStatus").val();
    this.SearchObject.PNRNo = $("#PNRNo").val();
    this.SearchObject.CustID = $("#CustID").val();
    this.SearchObject.FeeStatus = $("#FeeStatus").val();
    this.SearchObject.PassportNo = $("#PassportNo").val();
    
    this._http.post(this.CheckPNR.ApiUrl + "/DubaiAutomation/CheckPNR", this.SearchObject, { headers: headers })
      .subscribe((successData: any) => {
     
          this.usersObject = [];
          this.userlistarr;
          console.log(successData);
          this.usersObject.push(successData);
          this.userlistarr = this.usersObject[0].result.data;
          this.totalRecords = this.usersObject[0].result.totalRecords;
          this.totalPage = Math.ceil(this.usersObject[0].result.totalRecords / this.paginationSize);
      },
      (err: HttpErrorResponse) => {
        this._toastr.error("Data Not Found");
        console.error(err);    // SHOW ERRORS IF ANY.
      });
  };

  currentPageBind(currPage: any) {
    this.selectedIndex = currPage;
    this.page = currPage;
    $('ul.pagination li').removeClass('active');
    this.ngOnInit();
  }

  nextPage(nextpageNumber: number) {
    this.page = nextpageNumber + 1;
    if (this.page <= this.totalPage) {
      this.pageShow = this.pageShow + 10;
    }
  }

  previousPage(prevPageNumber: number) {
    if (prevPageNumber >= 0) {
      this.page = prevPageNumber + 1;
      this.pageShow = this.pageShow - 10;
    }
  }

  SearchData(){
    this.page = 1;
    this.GetCheckPNRData(this.page, this.paginationSize);
  }
  public CheckPNRFees(id:any,pnrno:string)
  {
    
    $('#visaLoader').show();
    this.lstdubaiVisaFeeApplicationStatus={
      processing_Fee:null,
      serviceCharges:null,
      visaFees:null,
      fK_Chargeid1:0,
      fK_Chargeid2:0,
      fK_Chargeid3:0,
      charge_Amount1:null,
      charge_Amount2:null,
      charge_Amount3:null,
      markupOnBasefare:0,
      remark:'',
      CurrentStatus:false,
      FK_StatusMasterId:41,
      FK_VisaApplicationId:id
      };
     this.apid=id;
     this.PnrNo=pnrno;
    this.dubaiupdateservice.GetdDubaiVisaFeesWithVendor(id).subscribe((res:any)=>{
          
         $('#visaLoader').hide();

         if(res.status==true)
         {
           if(res.result.feesDetails!=null && res.result.feesDetails!=undefined)
           {
          
            this.visafeescolor='green';
            this.lstdubaiVisaFeeApplicationStatus={
              processing_Fee:res.result.feesDetails.processing_Fee,
              serviceCharges:res.result.feesDetails.serviceCharges,
              visaFees:res.result.feesDetails.visaFees,
              fK_Chargeid1:res.result.feesDetails.fK_Chargeid1,
              fK_Chargeid2:res.result.feesDetails.fK_Chargeid2,
              fK_Chargeid3:res.result.feesDetails.fK_Chargeid3,
              charge_Amount1:res.result.feesDetails.charge_Amount1,
              charge_Amount2:res.result.feesDetails.charge_Amount2,
              charge_Amount3:res.result.feesDetails.charge_Amount3,
              markupOnBasefare:res.result.feesDetails.markupOnBasefare,
              remark:res.result.feesDetails.remark,
              CurrentStatus:false,
              FK_StatusMasterId:res.result.feesDetails.fK_StatusMasterId,
              FK_VisaApplicationId:res.result.id
        
             }
           }
           else{

            this.visafeescolor='red';
             this.lstdubaiVisaFeeApplicationStatus.FK_StatusMasterId=41;
             this.lstdubaiVisaFeeApplicationStatus.FK_VisaApplicationId=id;
             this.lstdubaiVisaFeeApplicationStatus.CurrentStatus=false;
             this.lstdubaiVisaFeeApplicationStatus.fK_Chargeid1=0;
             this.lstdubaiVisaFeeApplicationStatus.fK_Chargeid2=0;
             this.lstdubaiVisaFeeApplicationStatus.fK_Chargeid3=0;


           }

           if(res.result.vendordetails!=null && res.result.vendordetails!=undefined)
           {
            this.vendorcolor='green';
            this.fK_BranchId=res.result.vendordetails.fK_BranchId;
            this.vendorId=res.result.vendordetails.id;
            this.VendorName=res.result.vendordetails.vendor_Name;
            this.getBranches();

            let CountryCode='AE';
            let LocationCode='BOM';
            if(this.brnlist!='' && this.brnlist!=undefined)
            {
              LocationCode=this.brnlist.find(x=>x.id== this.fK_BranchId).erpLocCode;
            }
            else {
              LocationCode='BOM';
            }

            this.getVendorList(LocationCode,'AE');
            this.vendorcode=res.result.vendordetails.vendor_Code;

           }
           else{
             this.fK_BranchId=0;
             this.vendorcode="";
             this.VendorName="";
             this.vendorcolor='red';
             this.vendorId=0;
             this.getBranches();
           }
         
         
     
         }
         else{
          this.vendorcolor='red';
          this.visafeescolor='red';
            this.lstdubaiVisaFeeApplicationStatus.FK_StatusMasterId=41;
             this.lstdubaiVisaFeeApplicationStatus.FK_VisaApplicationId=id;
             this.lstdubaiVisaFeeApplicationStatus.CurrentStatus=false;
             this.lstdubaiVisaFeeApplicationStatus.fK_Chargeid1=0;
             this.lstdubaiVisaFeeApplicationStatus.fK_Chargeid2=0;
             this.lstdubaiVisaFeeApplicationStatus.fK_Chargeid3=0;
             this.fK_BranchId=0;
             this.vendorcode="";
             this.VendorName="";
             this.vendorId=0;
             this.getBranches();
         
     
         }







    },
    err=>{
            console.warn(err);
            $('#visaLoader').hide();
    
      });

  
    $("#DubaiVisa-Charges").modal('show');
  }
  VisaFeesUpdate(form,formData)
  {
    $('#btnupdatedubaiviasfees').prop('disabled', true);
     $('#visaLoader').show();
    try{
       this.dubaiupdateservice.saveDubaiVisaFeesAdd(formData).subscribe((res:any)=>{
        $('#btnupdatedubaiviasfees').prop('disabled', false);
        $('#visaLoader').hide();
         if(res.status==false)
         {
          this._toastr.error(res.messsage);
         }
         else if(res.status==true) {
          this._toastr.success('Visa Fees  Saved Succesfully!');
          $("#DubaiVisa-Charges").modal('hide');
          
         
         
         }

       },
       err=>{
        console.warn(err);
        $('#visaLoader').hide();

       });

    }
    catch(exception)
    {
      console.log(exception);
    }
  }

  getBranches()
  {
   this.dubaiupdateservice.getBranches().subscribe((data:any)=> {
    if(data.status==true)
    {
      this.brnlist=data.result;
    } 
    else{
      console.info(data.Messsage);
    }

   },
   err=>{
    console.log(err);
   }
   )

  }

  getBranchLocationCode($event)
  {
  
    let CountryCode='AE';
    let LocationCode='BOM';

    if($event.target.value!='' && $event.target.value!=undefined)
    {
      LocationCode=this.brnlist.find(x=>x.id==$event.target.value).erpLocCode;
    }
    else {
      LocationCode='BOM';
    }
    this.dubaiupdateservice.GetDubaiVisaVendors(CountryCode,LocationCode).subscribe((resp:any)=>{

      if(resp.status==true)
      {
        this.vendorList=resp.result;
        //this.notpostedStatus.dubaiVisaVenderName='BOMVEND000155';
      }
      else {
        console.log("No Vendorlist")
      }
     },
     err=>{

      console.log("No Vendorlist"+err.message);
     });

  }

  StatusSelectVendorName($event)
  {

    if($event.target.value!="")
    {
     let vendorname=this.vendorList.find(x=>x.code==$event.target.value);
    if(vendorname!=undefined)
    {
     
      this.VendorName=this.vendorList!=null && this.vendorList.length>0 ? this.vendorList.find(x=>x.code==$event.target.value).name  : null
    }
    else {
      this.VendorName="RIYA TRAVEL & TOURISM LLC (AMH Visa)";
    
    }
    }
    else{
      this.VendorName="RIYA TRAVEL & TOURISM LLC (AMH Visa)";
     
    }
    


  }
  getVisaServiceType()
  {
    this.dubaiupdateservice.getVisaServiceType().subscribe((resp:any)=>{
        if(resp.status==true)
        {
        this.serviseTypes=resp.result;
        }
        else{
          console.warn("Service Type Not found");
        }
      
      },
      err=>{
        console.log(err);
      });

  }

  getVendorList(LocationCode,CountryCode)
  {
   
  
    // if(LocationCode!='')
    // {
    //   LocationCode=this.brnlist.find(x=>x.id==$event.target.value).erpLocCode;
    // }
    // else {
    //   LocationCode='BOM';
    // }
    this.dubaiupdateservice.GetDubaiVisaVendors(CountryCode,LocationCode).subscribe((resp:any)=>{

      if(resp.status==true)
      {
        this.vendorList=resp.result;
        //this.notpostedStatus.dubaiVisaVenderName='BOMVEND000155';
      }
      else {
        console.log("No Vendorlist")
      }
     },
     err=>{

      console.log("No Vendorlist"+err.message);
     });

  }
  public ChargeTypechecked($ev,serviceType)
  {
    
    if(serviceType=='charge1')
    {
      this.VisaFeesCharge1Validator();
    
     
    }
    else if(serviceType=='charge2')
    {
      this.VisaFeesCharge2Validator();
     
      
     
    }
    else{
      this.VisaFeesCharge3Validator();
      
    }

  }
  public VisaFeesCharge1Validator()
  {
    
  
     if(this.lstdubaiVisaFeeApplicationStatus.fK_Chargeid1!='0')
      {
        this.dubaiVisaFees.form.get('charge_Amount1').setValidators([Validators.required]);
        this.dubaiVisaFees.form.get('charge_Amount1').updateValueAndValidity();
        
      }
      else {
        this.dubaiVisaFees.form.get('charge_Amount1').setValidators([Validators.nullValidator]);
        this.dubaiVisaFees.form.get('charge_Amount1').updateValueAndValidity();
        this.lstdubaiVisaFeeApplicationStatus.charge_Amount1=null;
       
      }
  }

  public VisaFeesCharge2Validator()
  {
     if(this.lstdubaiVisaFeeApplicationStatus.fK_Chargeid2!='0')
      {
        this.dubaiVisaFees.form.get('charge_Amount2').setValidators([Validators.required]);
        this.dubaiVisaFees.form.get('charge_Amount2').updateValueAndValidity();
      
      }
      else {
        this.dubaiVisaFees.form.get('charge_Amount2').setValidators([Validators.nullValidator]);
        this.dubaiVisaFees.form.get('charge_Amount2').updateValueAndValidity();
        this.lstdubaiVisaFeeApplicationStatus.charge_Amount2=null;
       
      }
  }
  public VisaFeesCharge3Validator()
  {
     if(this.lstdubaiVisaFeeApplicationStatus.fK_Chargeid3!='0')
      {
        this.dubaiVisaFees.form.get('charge_Amount3').setValidators([Validators.required]);
        this.dubaiVisaFees.form.get('charge_Amount3').updateValueAndValidity();
       
      }
      else {
        this.dubaiVisaFees.form.get('charge_Amount3').setValidators([Validators.nullValidator]);
        this.dubaiVisaFees.form.get('charge_Amount3').updateValueAndValidity();
        this.lstdubaiVisaFeeApplicationStatus.charge_Amount3=null;
        
      }
  }

  public VendorUpdated(dubaiVendors)
  {
    debugger;
    $('#btnvendorUpdated').prop('disabled', true);
    $('#visaLoader').show();
    let obj = {
      Id:this.vendorId,
      processing_Fee:null,
      serviceCharges:null,
      visaFees:null,
      fK_Chargeid1:null,
      fK_Chargeid2:null,
      fK_Chargeid3:null,
      charge_Amount1:null,
      charge_Amount2:null,
      charge_Amount3:null,
      markupOnBasefare:null,
      remark:this.PnrNo,
      CurrentStatus:true,
      FK_StatusMasterId:33  ,
      FK_VisaApplicationId:this.apid,
      fK_BranchId:this.fK_BranchId,
      Vendor_Name:this.VendorName,
      Vendor_Code:this.vendorcode ,
      Processing_By_Riya:"No"
    };
   try{
      this.dubaiupdateservice.AddOrUpdatedVendored(obj).subscribe((res:any)=>{
       $('#btnvendorUpdated').prop('disabled', false);
       $('#visaLoader').hide();
       debugger;
        if(res.status==false)
        {
         this._toastr.error(res.messsage);
        }
        else if(res.status==true) {
         this._toastr.success('Vendor Name  Add Or Updated Succesfully!');
         $("#DubaiVisa-Charges").modal('hide');
         
        }

      },
      err=>{
       console.warn(err);
       $('#btnvendorUpdated').prop('disabled', false);
       $('#visaLoader').hide();

      });

   }
   catch(exception)
   {
     console.log(exception);
   }

  }
}
