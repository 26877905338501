import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';
import { NgIcustMultiSelectModel } from '.././creations/reports/reports.component';
@Injectable({
  providedIn: 'root'
})

export class UpdateVisaService {
  // ApiUrl = 'http://192.168.60.101:83/api'; 
  pagesize: number;
  page: number;
  flag = 'True';
  uid: any;

  barcode: string;
  localData:any={};
  token:string=null;
  constructor(private _http: HttpClient,private datePipe: DatePipe) { 
    this.localData = JSON.parse(localStorage.getItem('currentUser'));
    this.token = this.localData.token;
    // this.userId = this.data.userId;  
  }
 

    // ---> For visaStatus List -- Created By : Anil
    getvisastatus() {
      let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
       return this._http.get(environment.ApiUrl + '/StatusMaster/StatusMaster',{ headers: headers});
    }

    // ----> Get user by Id -- Created By : Anil
  getbarcodeList(status, barcodearr){          // --Changes required parameter has to be sent after login------------
  
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.post( environment.ApiUrl + '/VisaApplication/VisaApplicationGetByBarCode?ToStatus='+status, barcodearr,{ headers: headers} );
  }
  getByStatus(status, from){          // --Changes required parameter has to be sent after login------------

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.get(environment.ApiUrl + '/VisaApplication/VisaApplicationGetByStatus?ToStatus='+status+'&'+'StatusID='+from,{ headers: headers} );
  }
  
  //api/VisaApplication/VisaApplicationGetByStatus

  updateSingle(applicationobj){
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.post( environment.ApiUrl + '/VisaApplStatus/UpdateSingle',applicationobj,{ headers: headers});
  }

  updateBulk(applicationobj){
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.post( environment.ApiUrl + '/VisaApplStatus/UpdateBulk?fromMobile=false',applicationobj,{ headers: headers});
  }

  //Reports Generats 
  getByDailyReport(status,txtfrom,txtTodate,brachArr:number[],icustarr:NgIcustMultiSelectModel[]){
  
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    
    txtfrom = this.datePipe.transform(txtfrom, 'M/d/yyyy');
    if(txtTodate!='' && txtTodate!=null)
    {
      txtTodate = this.datePipe.transform(txtTodate, 'M/d/yyyy');
    }
    
  
    var ReportBody={
      ReportName:status,
      FromDate:txtfrom,
      Todate:txtTodate,
      branchArr:brachArr,
      icustarr:icustarr
    };
    console.warn("report"+ReportBody);
     return this._http.post(environment.ApiUrl + '/Reports/ReportsDownload',ReportBody, { headers: headers} );
   // return this._http.get(environment.ApiUrl + '/Reports/ReportsDownload?ReportName='+status+ '&FromDate='+txtfrom+ '&Todate='+txtTodate+'&brachArr='+brachArr+'&icustarr='+icustarr, { headers: headers} );
  }
  getBranches(){
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    return this._http.get( environment.ApiUrl + '/BranchMstr/getBranchesByUser',{ headers: headers});
  }
  getIcustlist(branchId ,txtfrom,txtTodate)
  {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
   return this._http.get(environment.ApiUrl + '/VisaApplication/GetICustList?branchId='+branchId+'&FromDate='+txtfrom+ '&Todate='+txtTodate,{ headers: headers});
  
  // let param=new HttpParams();
  // branchId.forEach(element => {
  //   param.append("branchId",element);
  // });
  // return this._http.get(environment.ApiUrl + '/VisaApplication/test11?branchId='+branchId+'&FromDate='+txtfrom+ '&Todate='+txtTodate,{ headers: headers});
  }

 //Reports Generats 
 PNRReport(status,txtfrom,txtTodate,brachArr:number[],icustarr:NgIcustMultiSelectModel[]){
  
  let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
  
  txtfrom = this.datePipe.transform(txtfrom, 'M/d/yyyy');
  txtTodate = this.datePipe.transform(txtTodate, 'M/d/yyyy');
  var ReportBody={
    ReportName:status,
    FromDate:txtfrom,
    Todate:txtTodate,
    branchArr:brachArr,
    icustarr:icustarr
  };
  console.warn("report"+ReportBody);
   return this._http.post(environment.ApiUrl + '/Reports/PNRReport',ReportBody, { headers: headers} );
 // return this._http.get(environment.ApiUrl + '/Reports/ReportsDownload?ReportName='+status+ '&FromDate='+txtfrom+ '&Todate='+txtTodate+'&brachArr='+brachArr+'&icustarr='+icustarr, { headers: headers} );
 }

}