import { AnimationStyleMetadata } from '@angular/animations';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DubaivisaupdateserviceService } from "src/app/service/dubaivisaupdateservice.service";
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormControl, FormGroup, Validators,FormArray, NgForm } from '@angular/forms';
import { variable } from '@angular/compiler/src/output/output_ast';
import { Router,ActivatedRoute } from "@angular/router";
import { HttpErrorResponse } from '@angular/common/http/src/response';
import { DatePipe } from '@angular/common';
import { Console } from 'console';
import { HttpHeaders,HttpClient } from '@angular/common/http';
//import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { DomSanitizer } from '@angular/platform-browser';
import { debuglog } from 'util';
declare var $:any;
@Component({
  selector: 'app-view-dubaivisa',
  templateUrl: './view-dubaivisa.component.html',
  styleUrls: ['./view-dubaivisa.component.css']
})

export class ViewDubaivisaComponent implements OnInit {
  doclist: any=[]=[];
  token: any;
  private localData: any;
  visaUserType: string = null;
  issueingMaxDate:Date;
  countryid:any=0;
  appid:any=0;
  countryData: any = [];
  visaObj: any = {
    visaCustomer: {},
    lstApplicationStatus: [],
    lstVisaMiscFees: [],
    lstVisaApplBranch: [],
    lstVisaPassangerDetail:[],
    lstVisaDocument: []
   
  };
  CurrentApplicationStatus:any='';
    riyaprocessRight:boolean = false;
    maxDate: Date;
    minDate: Date;
    dobMinDate:Date;
    paxDOB: number;
    dobDate:any;
    visaFeesCharge1Right = false;
   visaFeesCharge2Right = false;
   visaFeesCharge3Right = false;
   notpostedStatus:any={};
   travedatechecked:boolean=false;
   VendorName:any=null;
   followupPathVisaCopy:any="";
   followupPathInsurenceCopy:any=""
   panNovalidate:boolean=false;
   currentdate:any = new Date();
   forntPassportFiles:File[];
  backPassportFiles:File[];
  passportPage1Src:any;
  passportPage2Src:any;
  passportfrontserverfile:any;
  passportbackserverfile:any;
  passportimagupload:boolean=false;
  serviseTypes: any;
  myFiles: any = [];
  PanMsg: string='';
  visaAdminUser = false;
  visaBranchManagerUser = false;
  visaDubaiManager = false;
  accountsUser = false;
   RoleRight = true;
  constructor(private _http: HttpClient,private dubaiupdateservice: DubaivisaupdateserviceService,private _toastr: ToastrService,private fb:FormBuilder, private _router: Router,private _activeroute: ActivatedRoute,private datePipe: DatePipe,private sanitizer: DomSanitizer) {

    this.localData = JSON.parse(localStorage.getItem('currentUser'));
    if(this.localData==null)
    {
      this._router.navigate(['/']);
    }
    else{
      debugger;
      this.token = this.localData.token;
      this.visaUserType = this.localData.userType;
         this._activeroute.queryParamMap.subscribe((param)=>{
           this.appid=param.get('id');
           this.countryid=param.get('countryid');
         })

         this.getDubaiVisaEditByid(this.appid,this.countryid);
        }
        $(".inner-content").css("padding", "92px 15px 0 15px");
   }

  ngOnInit() {

    this.visaObj.fK_MstProcessingOptionsId = '';
    this.visaObj.fK_educationid='';
    this.visaObj.fK_FaithName='';
    this.visaObj.fK_langid='';
    this.visaObj.Sign='';
    this.visaObj.Ecrtype='';
    this.visaObj.PanNo='';
    this.visaObj.Insurance='';
    this.visaObj.gender='';
    this.visaObj.PaxType='';
    this.visaObj.fk_comingCountryId='';
    this.visaObj.MaritalStatus='';
    this.visaObj.fk_Personalnationalid='';
    this.visaObj.fk_PersonalPRVnationalid='';
    this.visaObj.fK_ReligionId='';
    this.visaObj.fK_AppBranchId=this.localData.userBranchId;
    this.visaObj.fK_Uaeid='';
   // this.visaObj.feesDetails.FK_Chargeid1=0;
   // this.visaObj.feesDetails.FK_Chargeid2=0;
   // this.visaObj.feesDetails.FK_Chargeid3=0;
   
   this. getCountryData();

    this.getdocumentList();
    /*
    this._getProcessingOptionsByCountryid();
    this.getUAEVisatype();
    this.getCountryData();
    this. getUAEPassportType();
    this. getUAErofession();
    this.getReligion();
    this. getEdugation();
    this. getLanguages();
*/

    /*this.doc = {
      singleSelection: false,
      idField: 'docCode',
      textField: 'documentName',
      enableCheckAll: true,
      selectAllText: 'Select Document',
      unSelectAllText: 'All',
      allowSearchFilter: true,
      limitSelection: -1,
      clearSearchFilter: true,
      maxHeight: 197,
      itemsShowLimit: 3,
      searchPlaceholderText: 'Select Document',
      noDataAvailablePlaceholderText: 'Select Document',
      closeDropDownOnSelection: false,
      showSelectedItemsAtTop: false,
      defaultOpen: false,
    };*/
    //Doucment Validation
   /*
    this.followupPathVisaCopy=this.dubaiupdateservice.ApiUrl.split("/api")[0]+"/DubaiVisa/VisaCopy/";
    this.followupPathInsurenceCopy=this.dubaiupdateservice.ApiUrl.split("/api")[0]+"/DubaiVisa/Insurance/";

    this.minDate = new Date();
    this.maxDate = new Date();
    
   this.issueingMaxDate=new Date();
   this.passportExipryMaxDate=new Date();
   this.dobMinDate=new Date();
   this.travellingMaxDate=new Date();
 
   this.minDate.setDate(this.minDate.getDate());
   this.maxDate.setDate(this.maxDate.getDate());
   this.passportExipryMaxDate.setDate(this.passportExipryMaxDate.getDate() + 3650);
   this.dobMinDate.setDate(this.dobMinDate.getDate()-36500);
   this.travellingMaxDate.setDate(this.travellingMaxDate.getDate()+3700);
   */

   //this.getDubaiVisaStatus();
   ///this.getBranches();
   //this.getVisaServiceType();
   
   
   this.notpostedStatus={};
   
  }
  getCountryData() {
  
    this.dubaiupdateservice._getCountryList()
      .subscribe(result => {
        var data = { result };
        this.countryData = data;
      
      
      },
        (err: HttpErrorResponse) => {
          console.error(err.message);    // SHOW ERRORS IF ANY.
        });


      
  }

  delUploadedDoc(documentName,documentCode){
 
    var delDocObj =  this.myFiles.find(x=>x.URL==documentCode.trim());
    var delIndexPosition=this.myFiles.findIndex(x=>x.URL==documentCode.trim());
     if(delDocObj!=undefined && delIndexPosition>-1){
         this.dubaiupdateservice._delUploadedDocument(delDocObj.Id,delDocObj.bucketName,delDocObj.fileName)
         .subscribe((successData:any)=>{
          
             if(successData.status==true){
               
              //  $("input[name="+documentCode+"]").prop('checked', false);
                $('#imgDel-'+documentCode).hide();
                $('#imgUpload-'+documentCode).hide();
                
                $("#fileimg-" + documentCode).hide();
                 $("#file-"+ documentCode).parent().find('.fas').addClass('fa-upload');
                 
                this.myFiles.splice(delIndexPosition, 1);
                     // delete   uploaded doc from object
                this._toastr.success(successData.messsage);
             }
         },
         (err: HttpErrorResponse) => {
           this._toastr.error('Error in Deleting the Document!');
           console.log (err);    // SHOW ERRORS IF ANY.
         })
     }
  
    
     $('.'+documentCode).hide();
  }
  

  getdocumentList()
  {
    
   this. doclist= this.dubaiupdateservice._docList
    console.warn(this.doclist);
  }
  getDubaiVisaEditByid(appid,countryid)
{

 
  $('#visaLoader').show();
 
  this.dubaiupdateservice._getDubaiVisaEditVisaById(appid,countryid)  .subscribe((data:any)=>{
    if(data.status==true)
    {
    this.travedatechecked=true;
      $('#visaLoader').hide();
      this.visaObj=data.result;
      console.info(this.visaObj);

      console.info( this.visaObj.lstPassenserdetails.passportNo);
     debugger;

      this.visaObj.travelDate = this.datePipe.transform(this.visaObj.travelDate,'dd/MM/yyyy');
      this.visaObj.issuedateTime=this.datePipe.transform(this.visaObj.lstPassenserdetails.passportissuedate,'dd/MM/yyyy');
      this.visaObj.expirdateTime=this.datePipe.transform(this.visaObj.lstPassenserdetails.passportexpdate,'dd/MM/yyyy');
      this.visaObj.passportNo=this.visaObj.lstPassenserdetails.passportNo;
      this.visaObj.fK_PassportId=this.visaObj.lstPassenserdetails.fK_PassportId;
      this.visaObj.PassportIssuePlace=this.visaObj.lstPassenserdetails.passportIssuePlace;
      this.visaObj.fk_PassIssueCountryid=this.visaObj.lstPassenserdetails.fk_PassIssueCountryid;
      this.visaObj.Ecrtype=this.visaObj.lstPassenserdetails.ecrtype;
      this.visaObj.Sign=this.visaObj.lstPassenserdetails.sign;
      this.visaObj.Pantype=this.visaObj.lstPassenserdetails.pantype;
      this.visaObj.Insurance=this.visaObj.lstPassenserdetails.insurance;
      this.visaObj.PersonalFirstName=this.visaObj.lstPassenserdetails.personalFirstName;
      this.visaObj.dob=this.datePipe.transform(this.visaObj.lstPassenserdetails.dob,'dd/MM/yyyy');
      this.visaObj.PersonalMiddleName=this.visaObj.lstPassenserdetails.personalMiddleName;
      this.visaObj.PersonalLastName=this.visaObj.lstPassenserdetails.personalLastName;
     this.visaObj.gender=this.visaObj.lstPassenserdetails.gender.trim();
     this.visaObj.fk_birthCountryId=this.visaObj.lstPassenserdetails.fk_birthCountryId;
     this.visaObj.fK_NationalityMasterId=this.visaObj.lstPassenserdetails.fK_NationalityMasterId;
     this.visaObj.PaxType=this.visaObj.lstPassenserdetails.paxType.trim();
     this.visaObj.fatherName=this.visaObj.lstPassenserdetails.fatherName;
     this.visaObj.PersonalMotherName=this.visaObj.lstPassenserdetails.personalMotherName;
     this.visaObj.HusbandName=this.visaObj.lstPassenserdetails.husbandName;
     this.visaObj.MaritalStatus=this.visaObj.lstPassenserdetails.maritalStatus;
     this.visaObj.PersonalBirthPlace=this.visaObj.lstPassenserdetails.personalBirthPlace;
     this.visaObj.PancardNo=this.visaObj.lstPassenserdetails.pancardNo==null ? '' : this.visaObj.lstPassenserdetails.pancardNo ;
     
     this.visaObj.fK_ProfessionId=this.visaObj.lstPassenserdetails.fK_ProfessionId;
     this.visaObj.barcodeno=this.visaObj.barcodeNumber;
     this.visaObj.fk_comingCountryId=this.visaObj.lstPassenserdetails.fk_comingCountryId;
     this.visaObj.fK_ProfessionId=this.visaObj.lstPassenserdetails.fK_ProfessionId;
     this.visaObj.sChannelArabicFirstName=this.visaObj.lstPassenserdetails.sChannelArabicFirstName;
     this.visaObj.sChannelArabicMiddleName=this.visaObj.lstPassenserdetails.sChannelArabicMiddleName;
     this.visaObj.sChannelArabicLastName=this.visaObj.lstPassenserdetails.sChannelArabicLastName;
     this.visaObj.sChannelArabicMotherName=this.visaObj.lstPassenserdetails.sChannelArabicMotherName;
     this.visaObj.sChannelArabicBirthPlace=this.visaObj.lstPassenserdetails.sChannelArabicBirthPlace;
     this.visaObj.sChannelArabicPassportIssuePlace=this.visaObj.lstPassenserdetails.sChannelArabicPassportIssuePlace;
     this.visaObj.sChannelArabicFirstName=this.visaObj.lstPassenserdetails.sChannelArabicFirstName;
     this.visaObj.fk_Personalnationalid=this.visaObj.lstPassenserdetails.fk_Personalnationalid;
     this.visaObj.fk_PersonalPRVnationalid=this.visaObj.lstPassenserdetails.fk_PersonalPRVnationalid;
     this.visaObj.fK_ReligionId=this.visaObj.lstPassenserdetails.fK_ReligionId;
     //this.getFaithByReligionName(this.visaObj.fK_ReligionId);
     this.visaObj.fK_FaithName=this.visaObj.lstPassenserdetails.fK_FaithName;
     this.visaObj.fK_educationid=this.visaObj.lstPassenserdetails.fK_educationid;
     this.visaObj.fK_langid=this.visaObj.lstPassenserdetails.fK_langid;
     this.visaObj.lstApplicationStatus.forEach(element => {
      this.visaObj.visaRemark='';
      this.CurrentApplicationStatus=element.statusName;

      this.visaObj.fk_PassIssueCountry=this.countryData.result.find(x=>x.id==this.visaObj.fk_PassIssueCountryid).name;
      this.visaObj.fk_birthCountry=this.countryData.result.find(y=>y.id==this.visaObj.fk_birthCountryId).name;
     
      this.visaObj.fK_NationalityMaster=this.countryData.result.find(x=>x.id==this.visaObj.fK_NationalityMasterId).name; // for deafault country India
      this.visaObj.fk_comingCountry=this.countryData.result.find(t=>t.id==this.visaObj.fk_comingCountryId).name;
      this.visaObj.fk_Personalnational=this.countryData.result.find(n=>n.id==this.visaObj.fk_Personalnationalid).name;
      this.visaObj.fk_PersonalPRVnational=this.countryData.result.find(pr=>pr.id==this.visaObj.fk_PersonalPRVnationalid).name;
      
debugger;

     });
    
      if(this.visaObj.lstVisaDocument.length>0)
      {
      
        for(var doc of this.visaObj.lstVisaDocument) {

         var Id=this.doclist.find(x=>x.docCode==doc.url).Id;
         var tempObj = {
          Id:doc.id,
          URL:doc.url,
          documentName:doc.documentName,
          availability:doc.availability,
          fileName:doc.fileName,
          bucketName:doc.bucketName,
          encryptionKey:doc.encryptionKey,
          contentType:doc.contentType,
        }
        debugger;
        this.myFiles.push(tempObj);
          if(Id==1)
          {
            this.passportimagupload=true;
            this.imageshow(doc.id,doc.fileName,doc.url,Id);
           // $("#imgUpload-"+doc.url).show();
            $('#fileimg-passportpage1').show();
            $('#fileimg-'+doc.url).show();
            $('#imgDel-' + doc.url).show();
            $('#file-'+doc.url).hide();
            $("#imgUpload-"+doc.url).show();
            $('#imgDel-passportpage1').show();
           // let passport1 = 'file-'+doc.url; 
           //$('label[for="' + passport1 + '"]').css("display","none !important");
          
           
          }
          else if(Id==2)
          {
            this.passportimagupload=true;
            this.imageshow(doc.id,doc.fileName,doc.url,Id);
         
          //  $("#imgUpload-"+doc.url).show();
            $('#fileimg-passportpage2').show();
            $('#imgDel-passportpage2').show();

            $('#fileimg-'+doc.url).show();
            $('#imgDel-' + doc.url).show();
            $("#file-"+doc.url).hide();
            $("#imgUpload-"+doc.url).show();
          }
          else{
          
            if(doc.contentType!=null && doc.contentType!=''){
      
             // console.log(string2 + "found");
              $('#fileimg-'+doc.url).show();
              $("#file-"+doc.url).hide();
              this.imageshow(doc.id,doc.fileName,doc.url,Id);
           
              $("#imgUpload-"+doc.url).show();
              $('#imgDel-' + doc.url).show();
            }else{
              $('#fileimg-'+doc.url).hide();
             // $("#file-" + doc.url).parent().find('.fas').addClass('fa-file-upload');
              $("#imgUpload-"+doc.url).hide();
              $("#file-"+doc.url).show();
            }
           // $('#imgDel-' + doc.url).show();
           // $("#imgbrows-"+doc.url).hide();
          


           
          }
        }
      
      }
     
     /* if(this.visaObj.Pantype=="With PAN")
      {
        this.validateForm.form.get('PancardNo').setValidators([Validators.required]);
        this.validateForm.form.get('PancardNo').updateValueAndValidity();
      }
      else{
        this.validateForm.form.get('PancardNo').setValidators([Validators.nullValidator]);
        this.validateForm.form.get('PancardNo').updateValueAndValidity();
      }
      this.husValidator();*/
    //  $('#visaLoader').hide();
    debugger;
     if(this.visaObj.dxB_APIStatusID!=null && this.visaObj.dxB_APIStatusID!=undefined)
     {
      this.riyaprocessRight=true;
     }
     else {
       if(this.visaObj.fK_Uaeid==4 || this.visaObj.fK_Uaeid==6)
       {
        this.riyaprocessRight=false;
       }
       else{
        this.riyaprocessRight=true;
       }
     
     }

    }
    else{
     // $('#visaLoader').hide();
    alert("Application Data not Found");

    }
   
  },
  err=>{
    this.travedatechecked=false;
    $('#visaLoader').hide();
    console.info("Err"+err);

  });

}

public imageshow(id,fileName,docCode,Id)
  {
   
    let mySrc;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    this._http.get(this.dubaiupdateservice.ApiUrl+"/VisaApplDocument/DownloadDocument?id="+id,
    { headers: headers , observe: 'response', responseType: 'arraybuffer'}, )
    .subscribe(res => {
     
      var contentType = res.headers.get('content-type');
      var blob = new Blob([res.body], { type:contentType});
      console.log(res);
    
const reader = new FileReader();
reader.readAsDataURL(blob); 
reader.onloadend = function() {
   // result includes identifier 'data:image/png;base64,' plus the base64 data
   mySrc = reader.result;  
   if(Id==1)
   {
    $('#fileimg-passportpage1').attr('src',mySrc);
   }
   if(Id==2)
   {
    $('#fileimg-passportpage2').attr('src',mySrc);
   }
   $('#fileimg-'+docCode).attr('src',mySrc);   
}
    },
    err=>{
      console.error (err); 
    })

  }



}
