import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Route, Router } from "@angular/router";
@Component({
  selector: 'app-dummy',
  templateUrl: './dummy.component.html',
  styleUrls: ['./dummy.component.css']
})
export class DummyComponent implements OnInit {
  visaUserType: string = null;
  localData: any = {};
  loginUserName: string = null;

  constructor(private urlRoute: ActivatedRoute, private router: Router, ) {
    this.localData = JSON.parse(localStorage.getItem('currentUser'));
    this.visaUserType = this.localData.userType;
    this.loginUserName = this.localData.userName;
  }

  ngOnInit() {
	  
	
    this.router.navigate([this.visaUserType + '/globalsearch'], { skipLocationChange: true });
  }

}
