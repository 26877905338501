import { Component, OnInit, ViewChild } from '@angular/core';
import { UsermanagementService } from '../../service/userService';
import { HttpClient,HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { AddVisaService } from 'src/app/service/addVisaSerivce';
import {  Router } from '@angular/router';


declare var $: any;
@Component({
  selector: 'app-view-agent-profile',
  templateUrl: './view-agent-profile.component.html',
  styleUrls: ['./view-agent-profile.component.css']
})
export class ViewAgentProfileComponent implements OnInit {
  @ViewChild('chngpass') form;
  userpassworddata:any={};
  public passMatch:boolean;
  private localData: any;
  token: any;
  visaUserType: string = null;
  profileimagepath: string = null;
  companylogopath: string = null;
//resultdate: any = [];
  
  profiledata: any = {};
  constructor(private _userService: UsermanagementService,private _http: HttpClient, private _toastr: ToastrService,private _visaService:AddVisaService,
    private _router: Router) { }

  ngOnInit() {
  
    this.getUserProfile();
    this.localData = JSON.parse(localStorage.getItem('currentUser'));
    if(this.localData==null)
    {
      this._router.navigate(['/']);
    }
    else{
    this.token = this.localData.token;
    this.visaUserType = this.localData.userType;
    }
    var self=this;

    $(document).on('change','.profileimage',function(event){
     
      let this_id=$(this).attr('id');
      console.info('upload profile image');
      self.UploadProfileImage(event);
     
  
      });


      $(document).on('change','.companylogo',function(event){
     
        let this_id=$(this).attr('id');
       
        self.UploadCompanyLogo(event);
       
    
        });
  

  }


UploadProfileImage(e)
{
  
    // let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    var formData = new FormData();
    formData.append("ProfileImage", e.target.files[0]);
    this._http.post(this._visaService.ApiUrl + "/B2BUserDetails/UploadProfileImage", formData, { headers: headers } )
    .subscribe((successData:any) => {

      console.info(successData.result); 
      $('#imagePreview').src=successData.result;


      let objectURL = 'data:image/jpeg;base64,' + successData.result;

this.profileimagepath = objectURL;

    })
    }


    
UploadCompanyLogo(e)
{
  
    // let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    var formData = new FormData();
    formData.append("CompanyLogo", e.target.files[0]);
    this._http.post(this._visaService.ApiUrl + "/B2BUserDetails/UploadCompanyLogo", formData, { headers: headers } )
    .subscribe((successData:any) => {

      //console.info(successData.result); 
    //  $('#imagePreview').src=successData.result;

//assets/images/riya-visa-service-logo.png
      let objectURL = 'data:image/jpeg;base64,' + successData.result;

this.companylogopath = objectURL;

    })
    }


  changepassword()
  {

    $('.required').fadeOut();
    var validcheck=true;
    if($('#txtCurrentPwd').val()=='')
    {
      $(".currentpwd").fadeIn();
        
      validcheck= false;
    }
    if($('#txtNewPwd').val()=='')
    {
      $(".newpwd").fadeIn();
        
      validcheck= false;
    }
    if($('#txtReenterPwd').val()=='')
    {
      $(".reenterpwd").fadeIn();
        
      validcheck= false;
    }

    if(validcheck)
    {
      
    }

  }

  checkPassword(event) {
    const password = this.userpassworddata.newPwd;
    const confirm_new_password = event.target.value;

    if (password !== undefined) {
      if (confirm_new_password !== password) {
        this.passMatch = true;
      } else {
        this.passMatch = false;
      }
    }
  }
  
  createChangepass=function() {     
    $("#changePwdBtn").prop('disabled', true);
    debugger;
     if(this.userpassworddata.oldPwd==undefined||this.userpassworddata.oldPwd==""||this.userpassworddata.oldPwd==null){
     
      this._toastr.error("Old Password Cant be empty!"); 
      $("#changePwdBtn").prop('disabled', false);     
      return false;
     }
     if(this.userpassworddata.newPwd==undefined||this.userpassworddata.newPwd==""||this.userpassworddata.newPwd==null){
     
      this._toastr.error("New Password Cant be empty!"); 
      $("#changePwdBtn").prop('disabled', false);     
      return false;
     }
     else  if(this.userpassworddata.newPwd!==this.userpassworddata.cnfrmPwd){
      debugger;
      this._toastr.error("New Password and Confirm password should be same."); 
      $("#changePwdBtn").prop('disabled', false);     
      return false;
    }
    else if(this.userpassworddata.oldPwd==this.userpassworddata.newPwd){
      debugger;
        this._toastr.error("Old and New Passowrd Can't be Same!");
        $("#changePwdBtn").prop('disabled', false);
        return false;
    }
    else{
      this._visaService._changePasswordAfterLogin(this.userpassworddata.oldPwd,this.userpassworddata.newPwd)  
        // this._http.post('http://192.168.60.101:83/api/UserDetails/ChangeUserPassword?EmpCode='+this.userId+'&OldPass='+this.userpassworddata.oldPwd+'&NewPass='+this.userpassworddata.newPwd+'')
        .subscribe(
          (SuccesData:any) => { 
            debugger;
            $("#changePwdBtn").prop('disabled', false);
            //console.log(SuccesData);
            if(SuccesData.status = true){
              this.userpassworddata={};
              this.form.form.markAsPristine();
              this.form.form.markAsUntouched();
              this.form.form.updateValueAndValidity();
              this._toastr.success(SuccesData.messsage);
            //  this._toastr.success('Password changed Successfully');              
            }else{
              this._toastr.error(SuccesData.messsage);
            }
          },
        (error: HttpErrorResponse) => {
          $("#changePwdBtn").prop('disabled', false);
          this.userpassworddata={};          
          this.err = error;
          //console.error(error);
          this._toastr.error('Password Updation Failed!');
        });
      } 
   };

  getUserProfile() {
    // ---- Call all user data for grid binding ---
    //debugger;
    $('#visaLoader').show();
    this._userService.getAgentbyId().subscribe(
      (data: any)  => {
       // this.resultdate=data;
       console.info(data);
        this.profiledata=data.result;
        console.info(data);
        $('#txtFullName').val(this.profiledata.userName);
        $('#txtPhoneNo').val(this.profiledata.mobNo);

        $('#txtEmail').val(this.profiledata.email);
        $('#txtCompany').val(this.profiledata.customerName);
        $('#txtAddress').val(this.profiledata.address);


/*if(this.profiledata.profileImage!=null && this.profiledata.profileImage!="")
{
  this.profileimagepath=this.profiledata.profileImage;
}else{
  this.profileimagepath='assets/images/profile-pic.jpg';
}*/



if(this.profiledata.profileImage!=null && this.profiledata.profileImage!="")
{
  let objectURL = 'data:image/jpeg;base64,' + this.profiledata.profileImage;

  this.profileimagepath = objectURL;
  localStorage.setItem('UserImage',objectURL);
}else{
  this.profileimagepath='assets/images/profile-pic.jpg';
  localStorage.setItem('UserImage','assets/images/profile-pic.jpg');
}
if(this.profiledata.companyLogo!=null && this.profiledata.companyLogo!="")
{
  let objectURL = 'data:image/jpeg;base64,' + this.profiledata.companyLogo;

  this.companylogopath = objectURL;
}else{
  this.companylogopath='assets/images/riya-visa-service-logo.png';
} 
        

        $('#visaLoader').hide();
      },
      error => {
        $('#visaLoader').hide();
        //this.err = error;
       // this._toastr.error('Error :' + this.err.statusText);
      }
    );
  };






}
