import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RiyaQuotaService } from 'src/app/service/RiyaQuota.service';
import { FormsModule, NgModel, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from "@angular/router";
import { HttpErrorResponse } from '@angular/common/http/src/response';
import { environment } from 'src/environments/environment';
import { Newappservice } from '../../service/Newapplication_Service';


declare var $: any;
@Component({
  selector: 'app-Quota',
  templateUrl: './Quota.component.html',
  styleUrls: ['./Quota.component.css']
})

export class QuotaComponent implements OnInit {
  token: any;
  private localData: any;
  visaUserType: string = null;
  ServiceResponse: any;
  ReyaQuotaResponse: any;
  RiyaQuotaData: any;
  PageSizeArray: number[];
  paginationSize = 10;
  page: number = 1;
  SearchKeyWord: string = '';
  usersObject = [];
  totalRecords: any;
  totalPage: number = 0;
  pageShow = 0;
  selectedIndex: number = 0;
  userlistarr: any;
  SearchObject:any={
  };

  constructor(private _http: HttpClient, private riyaquota: RiyaQuotaService, private _toastr: ToastrService, private _router: Router, public _newappService: Newappservice) {
    debugger;
    this.localData = JSON.parse(localStorage.getItem('currentUser'));
    if (this.localData == null) {
      this._router.navigate(['/']);
    }
    else {
      this.token = this.localData.token;
      this.visaUserType = this.localData.userType;
    }
  }

  ngOnInit() {
    this.GetRiyaQuota();
    this.GetAPIStatus();
    debugger;
    this.PageSizeArray = this._newappService.PageSizeArray;
    this.GetRiyaQuotaVisaApplication(this.page, this.paginationSize);
    
  }

  ShowRecord(event: any) {
    debugger;
    this.paginationSize = parseInt(event.target.value);
    this.page = 1;
    this.GetRiyaQuotaVisaApplication(this.page, this.paginationSize);
  }

  GetRiyaQuotaVisaApplication(page, paginationSize) {
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);

    debugger;
    this.SearchObject.page = page;
    this.SearchObject.pageSize = paginationSize;
    this.SearchObject.PNRNo = $("#PNRNo").val();
    this.SearchObject.PassportNo = $("#PassportNo").val();
    this.SearchObject.PassangerName = $("#PassangerName").val();
    this.SearchObject.fromDate = $("#fromDate").val();
    this.SearchObject.toDate = $("#toDate").val();
    this.SearchObject.APIStatusIDF = $("#APIStatusIDF").val();
    this.SearchObject.CIPVIP = $("#CIPVIP").val();

    this._http.post(this.riyaquota.ApiUrl + "/DubaiAutomation/GetRiyaQuotaVisaApplication", this.SearchObject, { headers: headers })
      .subscribe((successData: any) => {
        debugger;
          this.usersObject = [];
          this.userlistarr;
          console.log(successData);
          this.usersObject.push(successData);
          this.userlistarr = this.usersObject[0].result.data;
          this.totalRecords = this.usersObject[0].result.totalRecords;
          this.totalPage = Math.ceil(this.usersObject[0].result.totalRecords / this.paginationSize);
      },
      (err: HttpErrorResponse) => {
        this._toastr.error("Data Not Found");
        console.error(err);    // SHOW ERRORS IF ANY.
      });
  };

  currentPageBind(currPage: any) {
    this.selectedIndex = currPage;
    this.page = currPage;
    $('ul.pagination li').removeClass('active');
    this.ngOnInit();
  }

  nextPage(nextpageNumber: number) {
    this.page = nextpageNumber + 1;
    if (this.page <= this.totalPage) {
      this.pageShow = this.pageShow + 10;
    }
  }

  previousPage(prevPageNumber: number) {
    if (prevPageNumber >= 0) {
      this.page = prevPageNumber + 1;
      this.pageShow = this.pageShow - 10;
    }
  }

  GetAPIStatus() {
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);

    this._http.get(this.riyaquota.ApiUrl + "/DubaiAutomation/GetDXB_APIStatus", { headers: headers })
      .subscribe((successData: any) => {
        debugger;
        this.ServiceResponse = successData.result;
      },
        (err: HttpErrorResponse) => {
          debugger;
          this._toastr.error("Data Not Found");
          console.error(err);    // SHOW ERRORS IF ANY.
        });
  }

  GetRiyaQuota() {
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    
    this._http.post(this.riyaquota.ApiUrl + "/DubaiAutomation/GetRiyaQuota", { headers: headers })
      .subscribe((successData: any) => {
        debugger;
        this.ReyaQuotaResponse = successData.result;
        $("#LongTerm").text(this.ReyaQuotaResponse.longTerm);
        $("#LongConsumed").text(this.ReyaQuotaResponse.long_Consumed);
        $("#LongInProcess").text(this.ReyaQuotaResponse.long_InProcess);
        $("#LongPermanent").text(this.ReyaQuotaResponse.long_Permanent);
        $("#LongTemporary").text(this.ReyaQuotaResponse.long_Temporary);
        $("#ReadyToPay").text(this.ReyaQuotaResponse.readyToPay);
        $("#ShortTerm").text(this.ReyaQuotaResponse.shortTerm);
        $("#ShortConsumed").text(this.ReyaQuotaResponse.short_Consumed);
        $("#ShortInProcess").text(this.ReyaQuotaResponse.short_InProcess);
        $("#ShortPermanent").text(this.ReyaQuotaResponse.short_Permanent);
        $("#ShortTemporary").text(this.ReyaQuotaResponse.short_Temporary);
      },
        (err: HttpErrorResponse) => {
          debugger;
          this._toastr.error("Data Not Found");
          console.error(err);    // SHOW ERRORS IF ANY.
        });
  }

  SearchData(){
    this.page = 1;
    this.GetRiyaQuotaVisaApplication(this.page, this.paginationSize);
  }

  // GetRiyaQuotaVisaApplication() {
  //   let headers = new HttpHeaders();
  //   headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);

  //   this._http.get(this.riyaquota.ApiUrl + "/DubaiAutomation/GetRiyaQuotaVisaApplication", { headers: headers })
  //     .subscribe((successData: any) => {
  //       debugger;
  //       this.RiyaQuotaData = successData.result;
  //     },
  //       (err: HttpErrorResponse) => {
  //         debugger;
  //         this._toastr.error("Data Not Found");
  //         console.error(err);    // SHOW ERRORS IF ANY.
  //       });
  // }
}
