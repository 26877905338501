import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse, HttpClient } from "@angular/common/http";
import { UpdateVisaService } from "src/app/service/updateVisaService";
declare var $: any;
@Component({
  selector: 'app-pnrreport-attributes',
  templateUrl: './pnrreport-attributes.component.html',
  styleUrls: ['./pnrreport-attributes.component.css']
})
export class PNRReportAttributesComponent implements OnInit {
  selectedOption: string;
  err: any;
  DailyDate: any;
  txtFrom: string;
  txttoDate: any;
  dt: any;
  brnlist:any;
  localData:any={};
  visaUserType:string=null;
  branchlist: any=[]=[];
  IcustList: any;
  IcustResult: any;
  public settings = {};
  public settingsIcust={};
  public selectedItems = [];
  public IcustSelected:NgIcustMultiSelectModel[]=[];
  multibracnes:NgMultiSelectModel[]=[];
  constructor( private _http: HttpClient,
    private _ReportService: UpdateVisaService,
    private toastr: ToastrService) { 
      this.localData = JSON.parse(localStorage.getItem('currentUser'));
      this.visaUserType=this.localData.userType;  

    }

  ngOnInit() {
    this.getBranches();
    this.settings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      enableCheckAll: true,
      selectAllText: 'Select Branch',
      unSelectAllText: 'All',
      allowSearchFilter: true,
      limitSelection: -1,
      clearSearchFilter: true,
      maxHeight: 197,
      itemsShowLimit: 3,
      searchPlaceholderText: 'Select Branch',
      noDataAvailablePlaceholderText: 'Select Branch',
      closeDropDownOnSelection: false,
      showSelectedItemsAtTop: false,
      defaultOpen: false,
    };

    this.settingsIcust = {
      singleSelection: false,
      idField: 'icust',
      textField: 'customerName',
      enableCheckAll: true,
      selectAllText: 'Select Icust',
      unSelectAllText: 'All',
      allowSearchFilter: true,
      limitSelection: -1,
      clearSearchFilter: true,
      maxHeight: 197,
      itemsShowLimit: 3,
      searchPlaceholderText: 'Select Icust',
      noDataAvailablePlaceholderText: 'Select Icust',
      closeDropDownOnSelection: false,
      showSelectedItemsAtTop: false,
      defaultOpen: false,
    };
  }

  ddStatus(status)
  {
    
    this.txttoDate='';
    this.selectedOption = status;

    //Commneted as per pooja to shaow from and to Date in dashboard Report as well
    // if(this.selectedOption==="1")
    // {
    //    $(".hidecountry").css("display","none");
    // }else{
    //   $(".hidecountry").css("display","block");
    // }
    $(".hidecountry").css("display","block");//added
    

    if(this.selectedOption==="2")
    {

      $('#lbldaily').text("To Date");
      $(".to").css("display","none");
      $('#adddailyrpt').addClass("addremove");
      
    }else{
      $('#lbldaily').text("From Date");
      $('#adddailyrpt').removeClass("addremove");
    }
  }

  PNRReport()
  {
    
    
    var txtfrom = $('#from').val();
    this.txttoDate=$('#to').val();
    
    debugger;
    $('#visaLoader').show();
    this._ReportService
		.PNRReport(this.selectedOption,txtfrom,this.txttoDate,this.selectedItems,this.IcustSelected)
		.subscribe(
        (data: any) => {
          console.info(data);
         
          if( data["result"]!=null){

            window.open(
              data["result"],
              '_blank' // <- This is what makes it open in a new window.
            );
  

          }
          $('#visaLoader').hide();
          this.toastr.info(data["messsage"]);
        },
        (error: HttpErrorResponse) => {
          $('#visaLoader').hide();
          this.err = error;
          this.toastr.error("Error " + this.err.statusText);
        }
       
      );
     
  }
  onReset(event)
  {
  //  this.brnlist=null;
    this.IcustList=null;
    this.IcustResult=null;
   // this.branchlist=null;
   this.getBranches();
    console.info("reset date");
  }

  Date_range2(event){
   
   // this.brnlist=null;
    this.IcustList=null;
    this.IcustResult=null;
   // this.branchlist=null;
   this.getBranches();
    var StartDate= $('#from').val();
    var EndDate= $('#to').val();
    var eDate = new Date(EndDate);
    var sDate = new Date(StartDate);


    if(StartDate!= '' && sDate> eDate)
      {
        this.toastr.warning("Please ensure that the To Date is greater than or equal to the From Date.");
      }
  }
     
  getBranches()
  {
   this._ReportService.getBranches().subscribe(data=> {
     this.brnlist=data;
    if(this.brnlist.status==true)
    {
       this.branchlist=this.brnlist.result;
    } 
    else{
      console.info(this.brnlist.Messsage);
    }

   },
   err=>{
    console.log(err);
   }
   )

  }
  
  public  onFilterChange(item: any) {
    console.info("fileter value"+item);
    }
  public onItemSelect(item: NgMultiSelectModel) {

   // this.multibracnes.push(item);
   this.selectedItems.push(item.id);
    if(this.selectedItems.length>0)
    {
      this.getICustomer();
    }
 else{
   alert("Please Select Branch Name");
    }
   
  }
  public onDeSelect(item: NgMultiSelectModel) {
    this.selectedItems.pop();

     if(this.selectedItems.length==0)
     {
      this.IcustResult=null;
     }
     else{
      this.getICustomer();
     }
     
  }

  public onSelectAll(items: NgMultiSelectModel[]) {
    items.forEach(x=>{
      this.selectedItems.push(x.id);
      
    })
    this.getICustomer();
  }
  public onDeSelectAll(items: NgMultiSelectModel[]) {
    // this.selectedItems.forEach(x=>{
    //   this.selectedItems.pop();
     
    // })
    this.IcustResult=null;
    this.selectedItems=[];
    this.getICustomer();
  }

  getICustomer()
  {

    var txtfrom = $('#from').val();
    if(this.selectedOption!='2' )//&& this.selectedOption !='1'
    {
      this.txttoDate=$('#to').val();
    }
    $('#visaLoader').show();

    this._ReportService.getIcustlist(this.selectedItems,txtfrom,this.txttoDate).subscribe(data=>{
       this.IcustList=data;
       if(this.IcustList.status==true)
       {
          this.IcustResult= this.IcustList.result;
       }
       else{
         console.info(this.IcustList.messsage);
       }
       $('#visaLoader').hide();
    },
    err=>{
      console.log(err);
      $('#visaLoader').hide();
    });
  }

  public onIcustItemSelect(item: NgIcustMultiSelectModel) {

  
    this.IcustSelected.push(item);
   
    
   }
   public onIcustDeSelect(item: NgIcustMultiSelectModel) {
     this.IcustSelected.pop();
 
     
      
   }
 
   public onIcustSelectAll(items: NgIcustMultiSelectModel[]) {
    debugger
    items.forEach(x=>{
       this.IcustSelected.push(x);
       
     })
     
   }
   public onIcustDeSelectAll(items: NgIcustMultiSelectModel[]) {
    
    this.IcustSelected=[];
   
   }

}
export interface NgMultiSelectModel{
  id?: any,
  name?: string
}

export interface NgIcustMultiSelectModel{
  icust?: string,
  customerName:string
}
