import { Component, OnInit } from "@angular/core";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpResponse
} from "@angular/common/http";
import { VisaprocessingService } from "src/app/service/visaProcessingService";
import {
  FormsModule,
  NgModel,
  FormBuilder,
  FormGroup,
  Validators
} from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { Newappservice } from '../../service/Newapplication_Service';


declare var $: any;
@Component({
  selector: "app-visa-processing-master",
  templateUrl: "./visa-processing-master.component.html",
  styleUrls: ["./visa-processing-master.component.css"]
})
export class VisaProcessingMasterComponent implements OnInit {
  VisaProcessingObject = [];
  getallCountry: any = [];
  countryList: any = {};
  OfficeList: any = {};
  err: any = {};
  VisaProcessinglistarr: any = [];
  newProccessingDays: any = {};
  selectedProcessing: any = [];
  VisaProcessingmodel = [];
  VisaProcessingobj = {};
  countryId: any;
  registerForm: FormGroup;
  submitted = false;
  visaTypeData: any = {};
  VisaTypecountryList: any = [];
  visatypeArray: any = [];
  onentriesChange: any;
  countryarray: any = [];
  searchString: string = '';
  getFeeMaster: any = {};
  PageSizeArray: number[];
  SearchKeyWord: string = '';
  fk_Countryid:any=0;
  visatypeid:any=0;
  constructor(
    private _http: HttpClient,
    private _visaprocessing: VisaprocessingService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    public _newappService: Newappservice
  ) { }
  // -- Pagination --
  paginationSize = 10;
  page: number = 1;
  pageShow = 0;
  totalNoofpages: number = 0;
  selectedIndex: number = 0;
  totalcount: any;
  Entries: any;
  vendorArray = [];
  visatype: any;
  EntriesArray: any = [];
  selectedCountryCode: any;
  ngOnInit() {
    this.allVisaProcessingList(this.page, this.paginationSize,this.SearchKeyWord);
    this.GetCountry();
    this.PageSizeArray = this._newappService.PageSizeArray;
  } 


  // All visa processing List
  allVisaProcessingList = function (page, paginationSize,SearchKeyWord) {
    $("#visaLoader").show();
    this._visaprocessing
      .getallVisaProcessing(page, paginationSize,this.SearchKeyWord,this.fk_Countryid,this.visatypeid)
      .subscribe(
        data => {
          this.VisaProcessingObject = [];
          this.VisaProcessingObject.push(data);
          $("#visaLoader").hide();
          // --- Used in edit update delete -----VisaProcessinglistarr-----
          this.VisaProcessinglistarr = this.VisaProcessingObject[0].result;
          // -- for pagination
          this.totalcount = this.VisaProcessingObject[0].totalCount;
          this.totalNoofpages = Math.ceil(
            this.VisaProcessingObject[0].totalCount / this.paginationSize
          );
        },
        error => {
          this.err = error;
          this.toastr.error("Error :" + this.err.status);
        }
      );
  };


  Addmodal = function () {
    this.newProccessingDays.id = null;
    this.newProccessingDays.Vistypeid = null;
    this.newProccessingDays.vendorid = null;
    this.newProccessingDays.AvgTime = null;
  }
  // Get All Country list
  GetCountry() {
    $("#visaLoader").show();
    this.newProccessingDays.Vistypeid = null;
    this.newProccessingDays.vendorid = null;
    this.newProccessingDays.entries = null;
    this._visaprocessing.getCountry().subscribe(
      data => {
        this.getallCountry = { data };
        this.getallCountry = this.getallCountry.data;
        $("#visaLoader").hide();
      },
      error => {
        this.err = error;
        this.toastr.error("Error :" + this.err.message);
      }
    );
  }


  // Get Country Code on change on country change
  getCountry_changeonCreate = function () {
    this.CountryCode = this.newProccessingDays.id;
    this.selectedCountryCode = this.newProccessingDays.id;
    if (this.CountryCode !== null) {
      this.GetCountry();
      this.getVisatypeByCountry(this.CountryCode); // Geting Visa Type
      //this.getVendorByCountry(this.CountryCode); // Geting Visa office
    } else {
      this.Addmodal();
    }
  };
  // Get Visa Type by Country Code
  getVisatypeByCountry(countryCode) {

    this.visatypeArray = [];
    if (countryCode != null) {
      this._visaprocessing._getVisaTypebyCountry(countryCode).subscribe(
        (data: any) => {
          this.visaTypeData = {};
          this.visaTypeData = data.result;
          this.visatypeArray = this.visaTypeData;
          //console.log(this.visatypeArray);
        },
        err => {
          this.toastr.error("Error :" + this.err.message);
        }
      );
    } else {
      this.Addmodal();
    }
  }
  // Change visa type
  getVisatype_changeonCreate() {
    this.getEntries_changeonCreate(this.newProccessingDays.Vistypeid);
    this.getVendorByCountry(this.newProccessingDays.Vistypeid);
  }

  // Get Visa office/Vendor by Country Code
  getVendorByCountry(visaTypeSelected) {

    this.vendorArray = [];
    if (this.selectedCountryCode != null) {

      this._visaprocessing.Get_VendorbyCountryId(this.selectedCountryCode, visaTypeSelected).subscribe(
        (data: any) => {

          const tempobj = [];
          tempobj.push(data.result);
          this.vendorArray = tempobj[0];
        },
        err => {
          this.toastr.error("Error :" + this.err.message);
        }
      );
    } else {
      this.newProccessingDays.visaType = null;
      // this.newProccessingDays.no_ = null;
    }
  }

  // Get Entries on change of visa type
  getEntries_changeonCreate(visatypeid) {
    this.EntriesArray = [];
    if (this.newProccessingDays.Vistypeid != null) {
      this._visaprocessing.Get_EntriesbyCountryId(visatypeid).subscribe(
        (data: any) => {
          const tempobj = [];
          tempobj.push(data.result);
          this.EntriesArray = tempobj[0];
          if (this.EntriesArray.length === 0) {
            this.newProccessingDays.entries = null;
          }
          // console.log(this.EntriesArray);
        },
        err => {
          this.toastr.error("Error :" + this.err.message);
        }
      );
    } else {
      // this.clearmodal();
    }
  }

  

  createConfirm = function () {
    this.ProccessingDaysobj = {
      "id": 0,
      "fK_VisaTypeId": this.newProccessingDays.Vistypeid,
      "entries": this.newProccessingDays.entries,
      "avgProcessingTime": this.newProccessingDays.AvgTime,
      "fK_CountryMasterId": this.newProccessingDays.id,
      "fK_MstVendorId": this.newProccessingDays.vendorid,
    };


    //console.log(JSON.stringify(this.ProccessingDaysobj));
    this._visaprocessing.createUsers(this.ProccessingDaysobj).subscribe(
      data => {
        if ((data.status = true)) {
          this.toastr.success(data["messsage"]);
          $(".class").click();
          this.newProccessingDays.AvgTime = null;
          this.newProccessingDays.vendorid = null;
          this.newProccessingDays.Entries = null;
          this.selectedProcessing.entries = null;
        
          this.allVisaProcessingList(this.page, this.paginationSize,this.SearchKeyWord);
        }
      },
      (error: HttpErrorResponse) => {
        this.err = error;
        this.toastr.error("Error :" + this.err.statusText);
      }
    );
  };



  // On click of Edit button - prashant
  editVisaProcessing = function (userData) {
    
    this.selectedProcessing = [];
    this.selectedProcessing = (userData);
  };

  editConfirm = function (editData) {
    this.VisaProcessingobj = {
      "id": editData.id,
      "fK_VisaTypeId": editData.visaTypeId,
      "entries": editData.entries,
      "avgProcessingTime": editData.avgProcessingTime,
      "fK_CountryMasterId": editData.fK_CountryMasterId,
      "fK_MstVendorId": editData.vendorId,

    };
    //console.log(JSON.stringify(this.VisaProcessingobj));
    this._visaprocessing
      .updateProccessingDays(this.VisaProcessingobj)
      .subscribe(
        data => {
          if (data.status === true) {
            this.toastr.success(data["messsage"]);
            $("#editclose").click();
            this.allVisaProcessingList(this.page, this.paginationSize,this.SearchKeyWord);
          }

         else if (data.status === false) {
            this.toastr.warning(data["messsage"]);
            
            
          }
        },
        error => {
          this.err = error;
          this.toastr.error("Error :" + this.err.statusText);
        }
      );
  };

  deleteUser = function (obj) {
    this.selectedProcessing = [];
    this.selectedProcessing = (obj);
  };

  deleteConfirm = function (id) {
    this._visaprocessing.deleteProccessingDays(id).subscribe(
      data => {
        if ((data.status = true)) {
          this.toastr.success(data["messsage"]);
          $("#btnclose").click();
          $("#btnclose1").click();
          this.allVisaProcessingList(this.page, this.paginationSize,this.SearchKeyWord);
        }
      },
      (error: HttpErrorResponse) => {
        this.err = error;
        this.toastr.error("Error :" + this.err.statusText);
      }
    );
  };



  //#region -- search autocomplete --
  onSearch_autocomplete(values) {
    if (values !== "") {
      this._visaprocessing.CountryAutocomplete(values)
        .subscribe(
          (data: any) => {
            this.countryarray = [];
            this.countryarray.push(data.result);
            $("#countrysearch").autocomplete({   //  -------- Autocomplete populate --- 
              source: function (request, response) {
                response($.map(data.result, function (value, key) {
                  return {
                    label: value.name,
                    value: value.id,
                  };
                }));
              },
              focus: function (event, ui) {
                $('#countrysearch').val(ui.item.label);
                return false;
              },
              // Once a value in the drop down list is selected, do the following:
              select: function (event, ui) {
                // place the person.given_name value into the textfield called 'select_origin'...
                $('#countrysearch').val(ui.item.label);
                $('#selectedSearchId').val(ui.item.value);
                return false;
              },
            });
          },
          error => {
            this.err = error;
            this.toastr.error('Error :' + this.err.statusText);
          });
    }

  }
  //#endregion

  // #region --Search By country
  SearchbyCountry() {
    this.page=1;
    this.allVisaProcessingList(this.page, this.paginationSize,this.SearchKeyWord);
    // this.searchString = $('#selectedSearchId').val();
    
    // if(this.searchString==""||this.searchString==null){

    //   this.toastr.error('Error : Country Not Found.');
    //   $("#visaLoader").hide();
    //   return false;
    // }

    // this.VisaProcessinglistarr = [];
    // $("#visaLoader").show();
    // this._visaprocessing.SearchbyCountry_visaprocessingday(this.searchString, this.page, this.paginationSize)
    //   .subscribe(
    //     data => {
    //       this.getFeeMaster = { data };
    //       this.VisaProcessinglistarr = this.getFeeMaster.data.result;
    //       // -- for pagination
    //       this.totalcount = this.getFeeMaster.data.totalCount;
    //       this.totalNoofpages = Math.ceil(this.getFeeMaster.data.totalCount / this.paginationSize);
    //       $("#visaLoader").hide();
    //       $('#selectedSearchId').val("");
    //     },
    //     error => {
    //       this.err = error;
    //       this.toastr.error('Error :' + this.err.statusText);
    //       $("#visaLoader").hide();
    //     }
    //   );
  }
  // #endregion
  myFunction() {
    this.searchString = "";
    $('#countrysearch').val("");
  }
  Reload() {
    this.SearchKeyWord='';
    this.ngOnInit();
  }


  // ---- Pagination Code Start here ----
  noofRecordstoDisplay(args: any) {
    // tslint:disable-next-line:radix
    this.paginationSize = parseInt(args.target.value);
    this.page = 1; // setting to default value
    this.pageShow = 0;
  }
  currentPageBind(currPage: any) {
    this.selectedIndex = currPage;
    this.page = currPage;
    $("ul.pagination li").removeClass("active");
    this.ngOnInit();
  }
  nextPage(nextpageNumber: number) {
    this.page = nextpageNumber + 1;
    if (this.page <= this.totalNoofpages) {
      this.pageShow = this.pageShow + 10;
    }
  }
  previousPage(prevPageNumber: number) {
    if (prevPageNumber >= 0) {
      this.page = prevPageNumber + 1;
      this.pageShow = this.pageShow - 10;
    }
  }
  noOfRecordsToShow(event: any) {
    this.paginationSize = parseInt(event.target.value);
    this.page = 1;
    this.allVisaProcessingList(this.page, this.paginationSize,this.SearchKeyWord);
  }

getvisaTypeonChange($event)
{
 
   if($event!=null)
   {
     this.fk_Countryid=$event.target.value
     this.getVisatypeByCountry($event.target.value);
     this.allVisaProcessingList(this.page, this.paginationSize,this.SearchKeyWord);
        
   }
  
   
}
getvisaTypesearch()
{
  $('#Searchwithid').val("0");
  $('#searchtypeval').val("");
  this.allVisaProcessingList(this.page, this.paginationSize,this.SearchKeyWord);
}

}
