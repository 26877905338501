import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AddVisaService } from 'src/app/service/addVisaSerivce';

@Component({
    selector: 'erp-push-status',
    templateUrl: './erp-push-status.component.html',
  })

  export class ERPPushStatusComponent implements OnInit {

    toShow: boolean = false;
    pnrSearch: string;
    records: any[];

constructor(private _visaService: AddVisaService) {
}

    ngOnInit(){
        if(environment.ApiUrl.includes("parikshan") || environment.ApiUrl.includes("localhost")){
            this.toShow = true;
        }
        else{
            this.toShow = false;
        }
    }  

    getPnrStatus(){

        this._visaService._GetErpPostStatus(this.pnrSearch).subscribe((a: any) =>{
            this.records = a.result;
        });

    }
  }