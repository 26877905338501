import { Component, OnInit } from '@angular/core';
import { Router ,ActivatedRoute} from '@angular/router';
import { AirindiaserviceService } from 'src/app/service/airindiaservice.service';
import { HttpErrorResponse } from '@angular/common/http/src/response';
import { ToastrService } from 'ngx-toastr';

import { HttpClient, HttpHeaders, HttpRequest, HttpResponse, } from '@angular/common/http';
declare var $: any;
@Component({
  selector: 'app-ai-registration',
  templateUrl: './ai-registration.component.html',
  styleUrls: ['./ai-registration.component.css']
})
export class AiRegistrationComponent implements OnInit {
  otpmessage='';
  OTP_emailmobile='';
  emailMsg: any;
  emailValidationStatus: boolean;
  userID =0;
  
  constructor(private _router: Router, private _toaster: ToastrService,private _airindiaService: AirindiaserviceService) { }

  ngOnInit() {
   // $("#visaLoader").show();

   //document.body.classList.add('regbody');

    $("input:checkbox").on('click', function() {
      // in the handler, 'this' refers to the box clicked on
      var $box = $(this);
      if ($box.is(":checked")) {
        // the name of the box is retrieved using the .attr() method
        // as it is assumed and expected to be immutable
        var group = "input:checkbox[name='" + $box.attr("name") + "']";
        // the checked state of the group/box on the other hand will change
        // and the current value is retrieved using .prop() method
        $(group).prop("checked", false);
        $box.prop("checked", true);
      } else {
        $box.prop("checked", false);
      }
    });

  }



  
  airindia_register()
  {
    $("#visaLoader").show();
    
    
    if($("#txtOTP").val()==null || $("#txtOTP").val()=="")
    {
      this._toaster.error("Please enter OTP");
      $("#visaLoader").hide();
      return;
    }


    var tempObj = {       
        
      userID:this.userID,     
      otp:$("#txtOTP").val(),
     
    }

    

    this._airindiaService._AuthenticateUser(tempObj)
    .subscribe((successData: any) => {
     console.info(successData.result)
      $('#btnregister').prop('disabled', false);  
      $('#otpmodal').modal('hide');
      if(successData.status==true)
      {
        localStorage.setItem('currentUser', JSON.stringify({ userId: successData.empCode, token: successData.toKen, userType: successData.userType, userName: successData.userName, userBranchId: successData.userBranchId }));
        //localStorage.setItem('currentUser', JSON.stringify({ userId:this.userID, token: successData.result, userType: 'AI', userName:$("#name").val(), userBranchId: '' }));
        localStorage.setItem('branchExecutiveUser', JSON.stringify({ userId: 'Username', token: successData.result }));
        localStorage.setItem('uaeApptype',"1");
        localStorage.setItem('AIUserID',this.userID+"");
        //this._router.navigate(['branchExecutive/VisaApplications/New']);

        this._airindiaService._InsertB2BUserLogs("Login","Login",this.userID,"Airindia");

        this._router.navigate(['/AI/addApplication'], { queryParams: { id: 0 } });
        //this._toaster.info("Registered successfully ! You will receive the credentials on the registered email address.");
        //this._router.navigate(['/ailogin']);
        $("#txtOTP").val('');
        $("#visaLoader").hide();
      }
      
else{
  this._toaster.error(successData.result); 
  $("#txtOTP").val('')
}
     //var temp = { successData };    

      $('#visaLoader').hide();     
    },
    (err: HttpErrorResponse) => {
      $('#visaLoader').hide();
      console.error(err.message);    // SHOW ERRORS IF ANY.
    });


  

  }

  getotp()
  {
    var validcheck=true;
    $("#btnregister").prop('disabled', true);
    $("#visaLoader").show();
    $('.required').fadeOut();
    if ($("#name").val() == "") {
      $(".name.required").fadeIn();
      validcheck = false;
    }
    if ($("#emailid").val() == "" ) { //&& $("#chkEmail").is(':checked')
      $(".emailid").fadeIn();
      validcheck = false;
    }
if($("#emailid").val() != "")
{
  var regexp = new RegExp("^[a-zA-Z0-9_!#$%&'*+/=?`{|}~^.-]+@[a-zA-Z0-9.-]+$");
  let testpwdformat = regexp.test($("#emailid").val());
  console.info(testpwdformat);
  if(!testpwdformat)
  {
  this._toaster.error("Invalid Email ID."); 
  $("#btnregister").prop('disabled', false);
  $("#visaLoader").hide();
  return false;
  }
}
   


    if ($("#phoneno").val() == "" ) {//&& $("#chkMobile").is(':checked')
      $(".phoneno").fadeIn();
      validcheck = false;
    }

    if ($("#phoneno").val().length !=10 ) {//&& $("#chkMobile").is(':checked')
      $(".invalidphoneno").fadeIn();
      validcheck = false;
    }

  /*  if ($("#loginid").val() == "" ) {//&& $("#chkMobile").is(':checked')
      $(".loginid").fadeIn();
      validcheck = false;
    }*/

    if(!validcheck)
    {
      $("#btnregister").prop('disabled', false);
      $('#visaLoader').hide();
      return;
    }

var otpmode='';

    /*if($("#chkEmail").is(':checked') )
    {
      this.otpmessage='We have sent an OTP on your email :'
      this.OTP_emailmobile=$("#emailid").val();
      otpmode='Email'
    }

    if($("#chkMobile").is(':checked') )
    {
      this.otpmessage='We have sent an OTP on your mobile :'
      this.OTP_emailmobile=$("#phoneno").val();
      otpmode='Mobile'
    }*/
    this.otpmessage='We have sent an OTP on your Email ID : '; //& Mobile No
    this.OTP_emailmobile=$("#emailid").val();
    otpmode='Both'
    var tempObj = {       
        
      userName:$("#name").val(),     
      emailID:$("#emailid").val(),
      mobileNo:$("#phoneno").val(),
      otpMode:otpmode,
      loginID:$("#emailid").val(),
    }
    console.info(tempObj);
    this._airindiaService._generateOTP(tempObj)
    .subscribe((successData: any) => {
     console.info(successData.result)
     
      if(successData.status==true)
      {
        $('#btnregister').prop('disabled', false);  
        $('#otpmodal').modal('show');
  
        this.userID=successData.result;
        $('#visaLoader').hide();
      }
    else{
      $("#btnregister").prop('disabled', false);
      $('#visaLoader').hide();
      this._toaster.error(successData.messsage);
    }

      //var temp = { successData };

     

      

     
    },
    (err: HttpErrorResponse) => {
      $("#btnregister").prop('disabled', false);
      $('#visaLoader').hide();
      console.error(err.message);    // SHOW ERRORS IF ANY.
    });


   
  }

  
loginlink()
{
  this._router.navigate(['/ailogin']);
}

  loginidsameas()
  {
    if($('input[name=chkloginid]:checked').val()=="Yes")
    {
    $('#loginid').val($('#emailid').val())
    }
    else{
      $('#loginid').val('')
    }
  }

  validateEmail(e)
{
  //var regExp = /^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$/;
  var regExp = /^(([^<>()\[\]\\.:\s@"]+(\.[^<>()\[\]\\.:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  //var regExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var emails =e.target.value;
  if(regExp.test(emails)){
    this.emailMsg = "";
    this.emailValidationStatus = true;
    $('.invalidemail').fadeOut();
    //console.log(this.emailError);
  }else{
    this.emailMsg = "Invalid Email ID";
    this.emailValidationStatus = false;
    $(".invalidemail").fadeIn();
  }
  console.info(this.emailMsg);
  //debugger;
}
}
