import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { UsermanagementService } from 'src/app/service/userService';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Newappservice } from '../../service/Newapplication_Service';
import { Router, CanActivate } from '@angular/router';

declare var $: any;
@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.css'],
  providers: [UsermanagementService]
})
export class UserManagementComponent implements OnInit {
  usersObject = [];
  selectedUser = [];
  selectedUserData: any = {};
  userupdatemodel = [];
  userTypelist = [];
  err: any;
  userlistarr: any = {
    branchDeatils: [{}]
  };
  branchlistarr: any;
  user: any;
  userobj: any = [];
  jsonobj: any;
  newuserdata: any = [];
  branchid: number;
  userid: number;
  userTypearr: any = [];
  usertype: any;
  registerForm: FormGroup;
  submitted = false;
  usermodel: any;
  unlockuserid: any;
  BrncId: number;
  // -- Pagination --
  paginationSize = 10;
  page: number = 1;
  pageShow = 0;
  totalNoofpages: number = 0;
  selectedIndex: number = 0;
  totalcount: any;
  localData: any;
  BranchName: any;
  Login_Usertype: any;
  PageSizeArray: number[];
  SearchKeyWord: string = '';
  constructor(private _http: HttpClient, private _userService: UsermanagementService, private formBuilder: FormBuilder, private toastr: ToastrService, public _newappService: Newappservice, private _router: Router) { }

  ngOnInit() {
    this.PageSizeArray = this._newappService.PageSizeArray;
    this.alluserlist(this.page, this.paginationSize, this.SearchKeyWord);
    this.branchlistfn();
    this.usertypelistfun();

  }

  // convenience getter for easy access to form fields
  get f() { return this.registerForm.controls; }

  onSubmit(newuserdata) {
    // this.submitted = true;
    // // stop here if form is invalid
    // if (this.registerForm.invalid) {
    //   return;
    // }
    this.createConfirm(newuserdata);

  }
  Reload() {
    this.SearchKeyWord = '';
    this.ngOnInit();
  }

  //onChange(val) { }

  alluserlist = function (page, paginationSize, SearchData) {
    // ---- Call all user data for grid binding ---
    //debugger;
    this._userService.getallusers(page, paginationSize, SearchData)

      .subscribe(
        data => {
          this.usersObject = [];
          this.userlistarr = [];
          console.log(data);
          this.usersObject.push(data);
          this.userlistarr = this.usersObject[0].result;                // --- Used in edit update delete -----userlistarr-----
          // -- for pagination
          this.totalcount = this.usersObject[0].totalCount;
          this.totalNoofpages = Math.ceil(this.usersObject[0].totalCount / this.paginationSize);
        },
        error => {
          this.err = error;
          this.toastr.error('Error :' + this.err.statusText);
        }
      );
  };


  editUser = function (userData) {
    //  this.branchlist();
    this.selectedUserData = userData;
    this.selectedUserData.branchid = userData.branchDeatils[0].id;
  };

  editConfirm = function (usermodel) {
    this.userupdatemodel = [];
    this.userupdatemodel.push(usermodel);
    for (let index = 0; index < this.userupdatemodel.length; index++) {
      const element = this.userupdatemodel[index];
      //const branchid = element.branchDeatils[0].id;
      this.userobj = {
        'id': usermodel.id,
        'empCode': usermodel.empCode,
        'userName': usermodel.userName,
        'designation': usermodel.designation,
        'email': usermodel.email,
        'mobNo': usermodel.mobNo,
        "lstUserBranchDetail": [{
          "fk_BranchMasterId": usermodel.branchid,
        }],
        'FK_UserTypeMasterId': usermodel.fK_UserTypeMasterId,
        'Dimension': '123'
      };
    }
    this.jsonobj = JSON.stringify(this.userobj);
    // console.log(this.jsonobj);
    this._userService.updateusers(this.userobj)
      .subscribe(
        data => {
          // console.log(data);
          if (data.status === true) {
            this.toastr.success(data["messsage"]);
            //$('.modal, .modal-backdrop').fadeOut();
            $('.modal').modal('hide');
          }
          this.alluserlist(this.page, this.paginationSize, this.SearchKeyWord);
        },
        error => {
          this.err = error;
          this.toastr.error('Error :' + this.err.statusText);
        });
  };

  resetUser = function (user) {


    this.selectedUser = [];
    for (let index = 0; index < this.userlistarr.length; index++) {
      const element = this.userlistarr[index];
      if (element.id === user.id) {
        this.selectedUser.push(element);
      }
    }
    // console.log(this.selectedUser);
  };

  UnlockUser = function (userid) {
    this.unlockuserid = userid;
  }

  UnlockUserApi = function () {

    console.info("i am here");

    if (this.unlockuserid == '') {

      this.toastr.error('Error : Invalid UserId');
      return false;
    }
    console.info("i am here");
    this._userService.UnlockUserApi(this.unlockuserid)
      .subscribe(
        data => {
          // console.log(data.status);
          if (data.status === true) {
            this.toastr.success(data["messsage"]);
            $('.modal, .modal-backdrop').fadeOut();

          }
        },
        error => {
          this.err = error;
          this.toastr.error('Error :' + this.err.statusText);
        }
      );


  };

  resetConfirm = function (emp) {
    if (emp.hasOwnProperty('islocked')) {
      delete emp.islocked;
    }
    this._userService.resetusers(emp, document.location.origin)
      .subscribe(
        data => {
          // console.log(data.status);
          if (data.status === true) {
            this.toastr.success(data["messsage"]);
            $('.modal, .modal-backdrop').fadeOut();

          }
        },
        error => {
          this.err = error;
          this.toastr.error('Error :' + this.err.statusText);
        }
      );
  };

  deleteUser = function (userobj) {
    this.selectedUser = [];
    this.selectedUser.push(userobj);
  };

  deleteConfirm = function (empcode) {
    this._userService.deleteusers(empcode.id)
      .subscribe(
        (data: any) => {
          if (data.status = true) {
            this.toastr.success(data["messsage"]);
            this.alluserlist(this.page, this.paginationSize, this.SearchKeyWord);
            $('#closedelete').click(); $('#closedelete1').click();
          }
        },
        (error: HttpErrorResponse) => {
          this.err = error;
          this.toastr.error('Error :' + this.err.statusText);
        }
      );
  };

  clearAdd() {
    $('#e1').val('');
    $('#s1').val('');
    $('#d1').val('');
    $('#em1').val('');
    $('#txtphone').val('');
    $('#BranchMaster').val('0: null');
    $('#usertype').val('0: null');
  }

  createConfirm = function (newuserdata) {
    debugger;
    this.userupdatemodel = [];
    this.userupdatemodel.push(newuserdata);
    for (let index = 0; index < this.userupdatemodel.length; index++) {
      const element = this.userupdatemodel[index];
      this.userobj = {
        'id': 0,
        'empCode': element.ecode,
        'userName': element.ename.trim(),
        'designation': element.desig,
        'email': element.email,
        'mobNo': element.phone,
        'password': element.usertypeid == 5 ? newuserdata.password : '',
        // 'FK_BranchManagerId' : 41,
        'FK_UserTypeMasterId': element.usertypeid,  /// element.userType,
        'CreatedBy': 1,
        'lstUserBranchDetail': [
          {
            'fK_BranchMasterId': element.branchid !== undefined ? element.branchid : this.BrncId
          }],
        'Dimension': '123'
      };
    }
    this.jsonobj = JSON.stringify(this.userobj);
    console.info('url', this._router);
    this._userService.createUsers(this.userobj, document.location.origin)
      .subscribe(
        data => {
          // console.log(data);
          if (data.status = true) {
            $('#adduserClose').click();
            this.alluserlist(this.page, this.paginationSize, this.SearchKeyWord);
            this.toastr.success(data["messsage"]);
            this.clearAdd();
          }
        },
        (error: HttpErrorResponse) => {
          debugger;
          this.err = error;

          this.toastr.error(error.error.messsage);
        });

  };

  createuserOpen = function () {
    this.branchlistfn();
    this.usertypelistfun();
    //this.registerForm.reset();
    this.submitted = false;
    this.localData = JSON.parse(localStorage.getItem('currentUser'));
    if (this.localData.userBranchId === 0) {
      this.Login_Usertype = 'Admin';
      this.BrncId = this.localData.userBranchId;
      console.info("Login_Usertype=", this.Login_Usertype);

    } else {
      this.BrncId = this.localData.userBranchId;
      this.BranchName = this.branchlistarr.find(x => x.id === this.localData.userBranchId).name;
      this.Login_Usertype = this.localData.userType;   // branchManager
    }
  };

  branchlistfn = function () {
    // ---- Call all user data for grid binding ---
    this._userService.getallbranch()
      .subscribe(
        data => {
          this.branchlist = [];
          this.branchlist.push(data);
          // --- Used in edit update delete -----userlistarr-----
          this.branchlistarr = this.branchlist[0].result;

        },
        (error: HttpErrorResponse) => {
          this.err = error;
          this.toastr.error('Error :' + this.err.statusText);
        }
      );
  };


  usertypelistfun = function () {
    // ---- Call all user type for dropdown binding ---
    this._userService.getallUserType()
      .subscribe(
        data => {
          this.userTypelist = [];
          this.userTypelist.push(data);

          // console.log(this.userTypelist);
          // --- Used in edit update delete -----userlistarr-----
          this.userTypearr = this.userTypelist[0].result;
        },
        (error: HttpErrorResponse) => {
          this.err = error;
          this.toastr.error('Error :' + this.err.statusText);
        }
      );
  };


  onbranchChange = function (id) {
    this.branchid = id;
    return this.branchid;
  };

  onuserChange = function (id) {
    if (this.newuserdata.usertypeid == 5) // hard coded for user type api user.
    {
      var result = '';
      var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      for (var i = 0; i < 7; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      this.newuserdata.ecode = result;
    } else {
      this.newuserdata.ecode = '';
    }
    this.userid = id;
    // alert(this.userid);
    this.userupdatemodel.userid = this.userid;
  };


  // ---- Pagination Code Start here ----
  noofRecordstoDisplay(args: any) {
    // tslint:disable-next-line:radix
    this.paginationSize = parseInt(args.target.value);
    this.page = 1;// setting to default value
    this.pageShow = 0;
  }
  currentPageBind(currPage: any) {
    this.selectedIndex = currPage;
    this.page = currPage;
    $('ul.pagination li').removeClass('active');
    this.ngOnInit();
  }
  nextPage(nextpageNumber: number) {
    this.page = nextpageNumber + 1;
    if (this.page <= this.totalNoofpages) {
      this.pageShow = this.pageShow + 10;
    }
  }
  previousPage(prevPageNumber: number) {
    if (prevPageNumber >= 0) {
      this.page = prevPageNumber + 1;
      this.pageShow = this.pageShow - 10;
    }
  }
  noOfRecordsToShow(event: any) {
    this.paginationSize = parseInt(event.target.value);
    this.page = 1;
    this.alluserlist(this.page, this.paginationSize, this.SearchKeyWord);
  }
  SearchUser(event) {
    console.info('Event==', event);
    this.page = 1;
    this.SearchKeyWord = event;
    this.alluserlist(this.page, this.paginationSize, event);

  }


  getTypesFOrEdit() {
    return this.userTypearr.filter(d => d.id != 5)
  }
}

