import { Component, OnInit,ViewChild,ElementRef} from '@angular/core';
// import { FormsModule } from '@angular/forms';
import { HttpClient, HttpHeaders, HttpRequest, HttpResponse, } from '@angular/common/http';
import { AddVisaService } from 'src/app/service/addVisaSerivce';
import { HttpErrorResponse } from '@angular/common/http/src/response';
//import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
//import { Router } from "@angular/router";
import {GlobalServiceService} from '../../service/global-service.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { VFSVASFeesService } from 'src/app/service/vfs-vfs.service';
import { DateTimeAdapter } from 'ng-pick-datetime';
import { DatePipe } from '@angular/common';
import { Route, ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { infocontent } from 'src/environments/environment';
declare function nexttab_click(ctl); 
declare function gototab_click(ctl); 
declare function previoustab_clk(ctl); 

declare var $: any;
export enum visaStatusMaster {
  ReadyforSubmission=1,
  Draft=43,
  DocumentPending,
  ForReview=3,
  Submitted,
  InterviewScheduled,
  Approved,
  Rejected,
  Canceled,
  Collected,
  PendingatVisaConsolate
}

@Component({
  selector: 'app-edit-app',
  templateUrl: './edit-app.component.html',
  styleUrls: ['./edit-app.component.css']
})

export class EditAppComponent implements OnInit {

  @ViewChild('attachment') attachment: any;
 
  @ViewChild('passfile') passfile: ElementRef;

  maxDate: Date;
  minDate: Date;
  passportExipryMaxDate:Date;
  dobMinDate:Date;
  travellingMaxDate:Date;
  containerClass: string;
  //public dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();

  customerDetailsData: any = { result: {} };
  locationData: any = {};
  transferBranchData: any = [];
  UserBranchData: any = [];
  visaTypeData: any = [];
  visaOfficeBoyData: any = {};
  visaObj: any = {
    visaCustomer: {},
    lstApplicationStatus: [],
    lstVisaMiscFees: [],
    lstVisaApplBranch: [],
    lstVisaPassangerDetail:[],
    lstVisaVFSVASFees: [],
    VisaAppType: 'Other Application'
  };

  //myuserFiles: any = {passengerid:'',myFiles:[]};
  myuserFiles: any = {};
  editVisaData:any={};
  tempVisaObj: any = {};
  lstVisaMiscFees: any = {
    mscAmount1: null,
    mscAmount2: null,
    mscAmount3: null,
    mscAmount4: null,
    mscAmount5: null,
    mscAmount6: null
  };
  passscan_error='';
  visaOfficeData: any = [];
  countryData: any = [];
  visaMiscChargesData: any = {};
  visaServicesData: any = {};

  resData: any = {};
  getCust: any = {};
  
 myFiles: any = [];
  myDocFilesType: string[] = [];
  passengerFiles=[];
  // sMsg: string = '';
  // abc: any = {};
  docfile: any = {};
  addDoc: any = {
    docName1: "Document1",
    docName2: "Document2",
    docName3: "Document3",
    docName4: "Document4",
    docName5: "Document5"
  };
  addNewdocCount: number = 0;
  addNewMiscCount: number = 0;
  misChagresAddedObj: any = [];
  totalMiscCharges: number = 0;
  visaEntriesData = [];
  visaProcessingOptData = [];
  autocompletearr = [];
  // imageShow="/assets/images/defaultDocUpload.jpg";
  // imageShow: any = {};
  DiscountedServCharge: any;
  resultarr: any = [];
  private localData: any;
  token: any;
  visaUserType: string = null;
  emailTag:any;
  today:any = new Date();
  passportExp:any;
  travelDate:any;
  travelDays:number;
  expDays:number;
  imageSrc: any;
  dobDate:any;
  paxDOB: number;
  emailMsg: any;
  emailValidationStatus: boolean;
  ff: any;
  Profession: any=[];
  Profession_auto : any=[];
  professionList:any=[];
  visaCountryData:any=[];
  fileToUpload: File = null;
  lstPassangerDetails:any=[];
  lstPassanger:any=[];
  filteredVisaMiscChargesData:any={};
  visaVfsVasChargesData:any[]=[];
  totalVfsVasCharges:number=0;
  processingoption='';
  branch_name='';
  country_name='';
  visa_type='';
  visaRemark='';
  vfsVasFeesAdded:{
    id: number,
    vfsVasFeesId: number,
    quantity: number,
    amount: number
  }[]=[];
  addNewVfsVasCount:number=0;
showServiceCategory:boolean = false;
  lstServiceCharge : any = [];
  categoryMasterId : any ;

  currentCust:any;
  creditLimit: number = null;
  selectedservicecharge:any; 
  passfrontsidefile:any;
  passfrontsms:string="";
  passportbackfileSize:any;
  passbacksidefile:any;
  passbacksms:string;
  allowimgextensions:any=["jpg","jpeg","JPG","JPEG"];
  fileExtension:any;
  fileExtensionback: any;
  forntPassportFiles:File[];
  backPassportFiles:File[];
  passportPage1Src:any;
  passportPage2Src:any;
  passportfrontserverfile:any;
  passportbackserverfile:any;
  passportimagupload:boolean=false;
  passportfrontfileSize:any;
  passportpageid1:string="";
  passportpageid2:string="";
  passenserpassport: any = [];
  activePath :string="";
  userbranch:number=0;

infobranch='';

  constructor(private _http: HttpClient, private _visaService: AddVisaService, private _toastr: ToastrService,private _router: Router,
    private formBuilder: FormBuilder,public _globle:GlobalServiceService,private datePipe: DatePipe,private _route: ActivatedRoute) {    
   this.localData = JSON.parse(localStorage.getItem('currentUser'));
   if(this.localData==null)
   {
     this._router.navigate(['/']);
   }
   else{
   this.token = this.localData.token;
   this.visaUserType = this.localData.userType;
   }

   
  $(document).ready(function () {
  $('img#toolbranch').data('liteTooltip',infocontent.nearestbranch); 
  $('img#toolcountry').data('liteTooltip',infocontent.country); 
  $('img#toolvisatype').data('liteTooltip',infocontent.visatype); 
  $('img#tooltraveldate').data('liteTooltip',infocontent.traveldate); 
  $('img#toolpassport').data('liteTooltip',infocontent.passport); 
  $('img#toolgivenname').data('liteTooltip',infocontent.givenname); 
  $('img#tooladditioninfo').data('liteTooltip',infocontent.additionalinfo); 
  $('img#toolscanpassport').data('liteTooltip',infocontent.scanpassport); 
  $('img#toolpassportexp').data('liteTooltip',infocontent.passportexpiry); 
  
  });

  }
  _statusMaster = visaStatusMaster;
 
  ngOnInit() {

    $('#visaLoader').show();
    $('.left-nav a').removeClass('active');
    this. getEditVisaById(this._route.snapshot.queryParams.id);
    //alert(this._statusMaster.Newsletter);
    var self=this;

    this.emailMsg = "Please enter valid Email ID";
    $(function() {

    var paxDiv=1;
     
    


    $(document).on('keyup','.searchautocomplete',function(event){ 
      
      self.onPassportplace_Change($(this).val());
    });

    $(document).on('change','.passdocinput',function(event){
       
      let this_id=$(this).attr('id');
      
      self.getFileDetails(event,'','');

  });

  $(document).on('keyup','.autocomplete_propession',function(event){
    
    self.GetAll_Profession(event);
   });

   $(document).on('change','.passportpage11',function(event){
     
    let this_id=$(this).attr('id');
    
    self.passportUploadfrontSide(event);

    });
    $(document).on('change','.passengerdocpath',function(event){
     
      let this_id=$(this).attr('id');
      console.info('upload');
      self.getFileDetailsPass(event);
     
  
      });

      $(document).on('change','.passengerdoctype',function(event){

        self.filetypechange(event)
       
    
        });
    


      $(document).on('click','.imgcsrpassport',function(){
      
        self.passportUploadFiles(); 
       });

       $(document).on('blur','.passportCustomerNo',function(event){
    
         self.getVisaCustomerByPassportNo(event);
    
        });

        $(".edit-btn").click(function(){
          
          
         let current_fs = $(this).parent();
          let next_fs = $(this).parent().next();
          
          //activate next step on progressbar using the index of next_fs
          $("#progressbar li").eq($("fieldset").index(0)).addClass("active");
          $("#progressbar li").eq($("fieldset").index(current_fs) -1 ).addClass("done");
         });

         
         

          $(document).on('click','.delete-doc',function(event){
            let ids=event.target.id;          
            var docid = ids.split("_").pop(); 
            //debugger;
            //if(docid!=1)
            {
             // $('#PassengerDoc_'+docid).remove();
             $('#PassengerDoc_'+docid).hide();
              var delIndexPosition=this.passengerFiles.findIndex(x=>x.docid==docid);
              if(delIndexPosition>0)
              {
                debugger;
                this.passengerFiles.splice(delIndexPosition, 1);  
              }
      
              //self._globle.ResetNumberDoc(); 
            }
            /*else{
              this.attachment.nativeElement.value = '';
            }*/
                       
            
            
            });


            $(document).on('click','.view-doc',function(event){
              let ids=event.target.id;          
              var docid = ids.split("_").pop(); 
              debugger;
              self.Viewdoc(docid)
             
                         
              
              
              });


              $(document).on('change','#pass-exp_1',function(event){
     
                //let this_id=$(this).attr('value');
                
                self.onPassExpChange(event);
            
                });
                $(document).on('blur','#pass-exp_1',function(event){
     
                  //let this_id=$(this).attr('value');
                  
                  self.onPassExpChange(event);
              
                  });
                $(document).on('change','#travel-date',function(event){
     
                  //let this_id=$(this).attr('value');
                  
                  self.onTravelDtChange(event);
              
                  });
  

                  $(document).on('blur','#travel-date',function(event){
     
                    //let this_id=$(this).attr('value');
                    
                    self.onTravelDtChange(event);
                
                    });
    

                


  });
  console.info( this._route.snapshot.queryParams.id);


    this.minDate = new Date();
    this.maxDate = new Date();
    this.passportExipryMaxDate = new Date();
    this.dobMinDate=new Date();
    this.travellingMaxDate= new Date();
    this.minDate.setDate(this.minDate.getDate());
    this.maxDate.setDate(this.maxDate.getDate());
    this.passportExipryMaxDate.setDate(this.passportExipryMaxDate.getDate() + 3650);
    this.dobMinDate.setDate(this.dobMinDate.getDate()-36500);
    this.travellingMaxDate.setDate(this.travellingMaxDate.getDate()+3700);
    //this.dpConfig.containerClass = 'theme-blue'; //or whatever color
    ///this.dpConfig.dateInputFormat = 'DD/MM/YYYY';
    //this.dpConfig.showWeekNumbers= false;
    this.visaObj.fK_VisaCountryMasterId = null;
    this.visaObj.fK_BranchMasterId = null;
    this.visaObj.fK_TransferBranchId= null;
    this.getCust.locationName = null;
    this.visaObj.visaCustomer.fK_NationalityMasterId = null;
    this.visaObj.entries = null;
    this.visaObj.paxType = null;
    this.visaObj.fK_VisaOfficeId = null;
    this.visaObj.fK_AppBranchId = null;
    this.visaObj.fK_UserDetailID = null;
    this.tempVisaObj.fK_BranchMasterId = null;
    this.visaObj.fK_MstProcessingOptionsId=null;
    this.visaObj.fK_VisaTypeId=null;;
    this.visaObj.fK_VendorId=null;
    this.getCust.locationId=null;


    this.pageLoadAPICalls();
 
    //this.visaObj.fK_VisaCountryMasterId= this._route.snapshot.queryParams.id;
  //console.info(this.visaObj.fK_VisaCountryMasterId);
  $('#visaLoader').hide();
  

  }



  getEditVisaById(editID){


    $('#visaLoader').show();
      debugger;
     this._visaService._getEditVisaByRefId(editID)
     .subscribe(data=>{
        $('#visaLoader').hide();
         //console.info("full data:",data);
         this.editVisaData = data;        
 
         this.visaObj = this.editVisaData.result[0];
         console.info("data:", this.editVisaData.result );


         this.visaObj.fK_AppBranchId =this.visaObj.fK_BranchMasterId;

         this.callCountryRelatedVisaAPIs('');
       //  this.lstPassanger.push(this.visaObj.lstVisaPassangerDetail[0]);
         
       let passorderid=1
       this.editVisaData.result.forEach(app=>
        {
          var passenger=app.lstVisaPassangerDetail[0];
     
          var tempObj={
            PassangerOrder:passorderid,
            PassportNum:passenger.passportNum,
             id:passenger.id,
             PassportExp : this.datePipe.transform(passenger.passportExp,"MM/dd/yyyy"),
            givenName:passenger.givenName,
            surname:passenger.surname,
            PlaceOfIssue:passenger.placeOfIssue.trim(),
            DOB : this.datePipe.transform(passenger.dob,"MM/dd/yyyy"),
            paxType:passenger.paxType.trim(),
            gender:passenger.gender.trim(),
            fK_NationalityId:passenger.fk_NationalityId,
            fK_ProfessionId:passenger.fk_ProfessionId,
            
            phoneNumber:passenger.phoneNumber,
            emailID:passenger.emailID.trim(),
            fK_NationalityName:passenger.fK_NationalityName,
            fK_ProfessionName:passenger.fK_ProfessionName
            ,lstVisaDocument:[]
          }
          let d=1
          var passfiles=[];
          app.lstVisaDocument.forEach(passdoc =>
            {
          
              var tempDoc = {
                docid:d,
                URL:passdoc.url,
                documentName:passdoc.documentName,
                availability:null,
                fileName:passdoc.fileName,
                bucketName:passdoc.bucketName,
                encryptionKey:passdoc.encryptionKey,
                contentType:passdoc.contentType,
              }
          d++;
        
          //this.passengerFiles.push(tempDoc);
          passfiles.push(tempDoc);
            });
            ///var passengerdoc=this.visaObj.lstVisaDocument;
          
          
          tempObj.lstVisaDocument=passfiles;
          this.lstPassanger.push(tempObj);
                 
          passorderid++;
          
        });
      
 
        

     });
    }


Viewdoc(docid)
{
  debugger;
  var passdoc=this.passengerFiles.find(x=>x.docid==docid);
   this.viewPassengerDoc(passdoc.URL,passdoc.bucketName,passdoc.fileName,passdoc.encryptionKey)
        
}

deletedoc(docid)
  {
    var delIndexPosition=this.passengerFiles.findIndex(x=>x.docid==docid);
    if(delIndexPosition>0)
    {
      debugger;
      this.passengerFiles.splice(delIndexPosition, 1);  
    }
  }


  adddoclist()
  {
    console.info(this.passengerFiles);
    if(this.docfile!=null && this.docfile.docid!=null)
    {

      var dupliIndexPosition=this.passengerFiles.findIndex(x=>x.URL==this.docfile.URL);
if(dupliIndexPosition>-1)
{
  this._toastr.warning(""+this.docfile.documentName+" already added in documents list.!");
}
else{
  this.passengerFiles.push(this.docfile);
}
    
      //console.info(this.passengerFiles);
      this.docfile={};
  this.attachment.nativeElement.value = '';
    }
    else{
      this._toastr.warning("Please select file to upload!");
    }
  
  
  }


  pageLoadAPICalls() {
   
   
   this.getBranchData();
     this.getCountryData();
    this.getLocationData();
    this.getTransferBranchData();
   // this.visaObj.fK_AppBranchId=1079;
    this.getvisaMiscChargesData();
    this.getOfficeBoyData();

    $(document).ready(function() {
      


      var today = new Date();

      this.minDate = new Date();
    this.maxDate = new Date();
    this.passportExipryMaxDate = new Date();
    this.dobMinDate=new Date();
    this.travellingMaxDate= new Date();
    this.minDate.setDate(this.minDate.getDate());
    this.maxDate.setDate(this.maxDate.getDate());
    this.passportExipryMaxDate.setDate(this.passportExipryMaxDate.getDate() + 3650);
    this.dobMinDate.setDate(this.dobMinDate.getDate()-36500);
    this.travellingMaxDate.setDate(this.travellingMaxDate.getDate()+3700);

      $('#pass-exp_datecal').calendar({
        type: 'date',
        monthFirst: false,
        formatter: {
        date: function (date, settings) {
          if (!date) return '';
          var day = date.getDate();
          var month = date.getMonth() + 1;
          var year = date.getFullYear();
          return day + '/' + month + '/' + year;
        }
        },
        minDate: this.minDate,
          maxDate: this.passportExipryMaxDate
        });


        $('#traveldate_datecal').calendar({
          type: 'date',
          monthFirst: false,
          formatter: {
          date: function (date, settings) {
            if (!date) return '';
            var day = date.getDate();
            var month = date.getMonth() + 1;
            var year = date.getFullYear();
            return day + '/' + month + '/' + year;
          }
          },
          minDate: this.minDate,maxDate:this.travellingMaxDate
          });

          $('#dob_datecal').calendar({
            type: 'date',
            monthFirst: false,
            formatter: {
            date: function (date, settings) {
              if (!date) return '';
              var day = date.getDate();
              var month = date.getMonth() + 1;
              var year = date.getFullYear();
              return day + '/' + month + '/' + year;
            }
            },
            maxDate: this.maxDate,minDate:this.dobMinDate
            });

    });

   



    
   
/**datepicker */
/*$("#pass-exp_1").datepicker({ minDate: this.minDate,maxDate:this.passportExipryMaxDate, 
  dateFormat: 'dd/mm/yy',
changeMonth: true,
    changeYear: true,
    showButtonPanel: false,
firstDay: 1,});




$("#dob_1").datepicker({ maxDate: this.maxDate,minDate:this.dobMinDate, dateFormat: 'dd/mm/yy',
changeMonth: true,
    changeYear: true,
    showButtonPanel: false,
firstDay: 1,});
$("#travel-date").datepicker({ minDate: this.minDate,maxDate:this.travellingMaxDate, dateFormat: 'dd/mm/yy',
changeMonth: true,
    changeYear: true,
    showButtonPanel: false,
firstDay: 1,});*/
//$("#datepicker").datepicker({ minDate: -0, maxDate: new Date(2013, 1, 18) });


    this.activePath= window.location.pathname.split('/')[2];
    console.info('activePath : '+this.activePath);

  }
  AddDoc()
  {

    this.addNewDocument();

    /*
   // console.info("AddDoc")
    var clonedDiv = $('#PassengerDoc_1').clone();
    //console.info(clonedDiv)
    $('.PassengerDoc:last').after(clonedDiv);
    let j=$('.PassengerDoc').length;

    $('.PassengerDoc:last').find('.dynamic-Pax').each(function(){

      //let id=$(this).attr('id');

      let ids=$(this).attr('id');
        
      const lastIndex = ids.lastIndexOf('_');

      let id= ids.substring(0, lastIndex);

      $(this).attr('id',id+'_'+j);
    });

debugger;
    $('.PassengerDoc:last').each(function(){
      debugger;
      //let id=$(this).attr('id');

      let ids=$(this).attr('id');
      console.info(ids);
      $(this).attr('id', 'PassengerDoc_'+j);
    })

  */
  


  }


  savePassenger( ) {    
    
    var validcheck=true;

    var validcheck=this._globle.validateAddPassenger();

    try{

      if (this.emailValidationStatus ==false) {
        this._toastr.warning("Invalid Email ID!");
      
        return false;
      }

      let currdate =new Date();      
      let newDate = new Date(this.dateFormatChange($('#pass-exp_1').val()));
      if(newDate+""=="Invalid Date")
      {
        this._toastr.error("Invalid passport expiry date");
        return;
      }
      else  if(newDate<currdate){
        this._toastr.error("Passport is expired!");
        return false;
    
      }
  


      this.dobDate = new Date(this.dateFormatChange($('#dob_1').val()));    

      if(this.dobDate=="Invalid Date")
      {
        this._toastr.error("Invalid date of birth");
        return;
      }


      this.paxDOB = (this.dobDate-this.today)/1000/60/60/24;
      if(this.paxDOB>1) {
        this._toastr.error("Invalid date of birth");
      }
    
    if(validcheck==false) {
      this._toastr.warning("Please fill all the required fields!");
      
      return false;
    }
  }
  catch(Exception)
  {
//debugger;
  }

    let docname='', bukname='',filename='',contenttype='',Encrytkey='',documentcode='';
    let passportimg=this.passenserpassport.find(x=>x.URL=='passportfront-page1_'+p);
     if(passportimg!=undefined)
     {
      documentcode=passportimg.URL;
      // docname=passportimg.documentName;
      bukname=passportimg.bucketName;
      filename=passportimg.fileName;
      contenttype=passportimg.contentType;
      Encrytkey=passportimg.encryptionKey;
     }
     else{
      documentcode='';
      docname='';
      bukname='';
      filename='';
      contenttype='';
      Encrytkey='';
     }

     if($('#hdnPassengerOrder').val()!="")
     {
      this.removepassenger($('#hdnPassengerOrder').val())
     }

  let p=1;
  if(this.lstPassanger!=null)
  {
   p=this.lstPassanger.length+1;
  }


 var tempObj={
   PassangerOrder:p,
   PassportNum:$('#pass-num_1').val().toUpperCase(),
   PassportExp : this.dateFormatChange($('#pass-exp_1').val()),
   givenName:$('#given-name_1').val().toUpperCase(),
   surname:$('#surname_1').val().toUpperCase(),
   PlaceOfIssue:$("#poi_1").val(),
   DOB : this.dateFormatChange($('#dob_1').val()),
   paxType:$('#paxType_1').val(),
   gender:$('input[name=radiogender]:checked').val(),
   fK_NationalityId:$('#nationality_1').val(),
   fK_ProfessionId:$('#professionhidden_1').val(),
   DocumentName:documentcode,
   BucketName:bukname,
   FileName:filename,
   ContentType:contenttype,
   EncryptionKey:Encrytkey,
   phoneNumber:$('#mob-num_1').val(),
   emailID:$('#emailId_1').val(),
   fK_NationalityName:$( "#nationality_1 option:selected" ).text(),
   fK_ProfessionName:$('#profession_1').val(),lstVisaDocument:[]
 }

 

tempObj.lstVisaDocument=this.passengerFiles;
this.passengerFiles=[];
 this.lstPassanger.push(tempObj);
debugger;
$('#btncontinuePassenger').show();
    
    $('#hdnPassengerOrder').val('');
    this.clearPassenger();
     console.info(this.lstPassanger);
     console.info(this.visaObj);
  }

  removepassenger(passengerorder)
  {
    
  var delIndexPosition=this.lstPassanger.findIndex(x=>x.PassangerOrder==passengerorder);

 this.lstPassanger.splice(delIndexPosition, 1);  
 debugger;
 this.ResetPassengerOrder();

if(this.lstPassanger!=null && this.lstPassanger.length>0)
{
  $('#btncontinuePassenger').show();
}
else{
  $('#btncontinuePassenger').hide();
}
  }

  ResetPassengerOrder()
  {
    console.info(this.lstPassanger);
    let p=1;
    this.lstPassanger.forEach(passenger =>
      {

        passenger.PassangerOrder=p;
      p++;
      });
      console.info(this.lstPassanger);
  }
 
  editpassenger(passengerorder)
  {
    let editPassenger= this.lstPassanger.find(x => x.PassangerOrder === passengerorder)
    $('#hdnPassengerOrder').val(editPassenger.PassangerOrder);
    $('#pass-num_1').val(editPassenger.PassportNum);
   // $('#pass-exp_1').val(editPassenger.PassportExp);
    $('#pass-exp_1').val(this.dateFormatChangeddmmyyyy(editPassenger.PassportExp));
    

    $('#given-name_1').val(editPassenger.givenName)
    $('#surname_1').val(editPassenger.surname)
    $("#poi_1").val(editPassenger.PlaceOfIssue)
    //$('#dob_1').val(editPassenger.DOB)
    $('#dob_1').val(this.dateFormatChangeddmmyyyy(editPassenger.DOB))
    $('#paxType_1').val(editPassenger.paxType)
    //$('input[name=radiogender]:checked').val(editPassenger.gender)
    if(editPassenger.gender=="Male"){
      $('#visaCustMale').prop("checked", true);
    }
    else if(editPassenger.gender=="Female") {
      $('#visaCustFemale').prop("checked", true);
    }

    $('#nationality_1').val(editPassenger.fK_NationalityId)
    //$('#professionhidden_1').val(editPassenger.fK_ProfessionId)
    $('#profession_1').val(editPassenger.fK_ProfessionId)
    $('#mob-num_1').val(editPassenger.phoneNumber)
    $('#emailId_1').val(editPassenger.emailID)
    this.passengerFiles=editPassenger.lstVisaDocument;
  console.info( this.passengerFiles);
  //debugger;
  //  $('.docdiv').hide();
    
  let cnt=this.passengerFiles.length;
  /*for(let i=1;i<=cnt;i++)
  {
    debugger;
    $('#PassengerDoc_' + i).show();
    $('#doctype_'+i).val(this.passengerFiles[i-1].URL)
    $('#view-doc_'+i).show();
  }*/
  this.attachment.nativeElement.value = '';

  }


clearPassenger()
{
  $('#pass-num_1').val(''),
  $('#pass-exp_1').val('');
  $('#given-name_1').val('');
  $('#surname_1').val('');
  $("#poi_1").val(''),
  $('#dob_1').val('');
  $('#paxType_1').val('');
  //$('input[name=radiogender]:checked').val(''),
  $('#visaCustMale').prop("checked", true);
  $('#professionhidden_1').val('');
  
  $('#mob-num_1').val('');
  $('#emailId_1').val('');
 $( "#nationality_1").val('');
 $('#profession_1').val('');

//let j=$('.PassengerDoc').length;

$('.docdiv').hide();
this.emailMsg='';
let docid=1;
/*$('.PassengerDoc').each(function(){
  debugger;
  //let id=$(this).attr('id');
  if(docid!=1)
  {
  
  
  $('#PassengerDoc_'+docid).remove();
  }
 
  docid++;
})
*/
this.attachment.nativeElement.value = '';
}

  getBranchData(){
    //console.info('getBranchData');
    this._visaService._GetUserBranchList()
    .subscribe((successData: any) => {
        var temp = { successData };
        this.UserBranchData = successData.result;
       // console.info(this.UserBranchData );
        this.userbranch=this.UserBranchData[0].branchId;
          
          
          this.visaObj.fK_AppBranchId=this.userbranch ;
          console.info(this.visaObj.fK_AppBranchId );

        if(this.UserBranchData.length>1){
          
          //$('#divUserBranch').show();
        }
        else{
          //$('#divUserBranch').hide();
        }
      },
        (err: HttpErrorResponse) => {
          console.error(err.message);    // SHOW ERRORS IF ANY.
        });
  }


 
  UploadPassport(e) {
    //this.fileToUpload = files.item(0);
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    var formData = new FormData();
    formData.append("Doc",e.target.files[0]);
debugger;
    this._http.post(this._visaService.ApiUrl + "/VisaApplDocument/UploadPassport", formData, { headers: headers } )
    .subscribe((successData:any) => {
             
              this.visaObj.visaCustomer.passportNumber=successData.result.passportNo;
              this.visaObj.visaCustomer.givenName=successData.result.fullname;
              this.visaObj.visaCustomer.surname=successData.result.surname;
              $('#dob').val(successData.result.dateOfBirth);
              $('#pass-exp').val(successData.result.dateOfExpiry) ;
              if(successData.result.sex=="F")
              {
                $('#visaCustFemale').prop("checked",true);
              }
              else
              {
                $('#visaCustMale').prop("checked",true);
              }
              $('#poi').val(successData.result.placeOfIssue);
              $('#nationality').val(successData.result.countryId);
              
            },
            (err: HttpErrorResponse) => {
              this._toastr.error('Error in Scanned file.');
              //console.error(err);    // SHOW ERRORS IF ANY.
            });
          
  }

  addNewDocument() {


    let visiable_div=$('.docdiv:visible').length;
debugger;
    if (visiable_div >= 5) {
      alert('Can Not Add more than 5 New Documents!');
    }


    for(let i=1;i<=5;i++){

      if(!$('#PassengerDoc_' + i).is(':visible')){
        $('#PassengerDoc_' + i).show();
        $('#view-doc_'+i).hide();
        break;
      }
     
    }

    
  }

  addNewMiscCharges() {
    if (this.addNewMiscCount < 3) {
      $('#totalMiscCharges').show();
      this.addNewMiscCount = this.addNewMiscCount + 1;
      $('#addNewMisc' + this.addNewMiscCount).show();
      var tempMiscObj = {
        id: this.addNewMiscCount,
        Amount: 'mscAmount' + this.addNewMiscCount,
        Quantity: 'mscQuntity' + this.addNewMiscCount
      }
      this.misChagresAddedObj.push(tempMiscObj);
    }
    else {
      alert('Can Not Add more than ' + this.addNewMiscCount + ' Misc Charges!');
    }
  }

  dobValidate(dob: Date): void {
    this.dobDate = dob;
    this.paxDOB = (this.dobDate-this.today)/1000/60/60/24;
    if(this.paxDOB>1) {
      this._toastr.error("Invalid date of birth");
    }
  }

  onPassExpDate(pExp: Date): void {
    this.passportExp = pExp;
    this.expDays = (this.passportExp-this.today)/1000/60/60/24;
    console.info(this.expDays);
    // if(this.expDays<1) {
    //   this._toastr.error("Passport is expired!");
    // } else if(this.expDays<241) {
    //   this._toastr.info("Passport is expiring within 6 months!");
    // }
  }


  onTravelDateChange(tDate: Date): void {
    if(tDate!=null) {
      console.info('expiry date==',this.expDays);
      this.travelDate = tDate;
      this.travelDays   = (this.travelDate-this.today)/1000/60/60/24;
     if(this.travelDays<1) {
       this._toastr.error("Invalid travel date");
      } else if(this.travelDays<3) {
       this._toastr.info("Less than 3 days left for travel!");
      } else if(this.travelDays>90) {
       this._toastr.info("The travel is later than 3 months!");
      }else if(this.travelDays>this.expDays){
          this._toastr.error("Your Passport is Expired!");
      }
    }

    // if(days<91) {
    //   this._toastr.info("Passport is expiring within 3 months!");
    // }
  }

   
onTravelDtChange(e)
{
 // debugger;
 if($('#travel-date').val()!="")
 {
  this.travelDate =   new Date(this.dateFormatChange($('#travel-date').val()));;
  this.travelDays   = (this.travelDate-this.today)/1000/60/60/24;
  debugger;
if(this.travelDate=="Invalid Date")
{
  this._toastr.error("Invalid travel date");
}
else
 if(this.travelDays<1) {
   this._toastr.error("Invalid travel date");
  } else if(this.travelDays<3) {
   this._toastr.info("Less than 3 days left for travel!");
  } else if(this.travelDays>90) {
   this._toastr.info("The travel is later than 3 months!");
  }
  /*else if(this.travelDays>this.expDays){
      this._toastr.error("Your Passport is Expired!");
  }*/
 }
 
}
  
onPassExpChange(e)
{
debugger;
  let currdate =new Date();
  var newDate = new Date(this.dateFormatChange($('#pass-exp_1').val()));
 if(newDate+""=="Invalid Date")
{
  this._toastr.error("Invalid passport expiry date");
  return;
}
else
  if(newDate<=currdate){
    this._toastr.error("Passport is expired!");
return;

  }


  currdate.setMonth(currdate.getMonth()+6);
  console.info(currdate.toISOString().slice(0,10));
  //this.dateFormatChange($('#pass-exp_1').val())

  let newDate2 = new Date(currdate.toISOString().slice(0,10));

  if(newDate<newDate2){
    this._toastr.info("Passport will be expiring soon!");
  }

}


  ngAfterViewInit() {
    //js for adding new documents

    $("#adddocument").click(function () {
    });

    $(document).ready(function () {
      $('.discount-show').hide();
      $('.discount-chk .checkbox-label').click(function () {

        if ($('.checkBoxClass').is(":checked")) {
          //                    alert('hi');
          $('.discount-show').fadeIn();
        }
        else {
          $('.discount-show').fadeOut();
        }
      });
    });
  }

  checkEmailpassenger(e)
  {
    var regExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var emails =e.target.value;
    console.info(emails);
    var emailArray = emails.split(";");
    var invEmails = "";
    if(emailArray.length > 7) {
      this.emailMsg = "only 7 email id allowed";
    } else {
      for(var i = 0; i <= (emailArray.length - 1); i++){
        if(regExp.test(emailArray[i])){
          this.emailMsg = "Email validated!";
          this.emailValidationStatus = true;
          //console.log(this.emailError);
        }else{
          invEmails += emailArray[i] + "\n";
        }
      }
    }
    debugger;
    if(invEmails.trim() != ""){
      //this.emailMsg = "Invalid emails:\n" + invEmails;
      this.emailMsg = "Invalid Email ID";
      this.emailValidationStatus = false;
      //console.log(this.emailError);
    }
    // else{
    //   this.emailMsg =null;
    // }

  }

  checkEmails(){
    var regExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var emails = $("#emailId").val();
    //console.log(emails);
    var emailArray = emails.split(";");
    var invEmails = "";
    if(emailArray.length > 7) {
      this.emailMsg = "only 7 email id allowed";
    } else {
      for(var i = 0; i <= (emailArray.length - 1); i++){
        if(regExp.test(emailArray[i])){
          this.emailMsg = "Email validated!";
          this.emailValidationStatus = true;
          //console.log(this.emailError);
        }else{
          invEmails += emailArray[i] + "\n";
        }
      }
    }
    
    if(invEmails.trim() != ""){
      this.emailMsg = "Invalid emails:\n" + invEmails;
      this.emailValidationStatus = false;
      //console.log(this.emailError);
    }
    // else{
    //   this.emailMsg =null;
    // }
  }


  successVisaApplication(statusMasterId, buttonId) 
  {
    this._router.navigate(['/B2bAppSuccess'], { queryParams: { id: '55555' }});
  }

  AddVisaDetails(ctl)
  {
    

    var validcheck=true;

    var validcheck=this._globle.validateVisainfo();

    
    this.travelDate =   new Date(this.dateFormatChange($('#travel-date').val()));;
    this.travelDays   = (this.travelDate-this.today)/1000/60/60/24;
if(this.travelDate=="Invalid Date")
{
  this._toastr.error("Invalid travel date");
  return false;
}
else
   if(this.travelDays<1) {
     this._toastr.error("Invalid travel date");
     return false;
    } 

   
    
    if(validcheck==false) {
     // this._toastr.warning("Please fill all the required fields!");
     
      return false;
    }
    else{
      this.branch_name=$( "#UserBranch option:selected" ).text();
      this.country_name=$( "#visaCountry option:selected" ).text();
      this.visa_type=$( "#visaType option:selected" ).text();
  this.visaObj.travelDate = this.dateFormatChange($('#travel-date').val());
  this.processingoption=$( "#mstprocessingOptions option:selected" ).text();
  
   nexttab_click(ctl);
   $('#perccompleted').text('50% ');
    }


    if(this.lstPassanger!=null && this.lstPassanger.length>0)
{
  $('#btncontinuePassenger').show();
}
else{
  $('#btncontinuePassenger').hide();
}
    
  }


  continueBranch(ctl)
  {

    var b=$("#UserBranch").val();

    if( ($("#UserBranch").val()=="0: null" || $("#UserBranch").val()=="null"||$("#UserBranch").val()==null)){
      $(".AppBranch.required").fadeIn();
      
    }
    else{
      $('#perccompleted').text('20% ');
      debugger;
      nexttab_click(ctl);
    }

    
    

   
  }
 

editclick(ctl)
{
  debugger;
  gototab_click(ctl)
}

previous_click(ctl)
{
  debugger;
  previoustab_clk(ctl)
}


  continuePassenger(ctl)
  {
    var validcheck=true;

  $('.required').fadeOut();

    if(this.lstPassanger!=null)
    {
    let cnt=this.lstPassanger.length;
    if(cnt>0)
    {
      nexttab_click(ctl);
      $('#perccompleted').text('90% ');
    }
    else{ validcheck = false;}


    }
else{
  validcheck = false;
}
  
if(!validcheck)
{

  this._toastr.warning("Please add atleast one passenger to proceed !");

  $(".nopassenger.required").fadeIn();
}


  }

  getnationalityname(fk_NationalityId)
  {
    if(fk_NationalityId!=null)
    {
      return this.countryData.result.find(x=>x.id==fk_NationalityId).nationality;
    }else{
      return "";
    }
    
  }

  
  getvisatype(fK_VisaTypeId)
  {
  
    if(fK_VisaTypeId!=null)
    {
      return this.visaTypeData.find(x=>x.id==fK_VisaTypeId).name;
    }else{
      return "";
    }
    
  }

  getprofessionname(fk_ProfessionId)
  {
    debugger;

    if(fk_ProfessionId!=null && fk_ProfessionId!="")
{
  return this.professionList.find(x=>x.id==fk_ProfessionId).name; 
  //console.info(this.profession);
}else{
  return "";
}


   }



  saveVisaApplication(statusMasterId, buttonId) {
    console.log(this.visaObj);
    console.info("this.myFiles==",this.myFiles);
//return false;
    var validcheck=true;

    //var validcheck=this._globle.validateB2BVisaForm();
    //console.log('validcheck'+validcheck);
 
  if(this.UserBranchData.length>1 && ($("#UserBranch").val()=="0: null" || $("#UserBranch").val()=="null"||$("#UserBranch").val()==null)){
    $(".AppBranch.required").fadeIn();
    validcheck = false;
  }
    if(validcheck==false) {
      this._toastr.warning("Please fill all the required fields!");
      return false;
    }


    $('#'+buttonId).prop('disabled', false);

    //var radios = document.getElementsByName("Gender");
      //var formValid = false;
      //var i = 0;
      //while (!formValid && i < radios.length) {
        //  if (radios[i].checked) formValid = true;
          //i++;
      //}
      //if (!formValid) {
      //$(".invalid-feedback.gender").fadeIn();
    //}

    try{

               
       
          $('#' + buttonId).prop('disabled', true);
          $("#visaLoader").show();
          var fK_StatusMasterName = this._visaService._statusMaster.find(x => x.id == statusMasterId).Name;
          var fK_StatusMasterId = this._visaService._statusMaster.find(x => x.id == statusMasterId).id;
          //this.visaObj.visaCustomer.gender= $('input[name=Gender]:checked').val();
          this.visaObj.impCase = $('input[name=IMPCase]:checked').val();
          this.visaObj.passportPlaceofIssue=$("#poi").val();

          this.visaObj.travelDate = this.dateFormatChange($('#travel-date').val());
          //this.visaObj.visaCustomer.dateOfBirth = this.dateFormatChange($('#dob').val());
          //this.visaObj.visaCustomer.passportExpiry = this.dateFormatChange($('#pass-exp').val());
          
          if (statusMasterId != this._statusMaster.ReadyforSubmission) {
            this.visaObj.lstApplicationStatus = [{
              remark: $('#visaRemark').val(),
              fK_StatusMasterId: this._statusMaster.Draft ,
             Fk_StatusId:this._statusMaster.Draft ,
              creditLimitAvailable: this.creditLimit
            }]
          }
          this.visaObj.lstVisaAppFollowUps=[];
          this.visaObj.lstVisaAppFollowUps=[{
            Remarks:$('#visaRemark').val(),
          //  Fk_StatusId: statusMasterId
          Fk_StatusId:this._statusMaster.Draft 
          }]
          this.visaObj.fK_TransferBranchId=this.visaObj.fK_AppBranchId;
          if (this.visaObj.fK_TransferBranchId != null) {
            
            this.visaObj.lstVisaApplBranch = [];
            if(this.visaObj.fK_TransferBranchId!=0){
              
            this.visaObj.lstVisaApplBranch.push(
              {
                fK_BranchMasterId: this.visaObj.fK_TransferBranchId
              })
            }
            /*else{
              this.visaObj.lstVisaApplBranch.push(
                {
                  fK_BranchMasterId: null
                })
           
            }*/
          }

          if(this.visaObj.fK_AppBranchId!=null){
            this.visaObj.lstVisaApplBranch.push(
              {
                fK_BranchMasterId: this.visaObj.fK_AppBranchId
              })
          }
console.info("this.fK_AppBranchId=="+this.visaObj.fK_AppBranchId);
          if (this.visaObj.vendorCode != null) {
            this.visaObj.vendorName = this.visaOfficeData.find(x => x.no_ = this.visaObj.vendorCode).name;
          }
  //Passanger details
          let p=1;
          this.visaObj.lstVisaPassangerDetail=[];
          this.lstPassangerDetails=[];
          
      
          this.lstPassangerDetails= this.lstPassanger;

          this.visaObj.VisaAppType='Other Application';
        this.visaObj.customerEmail="p@p.com"
this.visaObj.passportPlaceofIssue="";
this.visaObj.impCase="";
this.visaObj.PaxType="";
this.visaObj.barcodeNumber="";
this.visaObj.icust="";
this.visaObj.customerName="";
this.visaObj.customerType="";
this.visaObj.customerLocation="";
this.visaObj.customerPhoneNumber="";
this.visaObj.xONumber="";
this.visaObj.customerGroup="";
this.visaObj.createdOn=new Date();

this.visaObj.applicationRef=this.datePipe.transform(new Date(),'yyMMddhhmmss');
console.log(this.visaObj.ApplicationRef);
 p=1;
         this.lstPassangerDetails.forEach(passenger =>
          {
          //  passenger.Id=1;
            //passenger.Fk_VisaAppId=1;
            //passenger.CreatedOn=new Date();
            //passenger.CreatedBy=1;
            var lstPassanger:any=[];
            console.info("passenger==",passenger);
            lstPassanger.push(passenger)
            this.visaObj.lstVisaPassangerDetail=lstPassanger;
            debugger;
            if(passenger.lstVisaDocument!=undefined)
            {
              this.visaObj.lstVisaDocument=passenger.lstVisaDocument;
            }
            else{
              this.visaObj.lstVisaDocument=[];
            }
            this.visaObj.visaCustomer.mobileNo=passenger.phoneNumber;
            console.log(JSON.stringify(this.visaObj));
            this._visaService._saveb2bVisaApplication(this.visaObj)
              .subscribe((successData: any)=> {
                console.log(successData);
                $('#' + buttonId).prop('disabled', );
                $("#visaLoader").hide();
                //console.log("add visa" + result);
                $("#navMenuCountChange").click(); // to call the nav menu parent component counter method
                           
                if(successData.warning==true){
                  $('#' + buttonId).prop('disabled', false);  
                  this._toastr.warning(successData.messsage);
                  return false;
  
                }
                else if(successData.status==false){
                  $('#' + buttonId).prop('disabled', false);  
                  this._toastr.error(successData.messsage);
                  return false; 
                }
  
                else if(successData.status==false){
                  $('#' + buttonId).prop('disabled', false);  
                  this._toastr.error(successData.messsage);
                  return false; 
                }
              
  
                if (buttonId == "addPassinger") {
                  this.clearDataBindings();
                  this._toastr.success('Visa Application Saved Succesfully!');
                }
                else {
  
                  $('.left-nav a').removeClass('active');
                  if (statusMasterId == this._statusMaster.ForReview) {// for review
                    this._toastr.success('Visa Application Saved Succesfully!');
                    $("#New_leftNav").addClass('active');
                    $("#New_leftNav").click();
                  //  this._router.navigate(['/B2bAppSuccess'], { queryParams: { id: this.visaObj.applicationRef}});
                  console.info(this.visaUserType + '/B2BPayment');
                  debugger;
                  console.info(successData);
                  this._router.navigate([this.visaUserType + '/B2BPayment'], { queryParams: { id:this.visaObj.applicationRef}});
                  } else if (statusMasterId == this._statusMaster.DocumentPending || statusMasterId == this._statusMaster.ReadyforSubmission) {
                    if (buttonId == "raiseInvoice" || statusMasterId == this._statusMaster.ReadyforSubmission) {
                      this._toastr.success('Visa Application Saved and Moved to Raise Invoice!');
                      $("#raiseInvoicePopUp").click();
                    }
                    else {
                     this._toastr.success('Visa Application Saved and Moved to Document Pending!');
                    }
                    $("#Pending_leftNav").addClass('active');
                    $("#Pending_leftNav").click();
                    this._router.navigate([this.visaUserType + '/VisaApplications/Pending']);
                  }
                  else  if (statusMasterId == this._statusMaster.Draft ){
                    this._router.navigate([this.visaUserType + '/B2bAppSuccess'], { queryParams: { id:this.visaObj.applicationRef,status:'Draft'}});
      
                  }
                }
              },
                (err: HttpErrorResponse) => {
                  $('#' + buttonId).prop('disabled', false);
                  $("#visaLoader").hide();
                  this._toastr.error('Error in saving application');
                  //console.error(err);    // SHOW ERRORS IF ANY.
                })
                p++; 
          })
          //////
 // false        debugger;
    
      } catch(ex){
          console.info("error msg=="+ex);
          $('#' + buttonId).prop('disabled', false);
          $("#visaLoader").hide();
      }

  }

  previewApp(ctl)
  {
    var validcheck=true;

  $('.required').fadeOut();

  if($('#visaRemark').val()=='')
  {
    $(".visaRemark.required").fadeIn();
    return false;
  }
  console.info(this.lstPassanger);

  this.visaRemark=$('#visaRemark').val();
  nexttab_click(ctl);

  $('#perccompleted').text('95% ');
  }
 
  downloadDoc(documentCode){

    var downloadVisaDocument=this.visaObj.lstVisaDocument.find(x=>x.url==documentCode);
    if(downloadVisaDocument!=null){
       ;
      let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    // VisaApplDocument/DownloadVisaDoc?id=403 //DownloadSample //responseType 'blob'
        this._http.get(this._visaService.ApiUrl+"/VisaApplDocument/DownloadDocument?id="+downloadVisaDocument.id,
        { headers: headers , observe: 'response', responseType: 'arraybuffer'}, )
        .subscribe(res => {
           ;
          var filename=downloadVisaDocument.fileName.split('.')[0];
          var contentType = res.headers.get('content-type');
          if(contentType=="application/vnd.openxmlformatsofficedocument.spreadsheetml.sheet"){
            contentType="application/vnd.ms-excel";
          }
          var blob = new Blob([res.body], { type:contentType});
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob, filename);
          } else {
          var a = document.createElement('a');
          a.href = URL.createObjectURL(blob);
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          }
        },
         (err: HttpErrorResponse) => {
           this._toastr.error("Internal Server Error.")
          //this._toastr.error(err.message);
          console.error (err);    // SHOW ERRORS IF ANY.
        });
     }
  }

  
 
  getLocationData() {
    this._visaService._getLocationList()
      .subscribe(result => {
        this.locationData = result;
      },
        (err: HttpErrorResponse) => {
          console.error(err.message);    // SHOW ERRORS IF ANY.
        });
  }

  routBulkupload(){
    //window.location.href="Admin/bulkUpload";
    this._router.navigate(['/'+this.visaUserType+'/bulkUpload']);
  }

  getCustDetailsById() {
    $("#btnGetICUSTDetails").prop("disable",true);
      $('#loaderGetDetails').show();
    this.getCust.locationName=this.locationData.result.find(x=>x.id ===this.getCust.locationId).erpLocCode;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    this._http.get(this._visaService.ApiUrl + "/VisaApplication/GetCustomer?Loc=" + this.getCust.locationName + "&pattern=" + this.getCust.pattern, { headers: headers })
      .subscribe((successData: any) => {
       $("#btnGetICUSTDetails").prop("disable",false);
        if (successData.status == true) {
          if (successData.result.responseData.length > 0) {
            $('#custDetailsTable').show();
            
            successData.result.responseData.forEach(element => {
              console.info("customergroup==",element.customerGroup);
              
              switch(element.customerGroup){
                case "RI":
                  element.customerGroup="RI"; 
                  break; 
                  case "RC":
                    element.customerGroup= "Riya Corporate"; 
                    break;
                  case "RS":
                    element.customerGroup= "Riya Sub Agent"; 
                    break;
                  case "RE":
                    element.customerGroup= "Riya SME"; 
                    break;
                  case "RA":
                    element.customerGroup= "Riya Agent"; 
                    break;
                  default:
                    element.customerGroup= "New Type"; 
                    break;
               
              }
            
          });
            this.customerDetailsData = successData.result.responseData;
            
            
          }
          else {
            this._toastr.warning('No Data Found for Given Search Result');
          }
          //console.log(successData);
        }else{        
          this._toastr.warning(successData.messsage);
        }
        $('#loaderGetDetails').hide();
      },
        (err: HttpErrorResponse) => {
          $("#btnGetICUSTDetails").prop("disable",true);
          $('#loaderGetDetails').hide();
          if(err.status==400){
            this._toastr.error(err.statusText);
          }else{
            this._toastr.error(err.message);
          }
          console.error(err);    // SHOW ERRORS IF ANY.
        });
  }

  bindCustomerDetails(cust) {
    debugger; 
    this.creditLimit = null;
    this.currentCust = cust;
    this._visaService._getCreditLimit(cust.no_).subscribe((z:any)=>{
      if(z.status && z.result && z.result.length > 0){
        this.creditLimit = +z.result.split('-')[0];
        if (this.creditLimit <= 0) {
          $('#CloseGetCustPop').click();
          $('#creditLimitAv').modal({backdrop: 'static', keyboard: false});
         $("#creditLimitAv").modal('show');
          // if(window.confirm("Available credit limit for: "+ cust.no_ + " is " + this.creditLimit + 
          // ". Do you wish to continue ?")){
          //   this.bindCustData(cust);
          //  }
        }else{
          this.bindCustData(cust);
        }
      }else{
        this.bindCustData(cust);
      }
    })
  }

  closeCustomerCreditLimitModal(boo){
    $("#creditLimitAv").modal('hide');
    if(boo){
      this.bindCustData(this.currentCust);
    }else{
      $("#GET-ID").modal('show');
    }
  }

  bindCustData(cust){
           $('#cust-loc').val(this.getCust.locationName);
            this.visaObj.customerName = cust.name;
            this.visaObj.icust = cust.no_;
            this.visaObj.customerType = cust.customerType;
            this.visaObj.customerLocation = cust.location;
            this.visaObj.customerPhoneNumber = cust.phone;
           // this.visaObj.customerEmail=cust.email;
           this.visaObj.customerEmail='';
            this.visaObj.customerGroup=cust.customerGroup;
            this.visaObj.customerSEZ=cust.sez;
        
            this.visaObj.customerNo=cust.custNo;
            this.visaObj.custPostingGroup=cust.postingGroup;
            this.visaObj.divisionCode=cust.divisionCode;


            $('#transferBranch option[value="1101"]').prop('disabled',true);
        
            // if(this.visaObj.customerLocation=="BOM"){
            //   $('#transferBranch option[value="1101"]').prop('disabled',true);
            // }
        
            $('#CloseGetCustPop').click();
          //  $(".cancel-email").click(); // making empty of email ids textbox
          //  var emails=cust.email.split(";");
          //  for(var i=0;i<emails.length;i++){
          //   var getValue = emails[i];
          //   $('.all-mail').append('<span class="email-ids">' + getValue + ' <span class="cancel-email">x</span></span>');
          //  }
  }

  getCountryData() {
    this._visaService._getCountryList()
      .subscribe(result => {
        var data = { result };
        console.info(data)
        this.countryData = data;
        debugger;
        this.visaObj.visaCustomer.fK_NationalityMasterId=this.countryData.result.find(x=>x.countryCode=="IN").id; // for deafault country India
      },
        (err: HttpErrorResponse) => {
          console.error(err.message);    // SHOW ERRORS IF ANY.
        });

        
        this._visaService._getVisaFeesCountryList()
        .subscribe(result => {
          var data = { result };
         // this.visaCountryData = data;
          this.visaCountryData = data.result.filter((item : any)=> {
            return item.id != '1089'
    })
          this.visaObj.visaCustomer.fK_NationalityMasterId=this.countryData.result.find(x=>x.countryCode=="IN").id; // for deafault country India
        },
          (err: HttpErrorResponse) => {
            console.error(err.message);    // SHOW ERRORS IF ANY.
          });
  }

  getTransferBranchData() {
    //this._visaService._getTransferBranchList()
    
    this._visaService._getBranchesByUser()
      .subscribe((successData: any) => {
        if (successData.status == true) {
           this.transferBranchData = successData.result;
           console.info(this.transferBranchData);
        }
        
      },
        (err: HttpErrorResponse) => {
          console.error(err.message);    // SHOW ERRORS IF ANY.
        });
  }

  getVisaOfficeData() {
   // debugger;
  
 }

  getVisatypeByCountry() {
    this._visaService._getVisaTypebyCountry(this.visaObj.fK_VisaCountryMasterId)
      .subscribe((successData: any) => {
        //  this.visaTypeData={resData};
        if (successData.status == true) {
          this.visaTypeData = successData.result;
        }
      },
        (err: HttpErrorResponse) => {
          this.visaTypeData = [];
          console.log(err.message);    // SHOW ERRORS IF ANY.
        });
    // to get visa office  data based on country and branch selected
    // this.tempVisaObj.CountryCode=this.visaObj.visaCustomer.fK_NationalityMasterId;
    // this.getVisaOfficeData(this.tempVisaObj.CountryCode,this.tempVisaObj.fK_BranchMasterId);

  }


  hideValidationError(inputId) {
	  $("."+inputId+".required").fadeOut();
  }
  GetOptions(){
    this.getVisaProcessingOptions();
  }

  getVisaEntries() {
	  $(".visaType.required").fadeOut();
    if (this.visaObj.fK_VisaTypeId != null) {
      this._visaService._getEntriesbyCountryId(this.visaObj.fK_VisaTypeId)
        .subscribe((successData: any) => {
          if (successData.status == true) {
            this.visaEntriesData = successData.result;

           
            if(this.visaEntriesData.length==0){
              $("#visaEntries").attr('disabled',true);
            }else{
              $("#visaEntries").attr('disabled',false);
            }
          }
        },
          (err: HttpErrorResponse) => {
            $("#visaEntries").attr('disabled',false);
            this.visaEntriesData = [];
            console.log(err.message);    // SHOW ERRORS IF ANY.
          });
    }
    else {
      this.visaEntriesData = [];
    }
  }

  getVisaProcessingOptions() {
//debugger;
    this.visaObj.fK_MstProcessingOptionsId = '';

    if(this.visaObj.fK_VisaCountryMasterId!=null && this.visaObj.entries!=null && this.visaObj.fK_VisaTypeId!=null ){
      //&& this.visaObj.fK_VendorId!=null
    
    this._visaService._getVisaProcessingOptions(this.visaObj.fK_VisaCountryMasterId,this.visaObj.entries,this.visaObj.fK_VisaTypeId,this.visaObj.fK_VendorId)
      .subscribe((successData: any) => {
        debugger;
        if (successData.status == true) {

          this.visaProcessingOptData = successData.result;
          debugger;
          console.info('this.visaProcessingOptData====5',this.visaProcessingOptData);
          if(this.visaProcessingOptData.length==0){
            $("#mstprocessingOptions").attr('disabled',true);
          }else{
            $("#mstprocessingOptions").attr('disabled',false);
          }

        }else{
         
          $("#mstprocessingOptions").attr('disabled',true);
        }
      },
        (err: HttpErrorResponse) => {
          $("#mstprocessingOptions").attr('disabled',true);
         
          console.log(err.message);    // SHOW ERRORS IF ANY.
        });

      }

  }

  callCountryRelatedVisaAPIs(cname) {
  
   //this.visaObj.visaCustomer.fK_VisaCountryMasterId=this.countryData.result.find(x=>x.countryCode).name; // for deafault country UNITED ARAB EMIRATES
  //for(let i=0;i<this.countryData.result.length;i++){
   /* var dd = cname.target.options[cname.target.options.selectedIndex].text;
    $('#transferBranch').val('null');
   if(dd=='UNITED ARAB EMIRATES')
      {
        this._router.navigate(['/branchExecutive/dubaiaddApplication'], { queryParams: { id: '1089' }});
 
        
        $('#AE').hide();
      }else{
        $('#AE').show();
      }*/
    //}

  

  $(".visaCountry.required").fadeOut();
  
    if (this.visaObj.fK_VisaCountryMasterId != null) {
      this.getVfsVasFeesForCountry();
   
    //  this.visaObj.fK_VisaTypeId=0;
     // this.visaObj.fK_TransferBranchId=0;
      this.getVisatypeByCountry();
     // this.getVisaOfficeData();
      this.getVisaProcessingOptions();
    } else {
      this.visaTypeData = [];
      this.visaOfficeData = [];
      this.visaEntriesData = [];
      this.visaProcessingOptData=[];
      this.vfsVasFeesAdded = [];
    }
  }

  getVfsVasFeesForCountry(){
    /*this._VFSVASFeesService.getForCountry(this.visaObj.fK_VisaCountryMasterId).subscribe((data: any) => {
      if (data.status) {
        this.visaVfsVasChargesData = data.result;
        this.vfsVasFeesAdded = [];
        var vfsVasMiscIds =  this.visaVfsVasChargesData.map(a=>a.miscChargesMasterId);
        this.filteredVisaMiscChargesData = this.visaMiscChargesData.result.filter(a=> vfsVasMiscIds.indexOf(a.id) == -1);
      }
    },
    (err: HttpErrorResponse) => {
    });*/
  }

  clearCustomerAndSave(statusMasterId, buttonId) {
    this.saveVisaApplication(statusMasterId, buttonId);
  }

  dateFormatChange(dateData) {
    if (dateData!="" && dateData!="Invalid date" && dateData!=null) {
      return dateData.split('/')[1] + '/' + dateData.split('/')[0] + '/' + dateData.split('/')[2];
    }
  }


  dateFormatChangeddmmyyyy(dateData) {
    if (dateData!="" && dateData!="Invalid date" && dateData!=null) {
      return dateData.split('/')[1] + '/' + dateData.split('/')[0] + '/' + dateData.split('/')[2];
    }
  }



  frmData = new FormData();

  getFileDetails(e, uploadDocName, docCode) {
    var passanger;
 console.info("e==",e.target);
 console.info("eid=",e.target.id);

 if(uploadDocName==''||uploadDocName==null){
  //uploadDocName=$('#docName_'+e.target.id.split('-')[1]).val(); 
  uploadDocName=e.target.id.split('-')[1]; 
 }

 if(docCode==''||docCode==null){
   passanger=true;
   docCode=e.target.id.split('-')[1];
   //$("#" + docCode).parent().find('.fa-upload').removeClass('fa-upload').addClass('fa-spinner fa-spin');
 }
 else{
  $("#file-" + docCode).parent().find('.fa-upload').removeClass('fa-upload').addClass('fa-spinner fa-spin');
 }

    var typedd =e.target.files[0].name;
    var abc= typedd.split('.');
  
    
    
    // let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    var formData = new FormData();
    formData.append(docCode, e.target.files[0]);
    this._http.post(this._visaService.ApiUrl + "/VisaApplDocument/UploadDocument", formData, { headers: headers } )
    .subscribe((successData:any) => {
      
     // console.log(successData);
      var count = this.myFiles.length;
      for (var i = 0; i < count; i++) {
        if (this.myFiles[i].URL == docCode) {
          // this.myDocFilesType.splice(i, 1);
          this.myFiles.splice(i, 1);
        }
      }
      //var documentName=this._visaService..find()
      // this._VisaDocuments.
        var tempObj = {
          URL:docCode,
          documentName:uploadDocName,
          availability:null,
          fileName:successData.result.fileName,
          bucketName:successData.result.bucketName,
          encryptionKey:successData.result.encryptionKey,
          contentType:successData.result.contentType,
        }
        if(passanger){
         tempObj['documentName']='Passanger';
         }
        this.myFiles.push(tempObj);

      
       
        if(passanger){
         // $("#" + docCode).parent().find('.fas').removeClass('fa-spinner fa-spin');
        }
        else{
          $("#file-" + docCode).parent().find('.fas').removeClass('fa-spinner fa-spin');
        
        // show file previw start from here
        var string2='image';
        if(tempObj.contentType.indexOf(string2) != -1){
  
         // console.log(string2 + "found");
          $('#fileimg-'+docCode).show();
          $("#file-"+docCode).hide();
          $('#fileimg-'+docCode).attr('src', this._visaService.ApiUrl.split("/api")[0]+"/Upload/Tempdocument/"+tempObj.bucketName+"/"+tempObj.fileName);
        }else{
          $('#fileimg-'+docCode).hide();
          $("#file-" + docCode).parent().find('.fas').addClass('fa-file-upload');
        }
      }
      
        $('#imgDel-' + docCode).show();
        $("#imgbrows-"+docCode).hide();

    },
      (err: HttpErrorResponse) => {
        this._toastr.error("Document Upload Failed");
        console.error(err);    // SHOW ERRORS IF ANY.
      })
  }

getdocumenttypename(shortcode)
{

  switch(shortcode) { 
    case "photo": { 
       return "Photo"
       break; 
    } 
    case "travelTicket": { 
      return "Travel Tickets" 
       break; 
    } 
    case "accommodation":
      {
        return "Accommodation/Hotel Confirmation"
        break; 
      }
      case "forex_Credit_Card":
      {
        return "Forex/Credit Card"
        break; 
      }
      case "bankStatement":
      {
        return "Bank Statement"
        break; 
      }
      case "incomeTax":
      {
        return "Income Tax Papers"
        break; 
      }
      case "selfEmployment":
      {
        return "Proof of Self-employment"
        break; 
      }case "travelInsurance":
      {
        return "Travel Insurance"
        break; 
      }case "coveringLetter":
      {
        return "Covering Letter"
        break; 
      }case "invitationLetter":
      {
        return "Invitation Letter"
        break; 
      }
      case "NOCDocument":
      {
        return "NOC Document"
        break; 
      }
      case "VISA":
        {
          return "Visa"
          break; 
        }
    default: { 
       //statements; 
       break; 
    } 
 } 

}


  filetypechange(e) {
    let ids=e.target.id;          
    var docid = ids.split("_").pop(); // => "Tabs1"

    $('.required').fadeOut();
    var docCode=$('#doctype_'+docid).val();
    var filepath=$('#uploads_'+docid);

    
    if(docCode!="" && docCode!=null)
    {

      var IndexPosition=this.passengerFiles.findIndex(x=>x.docid==docid);

      if(IndexPosition!=-1)
      {
      this.passengerFiles[IndexPosition].URL=docCode;
      this.passengerFiles[IndexPosition].documentName=docCode;
      console.info(this.passengerFiles) 
      }
    }
  }


  getFileDetailsPass(e) {
    //var passid =e.target.id.split('_')[1];

   

var Files=[];
    let ids=e.target.id;          
    var docid = ids.split("_").pop(); // => "Tabs1"

    $('.required').fadeOut();
    var docCode=$('#doctype_'+docid).val();
    var filepath=$('#uploads_'+docid);

   /* var delIndexPosition=this.passengerFiles.findIndex(x=>x.docid==docid);
    if(delIndexPosition>0)
    {
      this.passengerFiles.splice(delIndexPosition, 1);  
    }*/
   

    debugger;
    if(docCode==""|| docCode==null)
    {
      $("#reqdocumenttype_"+docid).fadeIn();
        
      return false;
    }
    else
    {
      let headers = new HttpHeaders();
      headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
      var formData = new FormData();
      formData.append(docCode, e.target.files[0]);
      var docname=this.getdocumenttypename(docCode);
      this._http.post(this._visaService.ApiUrl + "/VisaApplDocument/UploadDocument", formData, { headers: headers } )
      .subscribe((successData:any) => {
        
        var tempObj = {
          docid:docid,
          URL:docCode,
          documentName:docname,
          availability:null,
          fileName:successData.result.fileName,
          bucketName:successData.result.bucketName,
          encryptionKey:successData.result.encryptionKey,
          contentType:successData.result.contentType,
        }
       
        this.passengerFiles.push(tempObj);
        console.info(this.passengerFiles);
        $('#view-doc_'+docid).show();
        
  debugger;
      });
    }
    
  }

  downloadPassengerDoc(url,BucketName,downloadVisaDocumentfilename,EncryptionKey){

    //var downloadVisaDocument=this.visaObj.lstVisaDocument.find(x=>x.url==documentCode);
    //if(downloadVisaDocument!=null){
       debugger;
      let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    // VisaApplDocument/DownloadVisaDoc?id=403 //DownloadSample //responseType 'blob'
        this._http.get(this._visaService.ApiUrl+"/VisaApplDocument/DownloadTempDocument?BucketName="+BucketName +"&FileName="+downloadVisaDocumentfilename+"&EncryptionKey="+EncryptionKey,
        { headers: headers , observe: 'response', responseType: 'arraybuffer'}, )
        .subscribe(res => {
           ;
          var filename=downloadVisaDocumentfilename.split('.')[0];
          var contentType = res.headers.get('content-type');
          if(contentType=="application/vnd.openxmlformatsofficedocument.spreadsheetml.sheet"){
            contentType="application/vnd.ms-excel";
          }
          var blob = new Blob([res.body], { type:contentType});
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob, filename);
          } else {
          var a = document.createElement('a');
          a.href = URL.createObjectURL(blob);
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          }
        },
         (err: HttpErrorResponse) => {
           this._toastr.error("Internal Server Error.")
          //this._toastr.error(err.message);
          console.error (err);    // SHOW ERRORS IF ANY.
        });
     
  }

  
  viewPassengerDoc(url,BucketName,downloadVisaDocumentfilename,EncryptionKey){

    //var downloadVisaDocument=this.visaObj.lstVisaDocument.find(x=>x.url==documentCode);
    //if(downloadVisaDocument!=null){
       debugger;
      let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    // VisaApplDocument/DownloadVisaDoc?id=403 //DownloadSample //responseType 'blob'
        this._http.get(this._visaService.ApiUrl+"/VisaApplDocument/DownloadTempDocument?BucketName="+BucketName +"&FileName="+downloadVisaDocumentfilename+"&EncryptionKey="+EncryptionKey,
        { headers: headers , observe: 'response', responseType: 'arraybuffer'}, )
        .subscribe(res => {
           ;
          var filename=downloadVisaDocumentfilename.split('.')[0];
          var contentType = res.headers.get('content-type');
          if(contentType=="application/vnd.openxmlformatsofficedocument.spreadsheetml.sheet"){
            contentType="application/vnd.ms-excel";
          }
          var blob = new Blob([res.body], { type:contentType});
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob, filename);
          } else {
          var a = document.createElement('a');
          a.href = URL.createObjectURL(blob);
          //a.download = filename;
          a.target = "_blank";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          }
        },
         (err: HttpErrorResponse) => {
           this._toastr.error("Internal Server Error.")
          //this._toastr.error(err.message);
          console.error (err);    // SHOW ERRORS IF ANY.
        });
     
  }

  validateFileDetailsPass(e)
  {
    var Files=[];
    let ids=e.target.id;          
    var docid = ids.split("_").pop(); // => "Tabs1"

    $('.required').fadeOut();
    var docCode=$('#doctype_'+docid).val();
    var filepath=$('#uploads_'+docid);
    debugger;
    if(docCode==""|| docCode==null)
    {
      $("#reqdocumenttype_"+docid).fadeIn();
        
      return false;
    }
    

  }

  
  
  delUploadedDoc(documentName,documentCode){
  
    var delDocObj =  this.myFiles.find(x=>x.URL==documentCode);
    var delIndexPosition=this.myFiles.findIndex(x=>x.URL==documentCode);
     if(delDocObj!=undefined && delIndexPosition>-1){
         this._visaService._delUploadedDocument(0,delDocObj.bucketName,delDocObj.fileName)
         .subscribe((successData:any)=>{
             if(successData.status==true){
                this._toastr.success(successData.messsage);
                $("input[name="+documentCode+"]").prop('checked', false);
                $('#imgDel-'+documentCode).hide();
                $('#imgUpload-'+documentCode).hide();
                
                $("#fileimg-" + documentCode).hide();
                 $("#file-"+ documentCode).parent().find('.fas').addClass('fa-upload');
                this.myFiles.splice(delIndexPosition, 1);  // delete   uploaded doc from object
                //console.log(this.myFiles);
             }
         },
         (err: HttpErrorResponse) => {
           this._toastr.error('Error in Deleting the Document!');
           console.log (err);    // SHOW ERRORS IF ANY.
         })
     }

    
     $('.'+documentCode).hide();
  }

  // delUploadedDoc(documentName, documentCode) {
  //   debugger;
  //   $("#file-" + documentCode).parent().find('.file_icon').removeClass('fa-file-upload');
  //   var docsCount = this.myFiles.length;
  //   var id=0;
  //  // var delDocObj =  this.myFiles.find(x=>x.documentName==documentName);
  //   for (var i = 0; i < docsCount; i++) {
  //     if (this.myFiles[i].URL == documentCode) {
  //       id=i;
  //       this._visaService._delUploadedDocument(0,this.myFiles[i].bucketName,this.myFiles[i].fileName)
  //         .subscribe((successData:any)=>{
  //             if(successData.status==true){
  //               this._toastr.success("Document deleted succesfully!");
  //               $('#imgDel-' + documentCode).hide();
  //               $("input[name=" + documentCode + "]").prop('checked', false);
  //               $("#file-" + documentCode).parent().find('.fa-file').removeClass('fa-file').addClass('fa-upload');
  //               // this.myDocFilesType.splice(i, 1);
  //               this.myFiles.splice(id, 1);  // delete the uploaded doc from object

  //               console.log("deleted doc obj"+this.myFiles);
  //               $("#file-" + documentCode).parent().find('.fas').addClass('fa-upload');
  //             }

  //         },
  //         (err: HttpErrorResponse) => {
  //           this._toastr.error('Error in Deleting the Document!');
  //           console.log (err.message);    // SHOW ERRORS IF ANY.
  //         })
  //       // var docName=this.myFiles[i].docName;
  //       // var checkDynmaicDoc=this._visaService._docList.find(x=>x.docName==docName);
  //       // if(checkDynmaicDoc==undefined){
  //       //   docName=docName.split('@@')[0];
  //       // }

  //     }
  //   }
  // }

  //code for uploading the document
  // documentUploadAPI(applicationId) {

  //   let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  //   headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);

  //   for (var i = 0; i < this.myFiles.length; i++) {
  //     this.frmData = new FormData();
  //     this.frmData.append(this.myFiles[i].docName, this.myFiles[i].file);

  //     var docAvailability = $("input[name=" + this.myFiles[i].documentId + "]:checked").val();
  //     this._http.post(this._visaService.ApiUrl + "/VisaApplDocument/UploadFile?Id=0&DocumentName=" + this.myFiles[i].docName + "&Availability=" + docAvailability + "&FK_VisaApplicationId=" + applicationId, this.frmData)
  //       .subscribe(result => {

  //         console.log(result);
  //       },
  //         (err: HttpErrorResponse) => {

  //           this._toastr.error("Document Upload Failed");
  //           console.error(err.message);    // SHOW ERRORS IF ANY.
  //         })
  //   }
  // }

  docAvailability(docType, avail) {
    //console.log(docType + '' + avail);
  }

  raiseVisaApplication(statusMasterId, buttonId) {
    try{
           $('#'+buttonId).prop('disabled', true);

        //check for empty quantity entries
        for (var i = 0; i < this.misChagresAddedObj.length; i++) {
          if ($('#mscSelected' + this.misChagresAddedObj[i].id)[0].selectedIndex != 0) {
            if ($('#' + this.misChagresAddedObj[i].Quantity).val() == "") {
              // alert('Quantity Can not be empty!');
              this._toastr.warning("Quantity Can not be empty!");
              $('#' + buttonId).prop('disabled', false);
              return false;
            }
            else if($('#'+this.misChagresAddedObj[i].Quantity).val()> $('.PassangerDetails').length){
              // alert('Quantity Can not be empty!');
              this._toastr.warning("Quantity Can not be more than Passenger count !");
              $('#'+buttonId).prop('disabled', false);
              return false;
          }
          }
        }
        //preparing the lstVisaMisc charges object
        this.visaObj.lstVisaMiscFees = [];
        for (var i = 0; i < this.misChagresAddedObj.length; i++) {
          if ($('#mscSelected' + this.misChagresAddedObj[i].id)[0].selectedIndex != 0) {
            var tempFeeObj = {};
            tempFeeObj = {
              feeAmount: parseFloat($("#" + this.misChagresAddedObj[i].Amount).val()) * parseFloat($("#" + this.misChagresAddedObj[i].Quantity).val()),
              // fK_MiscChargesMasterId: $('#mscSelected' + this.misChagresAddedObj[i].id)[0].selectedIndex
              Qty: parseFloat($("#"+this.misChagresAddedObj[i].Quantity).val()),
              BasicAmount: parseFloat($("#"+this.misChagresAddedObj[i].Amount).val()),
              fK_MiscChargesMasterId: $("#mscSelected"+this.misChagresAddedObj[i].id+ " option:selected").val().split(":")[1].trim()

            }
            this.visaObj.lstVisaMiscFees.push(tempFeeObj)
          }
        }

        this.visaObj.lstVisaVFSVASFees=[];
        if (this.vfsVasFeesAdded.filter(a=>a.quantity == 0 || a.vfsVasFeesId == 0).length > 0) {
          this._toastr.warning("Quantity 0 Fees, not selected fees will be ignored !");
        }
        if(this.vfsVasFeesAdded.filter(a=>a.quantity > $('.PassangerDetails').length).length > 0) {
          this._toastr.warning("Quantity for VFS VAS fees not be more than Passenger count !");
          $('#'+buttonId).prop('disabled', false);
          return false;
        }
        if(this.vfsVasFeesAdded.filter(a=>a.quantity != 0).length > 0) {
          var fa = this.vfsVasFeesAdded.filter(a=>a.quantity != 0).map(a=>a.vfsVasFeesId);
          if(fa.length != new Set(fa).size){
            this._toastr.warning("Duplicate Vfs Vas fees cant be added. Make duplicate misc fees quantity 0");
            $('#'+buttonId).prop('disabled', false);
            return false;
          }
        }
        
        var dataToSend = this.vfsVasFeesAdded.filter(a=>a.quantity != 0 && a.vfsVasFeesId != 0);
        for (let i = 0; i < dataToSend.length; i++) {
          var vfsVasObj = {
            id: 0,
            visaApplicationId: this.visaObj.id,
            vFSVASFeesMasterId: dataToSend[i].vfsVasFeesId,
            qty: dataToSend[i].quantity,
            vFSVASAmt: dataToSend[i].amount,
            totalVFSVASAmt: dataToSend[i].quantity * dataToSend[i].amount
          }
          this.visaObj.lstVisaVFSVASFees.push(vfsVasObj);
        }
        // if($('#markuponbaseFees').val()!="")
        // {
        //making of application status object
        this.visaObj.lstApplicationStatus = [];
        this.visaObj.lstApplicationStatus = [{
          fK_StatusMasterId: statusMasterId,
          remark: $('#visaRemark').val(),
          visaFees: $('#visaFees').val(),
          vfsFees: $('#vfsFees').val(),
          serviceCharges: $('#serviceCharges').val(),
          DiscountedServCharge: this.DiscountedServCharge,
          DiscountedServVal: $('#disc-val').val(),
          creditLimitAvailable: this.creditLimit,
          markupOnBasefare:$('#markuponbaseFees').val()!="" ? $('#markuponbaseFees').val():null,
        }]
        
        this.saveVisaApplication(this._statusMaster.ReadyforSubmission, buttonId);
      // }
      // else{
      //   this._toastr.warning("Markup on Base Fare amount is empty");
      // }
    }
    catch(exception){
      $('#'+buttonId).prop('disabled', false);
      console.error(exception);
    }
  }

  discountedServChargeCalc() {
   
    if ($('#serviceCharges').val() == "" || $('#serviceCharges').val() == null) {
      this._toastr.warning('Please enter the service charges.');
      return false;
    }
    var name = $("input[name=discountRadio]:checked").val();
    var serviceCharges = parseFloat($('#serviceCharges').val());
    var discountValue = parseFloat($('#disc-val').val());

    if (name == "percent") {
      if (discountValue < 100 && discountValue >= 0) {
        this.DiscountedServCharge = serviceCharges - (serviceCharges * discountValue) / 100;
        $('#Service-val').val(this.DiscountedServCharge);
      }
      else {
        this._toastr.warning('Percentage Charges Percentage Charges between 0 and 100');
      }
    } else {
      this.DiscountedServCharge = serviceCharges - discountValue;
      $('#Service-val').val(this.DiscountedServCharge);
    }
  }

  getMscFeeCharges(txtAmountid, selVal, id) {
   
    if (selVal != null) {
      for (var i = 0; i < this.visaMiscChargesData.result.length; i++) {
        if (this.visaMiscChargesData.result[i].id == selVal) {
          $('#' + txtAmountid).val(this.visaMiscChargesData.result[i].charges);
          break;
        }
      }
    }
    else {
      $('#' + txtAmountid).val('');
    }
    this.calTotalMscCharges();
  }

  calTotalMscCharges() {
    
    this.totalMiscCharges = 0;
    for (var i = 0; i < this.misChagresAddedObj.length; i++) {
      var amount = $("#" + this.misChagresAddedObj[i].Amount).val();
      var quantity = $("#" + this.misChagresAddedObj[i].Quantity).val();
      if (amount != "" && quantity != "")
        this.totalMiscCharges = this.totalMiscCharges + (parseFloat(amount) * parseFloat(quantity));
    }
  }

  getVfsVasFeeCharges(i) {
    this.vfsVasFeesAdded[i].amount = 0;
    this.vfsVasFeesAdded[i].quantity = 0;
    if (this.visaVfsVasChargesData.find(a => a.id == this.vfsVasFeesAdded[i].vfsVasFeesId) != null) {
      this.vfsVasFeesAdded[i].amount = this.visaVfsVasChargesData.find(a => a.id == this.vfsVasFeesAdded[i].vfsVasFeesId).miscChargeMasterCharge;
    }
    this.calTotalVfsVasCharges();
  }

  calTotalVfsVasCharges() {
    this.totalVfsVasCharges = 0;
    for (var i = 0; i < this.vfsVasFeesAdded.length; i++) {
      var amount = this.vfsVasFeesAdded[i].amount;
      var quantity = this.vfsVasFeesAdded[i].quantity;
      if (amount != 0 && quantity != 0)
        this.totalVfsVasCharges = this.totalVfsVasCharges + (amount * quantity);
    }
  }

  addNewVfsVasCharges() {
    $('#totalVfsVasCharges').show();
    var tempVfsVasObj = {
      id: this.vfsVasFeesAdded.length + 1,
      vfsVasFeesId: 0,
      amount: 0,
      quantity: 0,
    }
    this.vfsVasFeesAdded.push(tempVfsVasObj);
  }

  // getVisaServiceCharges() {
  //   this._http.get(this._visaService.ApiUrl + "/VisaFeesMst/GetByPara?ICUST=" + this.visaObj.icust + "&TravellerType=" + this.visaObj.paxType + "&VisaCountry=" + this.visaObj.fK_VisaCountryMasterId + "&Visatype=" + this.visaObj.visaType + "&VisaOfficeId=" + this.visaObj.fK_VisaOfficeId)
  //     .subscribe(responseData => {
  //       this.visaServicesData = responseData;
  //       try {
  //         if (this.visaServicesData.status == true) {
  //           var visaFees = this.visaServicesData.result.visaFees;
  //           var vfsFees = this.visaServicesData.result.vfsFees;
  //           var serviceCharges = this.visaServicesData.result.serviceCharges;
  //           $("#visaFees").val(visaFees);
  //           $("#vfsFees").val(vfsFees);
  //           $("#serviceCharges").val(serviceCharges);
  //         }
  //       }
  //       catch (exception) {
  //         console.error(exception.message);
  //         console.error(exception);
  //       }
  //       console.log(responseData);
  //     },
  //       (err: HttpErrorResponse) => {
  //         console.error(err.message);    // SHOW ERRORS IF ANY.
  //       });
  // }

  // Get Service Charge on change on Service category change
  changeServiceCategory()
  { debugger;
    var categoryMasterId = this.categoryMasterId;
    this.lstServiceCharge.filter(x=>{
      if(x.categoryMasterId === categoryMasterId){
        this.selectedservicecharge = x.serviceCharge;
      }
    });
    $("#serviceCharges").val(this.selectedservicecharge);
    //console.info("selectedservicecharge=>",this.selectedservicecharge);
  }

  getVisaServiceCharges(){
console.info("applied==",$("#UserBranch").val());
    var validcheck=this._globle.validateB2BVisaForm();

if(this.UserBranchData.length>1 && ($("#UserBranch").val()=="0: null" || $("#UserBranch").val()=="null"||$("#UserBranch").val()==null)){
    $(".AppBranch.required").fadeIn();
    validcheck = false;
  }
    if(validcheck==false) {     
      this._toastr.warning("Please fill all the required fields!");
      return false;
    }

    var MstProcessingOptionsId=$("#mstprocessingOptions option:selected").text();
    if(MstProcessingOptionsId!=null){
      MstProcessingOptionsId=MstProcessingOptionsId.trim();
    }
    // if(this.visaObj.fK_VisaCountryMasterId==null || this.visaObj.fK_VisaTypeId ==null || this.visaObj.fK_VendorId!=null || MstProcessingOptionsId == null || this.visaObj.entries == null ){
    //   this._toastr.warning("Please select all required fields for raise invoice");
    //   return false;
    // }
    //debugger;
    this.visaObj.VisaAppType='Other Application';
    this.showServiceCategory = false;
    this._visaService._getVisaServiceCharges(this.visaObj.fK_VisaCountryMasterId,this.visaObj.fK_VisaTypeId,this.visaObj.fK_VendorId,MstProcessingOptionsId,this.visaObj.entries,this.visaObj.icust)
      .subscribe( (successData:any)=>{
        //debugger;
        if(successData.status==true){
          $("#Raise-Invoice").modal('show');
       // this.visaServicesData=successData;
          var visaFees=successData.result.visaFees;
          var vfsFees=successData.result.vfsFees;
          var serviceCharges=successData.result.serviceCharges;
          if(successData.result.lstServiceCharge != null)
        {
          this.showServiceCategory = true;
          this.lstServiceCharge = successData.result.lstServiceCharge;
          this.categoryMasterId = this.lstServiceCharge[0].categoryMasterId;
          this.selectedservicecharge = this.lstServiceCharge[0].serviceCharge;
          $("#serviceCharges").val(this.selectedservicecharge);
        }
        else{
          $("#serviceCharges").val(serviceCharges);
        }
         $("#visaFees").val(visaFees);
         $("#vfsFees").val(vfsFees);
         
      } else{
        // $("#raiseInvoicePopUp").click();
        this._toastr.warning(" Visa charges are not available, contact admin");
        $("#visaFees").val("");
        $("#vfsFees").val("");
        $("#serviceCharges").val("");
      }
       //console.log(successData);
      },
      (err: HttpErrorResponse) => {
        // $("Raise-Invoice").hide();
        $("#visaFees").val("");
        $("#vfsFees").val("");
        $("#serviceCharges").val("");
        console.error (err.message);    // SHOW ERRORS IF ANY.
      });
    }

  getvisaMiscChargesData() {
    this._visaService._getvisaMiscChargesData()
      .subscribe(resData => {
        this.visaMiscChargesData = resData;
        this.filteredVisaMiscChargesData = JSON.parse(JSON.stringify(this.visaMiscChargesData.result));
      },
        (err: HttpErrorResponse) => {
          console.error(err.message);    // SHOW ERRORS IF ANY.
        });
  }

  getOfficeBoyData() {
    this._visaService._getOfficeBoyData()
      .subscribe(successData => {
        var temp = { successData };
        this.visaOfficeBoyData = successData;
      },
        (err: HttpErrorResponse) => {
          console.error(err.message);    // SHOW ERRORS IF ANY.
        });
  }

  getCustomerDeatilsPopUp() {
    this.getCust.pattern = $("#searchCustPattern").val();
  }

  // --Added by prashant - Passport place autointellisense --

  clearDataBindings() {
    var fK_VisaCountryMasterId = this.visaObj.visaCustomer.fK_NationalityMasterId;
    $("input[name=Male]").attr('checked', true);
    $("input[name=" +  this.visaObj.impCase + "]").attr('checked', false);
    this.visaObj.visaCustomer = {};
    $('#pass-exp').val('');
    $('#dob').val('');
    $("#visaRemark").val('');
    this.visaObj.paxType = null;
    this.visaObj.passportPlaceofIssue = '';
    this.visaObj.visaCustomer.fK_NationalityMasterId = fK_VisaCountryMasterId;
    this.tempVisaObj.fK_TransferBranchId = null;
    // code to clear the uploaded documets

    for (var i = 0; i < this.myFiles.length; i++) {
      $('#imgDel-' + this.myFiles[i].documentId).hide();
      $("input[name=" + this.myFiles[i].documentId + "]").prop('checked', false);
      // $("input[name="+docName+"]:checked").val('undefined');
      $("#file-" + this.myFiles[i].documentId).parent().find('.fa-file').removeClass('fa-file').addClass('fa-upload');

    }
    this.myFiles = [];

  }

  onPassportplace_Change(searchValue: string) {

    this.resultarr = [];
    this.autocompletearr = [];
    if (searchValue !== "") {
      this._visaService
        ._Passportplace_Api(searchValue)
        .subscribe((data: any) => {
          this.autocompletearr = [];
          this.autocompletearr = data.result;
          for (let i = 0; i < this.autocompletearr.length; i++) {
            this.resultarr.push(this.autocompletearr[i].name);
          }
          $(".searchautocomplete").autocomplete({   //  -------- Autocomplete populate ---
            source: this.resultarr,
            minLength: 0,
          },
          (err: HttpErrorResponse) => {
            console.error(err.message);    // SHOW ERRORS IF ANY.
          });

        });
    }
  }
  checkPassExpiryAlert(){
    // alert("dfsdfsdf");
    // debugger;
    // var dd= new Date(this.visaObj.visaCustomer.passportExpiry);
    // if(this.visaObj.visaCustomer.passportExpiry==new Date()){

    // }
  }
  getVisaCustomerByPassportNo(event){
  
   let passporNotid=event.target.id; 
    let lop=passporNotid.split('_')[1];
    this._visaService._getVisaCustomerByPassportNo( $('#'+passporNotid).val())
    .subscribe((successData:any)=>{
     
      if(successData.status==true && successData.result!=null){
        var response=successData.result;
        if(lop==1)
        {
        this.visaObj.visaCustomer.givenName=response.givenName;
        this.visaObj.visaCustomer.surname=response.surname;
        //this.visaObj.visaCustomer.mobileNo=response.mobileNo;
        if(response.gender=="Male"){
          $('#visaCustMale').prop("checked", true);
        }
        else if(response.gender=="Female") {
          $('#visaCustFemale').prop("checked", true);
        }
        if(response.passportExp!=null && response.passportExp!=undefined && response.passportExp!=""){
          this.visaObj.visaCustomer.passportExpiry =this.dateFormatChange(response.passportExp);
        }
        if(response.dob!=null && response.dob!=undefined && response.dob!=""){
          this.visaObj.visaCustomer.dateOfBirth = this.dateFormatChange(response.dob);
        }
       }
       
       // $('#pass-num_'+lop).val(this.visaObj.visaCustomer.passportNumber);
        $('#pass-exp_'+lop).val(response.passportExp);
        $('#given-name_'+lop).val(response.givenName);
        $('#surname_'+lop).val(response.surname);
        $('#poi_'+lop).val(response.placeOfIssue);
        $('#dob_'+lop).val(response.dob);
        $('#paxType_'+lop).val(response.paxType.trim());
        $('#nationality_'+lop).val(response.fk_NationalityId);
        $('#professionhidden_'+lop).val(response.fk_ProfessionId);
        if(response.gender.trim()=="Male"){
          $('#visaCustMale_'+lop).prop("checked", true);
        }
        else if(response.gender.trim()=="Female") {
          $('#visaCustFemale_'+lop).prop("checked", true);
        }

      }
    },
      (err: HttpErrorResponse) => {
        console.error(err.message);    // SHOW ERRORS IF ANY.
      });
  }
  changeDateFormat(value){
    var changeDate = new Date(value);
    return changeDate.getDay() + '/' + changeDate.getMonth() + '/' + changeDate.getFullYear();
  }



  // onProfession_Change($event) {
   
   
  //   if ($event !=null) {
  //        this.GetAll_Profession($event,this.visaObj);
  //   }
  // }

  GetAll_Profession($event){
   // this.Profession = [];
    //this.Profession_auto = [];
    this.professionList=[];
    this._visaService._getProfessionList($event.target.value)
    .subscribe((professionList:any)=>{
      if(professionList.status==true){
       
        this.professionList=professionList.result;
        console.info('professionList==',this.professionList);
  
        // for (let i = 0; i < this.professionList.length; i++) {
  
        //   let ar={label: this.professionList[i].name,value:this.professionList[i].name,this_id:this.professionList[i].id};
  
        //   this.Profession_auto.push(ar);
        // }
      
      //  console.info("this.Profession_auto",this.Profession_auto);
         let data=this.professionList;
        let id=$event.target.id;
        $("#"+id).autocomplete({  
          minLength: 1,
          source:function (request, response) {
            response($.map(data, function (value, key) {
                 return {
                     label: value.name,
                     value: value.name,
                     this_id: value.id
                 }
             }));
         
            },
          select: function (event, ui) {
            debugger;
            let proid=$(this).attr('alt');
            
           var value = ui.item.this_id;
            
           //this.setMyProfessionId(value);
           console.info('value11=='+value);
          //  visaObj.fK_ProfessionId=value;
          $('#'+proid).val(value);  

           //store in session
         
        }
  
  
        });
      }
    },
    (err: HttpErrorResponse) => {
      this.visaTypeData = [];
      console.log(err);    // SHOW ERRORS IF ANY.
    });
   }

   //Code For Ocr

   passportUploadfrontSide($event)
   {
     
      this.passportpageid1="";
      this.passportpageid1=$event.target.id;
      let passfrontside=$event.target.files[0];
      this.passfrontsidefile=passfrontside;
      this.forntPassportFiles=$event.target.files;
  
    
     
    
         if(passfrontside)
          {
            this.fileExtension=this.passfrontsidefile.name.split('.').pop();
        
  
            this.passportfrontfileSize=(Math.round(passfrontside.size*100 / 1024) /100);
            if(this.passportfrontfileSize>95)
            {
              this.passfrontsms="Max 95 KB file Upload then this file Size:"+this.passportfrontfileSize+":KB"; 
              $('#passportfrontside').val('');
            }
           else if(!this._visaService.isimgArray(this.allowimgextensions,this.fileExtension))
            {
              alert('Only front Side JPEG and JPG file are allowed');
              $('#passportfrontside').val('');
            }
            else {
              this.passfrontsms="";
             // Array.from(this.forntPassportFiles).forEach(p=>this.passportformdata.append('passportfrontside',p));
            }
           
          }
          else{
            alert("Choose File the passport front side");
          }
   }
  //  passportUploadBackSide($event)
  //  {
  
  //   this.passportpageid2="";
  //   this.passportpageid2=$event.target.id;
  //   let passbackside=$event.target.files[0];
  //   this.passbacksidefile=passbackside;
  //   this.backPassportFiles=$event.target.files;
  //    if(passbackside)
  //    {
  //             this.fileExtension=this.passfrontsidefile.name.split('.').pop();
  //             this.fileExtensionback=this.passbacksidefile.name.split('.').pop();
  //          this.passportbackfileSize=(Math.round(passbackside.size * 100 / 1024) /100);
  //           if(this.passportbackfileSize>95)
  //           {
  //             this.passbacksms="Max 95 KB file Upload then this file Size:"+this.passportbackfileSize+":KB"; 
  //             $('#passportBackside').val('');
  //           }
  //           else if(!this._visaService.isimgArray(this.allowimgextensions,this.fileExtension))
  //           {
  //             alert('Only front Side JPEG and JPG file are allowed');
  //             $('#passportBackside').val('');
  //           }
  //           else {
  //             this.passbacksms="";
  //            // Array.from(this.backPassportFiles).forEach(p1=>this.passportformdata.append('passportBackside',p1));
  //           }
  //    }
  //    else {
  //     alert("Choose File the passport back side");
  //    }
  
  //  }
  
   passportUploadFiles()
   {
     this.passscan_error='';
     $('#visaLoader').show();
     $('.required').fadeOut();
     try{
       debugger;
       if(this.passportpageid1!="")
       {
       let passportformdata=new FormData();
       if(this.passfrontsidefile!=null)
       {
        if(this.passportfrontfileSize>95)
        {
          //alert("File size exceeded,file size not allowed more than 95KB");
          this.passscan_error='File size exceeded,file size not allowed more than 95KB';
          $('#visaLoader').hide();
          $(".pass-scan.required").fadeIn();
        }
       else {
         this.fileExtension=this.passfrontsidefile.name.split('.').pop();
       //  this.fileExtensionback=this.passbacksidefile.name.split('.').pop();
          if(!this._visaService.isimgArray(this.allowimgextensions,this.fileExtension))
          {
            //alert('Only front Side JPEG and JPG file are allowed');
            this.passscan_error='Only front Side JPEG and JPG file are allowed';
            $('#visaLoader').hide();
            $(".pass-scan.required").fadeIn();
          }
          else{
            Array.from(this.forntPassportFiles).forEach(p=>passportformdata.append('passportfrontside',p));
           // Array.from(this.backPassportFiles).forEach(p1=>passportformdata.append('passportBackside',p1));
           
          //Upload Passport
          
          this._visaService._uploadDubaiVisaPassport(passportformdata).subscribe((resp:any)=>{
  
            $('#visaLoader').hide();
            console.log("this"+resp);
            if(resp.status==true)
            {
              debugger;
              let lop=this.passportpageid1.split('_')[1];
              this.passportPage1Src="";
              this.passportPage2Src="";
              this.passportimagupload=true;
              this.passportfrontserverfile=resp.result.passportforntside;
              this.passportbackserverfile=resp.result.passportbacksideside;
              this.passportPage1Src=resp.result.passportPath+resp.result.passportPage1+"//"+resp.result.passportforntside;
            //  this.passportPage2Src=resp.result.passportPath+resp.result.passportPage2+"//"+resp.result.passportbacksideside;
              this.passfrontsidefile=null;
              this.passbacksidefile=null;
              this.passportpageid1="";
              this.passportpageid2="";
              $('#passportfront-page1_'+lop).attr('src',this.passportPage1Src);
              $('#fileimg-passport').attr('src',this.passportPage1Src);
              // $('#passportbackside-page2_'+lop).attr('src',this.passportPage2Src);
             debugger;
              var tempObj = {
                URL:'passportfront-page1_'+lop,
                documentName:"Passport",
                availability:"Original",
                fileName:resp.result.passportforntside,
                bucketName:resp.result.passportPage1,
                encryptionKey:resp.result.encryptionKey,
                contentType:resp.result.frontFileContent,
              }
             this.passenserpassport.push(tempObj);

              if(resp.result.ocrpassportpage1!='')
              {
                   if(resp.result.ocrpassportpage1.data!=null)
                   {
                  
                     
                      console.log(resp.result.ocrpassportpage1.data.dob);
                      $('#pass-num_'+lop).val(resp.result.ocrpassportpage1.data.passport_num!=null? resp.result.ocrpassportpage1.data.passport_num:'');
                      $('#pass-exp_'+lop).val(resp.result.ocrpassportpage1.data.doe);
                      $('#given-name_'+lop).val(resp.result.ocrpassportpage1.data.given_name!=null ? resp.result.ocrpassportpage1.data.given_name:'');
                      $('#surname_'+lop).val(resp.result.ocrpassportpage1.data.surname!=null && resp.result.ocrpassportpage1.data.surname!=""? resp.result.ocrpassportpage1.data.surname:'NLU');
                      $('#poi_'+lop).val(resp.result.ocrpassportpage1.data.place_of_issue!=null? resp.result.ocrpassportpage1.data.place_of_issue:'');
                      $('#dob_'+lop).val(resp.result.ocrpassportpage1.data.dob);
                    
                     if(resp.result.ocrpassportpage1.data.gender=='F')
                     {
                      $('#visaCustFemale').prop('checked',true);
                     }
                     else{
                      $('#visaCustMale').prop('checked',true);
                     }

                     //this.passfile.nativeElement.value = "";

                    if(this.countryData.result!=null &&  resp.result.ocrpassportpage1.data.nationality!="")
                    {
                       let countrycode=resp.result.ocrpassportpage1.data.country_code.substring(0,2);
                       let countrylist=this.countryData.result.find(y=>y.countryCode==countrycode);
                       if(countrylist!=undefined)
                       {
                        $('#nationality_'+lop).val(countrylist.id);
                       }
                       
                    }
                   
                   let dob=new Date(this.dateFormatChange(resp.result.ocrpassportpage1.data.dob));
                   let timeDiff = Math.abs(Date.now() - dob.getTime());
                   let age = Math.floor((timeDiff / (1000 * 3600 * 24))/365.25);
                    if(age>18)
                    {
                      $('#paxType_'+lop).val('Adult');
                    }
                    else{
                      $('#paxType_'+lop).val('Child');
                    }

                    $('#passportfrontside_1').val('');

                    if($('#pass-exp_1').val()!="")
                    {
                      let currdate =new Date();
                      var newDate = new Date(this.dateFormatChange($('#pass-exp_1').val()));
                      if(newDate<=currdate){
                        this._toastr.error("Passport is expired!");                  
                    
                      }
                      else{
                        currdate.setMonth(currdate.getMonth()+6);
                        console.info(currdate.toISOString().slice(0,10));
                        //this.dateFormatChange($('#pass-exp_1').val())
                      
                        let newDate2 = new Date(currdate.toISOString().slice(0,10));
                      
                        if(newDate<newDate2){
                          this._toastr.info("Passport will be expiring soon!");
                        }
                      }

                     

                    }
                   
                  

                    //this.visaObj.PersonalBirthPlace=resp.result.ocrpassportpage1.data.place_of_birth!=null? resp.result.ocrpassportpage1.data.place_of_birth.split(',')[0]:'';
                   }
            
           
  
              } 
  
  
            }
            else {
              this.passportimagupload=false;
              this.passportPage1Src="";
              this.passportPage2Src="";
              this.passportfrontserverfile="";
              this.passportbackserverfile="";
            }
  
          },
          err=>{
            $('#visaLoader').hide();
         console.log(err);
          })
            
       
  
  
       
         
         //end
  
  
            }
  
      
        
           }
  
         }
       else {
       // alert("Please Choose both side passports");
        this.passscan_error='Please Choose both side passports';
        $('#visaLoader').hide();
        $(".pass-scan.required").fadeIn();
          }
        }
        else{
         // alert("Please Choose both side passports");
          this.passscan_error='Please Choose both side passports';
          $('#visaLoader').hide();
          $(".pass-scan.required").fadeIn();
        }
  
      }
     catch(err)
     {
      $('#visaLoader').hide();
        console.error(err,[this.passfrontsidefile]);
      }

     
  
   }

     

}
