import { Component, OnInit } from '@angular/core';
import { BranchmanagementService } from '../../service/servicecall.service';
import { ToastrService } from 'ngx-toastr';
import { Newappservice } from '../../service/Newapplication_Service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

declare var $: any;
@Component({
  selector: 'app-branch-ip',
  templateUrl: './branch-ip.component.html',
  styleUrls: ['./branch-ip.component.css']
})
export class BranchIpComponent implements OnInit {
  err: any = {};
  getBranchIp: any = {};
  AllData: any = [];
  editIpObj: any = {};
  data: any;
  BranchIpObj: any = {};
  submitted = false;

  // -- Pagination --
  paginationSize = 10;
  page: number = 1;
  pageShow = 0;
  totalNoofpages: number = 0;
  selectedIndex: number = 0;
  totalcount: any;
  PageSizeArray: number[];
  registerForm: FormGroup;
  
  constructor(private _service: BranchmanagementService, private formBuilder: FormBuilder, private toastr: ToastrService,public _newappService: Newappservice) {}

  ngOnInit() {
    this.pageLoadAPICalls(this.page, this.paginationSize);
  }

  pageLoadAPICalls(page, paginationSize) {
    this.GetBranchIp(page, paginationSize);
    this.PageSizeArray = this._newappService.PageSizeArray;
    this.registerForm = this.formBuilder.group({
      BranchName: ['', Validators.required],
      Ip: ['', Validators.required],
    });

  }

  GetBranchIp(page, paginationSize) {
    this._service.getall_BranchIp(page, paginationSize)
      .subscribe(
        data => {
          console.info("data==",data);
          this.getBranchIp = { data };
          this.AllData = [];
          this.AllData.push(this.getBranchIp.data.result);
          // -- for pagination
          this.totalcount = this.getBranchIp.data.totalCount;
          this.totalNoofpages = Math.ceil(this.getBranchIp.data.totalCount / this.paginationSize);
        },
        error => {
          this.err = error;
          this.toastr.error("Error " + this.err.statusText);
        }
      );
  }

  BranchIpedit(edit) {
    this.editIpObj = {
      "id":edit.id,
      "branchName": edit.branchName,
      "ipAddress": edit.ipAddress,
      'IsActive': 1,
    };
  }

  AddIp() {
    this.submitted = true;
    // stop here if form is invalid

    this.BranchIpObj.branchName = $('#Add_branchName').val();
    this.BranchIpObj.ipAddress = $('#Add_ipAddress').val();
    //console.log(JSON.stringify(this.visaMissfeesMasterObj));
    this._service.BranchIp_Add(this.BranchIpObj)
      .subscribe(
        data => {
          if (data["status"] === true) {
          this.toastr.success(data["messsage"]);
          $('#BranchipClose').click();
          this.GetBranchIp(this.page, this.paginationSize);
          $('#Add_branchName').val("");
          $('#Add_ipAddress').val("");
          }
          else{
            this.toastr.error("Error " + "Ip already exists!");
          }
        },
        error => {
          this.err = error;
          this.toastr.error("Error " + this.err.statusText);
        }
      );
  }
  UpdateIp(objUpdate){

    // console.info("Sending Data For update====",objUpdate);
    this._service.updateBranchIp(this.editIpObj)
    .subscribe(
      data => {
        //console.log("this.editIpObj==",this.editIpObj);
        if (data["status"] === true) {
          this.toastr.success(data["messsage"]);
          this.GetBranchIp(this.page, this.paginationSize);
          $('#editclose').click();
          $('#branchNameEdit').val("");
          $('#ipAddressEdit').val("");
        }
      },
      error => {
        this.err = error;
        this.toastr.error("Error " + this.err.statusText);
      });
   }

  currentPageBind(currPage: any) {
    this.selectedIndex = currPage;
    this.page = currPage;
    $('ul.pagination li').removeClass('active');
    this.ngOnInit();
  }
  nextPage(nextpageNumber: number) {
    this.page = nextpageNumber + 1;
    if (this.page <= this.totalNoofpages) {
      this.pageShow = this.pageShow + 10;
    }
  }
  previousPage(prevPageNumber: number) {
    if (prevPageNumber >= 0) {
      this.page = prevPageNumber + 1;
      this.pageShow = this.pageShow - 10;
    }
  }

  noOfRecordsToShow(event: any) {
    this.paginationSize = parseInt(event.target.value);
    this.page = 1;
    this.GetBranchIp(this.page, this.paginationSize);
  }
}
