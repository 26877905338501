import { Component, OnInit,ViewChild} from '@angular/core';
// import { FormsModule } from '@angular/forms';
import { HttpClient, HttpHeaders, HttpRequest, HttpResponse, } from '@angular/common/http';
import { AddVisaService } from 'src/app/service/addVisaSerivce';
import { HttpErrorResponse } from '@angular/common/http/src/response';
//import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
//import { Router } from "@angular/router";
import {GlobalServiceService} from '../../service/global-service.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { VFSVASFeesService } from 'src/app/service/vfs-vfs.service';
import { DateTimeAdapter } from 'ng-pick-datetime';
import { DatePipe } from '@angular/common';
import { Route, ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { infocontent ,environment} from 'src/environments/environment';
declare var $: any;
export enum visaStatusMaster {
  ReadyforSubmission=1,
  Draft=43,
  DocumentPending,
  ForReview=3,
  Submitted,
  InterviewScheduled,
  Approved,
  Rejected,
  Canceled,
  Collected,
  PendingatVisaConsolate
}
@Component({
  selector: 'app-view-app',
  templateUrl: './view-app.component.html',
  styleUrls: ['./view-app.component.css']
})

export class ViewAppComponent implements OnInit {
  private localData: any;
  token: any;
  visaUserType: string = null;
  branch_name='';
  country_name='';
  visa_type='';
  visaRemark='';
  visaObj:any={
    visaCustomer:{},
    lstApplicationStatus:[],
    lstVisaMiscFees:[],
    lstVisaApplBranch:[],
    lstVisaDocument:[],
    lstVisaVFSVASFees: [],
   
  };
  followupPathVisaCopy={};
  editVisaData:any={};
  payresponse:any={};
  isAI :boolean=false;
  location: string;
  profession='';
  visaCountry='';
  nationality='';
  professionList:any=[];
  countryData:any=[];
  showpaymentbuttonflag=false;
  lstAgentPaymentInfo:[]
  miscInfo=[];
  constructor(private _http: HttpClient, private _visaService: AddVisaService, private _toastr: ToastrService,private _router: Router,
    private formBuilder: FormBuilder,public _globle:GlobalServiceService,private datePipe: DatePipe,private _route: ActivatedRoute) {    
   this.localData = JSON.parse(localStorage.getItem('currentUser'));
   if(this.localData==null)
   {
     this._router.navigate(['/']);
   }
   else{
   this.token = this.localData.token;
   this.visaUserType = this.localData.userType;
   }
   

   if(window.location.href.indexOf( environment.AirIndiaURL)>-1   )
   { this.isAI=true;
   }

   //$('#btnAddApp').hide();
  // $('#btnViewApp').show();
  }
  _statusMaster = visaStatusMaster;
 
  ngOnInit() {
    this.followupPathVisaCopy=this._visaService.ApiUrl.split("/api")[0]+"/Upload/Document/";
    this.getCountryData();
    this.GetAll_Profession();
    this.getEditVisaById(this._route.snapshot.queryParams.id);
  }

  
  getEditVisaById(editID){


    $('#visaLoader').show();
      debugger;
     this._visaService._getEditVisaById(editID)
     .subscribe(data=>{
        $('#visaLoader').hide();
         //console.info("full data:",data);
         this.editVisaData = data;

         //this.riyaVisaPNR="123";         
 
         this.location = this.editVisaData.result.customerLocation;
 
         this.visaObj = this.editVisaData.result;
        // this.countryData= this.visaObj.countryMaster;
  console.info(this.visaObj);
  debugger;
  this.visaCountry= this.visaObj.countryMaster.name;
  this.visa_type= this.visaObj.visaType.name;
        //  console.info("visainfo===",   this.visaObj );
         this.miscInfo = this.editVisaData.result.miscInfo;
         console.info(this.miscInfo);
         setTimeout(() => this.getApplicationPayment(), 100);
         //console.info("locatn===",this.visaObj.customerLocation);
        
         // if(this.visaObj.customerLocation=="BOM"){
         //   $('#visaTransferBranch option[value="1101"]').prop('disabled',true);
         // }
  //console.info("this.visaObj.lstApplicationStatus.statusName==",this.visaObj.lstApplicationStatus[0].statusName);
 
 
        
         //$("#riyaVisaPNR").text(this.visaObj.riyaVisaPNR);
 
 

       /*  this.getVisatypeByCountry() ;
         this.getVisaEntries();
         this.getVisaProcessingOptions()

         console.info("this.visaObj.lstVisaDocument==",this.visaObj.lstVisaDocument);
         if(this.visaObj.lstVisaDocument.length>0){
           this.bindVisaDocumets();
         }*/

if(this.visaObj.lstVisaPassangerDetail.length>0){

  
  debugger;
  this.nationality=this.countryData.result.find(x=>x.id==this.visaObj.lstVisaPassangerDetail[0].fk_NationalityId).nationality; 
//console.info(this.nationality);

let FK_StatusMasterId= this.visaObj.lstApplicationStatus.find(x=>x.currentStatus==true).fK_StatusMasterId;
        
if(FK_StatusMasterId==43)
{
this.showpaymentbuttonflag=true;
}


if(this.visaObj.lstVisaPassangerDetail[0].fk_ProfessionId!=null)
{
  console.info(this.visaObj.lstVisaPassangerDetail[0].fk_ProfessionId);
  this.profession=this.professionList.find(x=>x.id==this.visaObj.lstVisaPassangerDetail[0].fk_ProfessionId).name; 
  
}


}


       /*  if(this.visaObj.lstApplicationStatus.length>0){
          
           this.CurrentApplicationStatus=this.visaObj.lstApplicationStatus[0].statusName;
         
           this.FK_StatusMasterId= this.visaObj.lstApplicationStatus.find(x=>x.currentStatus==true).fK_StatusMasterId;
          // if(this.FK_StatusMasterId==this._statusMaster.ForReview)
            var rbtDisCharges=this.visaObj.lstApplicationStatus.find(x=>x.fK_StatusMasterId==this._statusMaster.ForReview);
            if(rbtDisCharges!=undefined){
             this.rbtDiscountedServCharge=rbtDisCharges.discountedServCharge;
            }
           if((this.FK_StatusMasterId!= this._statusMaster.ForReview) && (this.FK_StatusMasterId!=this._statusMaster.DocumentPending)){
 
             $(document).ready(function() {
               //$(".input__field.input__field--custom").prop('readonly',true);
               $('.input__field--custom,.select-option select').attr("disabled", true);
               $('#dubaistatus').removeAttr('disabled');
               // $(':radio:not(:checked)').attr('disabled', true);
               $('input[name="Gender"]').attr('disabled', true);
               $('input[name="IMPCase"]').attr('disabled', true);
               $('#addPassanger').css('display',"none");
               $('.RemovePass').css('display',"none");
               $('.dynamic-Pax').attr('disabled', true);
             });
             if(this.FK_StatusMasterId==this._statusMaster.ScheduleSubmission|| this.FK_StatusMasterId==this._statusMaster.ReadyforSubmission){
               $(document).ready(function() {
               $("#office-boy").attr("disabled",false); // for enalble to edit office boy
               });
             }
           }
        //   this.tempVisaObj.isInterviewScheduleBeforeSubmit=this.visaObj.isInterviewScheduleBeforeSubmit;
           // var test={abc:this.visaObj.lstApplicationStatus};
           // if( this.FK_StatusMasterId==this._statusMaster.InterviewScheduled && (this.visaObj.lstApplicationStatus.find(x=>x.fK_StatusMasterId!=this._statusMaster.Submitted)!=undefined))
           // {
           //   this.tempVisaObj.isInterviewScheduleBeforeSubmit=true;
           // }
         }*/
         // this.visaObj.travelDate = this._globle.changeDateFormat(this.visaObj.travelDate,"mm/dd/yyyy","dd/mm/yyyy");
        //  this.visaObj.travelDate = this._globle.changeDateFormat(this.visaObj.travelDate,"mm/dd/yyyy","dd-MMM-yyyy");
          //this.visaObj.visaCustomer.dateOfBirth = this._globle.changeDateFormat(this.visaObj.visaCustomer.dateOfBirth,"mm/dd/yyyy","dd/mm/yyyy");
          //this.visaObj.visaCustomer.passportExpiry = this._globle.changeDateFormat(this.visaObj.visaCustomer.passportExpiry,"mm/dd/yyyy","dd/mm/yyyy");
 
        // this.Cust_travelDate=this.visaObj.travelDate;
         //this.Cust_dateOfBirth=this.visaObj.visaCustomer.dateOfBirth;
         //this.Cust_passportExpiry=this.visaObj.visaCustomer.passportExpiry;
 
       /*  $('#costcenter').val(this.visaObj.costCenter);
         $('#empcode').val(this.visaObj.empCode);
         $('#location').val(this.visaObj.location);
         $('#department').val(this.visaObj.department);
 
         $('#bpcode').val(this.visaObj.bpCode);
         $('#companycode').val(this.visaObj.companyCode);*/
 
          // if(this.visaObj.lstVisaApplBranch.length>0){
         //   fk_BranchMasterId
         // }
        
        //this.tempVisaObj.fK_TransferBranchId = this.visaObj.fK_TransferBranchId;
         //this.visaObj.visaCustomer.dateOfBirth="02/02/2019";
         // alert(this.visaObj.visaCustomer.dateOfBirth);
       //  this.visaObj.visaCustomer.dateOfBirth=null;
       //  $('#dob').val(this.visaObj.visaCustomer.dateOfBirth.split('T')[0]);
         if(this.visaObj.visaCustomer==null){
           this.visaObj.visaCustomer={};
         }
         // this.visaAppDelivery.courierPartner = this.visaObj.courierPartner;
         // this.visaAppDelivery.deliveryRefNo = this.visaObj.deliveryReferenceNo;
    
         
         // else{
         //   $('#markuponbaseFees').val(this.miscInfo.feesDetails.markupOnBasefare);
         // }
        
     })
   


     

   }

getApplicationPayment()
{
debugger;
  this._visaService._getAgentPaymentList(this.visaObj.applicationRef,this.visaObj.id )
     .subscribe(data=>{
        $('#visaLoader').hide();
         console.info("payment data:",data);
        
         //let payresponse={};
         this.payresponse=data;
         this.lstAgentPaymentInfo = this.payresponse.result;
        
        // this.editVisaData = data;
         
     })

}


editPassenger()
{
  this._router.navigate([this.visaUserType + '/editApplication'], { queryParams: { id:this.visaObj.id}});


}


   GetAll_Profession()
   {
     this.professionList=[];
     this._visaService._getProfessionList('')
     .subscribe((professionList:any)=>{
       if(professionList.status==true){
        
         this.professionList=professionList.result;
       }})
   }


   saveVisaApplication(statusMasterId, buttonId)
   {


   
  this._router.navigate([this.visaUserType + '/B2BPayment'], { queryParams: { id:this.visaObj.applicationRef}});
  //$('#'+buttonId).prop('disabled', false);
  //$("#visaLoader").hide();
  


    //$('#'+buttonId).prop('disabled', false);
                 

   }
   
getdocumenttypename(shortcode)
{

  switch(shortcode) { 
    case "photo": { 
       return "Photo"
       break; 
    } 
    case "passport": { 
      return "Passport"
      break; 
   } 
    case "travelTicket": { 
      return "Travel Tickets" 
       break; 
    } 
    case "accommodation":
      {
        return "Accommodation/Hotel Confirmation"
        break; 
      }
      case "forex_Credit_Card":
      {
        return "Forex/Credit Card"
        break; 
      }
      case "bankStatement":
      {
        return "Bank Statement"
        break; 
      }
      case "incomeTax":
      {
        return "Income Tax Papers"
        break; 
      }
      case "selfEmployment":
      {
        return "Proof of Self-employment"
        break; 
      }case "travelInsurance":
      {
        return "Travel Insurance"
        break; 
      }case "coveringLetter":
      {
        return "Covering Letter"
        break; 
      }case "invitationLetter":
      {
        return "Invitation Letter"
        break; 
      }
      case "NOCDocument":
      {
        return "NOC Document"
        break; 
      }
      case "VISA":
        {
          return "Visa"
          break; 
        }
    default: { 
       //statements; 
       break; 
    } 
 } 
}

downloadDoc(documentCode){

  var downloadVisaDocument=this.visaObj.lstVisaDocument.find(x=>x.url==documentCode);
  if(downloadVisaDocument!=null){
     ;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
  // VisaApplDocument/DownloadVisaDoc?id=403 //DownloadSample //responseType 'blob'
      this._http.get(this._visaService.ApiUrl+"/VisaApplDocument/DownloadDocument?id="+downloadVisaDocument.id,
      { headers: headers , observe: 'response', responseType: 'arraybuffer'}, )
      .subscribe(res => {
         ;
        var filename=downloadVisaDocument.fileName.split('.')[0];
        var contentType = res.headers.get('content-type');
        if(contentType=="application/vnd.openxmlformatsofficedocument.spreadsheetml.sheet"){
          contentType="application/vnd.ms-excel";
        }
        var blob = new Blob([res.body], { type:contentType});
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, filename);
        } else {
        var a = document.createElement('a');
        a.href = URL.createObjectURL(blob);
        a.download = filename;
        //a.target="_blank";
        debugger;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        }
      },
       (err: HttpErrorResponse) => {
         this._toastr.error("Internal Server Error.")
        //this._toastr.error(err.message);
        console.error (err);    // SHOW ERRORS IF ANY.
      });
   }
}



viewDoc(documentCode){

  var downloadVisaDocument=this.visaObj.lstVisaDocument.find(x=>x.url==documentCode);
  if(downloadVisaDocument!=null){
     ;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
  // VisaApplDocument/DownloadVisaDoc?id=403 //DownloadSample //responseType 'blob'
      this._http.get(this._visaService.ApiUrl+"/VisaApplDocument/DownloadDocument?id="+downloadVisaDocument.id,
      { headers: headers , observe: 'response', responseType: 'arraybuffer'}, )
      .subscribe(res => {
         ;
        var filename=downloadVisaDocument.fileName.split('.')[0];
        var contentType = res.headers.get('content-type');
        if(contentType=="application/vnd.openxmlformatsofficedocument.spreadsheetml.sheet"){
          contentType="application/vnd.ms-excel";
        }
        var blob = new Blob([res.body], { type:contentType});
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, filename);
        } else {
        var a = document.createElement('a');
        a.href = URL.createObjectURL(blob);
        //a.download = filename;
        a.target="_blank";
        debugger;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        }
      },
       (err: HttpErrorResponse) => {
         this._toastr.error("Internal Server Error.")
        //this._toastr.error(err.message);
        console.error (err);    // SHOW ERRORS IF ANY.
      });
   }
}

   getCountryData() {
     this._visaService._getCountryList()
       .subscribe(result => {
         var data = { result };
         console.info(data)
         this.countryData = data;
         //this.visaObj.visaCustomer.fK_NationalityMasterId=this.countryData.result.find(x=>x.countryCode=="IN").id; // for deafault country India
       },
         (err: HttpErrorResponse) => {
           console.error(err.message);    // SHOW ERRORS IF ANY.
         });
 
         
         /*this._visaService._getVisaFeesCountryList()
         .subscribe(result => {
           var data = { result };
           this.visaCountryData = data;
           this.visaObj.visaCustomer.fK_NationalityMasterId=this.countryData.result.find(x=>x.countryCode=="IN").id; // for deafault country India
         },
           (err: HttpErrorResponse) => {
             console.error(err.message);    // SHOW ERRORS IF ANY.
           });*/
   }
 

}
