import { Component, OnInit } from '@angular/core';
import { Inject, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpResponse, } from '@angular/common/http';
import { AddVisaService } from 'src/app/service/addVisaSerivce';
import { HttpErrorResponse } from '@angular/common/http/src/response';
//import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

declare var $: any;

@Component({
  selector: 'app-approval',
  templateUrl: './approval.component.html',
  styleUrls: ['./approval.component.css']
})
export class ApprovalComponent implements OnInit {
  @ViewChild('attachments') attachment: any;
  selectedFile: File;
  fileList: File[] = [];
  listOfFiles: any = [];
  formData: any= [];
  UploadedFiles= [];
  displayButton=false;
  error_selected_file=0

  visaObj: any[]=[];
  token: any;
  constructor(private _http: HttpClient, private _visaService: AddVisaService, private _toastr: ToastrService, private _router: Router, private formBuilder: FormBuilder) { }

  ngOnInit() {
    $('#lblsizelimit').hide();
  }


onFileChanged(event: any) {

  this.formData = new FormData();
  


  for (var i = 0; i <= event.target.files.length - 1; i++) {
	  
      var selectedFile = event.target.files[i];
 //console.info(selectedFile.size);
 //console.info(selectedFile.size<5242880);
    this.fileList.push(selectedFile);
    this.listOfFiles.push({'name':selectedFile.name,'size':selectedFile.size});
    
    if(selectedFile.size<5242880)
      {
        this.formData.append(selectedFile.name, selectedFile);
        
        
      }else{

        this.error_selected_file=1;

        
        
      }
  }

  if(this.error_selected_file==1){

    $('#lblsizelimit').show();
    this.displayButton=false;
  }else{

    this.displayButton=true;
    $('#lblsizelimit').hide();

  }
  this.attachment.nativeElement.value = '';

  
}



removeSelectedFile(index) {
 // Delete the item from fileNames list
 this.listOfFiles.splice(index, 1);
 // delete file from FileList
 this.fileList.splice(index, 1);

 this.error_selected_file=this.error_selected_file-1;

 if(this.error_selected_file==0){
  this.displayButton=true;
  $('#lblsizelimit').hide();
 }
}

btnapprovalSave(){
 // var formData = new FormData();
 $('#visaLoader').show();
  let headers = new HttpHeaders();
  headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
  //const formData = new FormData();
  //formData.append('file', this.listOfFiles);
  
  console.info('this.formData==',this.formData);
  
  
  this._http.post(this._visaService.ApiUrl + "/VisaApplDocument/UploadDocumentBulkApproval", this.formData, { headers: headers } )
  .subscribe((successData:any) => {
    console.log(successData);
	
    this.UploadedFiles=successData.result;
   
    this._toastr.success(successData.messsage);
   
  },
    (err: HttpErrorResponse) => {


      //this._toastr.error("Document Upload Failed");
      this._toastr.error(err.error.messsage);
      console.error(err.error.messsage);    // SHOW ERRORS IF ANY.
    })

    $('#visaLoader').hide();
    this.fileList = [];
    this.listOfFiles = [];
}

}
