import { Component, OnInit } from '@angular/core';

import { BranchmanagementService } from '../../service/servicecall.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { DomElementSchemaRegistry } from '@angular/compiler';
import { ToastrService } from 'ngx-toastr';
import { Newappservice } from '../../service/Newapplication_Service';

declare var $: any;
@Component({
  selector: 'app-misc-fees',
  templateUrl: './misc-fees.component.html',
  styleUrls: ['./misc-fees.component.css'],
  providers: [BranchmanagementService]
})
export class MiscFeesComponent implements OnInit {
  searchString: any;
  MissFees: string = null;
  editMissFeesMasterObj: any = {};
  UpdateMissFeesMasterObj: any = {};
  visaMissfeesMasterObj: any = {};
  AllMissFeesData: any = [];
  getMissFeeMaster: any = {};
  err: any = {};
  editFeeChargesObj: any = {};
  UpdateFeeChargesObj: any = {};
  deleteData: any = {};
  visaUpdateChargesModel: any = [];
  active: any;
  deleteobj: any = [];
  data: any;
  registerForm: FormGroup;
  submitted = false;

  // -- Pagination --
  paginationSize = 10;
  page: number = 1;
  pageShow = 0;
  totalNoofpages: number = 0;
  selectedIndex: number = 0;
  totalcount: any;
  PageSizeArray: number[];

  constructor(private _http: HttpClient, private toastr: ToastrService, private _service: BranchmanagementService, private formBuilder: FormBuilder, public _newappService: Newappservice) { }

  ngOnInit() {
    this.pageLoadAPICalls(this.page, this.paginationSize);
    this.PageSizeArray = this._newappService.PageSizeArray;
    this.registerForm = this.formBuilder.group({
      FeeType: ['', Validators.required],
      Charges: ['', Validators.required],
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.registerForm.controls; }

  pageLoadAPICalls(page, paginationSize) {
    this.GetAllMissFeeMaster(page, paginationSize, "");

  }
  myFunction() {
    this.searchString = "";
    $('#countrysearch').val("");
  }
  SearchbyCountry() {

    //this.SearchKeyWord = $('#countrysearch').val();
    //this.paginationSize = parseInt(event.target.value);
    this.page = 1;
    this.GetAllMissFeeMaster(this.page, this.paginationSize,this.searchString);
  }
  Reload() {
    this.GetAllMissFeeMaster(this.page,this.paginationSize,"");
  }
  GetAllMissFeeMaster(page, paginationSize, SearchKeyWord="") {
    this._service.getall_MissFessCharges(page, paginationSize,SearchKeyWord)
      .subscribe(
        data => {
          this.getMissFeeMaster = { data };
          this.AllMissFeesData = [];
          this.AllMissFeesData.push(this.getMissFeeMaster.data.result);
          // -- for pagination
          this.totalcount = this.getMissFeeMaster.data.totalCount;
          this.totalNoofpages = Math.ceil(this.getMissFeeMaster.data.totalCount / this.paginationSize);
        },
        error => {
          this.err = error;
          this.toastr.error("Error " + this.err.statusText);
        }
      );
  }

  visaMiscModel() {
    this.registerForm.reset();
    this.submitted = false;
  }
  AddMiscFees() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }

    debugger;

    this.visaMissfeesMasterObj.FeeType = $('#e1').val();
    this.visaMissfeesMasterObj.Charges = $('#s1').val();
    //console.log(JSON.stringify(this.visaMissfeesMasterObj));
    this._service.MissFess_Add(this.visaMissfeesMasterObj)
      .subscribe(
        data => {
          this.toastr.success(data["messsage"]);
          $('#adduserClose').click();
          this.GetAllMissFeeMaster(this.page, this.paginationSize);


        },
        error => {
          this.err = error;
          this.toastr.error("Error " + this.err.statusText);
        }
      );
  }
  FeesChargesedit(edit) {
    this.editFeeChargesObj = [];
    this.editFeeChargesObj = edit;
  }

  updateFessCharges = function (FessChargesModel) {
    debugger;
    this.visaUpdateChargesModel.push(FessChargesModel);
    for (let index = 0; index < this.visaUpdateChargesModel.length; index++) {
      const element = this.visaUpdateChargesModel[index];
      this.Visaobj = {
        'feeType': element.feeType,
        'charges': element.charges,
        'id': element.id,
        'IsActive': 1,
      };

    }
    //console.log(JSON.stringify(this.Visaobj));
    debugger;
    this._service.updateFessCharges(this.Visaobj)
      .subscribe(
        data => {
         // console.log(data);
          if (data.status === true) {
            this.toastr.success(data["messsage"]);
            this.GetAllMissFeeMaster(this.page, this.paginationSize);
            $('#editclose').click();
          }
        },
        error => {
          this.err = error;
          this.toastr.error("Error " + this.err.statusText);
        });

  };

  delete_feeCharges(deleteob) {
    this._service.deleteFeeCharges(deleteob.id)
      .subscribe(
        data => {
          const temp = data;
          this.toastr.success(data["messsage"]);
          $('#closedelete').click();
          $('#closedelete1').click();
          this.GetAllMissFeeMaster(this.page, this.paginationSize);

        },
        error => {
          this.err = error;
          this.toastr.error("Error " + this.err.statusText);
        }
      );
  }

  deleteFeeCharges(dele) {
    this.deleteobj = [];
    this.deleteobj.push(dele);
    // this.deleteData.name=dele.name;
    // this.deleteData.id=dele.id;
    // this.deleteData.IsActive = dele.IsActive;
  }
  noofRecordstoDisplay(args: any) {
    // tslint:disable-next-line:radix
    this.paginationSize = parseInt(args.target.value);
    this.page = 1;// setting to default value
    this.pageShow = 0;
  }
  currentPageBind(currPage: any) {
    this.selectedIndex = currPage;
    this.page = currPage;
    $('ul.pagination li').removeClass('active');
    this.ngOnInit();
  }
  nextPage(nextpageNumber: number) {
    this.page = nextpageNumber + 1;
    if (this.page <= this.totalNoofpages) {
      this.pageShow = this.pageShow + 10;
    }
  }
  previousPage(prevPageNumber: number) {
    if (prevPageNumber >= 0) {
      this.page = prevPageNumber + 1;
      this.pageShow = this.pageShow - 10;
    }
  }
  noOfRecordsToShow(event: any) {
    this.paginationSize = parseInt(event.target.value);
    this.page = 1;
    this.GetAllMissFeeMaster(this.page, this.paginationSize);
  }
}
