import { LoginComponent } from './../login/login.component';
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Route, Router } from "@angular/router";
//import { BsDatepickerConfig } from "ngx-bootstrap/datepicker";
import { FormsModule } from "@angular/forms";
import { GlobalsearchService } from "../service/GlobalsearchService";
import { validateConfig } from '@angular/router/src/config';
//import { idLocale } from 'ngx-bootstrap/chronos/i18n/id';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { AddVisaService } from 'src/app/service/addVisaSerivce';
import { NavMenuComponent } from '../nav-menu/nav-menu.component';
declare var $: any;

@Component({
  selector: "app-visa-home",
  templateUrl: "./visa-home.component.html",
  styleUrls: ["./visa-home.component.css"],
  providers: [NavMenuComponent]
})
export class VisaHomeComponent implements OnInit {
  visaofficeId: number;
  searchname: number;
  apreceivedId: number;
  branchId: number;
  err: HttpErrorResponse;
  applicationlist: any = [];
  temparr: any = [];
  result: any = [];
  tempobj: any = [];
  localData: any = {};
  visaUserType: string = null;
  loginUserName: string = null;
  searchvalueName: string = null;
  disabled: boolean = false;
  catId: any;
  href: any ;
  // -- Pagination --
  paginationSize = 10;
  page: number = 1;
  pageShow = 0;
  totalNoofpages: number = 0;
  selectedIndex: number = 0;
  totalcount: any;
  currentPage=1;
  resultarr: any = [];
  autocompletearr: any = [];
  CategoryValue: number;
  catFilter: string = null;
  containerClass: string;
  SearchTag_List: any = [];
  data: any = {};
  status: any = "";
  searcharray: any = [];
  firstarray: any = [];
  flaginlist: boolean = true;   // --- True when autocomplete data available from api ------------
  dateflag = 0;
  searchData: any = [];
  searchvalueId: number = 0;
  statusId: number = 0;
  //public dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();
  allSearchData: any = [];
  searchlistLength: number = 0;
  Flag: boolean;
  globalsearchObj: any =
    {
      "page": 1,
      "pageSize": 100,
      "applFromDate": "",
      "applToDate": "",
      "updateFromDate": "",
      "updateToDate": "",
      "collectionFromDate": "",
      "collectionToDate": "",
      "lstVisaExecutiveId": [],
      "lstCurrentStatus": [],
      "lstVisaType": [],
      "lstVendorId": [],
      "lstApplicantName": [],
      "lstPassportNo": [],
      "lstVisaCountry": [],
      "lstBarcode": [],
      "lstBranch": [],
      "lstIcust": [],
      "lstPNR": []
    };
    countryData: any = [];
    visaCountry:any=0;
    visaCountryData:any=[];
    filerstype:any=0;
    apptoggleopen:boolean=false;
    filterData:any={
      PNRNo:'',
      PassportNo:'',
      FirstName:'',
      fromdateTime:'',
      todateTime:'',
      Status:0,
      CIPV:'0',
      page:0,
      Pagesize:10,
      VisaCountry:1089
     
    };
    Status:any;
    listobj: any = {};
    id:number;
    UaeAppType:any=0;
    activePath:string=null;
  constructor(private urlRoute: ActivatedRoute, private router: Router, private searchservice: GlobalsearchService, private toastr: ToastrService,private _visaService: AddVisaService) {
      this.localData = JSON.parse(localStorage.getItem('currentUser'));
      if(this.localData!=null){
        this.visaUserType = this.localData.userType;
        this.loginUserName = this.localData.userName;      
      }
      $('#addnewVisaApplication').modal("hide");
     
     
      let nav_array={"New":1,"Pending":2,"Submitted":3,"Closed":4,'Open':30,'Document_Pending':31,'Document_Complete':32,'Not_Posted_Emigration':33,'Posted_Emigration':34,'Hold_Has_Resident':35,'Hold_Has_Valid_Visa':36,'Hold_Inside_Emir_by_Visa':37,'Hold_Other':38,'Uae_Approved':39,'Close':40,'Uae_Rejected':42};
      let activePathStatus=window.location.pathname.split('/')[3];
      this.id=nav_array[activePathStatus];
      this.UaeAppType=JSON.parse(localStorage.getItem('uaeApptype'));
      if(this.UaeAppType!=null)
      {
        this.filerstype=this.UaeAppType;
        this.apptoggleopen=this.filerstype==2 ? true:false;
      }
      else{
        this.filerstype=0;
        this.apptoggleopen=this.filerstype==2 ? true:false;
      }

    }
  ngOnInit() {
   
    this. getStatus();
    this.disabled = true;
    this.CategoryValue = 0;
    //this.dpConfig.containerClass = "theme-red";
    //this.clearsearchJson();
    this.SearchTag_List = [];
    this.allSearchData = [];
    this. getCountryData();
    // if(this.id>=30 && this.id<=40)
    // {
    //   this.filerstype=2;
    // }
    // else {
    //   this.filerstype=0;
    // }
    this.activePath= window.location.pathname.split('/')[2];
    console.info('activePath : '+this.activePath);
   /* if(this.activePath=='VisaApplications')
    {
      $('#btnViewApp').hide();
      $('#btnAddApp').show();
    }
    else if(this.activePath=='addB2bApplication'){
      $('#btnViewApp').show();
      $('#btnAddApp').hide();
    }
    else{
      $('#btnViewApp').show();
      $('#btnAddApp').show();
    }*/

   // $('#btnViewApp').hide();
   //$('#btnAddApp').hide();
  }

  clearsearchJson() {
    this.globalsearchObj = {
      "page": 1,
      "pageSize": 100,
      "applFromDate": "",
      "applToDate": "",
      "updateFromDate": "",
      "updateToDate": "",
      "collectionFromDate": "",
      "collectionToDate": "",
      "lstVisaExecutiveId": [],
      "lstCurrentStatus": [],
      "lstVisaType": [],
      "lstVendorId": [],
      "lstApplicantName": [],
      "lstBranch": [],
      "lstPassportNo": [],
      "lstVisaCountry": [],
      "lstBarcode": [],
      "lstIcust": [],
      "lstPNR": []
    }
  }


  //#region  --- On Key press Enter for search string --
  onSearchChange(searchValue: string) {
    this.Flag = false;
    if (this.CategoryValue === 0) {
      $("#dtRange").val('');
      this.toastr.info('Please select a category');
    } else if (searchValue !== undefined || searchValue !== "") {
      this.href = this.router.url;

      if (this.href.indexOf("/Admin/New") !== -1) {
        // if(this.SearchTag_List.length);
        //this.SearchTag_List = this.searchservice.getCleaSearchValue();
        //this.clearsearchJson();
      }
      if (this.CategoryValue === 1 || this.CategoryValue === 4 || this.CategoryValue === 9) {
      } else {
        this.Autocomplete(searchValue, this.CategoryValue);
      }
    }
  }
  //#endregion


  //#region -- On Text input Autointellisense --
  Autocomplete(searchValue, categoryValue) {
    if (searchValue !== "") {
      this.searchservice
        ._globalsearchApi(categoryValue, searchValue)
        .subscribe(data => {
          this.resultarr = [];
          this.autocompletearr = [];
          this.autocompletearr = data;
          this.firstarray = this.autocompletearr.result;  // ---- First array with all record for autocomplete -----
          if (this.autocompletearr.result.length > 0) {
            this.flaginlist = true;   // --- True when autocomplete data available from api ------------
          }
          if (this.autocompletearr.status === true) {
            for (let index = 0; index < this.autocompletearr.result.length; index++) {
              this.resultarr.push(this.autocompletearr.result[index].name);
            }
            this.searchData = [];
            this.searchData.push(this.resultarr);

            console.info(this.resultarr);


          } else {
            this.toastr.info('Search Result Not Found');
          }
          $(".searchautocomplete").autocomplete({   //  -------- Autocomplete populate --- 
            source: this.resultarr,
            minLength: 0,
          });
          if ($(".searchautocomplete").val().length > 5) {
            // alert();
          }
        },
          error => {
            this.err = error;
            this.toastr.error('Error :' + this.err.statusText);
          });
    }
  }
  //#endregion

  //#region --Creating Search tag
  create_SearchTag(value) {
    if (this.SearchTag_List.length !== undefined) {
      const CheckBool = value !== undefined ? this.SearchTag_List.some(e => e === value) : true;  // Checking string already available in search tag
      if (CheckBool === false) {
        this.SearchTag_List.push(value);
        this.href = "";
        this.searchservice.SetSearchTag(this.SearchTag_List);  // Sending via service to global search
       // this.router.navigate([this.visaUserType + '/Dummy'], { skipLocationChange: true });
      } else {
        this.toastr.info('Already added.');
      }
    }
    else {
      this.SearchTag_List.push(value);   // For 1st Time
      this.searchservice.SetSearchTag(this.SearchTag_List);  // Sending via service to global search
      this.router.navigate([this.visaUserType + '/globalsearch']);
    }

  }
  //#endregion



  //#region --Category Global Search
  catFilterFun() {
    const applicationName = $("#dtRange").val().trim().toLocaleUpperCase();
    if(applicationName=='')
    {
      this.toastr.error('Enter text What you want to search.'); 
      return false;
    }

    if ($("#dtRange").val() === undefined) {
      this.searchData = [];
      this.autocompletearr = [];
    }
   
    if (this.CategoryValue === 1) {                              // "lstApplicantName": ["string"],
      const applicationName = $("#dtRange").val().trim().toLocaleUpperCase();
      if (this.flaginlist === true) {                             // --- True when autocomplete data available from api ------------
        $('#searchTag').show();
        this.SearchTag_List.push(applicationName);           // --For search tag 
        this.globalsearchObj.lstApplicantName.indexOf(applicationName) === -1 ? this.globalsearchObj.lstApplicantName.push(applicationName) : this.toastr.info('Already applied.');                                     // --For search request parameters
        this.allSearchData.indexOf({ categoryId: this.CategoryValue, name: applicationName }) === -1 ? this.allSearchData.push({ categoryId: this.CategoryValue, name: applicationName }) : this.allSearchData;           // for delete particular from selected category
        this.disabled = false;
      }
    }
    else if (this.CategoryValue === 2) {                          // "lstCurrentStatus": [0],
      const CurrentStatus = $("#dtRange").val();
      if (this.flaginlist === true) {                              // --- True when autocomplete data available from api --
        const CurrentStatusId = this.getNamebyID(CurrentStatus, this.CategoryValue);
        $('#searchTag').show();
        this.SearchTag_List.push($("#dtRange").val());             // --For search tag 
        this.globalsearchObj.lstCurrentStatus.indexOf(CurrentStatusId) === -1 ? this.globalsearchObj.lstCurrentStatus.push(CurrentStatusId) : this.toastr.info('Already applied.');                                       // --For search request parameters
        this.allSearchData.indexOf({ categoryId: this.CategoryValue, name: CurrentStatus, Id: CurrentStatusId }) === -1 ? this.allSearchData.push({ categoryId: this.CategoryValue, name: CurrentStatus, Id: CurrentStatusId }) : this.allSearchData;    // for delete particular from selected category
        this.disabled = false;
      }
    }
    else if (this.CategoryValue === 3) {                  //   "applFromDate": "2019-04-10T10:05:06.875Z",  "applToDate": "2019-04-10T10:05:06.875Z",
      if (this.dateflag === 1) {
        this.toastr.info('Date Filter already applied.Please remove it first.');
      } else {
        const applFromDate = $("#dtRange").val().split('-')[0].trimRight();
        const applToDate = $("#dtRange").val().split('-')[1].trimLeft();

        if (applFromDate !== undefined && applToDate !== undefined) {
          $('#searchTag').show();
          this.allSearchData.push({ categoryId: this.CategoryValue, name: applFromDate + '-' + applToDate });
          this.SearchTag_List.push($("#dtRange").val());
          this.globalsearchObj.applFromDate = applFromDate;
          this.globalsearchObj.applToDate = applToDate;
          this.allSearchData.indexOf({ categoryId: this.CategoryValue, name: applFromDate }) === -1 ? this.allSearchData.push({ categoryId: this.CategoryValue, name: applFromDate }) : this.allSearchData;                              // for delete particular from selected category
          this.allSearchData.indexOf({ categoryId: this.CategoryValue, name: applToDate }) === -1 ? this.allSearchData.push({ categoryId: this.CategoryValue, name: applToDate }) : this.allSearchData;                              // for delete particular from selected category
          this.disabled = false;
        }
        this.dateflag = 1; // --- Flag for fist time date timeset
      }
    }
    else if (this.CategoryValue === 4) {                             // "lstBarcode": ["string"]
      const Barcode = $("#dtRange").val().trim().toLocaleUpperCase();
      if (this.flaginlist === true) {                                // --- True when autocomplete data available from api --
        $('#searchTag').show();
        this.SearchTag_List.push(Barcode);               // --For search tag 
        if (this.globalsearchObj.lstBarcode !== undefined) {
          this.globalsearchObj.lstBarcode.indexOf(Barcode) === -1 ? this.globalsearchObj.lstBarcode.push(Barcode) : this.toastr.info('Already applied.');                           // --For search request parameters
        } else {
          this.globalsearchObj.lstBarcode.push(Barcode);
        }

        this.allSearchData.indexOf({ categoryId: this.CategoryValue, name: Barcode }) === -1 ? this.allSearchData.push({ categoryId: this.CategoryValue, name: Barcode }) : this.allSearchData;                              // for delete particular from selected category
        this.disabled = false;
      }
    }
    else if (this.CategoryValue === 5) {                         // "Branch": [0]
      const Branchname = $("#dtRange").val();
      if (this.flaginlist === true) {                               // --- True when autocomplete data available from api --
        const BranchId = this.getNamebyID(Branchname, this.CategoryValue);
        $('#searchTag').show();
        this.SearchTag_List.push($("#dtRange").val());             // --For search tag 
        this.globalsearchObj.lstBranch.indexOf(BranchId) === -1 ? this.globalsearchObj.lstBranch.push(BranchId) : this.toastr.info('Already applied.');
        this.allSearchData.indexOf({ categoryId: this.CategoryValue, name: Branchname, Id: BranchId }) === -1 ? this.allSearchData.push({ categoryId: this.CategoryValue, name: Branchname, Id: BranchId }) : this.allSearchData;                 // for delete particular from selected category
        this.disabled = false;
      }
    }
    else if (this.CategoryValue === 6) {                  // "collectionFromDate": "2019-04-10T11:30:01.570Z","collectionToDate": 2019-04-10T11:30:01.570Z",
      if (this.dateflag === 1) {
        this.toastr.info('Date Filter already applied.Please remove it first.');
      } else {
        const collectionFromDate = $("#dtRange").val().split('-')[0].trimRight();
        const collectionToDate = $("#dtRange").val().split('-')[1].trimLeft();

        if (collectionFromDate !== undefined && collectionToDate !== undefined) {
          $('#searchTag').show();
          this.allSearchData.push({ categoryId: this.CategoryValue, name: collectionFromDate + '-' + collectionToDate });
          this.SearchTag_List.push($("#dtRange").val());
          this.globalsearchObj.collectionFromDate = collectionFromDate;
          this.globalsearchObj.collectionToDate = collectionToDate;
          this.allSearchData.indexOf({ categoryId: this.CategoryValue, name: collectionFromDate }) === -1 ? this.allSearchData.push({ categoryId: this.CategoryValue, name: collectionFromDate }) : this.allSearchData;                              // for delete particular from selected category
          this.allSearchData.indexOf({ categoryId: this.CategoryValue, name: collectionToDate }) === -1 ? this.allSearchData.push({ categoryId: this.CategoryValue, name: collectionToDate }) : this.allSearchData;                              // for delete particular from selected category
          this.disabled = false;
        }
        this.dateflag = 1; // --- Flag for fist time date timeset
      }
    }
    else if (this.CategoryValue === 7) {                         // "lstIcust": ["string"]
      const icust = $("#dtRange").val();
      if (this.flaginlist === true) {                  // --- True when autocomplete data available from api --
        $('#searchTag').show();
        this.SearchTag_List.push($("#dtRange").val());
        if (this.globalsearchObj.lstIcust !== undefined) {
          this.globalsearchObj.lstIcust.indexOf(icust) === -1 ? this.globalsearchObj.lstIcust.push(icust) : this.toastr.info('Already applied.');
        } else {
          this.globalsearchObj.lstIcust.push(icust);
        }
        this.allSearchData.indexOf({ categoryId: this.CategoryValue, name: icust }) === -1 ? this.allSearchData.push({ categoryId: this.CategoryValue, name: icust }) : this.allSearchData;                              // for delete particular from selected category
        this.disabled = false;
      }
    }
    else if (this.CategoryValue === 8) {                         // "lstPassportNo": ["string"]
      const passportno = $("#dtRange").val();
      if (this.flaginlist === true) {                           // --- True when autocomplete data available from api --
        $('#searchTag').show();
        this.SearchTag_List.push($("#dtRange").val());
        this.globalsearchObj.lstPassportNo.indexOf(passportno) === -1 ? this.globalsearchObj.lstPassportNo.push(passportno) : this.toastr.info('Already applied.');                                              // for delete particular from selected category
        this.allSearchData.indexOf({ categoryId: this.CategoryValue, name: passportno }) === -1 ? this.allSearchData.push({ categoryId: this.CategoryValue, name: passportno }) : this.allSearchData;                              // for delete particular from selected category
        this.disabled = false;
      }
    }
    else if (this.CategoryValue === 9) {                         //  "lstPNR": ["string"]
      const lstPNR = $("#dtRange").val().trim().toLocaleUpperCase();
      if (this.flaginlist === true) {                           // --- True when autocomplete data available from api --
        $('#searchTag').show();
        this.SearchTag_List.push(lstPNR);
        if (this.globalsearchObj.lstPNR !== undefined) {
          this.globalsearchObj.lstPNR.indexOf(lstPNR) === -1 ? this.globalsearchObj.lstPNR.push(lstPNR) : this.toastr.info('Already applied.');                                              // for delete particular from selected category
        } else {
          this.globalsearchObj.lstPNR.push(lstPNR);                                          // for delete particular from selected category
        }
        this.allSearchData.indexOf({ categoryId: this.CategoryValue, name: lstPNR }) === -1 ? this.allSearchData.push({ categoryId: this.CategoryValue, name: lstPNR }) : this.allSearchData;                              // for delete particular from selected category
        this.disabled = false;
      }
    }
    else if (this.CategoryValue === 10) {                  //    "updateFromDate": "2019-04-10T10:28:40.876Z","updateToDate": "2019-04-10T10:28:40.876Z",
      if (this.dateflag === 1) {
        this.toastr.info('Date Filter already applied.Please remove it first.');
      } else {
        const updateFromDate = $("#dtRange").val().split('-')[0].trimRight();
        const updateToDate = $("#dtRange").val().split('-')[1].trimLeft();

        if (updateFromDate !== undefined && updateToDate !== undefined) {
          $('#searchTag').show();
          this.allSearchData.push({ categoryId: this.CategoryValue, name: updateFromDate + '-' + updateToDate });
          this.SearchTag_List.push($("#dtRange").val());
          this.globalsearchObj.updateFromDate = updateFromDate;
          this.globalsearchObj.updateToDate = updateToDate;
          this.allSearchData.indexOf({ categoryId: this.CategoryValue, name: updateFromDate }) === -1 ? this.allSearchData.push({ categoryId: this.CategoryValue, name: updateFromDate }) : this.allSearchData;                              // for delete particular from selected category
          this.allSearchData.indexOf({ categoryId: this.CategoryValue, name: updateToDate }) === -1 ? this.allSearchData.push({ categoryId: this.CategoryValue, name: updateToDate }) : this.allSearchData;                              // for delete particular from selected category
          this.disabled = false;
        }
        this.dateflag = 1; // --- Flag for fist time date timeset
      }
    }
    else if (this.CategoryValue === 11) {                         // "lstVendorId": [0]
      const VendorName = $("#dtRange").val();
      if (this.flaginlist === true) {   // --- True when autocomplete data available from api ------------
        $('#searchTag').show();
        const VendorId = this.getNamebyID(VendorName, this.CategoryValue);
        this.SearchTag_List.push($("#dtRange").val());
        this.globalsearchObj.lstVendorId.indexOf(VendorId) === -1 ? this.globalsearchObj.lstVendorId.push(VendorId) : this.toastr.info('Already applied.');
        this.allSearchData.indexOf({ categoryId: this.CategoryValue, name: VendorName, Id: VendorId }) === -1 ? this.allSearchData.push({ categoryId: this.CategoryValue, name: VendorName, Id: VendorId }) : this.allSearchData;                    // for delete particular from selected category
        this.disabled = false;
      }
    }
    else if (this.CategoryValue === 12) {                         // "lstVisaCountry": [0]
      const Countryname = $("#dtRange").val();
      if (this.flaginlist === true) {   // --- True when autocomplete data available from api ------------
        $('#searchTag').show();
        const CountryId = this.getNamebyID(Countryname, this.CategoryValue);
        this.SearchTag_List.push($("#dtRange").val());
        this.globalsearchObj.lstVisaCountry.indexOf(CountryId) === -1 ? this.globalsearchObj.lstVisaCountry.push(CountryId) : this.toastr.info('Already applied.');
        this.allSearchData.indexOf({ categoryId: this.CategoryValue, name: Countryname, Id: CountryId }) === -1 ? this.allSearchData.push({ categoryId: this.CategoryValue, name: Countryname, Id: CountryId }) : this.allSearchData;                    // for delete particular from selected category
        this.disabled = false;
      }
    } 
    else if (this.CategoryValue === 13) {                         // ""lstVisaType": [0 ],
      const Visatype = $("#dtRange").val();
      
      if (this.flaginlist === true) {   // --- True when autocomplete data available from api ------------
        $('#searchTag').show();
        const VisatypeId = this.getNamebyID(Visatype, this.CategoryValue);
        this.SearchTag_List.push($("#dtRange").val());
        console.info(VisatypeId);
        //this.globalsearchObj.lstVisaType.indexOf(VisatypeId) === -1 ? this.globalsearchObj.lstVisaType.push(VisatypeId) : this.toastr.info('Already applied.');
        this.globalsearchObj.lstVisaType.indexOf(Visatype) === -1 ? this.globalsearchObj.lstVisaType.push(Visatype) : this.toastr.info('Already applied.');
        this.allSearchData.indexOf({ categoryId: this.CategoryValue, name: Visatype, Id: VisatypeId }) === -1 ? this.allSearchData.push({ categoryId: this.CategoryValue, name: Visatype, Id: VisatypeId }) : this.allSearchData;                   // for delete particular from selected category
        this.disabled = false;
      }
    }
    else if (this.CategoryValue === 14) {                         //  "lstVisaExecutiveId": [0]
      const Visaexecutive = $("#dtRange").val();
      if (this.flaginlist === true) {   // --- True when autocomplete data available from api ------------
        $('#searchTag').show();
        const VisaexecutiveId = this.getNamebyID(Visaexecutive, this.CategoryValue);
        this.SearchTag_List.push($("#dtRange").val());
        this.globalsearchObj.lstVisaExecutiveId.indexOf(VisaexecutiveId) === -1 ? this.globalsearchObj.lstVisaExecutiveId.push(VisaexecutiveId) : this.toastr.info('Already applied.');
        this.allSearchData.indexOf({ categoryId: this.CategoryValue, name: Visaexecutive, Id: VisaexecutiveId }) === -1 ? this.allSearchData.push({ categoryId: this.CategoryValue, name: Visaexecutive, Id: VisaexecutiveId }) : this.allSearchData;               // for delete particular from selected category
        this.disabled = false;
      }
    }
    this.catFilter = "";
  }
  //#endregion

  getNamebyID(searchvalue, catid) {  // -- Getting Id by passing name---
    // -- update firstarray by calling autocomplete ------------------
    this.Autocomplete(searchvalue, catid);
   console.info( this.firstarray);
    // ------- Chechking input data with firstarray & create object with ID & userName -----------
    for (let index = 0; index < this.firstarray.length; index++) {
      const name = this.firstarray[index].name;
      debugger;
      if (searchvalue === name) {
        this.searchvalueId = this.firstarray[index].id;
        return (this.searchvalueId);

      }
    }
  }

  getIDbyName(searchvalue, catid) {  // -- Getting name by passing Id---
    // -- update firstarray by calling autocomplete ------------------
    this.Autocomplete(searchvalue, catid);
    // ------- Chechking input data with firstarray & create object with ID & userName -----------
    for (let index = 0; index < this.firstarray.length; index++) {
      const name = this.firstarray[index].name;
      if (searchvalue === name) {
        this.searchvalueName = this.firstarray[index].name;
        return (this.searchvalueName);

      }
    }
  }

  Searchinallrecord(name) {
    for (let index = 0; index < this.allSearchData.length; index++) {
      const element = this.allSearchData[index];
      if (element.name === name) {
        return (element.Id);
      }
    }
  }

  catFilterDelFun(cat) {
  this.searchvalueId = null;
	console.info('this.globalsearchObj.lstVisaCountry',this.globalsearchObj.lstVisaCountry)

    for (let i = 0; i < this.SearchTag_List.length; i++) {
      if (this.SearchTag_List[i] === cat) {  // -- for delete by name use cat 
        this.SearchTag_List.splice(i, 1);
        // ---- Id of selected name by dropdown---
        this.searchvalueId = this.Searchinallrecord(cat);
		
		
			console.info('this.globalsearchObj.lstVisaType',this.globalsearchObj.lstVisaType);
			console.info('searchvalueId',this.searchvalueId);
			console.info('cat',cat);
			
		
		
		
        this.globalsearchObj.collectionFromDate = '';
        this.globalsearchObj.collectionToDate = '';
        this.globalsearchObj.applFromDate = '';
        this.globalsearchObj.applToDate = '';
        this.globalsearchObj.updateFromDate = '';
        this.globalsearchObj.updateToDate = '';
        if (this.globalsearchObj.collectionFromDate === '' || this.globalsearchObj.collectionToDate === '' || this.globalsearchObj.applFromDate === '' ||
          this.globalsearchObj.applToDate === '' || this.globalsearchObj.updateFromDate === '' || this.globalsearchObj.updateToDate === '') {
          this.dateflag = 0;
        }

        this.globalsearchObj.lstApplicantName.splice(this.globalsearchObj.lstApplicantName.indexOf(cat), 1);

        this.globalsearchObj.lstVisaCountry.splice(this.globalsearchObj.lstVisaCountry.indexOf(this.searchvalueId), 1);
        this.globalsearchObj.lstVisaCountry.push(this.globalsearchObj.lstVisaCountry);
        // this.globalsearchObj.lstCurrentStatus.indexOf(CurrentStatusId) === -1 ? this.globalsearchObj.lstCurrentStatus.push(CurrentStatusId) : this.toastr.info('Already applied.');           

        this.globalsearchObj.lstVisaExecutiveId.splice(this.globalsearchObj.lstVisaExecutiveId.indexOf(this.searchvalueId), 1);

        this.globalsearchObj.lstCurrentStatus.splice(this.globalsearchObj.lstCurrentStatus.indexOf(this.searchvalueId), 1);

        this.globalsearchObj.lstVisaType.splice(this.globalsearchObj.lstVisaType.indexOf(this.searchvalueId), 1);
        //this.globalsearchObj.lstVisaType.splice(this.globalsearchObj.lstVisaType.indexOf(cat), 1);

        this.globalsearchObj.lstBranch.splice(this.globalsearchObj.lstBranch.indexOf(this.searchvalueId), 1);

        this.globalsearchObj.lstVendorId.splice(this.globalsearchObj.lstVendorId.indexOf(this.searchvalueId), 1);

        this.globalsearchObj.lstVisaCountry.splice(this.globalsearchObj.lstVisaCountry.indexOf(this.searchvalueId), 1);

        this.globalsearchObj.lstPassportNo.splice(this.globalsearchObj.lstPassportNo.indexOf(cat), 1);

        this.globalsearchObj.lstBarcode.splice(this.globalsearchObj.lstBarcode.indexOf(cat), 1);

        this.globalsearchObj.lstIcust.splice(this.globalsearchObj.lstIcust.indexOf(cat), 1);

        this.globalsearchObj.lstPNR.splice(this.globalsearchObj.lstPNR.indexOf(cat), 1);
        this.sendsearchData();           // -- Search API CALL --
      }
    }
  }


  sendsearchData() {
    // if (this.globalsearchObj.fromDate === "" || this.globalsearchObj.toDate === "") {
    //   delete this.globalsearchObj.fromDate;
    //   delete this.globalsearchObj.toDate;
    // }

    //console.log(JSON.stringify(this.globalsearchObj));
    $("#visaLoader").show();
console.info('globalsearchObj' +this.globalsearchObj)
    this.searchservice._sendSearchData(this.globalsearchObj)
  
      .subscribe(
        (data: any) => {
          console.info("SearchTag_List==",data);
          $("#visaLoader").hide();
          this.tempobj = [];
          this.applicationlist = [];
          this.tempobj.push(data.result);
          this.applicationlist = data.result;
          // -- for pagination
          this.totalcount = data.totalCount;
          this.totalNoofpages = Math.ceil(data.totalCount / this.paginationSize);
          // this.searchservice.SetSearchTag(this.SearchTag_List);  // Sending via service to global search
          this.searchservice.setData(this.applicationlist);
         // this.searchservice.setData(data);
          this.searchservice.setPagination(this.totalcount, this.totalNoofpages, this.paginationSize, data.rowCount,this.currentPage);
          const len = this.applicationlist.length;
          this.disabled = true;
          this.CategoryValue = 0;

         
          
          $("#dtRange").val();  // clearing search textbox
         // this.href = this.urlRoute.snapshot; // router.url;this._route.snapshot.queryParams.id
         this.router.navigate([this.visaUserType + '/Dummy']);
        },
        error => {
          $("#visaLoader").hide();
          this.err = error;
          this.toastr.error('Error :' + this.err.statusText);
        });
  }
 
  getCountryData() {
    

        this._visaService._getVisaFeesCountryList()
        .subscribe(result => {
          var data = { result };
          this.visaCountryData = data;
          this.visaCountry=1089;
         
        },
          (err: HttpErrorResponse) => {
            console.error(err.message);    // SHOW ERRORS IF ANY.
          });
  }


  VisaCountryType($event) {
  
    console.warn($event.target.value);
    console.warn(this.visaCountry);
  }

  MoveVisaApplication()
  {
   
    if(this.visaCountry==0)
    {
      alert("Select Visa Country");
      $('#visaCountry').focus();
     
    }
    else if(this.visaCountry!=1089) {
     //this.router.navigate(['/' + this.visaUserType + '/addVisaApplication'], { queryParams: { id: this.visaCountry } });
     this.router.navigate(['/' + this.visaUserType + '/addB2bApplication'], { queryParams: { id: this.visaCountry } });
     console.info('addB2bApplication')
      //$('#addnewVisaApplication').removeClass("in");
      $('#addnewVisaApplication').modal("hide");
     }
   else{
    this.router.navigate(['/' + this.visaUserType + '/DubaiVisaApplication'], { queryParams: { id: this.visaCountry } });
    $('#addnewVisaApplication').modal("hide");
      }
  }
  public FilterChecked($ev)
  {
  
 
    if($ev.target.value!=0 && $ev.target.value!=1)
    {
     localStorage.setItem('uaeApptype',$ev.target.value);
     this.searchservice.CurrentFilter($ev.target.value);
    }
    else{
     localStorage.setItem('uaeApptype',$ev.target.value);
      this.searchservice.CurrentFilter(this.filerstype);
    }
    
  }
  public VisaStatusFilters()
  {
   
  
    $("#visaLoader").show();
   let activePath= window.location.pathname.split('/')[2];
   if(activePath!='VisaApplications')
   {
    
    let nav_array={30:'Open',31:'Document_Pending',32:'Document_Complete',33:'Not_Posted_Emigration',34:'Posted_Emigration',35:'Hold_Has_Resident',36:'Hold_Has_Valid_Visa',37:'Hold_Inside_Emir_by_Visa',38:'Hold_Other',39:'Uae_Approved',40:'Close',42:'Uae_Rejected'};
    if(this.filterData.Status!=0)
    {

      let status =nav_array[this.filterData.Status];
     
      this.router.navigate([this.visaUserType+'/VisaApplications',status]);
    }
    else{
      this.router.navigate([this.visaUserType+'/VisaApplications','Open']);
      //this.searchservice.SendDubaiVisaSearch(this.filterData);
    }
    
   }
   else{
    this.searchservice.SendDubaiVisaSearch(this.filterData);
   
   }
   $("#visaLoader").hide();
   $('#dubaiStatusfilters').modal('hide');
   this.filterData={
    PNRNo:'',
    PassportNo:'',
    FirstName:'',
    fromdateTime:'',
    todateTime:'',
    Status:0,
    CIPV:'0',
    page:0,
    Pagesize:10,
    VisaCountry:1089
   
  };

  }
  getStatus()
  {
  
    this.searchservice._getDubaiVisaStatus().subscribe((res:any)=>{
     
        this.status=res;
    
      
    },
    err=>{

    });
  }
  toggleChangedUae(val:any)
  {
   debugger;
    this.apptoggleopen=val;
    if(val==true)
    {
      this.filerstype=2;
      localStorage.setItem('uaeApptype',"2");
     this.searchservice.CurrentFilter(2);
    }
    else{
      this.filerstype=1;
      localStorage.setItem('uaeApptype',"1");
      this.searchservice.CurrentFilter(1);
    }
    
  }
   public addnewCountryvisa($event)
   {
   
   
   /*$('#addnewVisaApplication').modal("show");
    $event.preventDefault();
   // $('#visaCountry').removeAttr('disabled');
    $('.ng-valid').prop("disabled", false);
*/

this.router.navigate([this.visaUserType+"/addB2bApplication"]);
    this.activePath= window.location.pathname.split('/')[2];
    console.info('activePath : '+this.activePath);
    
   }

public viewvisalist($event)
{
  
    /*if(this.apptoggleopen==true)
    {
    
      this.router.navigate([this.visaUserType+"/VisaApplications/Open"]);
    }
    else{
      this.router.navigate([this.visaUserType+"/VisaApplications/New"]);
    }*/

    this.router.navigate([this.visaUserType+"/B2bVisalist"]);//combined all countries
    this.activePath= window.location.pathname.split('/')[2];
    console.info('activePath : '+this.activePath);
   
}

}

