import { Component, OnInit,ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpResponse, } from '@angular/common/http';
import { AddVisaService } from 'src/app/service/addVisaSerivce';
import { HttpErrorResponse } from '@angular/common/http/src/response';
//import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { Router,ActivatedRoute } from "@angular/router";
import {GlobalServiceService} from '../../service/global-service.service';
import { FormBuilder, FormGroup, Validators, FormControl, RequiredValidator,NgForm } from '@angular/forms';
import { forEach } from '@angular/router/src/utils/collection';
import { VFSVASFeesService } from 'src/app/service/vfs-vfs.service';
import {AdddubaivisaService } from '../../service/adddubaivisa.service'
import { DatePipe } from '@angular/common';
import { NULL_EXPR } from '@angular/compiler/src/output/output_ast';
import { Console } from 'console';
declare function nexttab_click(ctl); 
declare function gototab_click(ctl); 

declare var $: any;
export enum visaStatusMaster {
  Open=30,
  FreeStructure=41,
  Draft=43
}
@Component({
  selector: 'app-dubai-add-app',
  templateUrl: './dubai-add-app.component.html',
  styleUrls: ['./dubai-add-app.component.css']
})
export class DubaiAddAppComponent implements OnInit {
  @ViewChild('dubaiVisaFees') dubaiVisaFees: NgForm;
  getCust: any = {};
  token: any;
  issueingMaxDate:Date;
  //public dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();
  visaUserType: string = null;
  private localData: any;
  visaObj: any = {
    visaCustomer: {},
    lstApplicationStatus: [],
    lstVisaMiscFees: [],
    lstVisaApplBranch: [],
    lstVisaPassangerDetail:[],
    lstVisaVFSVASFees: []
  };
  locationData: any = {};
  customerDetailsData: any = { result: {} };
  UserBranchData: any = [];
  userbranch:number=0;
  countryData: any = [];
  visaCountryData:any=[];
  transferBranchData: any = [];
  visaMiscChargesData: any = {};
  filteredVisaMiscChargesData:any={};
  visaOfficeBoyData: any = {};
  CustomerData: any = [];
  passengerFiles=[];
  addDoc: any = {
    docName1: "Document1",
    docName2: "Document2",
    docName3: "Document3",
    docName4: "Document4",
    docName5: "Document5"
  };
  lstVisaMiscFees: any = {
    mscAmount1: null,
    mscAmount2: null,
    mscAmount3: null,
    mscAmount4: null,
    mscAmount5: null,
    mscAmount6: null
  };
  currentCust:any;
  creditLimit: number = null;
  visaTypeData: any = [];
  visaProcessingOptData = [];
  vfsVasFeesAdded:{
    id: number,
    vfsVasFeesId: number,
    quantity: number,
    amount: number
  }[]=[];
 // visaVfsVasChargesData:any[]=[];
  countryid:any=0;
  professionData: any = [];
  PassportTypeData: any = [];
  issueDate:any;
  issueDays:number;
  expDays:number;
  today:any = new Date();

  maxDate: Date;
  minDate: Date;
  passportExipryMaxDate:Date;
  dobMinDate:Date;
  travellingMaxDate:Date;
  travelDate:any;
  travelDays:number;
  ReligionData: any = [];
  faithData: any = [];
  EducationData: any = [];
  LangData: any = [];
  _statusMaster = visaStatusMaster;
  emailMsg: any;
  emailValidationStatus: boolean;
  passportfrontfileSize:any;
  passfrontsidefile:any;
  passfrontsms:string="";
  passportbackfileSize:any;
  passbacksidefile:any;
  passbacksms:string;
  allowimgextensions:any=["jpg","jpeg","JPG","JPEG"];
  fileExtension:any;
  fileExtensionback: any;
  ImpCase:boolean=false;
  ImpCaseval:string ="";
  MaritalStatus:string="";
  PanMsg: string='';
  myFiles: any = [];
  lstPassangerDetails:any=[];
  public placename='name';
  public countries:any=[];
  passportExp: any;
  
  forntPassportFiles:File[];
  backPassportFiles:File[];
  passportPage1Src:any;
  passportPage2Src:any;
  passportfrontserverfile:any;
  passportbackserverfile:any;
  passportimagupload:boolean=false;
  serviseTypes: any=[];
  lstdubaiVisaFeeApplicationStatus:any={ };
  paxDOB: number;
  dobDate:any;
  visaFeesCharge1Right:boolean = true;
  visaFeesCharge2Right:boolean = true;
  visaFeesCharge3Right:boolean= true;
  travedatechecked:boolean=false;
  panNovalidate:boolean=false;
  currentdate:any = new Date();
  constructor(private _http: HttpClient, private _visaService: AddVisaService, private _toastr: ToastrService, private _router: Router,
    private formBuilder: FormBuilder,public _globle:GlobalServiceService,private _VFSVASFeesService: VFSVASFeesService,private _activeroute: ActivatedRoute,private dubaivisaservice:AdddubaivisaService,private datePipe: DatePipe) { 
  
   this.localData = JSON.parse(localStorage.getItem('currentUser'));
    if(this.localData==null)
    {
      this._router.navigate(['/']);
    }
    else{
      this.token = this.localData.token;
      this.visaUserType = this.localData.userType;
       

         this.countryid= 1089;
       // this.countryid=this._activeroute.snapshot.paramMap.get('id');
       this.getCust.locationId=null;
       this.visaObj.fK_MstProcessingOptionsId = 0;
       this.visaObj.fK_educationid=0;
       this.visaObj.fK_FaithName=0;
       this.visaObj.fK_langid=0;
       this.visaObj.Sign=0;
       this.visaObj.Ecrtype=0;
       this.visaObj.Pantype=0;
       this.visaObj.Insurance=0;
       this.visaObj.gender=0;
       this.visaObj.PaxType=0;
       this.visaObj.fk_comingCountryId=0;
       this.visaObj.MaritalStatus=0;
       this.visaObj.fk_Personalnationalid=0;
       this.visaObj.fk_PersonalPRVnationalid=0;
       this.visaObj.fK_ReligionId=0;
       this.visaObj.fK_AppBranchId=this.localData.userBranchId;
       this.lstdubaiVisaFeeApplicationStatus.FK_Chargeid1=0;
       this.lstdubaiVisaFeeApplicationStatus.FK_Chargeid2=0;
       this.lstdubaiVisaFeeApplicationStatus.FK_Chargeid3=0;

        }
       // $('#btnAddApp').hide();
        //$('#btnViewApp').show();
      
      
    }

  ngOnInit() {
   //Api call
   console.info('ngOnInit')
  
   this.getCustomerData();
   this.getLocationData();
    this._getProcessingOptionsByCountryid();
    this.getUAEVisatype();
    this.getBranchData();
    this.getCountryData();
    this. getUAEPassportType();
    this. getUAErofession();
    this.getReligion();
    this. getEdugation();
    this. getLanguages();
    this.getVisaServiceType();
    var self=this;

    this.emailMsg = "Please enter valid Email ID";
    $(function() {
    $(document).on('change','.passengerdocpath',function(event){
     
      let this_id=$(this).attr('id');
      console.info('upload');
      self.getFileDetailsPass(event);
     
  
      });

      $(document).on('change','.passengerdoctype',function(event){

        self.filetypechange(event)
       
    
        });
      });
   
  }


  
  filetypechange(e) {
    let ids=e.target.id;          
    var docid = ids.split("_").pop(); // => "Tabs1"

    $('.required').fadeOut();
    var docCode=$('#doctype_'+docid).val();
    var filepath=$('#uploads_'+docid);

    debugger;
    if(docCode!="" && docCode!=null)
    {

var IndexPosition=this.passengerFiles.findIndex(x=>x.docid==docid);
this.passengerFiles[IndexPosition].URL=docCode;
this.passengerFiles[IndexPosition].documentName=docCode;


console.info(this.passengerFiles) 
    }
  }
  
  getFileDetailsPass(e) {
    //var passid =e.target.id.split('_')[1];

   

var Files=[];
    let ids=e.target.id;          
    var docid = ids.split("_").pop(); // => "Tabs1"

    $('.required').fadeOut();
    var docCode=$('#doctype_'+docid).val();
    var filepath=$('#uploads_'+docid);

   /* var delIndexPosition=this.passengerFiles.findIndex(x=>x.docid==docid);
    if(delIndexPosition>0)
    {
      this.passengerFiles.splice(delIndexPosition, 1);  
    }*/
   

    debugger;
    if(docCode==""|| docCode==null)
    {
      $("#reqdocumenttype_"+docid).fadeIn();
        
      return false;
    }
    else
    {
      let headers = new HttpHeaders();
      headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
      var formData = new FormData();
      formData.append(docCode, e.target.files[0]);
      this._http.post(this._visaService.ApiUrl + "/VisaApplDocument/UploadDocument", formData, { headers: headers } )
      .subscribe((successData:any) => {
        
        var tempObj = {
          docid:docid,
          URL:docCode,
          documentName:docCode,
          availability:null,
          fileName:successData.result.fileName,
          bucketName:successData.result.bucketName,
          encryptionKey:successData.result.encryptionKey,
          contentType:successData.result.contentType,
        }
       
        this.passengerFiles.push(tempObj);
        console.info(this.passengerFiles);
  debugger;
      });
    }
    
  }


  dobValidate(dob: Date): void {
    this.dobDate = dob;
    this.paxDOB = (this.dobDate-this.today)/1000/60/60/24;
    if(this.paxDOB>1) {
      this._toastr.error("Invalid date of birth");
    }
  }
  
  ngAfterContentChecked(){
    //console.info("after view init")
    this.minDate = new Date();
    this.maxDate = new Date();
    
   this.issueingMaxDate=new Date();
   this.passportExipryMaxDate=new Date();
   this.dobMinDate=new Date();
   this.travellingMaxDate=new Date();
   //this.dpConfig.containerClass = 'theme-blue'; //or whatever color
   //this.dpConfig.dateInputFormat = 'DD/MM/YYYY';
   this.minDate.setDate(this.minDate.getDate());
   this.maxDate.setDate(this.maxDate.getDate());
   this.passportExipryMaxDate.setDate(this.passportExipryMaxDate.getDate() + 3650);
   this.dobMinDate.setDate(this.dobMinDate.getDate()-36500);
   this.travellingMaxDate.setDate(this.travellingMaxDate.getDate()+3700);

   //console.info('load datepicker')
   $("#expirdateTime").datepicker({ minDate: this.minDate,maxDate:this.passportExipryMaxDate, 
    dateFormat: 'dd/mm/yy',
  changeMonth: true,
      changeYear: true,
      showButtonPanel: false,
  firstDay: 1,});
  $("#dob").datepicker({ maxDate: this.maxDate,minDate:this.dobMinDate, dateFormat: 'dd/mm/yy',
  changeMonth: true,
      changeYear: true,
      showButtonPanel: false,
  firstDay: 1,});
  $("#travel-date").datepicker({ minDate: this.minDate,maxDate:this.travellingMaxDate, dateFormat: 'dd/mm/yy',
  changeMonth: true,
      changeYear: true,
      showButtonPanel: false,
  firstDay: 1,});

  $("#issuedateTime").datepicker({ maxDate:this.maxDate, 
    dateFormat: 'dd/mm/yy',
  changeMonth: true,
      changeYear: true,
      showButtonPanel: false,
  firstDay: 1,});

     

  }

  savePassenger( ) {    
    
    var validcheck=true;

    //var validcheck=this._globle.validateAddPassenger();
    var validcheck=this._globle.validateUaeVisaFormPassenger(this.passfrontsidefile, this.passbacksidefile,this.ImpCase,this.MaritalStatus,this.panNovalidate)

    

    
    if(validcheck==false) {
      this._toastr.warning("Please fill all the required fields!");
      
      return false;
    }
  
}

  getCustomerData(){
    
    this._visaService._getCustDetailsByUser()
    .subscribe((successData: any) => {
        var temp = { successData };
       console.info(successData.result.responseData[0]);
      this.CustomerData=successData.result.responseData[0];
      /*$('#cust-name').val(this.CustomerData.name);
      $('#cust-id').val(this.CustomerData.custNo);
      $('#cust-type').val(this.CustomerData.customerType);
      $('#cust-loc').val(this.CustomerData.location);
      $('#phone-num').val(this.CustomerData.phone);*/
      this.bindCustData(this.CustomerData);
     // $('#phone-num').val(this.CustomerData.location);
   
      },
        (err: HttpErrorResponse) => {
          console.error(err.message);    // SHOW ERRORS IF ANY.
        });
  }

  AddVisaDetails(ctl)
  {
    

    var validcheck=true;

    var validcheck=this._globle.validatevisainfouae(this.ImpCase);

   debugger;
   
    if(validcheck==false) {
     // this._toastr.warning("Please fill all the required fields!");
      debugger;
      return false;
    }
    else{
    /*  this.branch_name=$( "#UserBranch option:selected" ).text();
      this.country_name=$( "#visaCountry option:selected" ).text();
      this.visa_type=$( "#visaType option:selected" ).text();
 
  this.processingoption=$( "#mstprocessingOptions option:selected" ).text();*/
  this.visaObj.travelDate = $('#travel-date').val();
  debugger;
   nexttab_click(ctl);

    }
    
  }

  continueBranch(ctl)
  {
   
    nexttab_click(ctl);
  }


  continuePassenger(ctl)
  {
    var validcheck=true;

  $('.required').fadeOut();

  this.assignformvalues();
  var validcheck=true;

  //var validcheck=this._globle.validateAddPassenger();
  var validcheck=this._globle.validateUaeVisaFormPassenger(this.passfrontsidefile, this.passbacksidefile,this.ImpCase,this.MaritalStatus,this.panNovalidate)

  if(validcheck)
  {
    const passportExpireddatechk= new Date(this.datePipe.transform(this.currentdate.setMonth(new Date().getMonth() + 6),'M/d/yy')); 
    const exppassrport=new Date(this.dateFormatChange(this.visaObj.expirdateTime));
   // const diffDays = Math.round(Math.abs((exppassrport.getDay(*) - passportExpireddatechk) / oneDay));
   if(this.travelDays<1) {
    validcheck=false
     this._toastr.error("Invalid travel date");
    } 
  else if(this.travelDays>this.expDays){
    validcheck=false
        this._toastr.error("Your Passport is Expired!");
    }
  else if(passportExpireddatechk>=exppassrport)
    {
      validcheck=false
     this._toastr.error("check passport expiry date!");
    }
  else{
  
   if(this.travelDays<3) {
    validcheck=false
     this._toastr.info("Less than 3 days left for travel!");
    }
  
   if(this.travelDays>90) {
    validcheck=false
   this._toastr.info("The travel is later than 3 months!");
   }
    
  }
  }
  

  
  if(validcheck==false) {
    this._toastr.warning("Please fill all the required fields!");
    
    return false;
  }
  else{nexttab_click(ctl);}


    /*if(this.lstPassanger!=null)
    {
    let cnt=this.lstPassanger.length;
    if(cnt>0)
    {
      nexttab_click(ctl);
    }
    else{ validcheck = false;}


    }
else{
  validcheck = false;
}
  
if(!validcheck)
{
  $(".nopassenger.required").fadeIn();
}
*/

  }


  getBranchData(){
    
    this._visaService._GetUserBranchList()
    .subscribe((successData: any) => {
        var temp = { successData };
        this.UserBranchData = successData.result;
        this.userbranch=this.UserBranchData[0].branchId;
          
          
        this.visaObj.fK_AppBranchId=this.userbranch ;
        if(this.UserBranchData.length>1){
          $('#divUserBranch').show();
        }
        else{
          $('#divUserBranch').hide();
        }
      },
        (err: HttpErrorResponse) => {
          console.error(err.message);    // SHOW ERRORS IF ANY.
        });
  }

  getLocationData() {
    this._visaService._getLocationList()
      .subscribe(result => {
        this.locationData = result;
      },
        (err: HttpErrorResponse) => {
          console.error(err.message);    // SHOW ERRORS IF ANY.
        });
  }
  getCountryData() {
  
    this._visaService._getCountryList()
      .subscribe(result => {
        var data = { result };
        this.countryData = data;
        this.countries=result;
        console.warn(this.countries);
        this.visaObj.fk_PassIssueCountryid=this.countryData.result.find(x=>x.countryCode=="IN").id;
        this.visaObj.fk_birthCountryId=this.countryData.result.find(y=>y.countryCode=="IN").id;
       
        this.visaObj.fK_NationalityMasterId=this.countryData.result.find(x=>x.countryCode=="IN").id; // for deafault country India
        this.visaObj.fk_comingCountryId=this.countryData.result.find(t=>t.countryCode=="IN").id;
        this.visaObj.fk_Personalnationalid=this.countryData.result.find(n=>n.countryCode=="IN").id;
        this.visaObj.fk_PersonalPRVnationalid=this.countryData.result.find(pr=>pr.countryCode=="IN").id;
        
      },
        (err: HttpErrorResponse) => {
          console.error(err.message);    // SHOW ERRORS IF ANY.
        });


        // this._visaService._getVisaFeesCountryList()
        // .subscribe(result => {
        //   var data = { result };
        //   this.visaCountryData = data;
        //   this.visaObj.visaCustomer.fK_NationalityMasterId=this.countryData.result.find(x=>x.countryCode=="IN").id; // for deafault country India
        // },
        //   (err: HttpErrorResponse) => {
        //     console.error(err.message);    // SHOW ERRORS IF ANY.
        //   });
  }

  
 
  getCustomerDeatilsPopUp() {
    this.getCust.pattern = $("#searchCustPattern").val();
    console.warn(this.getCust.pattern);
  }
  

  getCustDetailsById() {
    
    $("#btnGetICUSTDetails").prop("disable",true);
      $('#loaderGetDetails').show();
    this.getCust.locationName=this.locationData.result.find(x=>x.id==this.getCust.locationId).erpLocCode;
   // var test=this.locationData.result.find(x=>x.id==this.getCust.locationId);
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    this._http.get(this._visaService.ApiUrl + "/VisaApplication/GetCustomer?Loc=" + this.getCust.locationName + "&pattern=" + this.getCust.pattern, { headers: headers })
      .subscribe((successData: any) => {
       $("#btnGetICUSTDetails").prop("disable",false);
        if (successData.status == true) {
          if (successData.result.responseData.length > 0) {
            $('#custDetailsTable').show();
            
            successData.result.responseData.forEach(element => {
              console.info("customergroup==",element.customerGroup);
              
              switch(element.customerGroup){
                case "RI":
                  element.customerGroup="RI"; 
                  break; 
                  case "RC":
                    element.customerGroup= "Riya Corporate"; 
                    break;
                  case "RS":
                    element.customerGroup= "Riya Sub Agent"; 
                    break;
                  case "RE":
                    element.customerGroup= "Riya SME"; 
                    break;
                  case "RA":
                    element.customerGroup= "Riya Agent"; 
                    break;
                  default:
                    element.customerGroup= "New Type"; 
                    break;
               
              }
            
          });
            this.customerDetailsData = successData.result.responseData;
            
            
          }
          else {
            this._toastr.warning('No Data Found for Given Search Result');
          }
          //console.log(successData);
        }else{        
          this._toastr.warning(successData.messsage);
        }
        $('#loaderGetDetails').hide();
      },
        (err: HttpErrorResponse) => {
          $("#btnGetICUSTDetails").prop("disable",true);
          $('#loaderGetDetails').hide();
          if(err.status==400){
            this._toastr.error(err.statusText);
          }else{
            this._toastr.error(err.message);
          }
          console.error(err);    // SHOW ERRORS IF ANY.
        });
  }

  bindCustomerDetails(cust) { 
    this.creditLimit = null;
    this.currentCust = cust;
    this._visaService._getCreditLimit(cust.no_).subscribe((z:any)=>{
      if(z.status && z.result && z.result.length > 0){
        this.creditLimit = +z.result.split('-')[0];
        // if (this.creditLimit <=0) {
          $('#CloseGetCustPop').click();
          $('#creditLimitAv').modal({backdrop: 'static', keyboard: false});
          $("#creditLimitAv").modal('show');
          // if(window.confirm("Available credit limit for: "+ cust.no_ + " is " + this.creditLimit + 
          // ". Do you wish to continue ?")){
          //   this.bindCustData(cust);
          //  }
        // }else{
        //   this.bindCustData(cust);
        // }
      }else{
        this.bindCustData(cust);
      }
    })
   
  }
  bindCustData(cust){
    $('#cust-loc').val(this.getCust.locationName);
            this.visaObj.customerName = cust.name;
            this.visaObj.icust = cust.no_;
            this.visaObj.customerType = cust.customerType;
            this.visaObj.customerLocation = cust.location;
            this.visaObj.customerPhoneNumber = cust.phone;
            this.visaObj.customerEmail=cust.email;
            this.visaObj.customerGroup=cust.customerGroup;
            this.visaObj.customerSEZ=cust.sez;
        
            this.visaObj.customerNo=cust.custNo;
            this.visaObj.custPostingGroup=cust.postingGroup;
            this.visaObj.divisionCode=cust.divisionCode;
           

            $('#transferBranch option[value="1101"]').prop('disabled',true);
        
            // if(this.visaObj.customerLocation=="BOM"){
            //   $('#transferBranch option[value="1101"]').prop('disabled',true);
            // }
        
            $('#CloseGetCustPop').click();
          //  $(".cancel-email").click(); // making empty of email ids textbox
          //  var emails=cust.email.split(";");
          //  for(var i=0;i<emails.length;i++){
          //   var getValue = emails[i];
          //   $('.all-mail').append('<span class="email-ids">' + getValue + ' <span class="cancel-email">x</span></span>');
          //  }
  }

  closeCustomerCreditLimitModal(boo){
    $("#creditLimitAv").modal('hide');
    if(boo){
      this.bindCustData(this.currentCust);
    }else{
     // this.bindCustData(this.currentCust);
      $("#GET-ID").modal('show');
    }
  }


  hideValidationError(inputId) {
	  $("."+inputId+".required").fadeOut();
  }

  onIssueDateChange(tDate: Date): void {
    if(tDate!=null) {
      console.info('expiry date==',this.expDays);
      this.issueDate = tDate;
      this.issueDays   = (this.issueDate-this.today)/1000/60/60/24;
     if(this.issueDays<1) {
       this._toastr.error("Invalid travel date");
      } else if(this.issueDays<3) {
       this._toastr.info("Less than 3 days left for travel!");
      } else if(this.issueDays>90) {
       this._toastr.info("The travel is later than 3 months!");
      }else if(this.issueDays>this.expDays){
          this._toastr.error("Your Passport is Expired!");
      }
    }

    // if(days<91) {
    //   this._toastr.info("Passport is expiring within 3 months!");
    // }
  }
   onPassExpDate(pExp: Date): void {
    this.passportExp = pExp;
    this.expDays = (this.passportExp-this.today)/1000/60/60/24;
    console.info(this.expDays);
   
  }

  onTravelDateChange(tDate: Date): void {
    debugger;
    if(tDate!=null && this.travedatechecked==false) {
      console.info('expiry date==',this.expDays);
      this.travelDate = tDate;
      this.travelDays   = (this.travelDate-this.today)/1000/60/60/24;
     if(this.travelDays<1) {
       this._toastr.error("Invalid travel date");
      } else if(this.travelDays<3) {
       this._toastr.info("Less than 3 days left for travel!");
      } else if(this.travelDays>90) {
       this._toastr.info("The travel is later than 3 months!");
      }else if(this.travelDays>this.expDays){
          this._toastr.error("Your Passport is Expired!");
      }
    }

    // if(days<91) {
    //   this._toastr.info("Passport is expiring within 3 months!");
    // }
  }
  
  _getProcessingOptionsByCountryid() {

    this.visaObj.fK_MstProcessingOptionsId = 0;
    this.visaObj.fK_VisaTypeId=0;
    this.visaObj.fK_VendorId=0;
   
    if(this.countryid!=null && this.countryid!=0){
      this.visaObj.fK_VisaCountryMasterId=this.countryid;
    this.dubaivisaservice._getProcessingOptionsByCountryid(this.visaObj.fK_VisaCountryMasterId)
      .subscribe((successData: any) => {
        if (successData.status == true) {

          this.visaProcessingOptData = successData.result;

          console.info('this.visaProcessingOptData====5',this.visaProcessingOptData);
          

        }else{
         
          $("#mstprocessingOptions").attr('disabled',false);
        }
      },
        (err: HttpErrorResponse) => {
          $("#mstprocessingOptions").attr('disabled',false);
         
          console.log(err.message);    // SHOW ERRORS IF ANY.
        });

      }

  }

  EchannelArabicConversion(name,filedName)
  {
     
     
     this.dubaivisaservice.SChannelArabicConversion(name).subscribe((res:any)=>{
     // console.warn("fateh"+res);
       if(filedName=="sChannelArabicFirstName")
       {

        this.visaObj.sChannelArabicFirstName=res;
       $('#sChannelArabicFirstName').val(res);
       }
       else if(filedName=="sChannelArabicMiddleName")
       {
         this.visaObj.sChannelArabicMiddleName=res;
         $('#sChannelArabicMiddleName').val(res);
       }
       else if(filedName=="sChannelArabicLastName")
       {
         this.visaObj.sChannelArabicLastName=res;
         $('#sChannelArabicLastName').val(res);
       }
       else if(filedName=="sChannelArabicPassportIssuePlace")
       {
        this.visaObj.sChannelArabicPassportIssuePlace=res;
        $('#sChannelArabicPassportIssuePlace').val(res);
       }
       else if(filedName=="sChannelArabicMotherName")
       {
        this.visaObj.sChannelArabicMotherName=res;
        $('#sChannelArabicMotherName').val(res);
       }
       else if(filedName=="sChannelArabicBirthPlace")
       {
        this.visaObj.sChannelArabicBirthPlace=res; 
        $('#sChannelArabicBirthPlace').val(res);
       }
       else {
         console.warn("not found");
       }

     }
    //  err=>{
    //   console.warn("Error"+err);
    //    }
     )

  }
 getUAEVisatype()
 {
  
   this.visaObj.fK_VisaTypeId=0;
  this.dubaivisaservice._getUAEVisaType().subscribe((res:any)=>{
    if (res.status == true) {
      this.visaTypeData = res.result;
     }
     else{
      this.visaTypeData = [];
      console.warn("Not found UAE Visa Type");
     }
    },
    err=>{
      console.warn("Error"+err);
      });
   
  }     
  getUAErofession()
  {
   
    this.visaObj.fK_ProfessionId=0;
   this.dubaivisaservice.getUAErofession().subscribe((res:any)=>{
     if (res.status == true) {
       this.professionData = res.result;
      }
      else{
       this.professionData = [];
       console.warn("Not found UAE Visa Type");
      }
     },
     err=>{
       console.warn("Error"+err);
       });
    
   }  
 
 getUAEPassportType()
  {
   

   this.dubaivisaservice.getUAEPassportType().subscribe((res:any)=>{
     if (res.status == true) {
       this.PassportTypeData = res.result;
       this.visaObj.fK_PassportId=this.PassportTypeData.find(p=>p.typeName=="Normal").id;
      }
      else{
       this.PassportTypeData = [];
       console.warn("Not found UAE Visa Type");
      }
     },
     err=>{
       console.warn("Error"+err);
       });
    
   }  

   getReligion()
   {
    
    this.visaObj.fK_ReligionId=0;
    this.dubaivisaservice.getReligion().subscribe((res:any)=>{
      if (res.status == true) {
        this.ReligionData = res.result;
       }
       else{
        this.ReligionData = [];
        console.warn("Not found Religion");
       }
      },
      err=>{
        console.warn("Error"+err);
        });
     
    }  
getFaithByReligionName(name:string)
    {
    
     this.visaObj.fK_FaithName=0;
     this.dubaivisaservice.getFaithByReligionName(name).subscribe((res:any)=>{
       if (res.status == true) {
         this.faithData = res.result;
        }
        else{
         this.faithData = [];
         console.warn("Not found faith");
        }
       },
       err=>{
         console.warn("Error"+err);
         });
      
     }  
     
 getEdugation()
     {
      
      this.visaObj.fK_educationid=0;
      this.dubaivisaservice.getEducationlist().subscribe((res:any)=>{
        if (res.status == true) {
          this.EducationData = res.result;
         }
         else{
          this.EducationData = [];
          console.warn("Not found Education");
         }
        },
        err=>{
          console.warn("Error"+err);
          });
       
      }  
 getLanguages()
     {
      
     
      this.dubaivisaservice.getAllLangauges().subscribe((res:any)=>{
        if (res.status == true) {
          this.LangData = res.result;
          this.visaObj.fK_langid=this.LangData.find(l=>l.id==1).id;
         }
         else{
          this.LangData = [];
          console.warn("Not found Languages");
         }
        },
        err=>{
          console.warn("Error"+err);
          });
       
      }  
 checkEmails(){
      
        var regExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        var emails = $("#emailId").val();
        //console.log(emails);
        var emailArray = emails.split(";");
        var invEmails = "";
        if(emailArray.length > 7) {
          this.emailMsg = "only 7 email id allowed";
        } else {
          for(var i = 0; i <= (emailArray.length - 1); i++){
            if(regExp.test(emailArray[i])){
              this.emailMsg = "Email validated!";
              this.emailValidationStatus = true;
              //console.log(this.emailError);
            }else{
              invEmails += emailArray[i] + "\n";
            }
          }
        }
        
        if(invEmails.trim() != ""){
          this.emailMsg = "Invalid emails:\n" + invEmails;
          this.emailValidationStatus = false;
          //console.log(this.emailError);
        }
        // else{
        //   this.emailMsg =null;
        // }
      }


 passportUploadfrontSide($event)
 {
   
   let passfrontside=$event.target.files[0];
   this.passfrontsidefile=passfrontside;
   this.forntPassportFiles=$event.target.files;

  
   
   // passportfrontfileSize
       if(passfrontside)
        {
          this.fileExtension=this.passfrontsidefile.name.split('.').pop();
        //  this.fileExtensionback=this.passbacksidefile.name.split('.').pop();

          this.passportfrontfileSize=(Math.round(passfrontside.size*100 / 1024) /100);
          if(this.passportfrontfileSize>95)
          {
            this.passfrontsms="Max 95 KB file Upload then this file Size:"+this.passportfrontfileSize+":KB"; 
            $('#passportfrontside').val('');
          }
         else if(!this.dubaivisaservice.isimgArray(this.allowimgextensions,this.fileExtension))
          {
            alert('Only front Side JPEG and JPG file are allowed');
            $('#passportfrontside').val('');
          }
          else {
            this.passfrontsms="";
           // Array.from(this.forntPassportFiles).forEach(p=>this.passportformdata.append('passportfrontside',p));
          }
         
        }
        else{
          alert("Choose File the passport front side");
        }
 }
 passportUploadBackSide($event)
 {
 
 
  let passbackside=$event.target.files[0];
  this.passbacksidefile=passbackside;
  this.backPassportFiles=$event.target.files;
   if(passbackside)
   {
            this.fileExtension=this.passfrontsidefile.name.split('.').pop();
            this.fileExtensionback=this.passbacksidefile.name.split('.').pop();
         this.passportbackfileSize=(Math.round(passbackside.size * 100 / 1024) /100);
          if(this.passportbackfileSize>95)
          {
            this.passbacksms="Max 95 KB file Upload then this file Size:"+this.passportbackfileSize+":KB"; 
            $('#passportBackside').val('');
          }
          else if(!this.dubaivisaservice.isimgArray(this.allowimgextensions,this.fileExtension))
          {
            alert('Only front Side JPEG and JPG file are allowed');
            $('#passportBackside').val('');
          }
          else {
            this.passbacksms="";
           // Array.from(this.backPassportFiles).forEach(p1=>this.passportformdata.append('passportBackside',p1));
          }
   }
   else {
    alert("Choose File the passport back side");
   }

 }

 passportUploadFiles()
 {
  
   try{
    
    let passportformdata=new FormData();
     if(this.passfrontsidefile!=null && this.passbacksidefile!=null)
     {
      if(this.passportfrontfileSize>95 || this.passportbackfileSize>95)
      {
        alert("File size exceeded,file size not allowed more than 95KB");

      }
     else {
       this.fileExtension=this.passfrontsidefile.name.split('.').pop();
       this.fileExtensionback=this.passbacksidefile.name.split('.').pop();
        if(!this.dubaivisaservice.isimgArray(this.allowimgextensions,this.fileExtension))
        {
          alert('Only front Side JPEG and JPG file are allowed');
        }
        else{
          Array.from(this.forntPassportFiles).forEach(p=>passportformdata.append('passportfrontside',p));
          Array.from(this.backPassportFiles).forEach(p1=>passportformdata.append('passportBackside',p1));
         
        //Upload Passport
        $('#visaLoader').show();
        this.dubaivisaservice._uploadDubaiVisaPassport(passportformdata).subscribe((resp:any)=>{
         
          $('#visaLoader').hide();
          console.log("this"+resp);
          if(resp.status==true)
          {
            this.passportimagupload=true;
            this.passportfrontserverfile=resp.result.passportforntside;
            this.passportbackserverfile=resp.result.passportbacksideside;
            this.passportPage1Src=resp.result.passportPath+resp.result.passportPage1+"//"+resp.result.passportforntside;
            this.passportPage2Src=resp.result.passportPath+resp.result.passportPage2+"//"+resp.result.passportbacksideside;
          
            $('#fileimg-PassportFrontSide').attr('src',  this.passportPage1Src);
            $('#fileimg-PassportBackSide').attr('src',  this.passportPage2Src);
           
            var passportfirst=this.myFiles.find(x=>x.URL=="PassportFrontSide");
  
            if(passportfirst==undefined)
            {
              var tempObj = {
                URL:"PassportFrontSide",
                documentName:"Passport Page-1",
                availability:"Original",
                fileName:resp.result.passportforntside,
                bucketName:resp.result.passportPage1,
                encryptionKey:resp.result.encryptionKey,
                contentType:resp.result.frontFileContent,
              }
              this.myFiles.push(tempObj);
            }
            else{
              let index = this.myFiles.findIndex(x=>x.URL=="PassportFrontSide");
              this.myFiles.splice(index,1);

              var tempObj = {
                URL:"PassportFrontSide",
                documentName:"Passport Page-1",
                availability:"Original",
                fileName:resp.result.passportforntside,
                bucketName:resp.result.passportPage1,
                encryptionKey:resp.result.encryptionKey,
                contentType:resp.result.frontFileContent,
              }
              this.myFiles.push(tempObj);
            }

          


            var passportsecond=this.myFiles.find(x=>x.URL=="PassportBackSide");
            if(passportsecond==undefined)
            {
              var tempObj = {
                URL:"PassportBackSide",
                documentName:"Passport Page-2",
                availability:"Original",
                fileName:resp.result.passportbacksideside,
                bucketName:resp.result.passportPage2,
                encryptionKey:resp.result.encryptionKey,
                contentType:resp.result.backFileContent,
              }
              this.myFiles.push(tempObj);
            }
            else{
              let index = this.myFiles.findIndex(x=>x.URL=="PassportBackSide");
              this.myFiles.splice(index,1);

              var tempObj = {
                URL:"PassportBackSide",
                documentName:"Passport Page-2",
                availability:"Original",
                fileName:resp.result.passportbacksideside,
                bucketName:resp.result.passportPage2,
                encryptionKey:resp.result.encryptionKey,
                contentType:resp.result.backFileContent,
              }
              this.myFiles.push(tempObj);
            }


           
            
            $("#fileimg-PassportFrontSide").show();
            $("#fileimg-PassportBackSide").show();
            $('#imgDel-PassportFrontSide').show();
            $('#imgDel-PassportBackSide').show();
            $('#file-PassportFrontSide').hide();
            $('#file-PassportBackSide').hide();
            

          
            if(resp.result.ocrpassportpage1!='' && resp.result.ocrpassportpage2!='')
            {
                 if(resp.result.ocrpassportpage1.data!=null && resp.result.ocrpassportpage2.data!=null)
                 {
                
                   // console.log(y);
                    debugger;
                    console.log(resp.result.ocrpassportpage1.data.dob);
                    this.visaObj.dob=resp.result.ocrpassportpage1.data.dob;
                    this.visaObj.issuedateTime=resp.result.ocrpassportpage1.data.doi;
                    this.visaObj.expirdateTime=resp.result.ocrpassportpage1.data.doe;
                    this.visaObj.gender=resp.result.ocrpassportpage1.data.gender=='F'? 'Female' :'Male'
                    this.visaObj.PersonalFirstName=resp.result.ocrpassportpage1.data.given_name!=null ? resp.result.ocrpassportpage1.data.given_name:'';
                    this. EchannelArabicConversion(this.visaObj.PersonalFirstName,'sChannelArabicFirstName');

                    this.visaObj.PersonalMiddleName=resp.result.ocrpassportpage1.data.surname!=null? resp.result.ocrpassportpage1.data.surname:'';
                    this. EchannelArabicConversion(this.visaObj.PersonalMiddleName,'sChannelArabicMiddleName');

                    this.visaObj.PersonalLastName=resp.result.ocrpassportpage2.data.father!=null ? resp.result.ocrpassportpage2.data.father:'' ;
                    this. EchannelArabicConversion(this.visaObj.PersonalLastName,'sChannelArabicLastName');
                   
                   
                    this.visaObj.fatherName=resp.result.ocrpassportpage2.data.father!=null ? resp.result.ocrpassportpage2.data.father:'' ;
                    this.visaObj.PersonalMotherName=resp.result.ocrpassportpage2.data.mother!=null? resp.result.ocrpassportpage2.data.mother:'';
                    this. EchannelArabicConversion(this.visaObj.PersonalMotherName,'sChannelArabicMotherName');
                    this.visaObj.passportNo=resp.result.ocrpassportpage1.data.passport_num!=null? resp.result.ocrpassportpage1.data.passport_num:'';
                    this.visaObj.PassportIssuePlace=resp.result.ocrpassportpage1.data.place_of_issue!=null? resp.result.ocrpassportpage1.data.place_of_issue:'';
                    this. EchannelArabicConversion(this.visaObj.PassportIssuePlace,'sChannelArabicPassportIssuePlace');
                   let birthplace=resp.result.ocrpassportpage1.data.place_of_birth!=null? resp.result.ocrpassportpage1.data.place_of_birth.split(',')[0]:'';
                   let birthState=resp.result.ocrpassportpage1.data.place_of_birth.split(',')[1]!=null? resp.result.ocrpassportpage1.data.place_of_birth.split(',')[1]:'';
                   
                   
                    this.visaObj.PersonalBirthPlace=birthplace+' '+birthState.trim();
                    this. EchannelArabicConversion(this.visaObj.PersonalBirthPlace,'sChannelArabicBirthPlace');

                    this.visaObj.HusbandName=resp.result.ocrpassportpage2.data.spouse!=null ? resp.result.ocrpassportpage2.data.spouse:'' ;
                
                 }
          
         

            } 


          }
          else {
            this.passportimagupload=false;
            this.passportPage1Src="";
            this.passportPage2Src="";
            this.passportfrontserverfile="";
            this.passportbackserverfile="";
          }
         /*  debugger;
            $("#file-PassportFrontSide").html(this.passportPage1Src);
            $("#file-PassportBackSide").html(this.passportPage2Src); */

        },
        err=>{
          $('#visaLoader').hide();
       console.log(err);
        })
          
     


     
       
       //end


          }

    
      
         }

       }
     else {
      alert("Please Choose both side passports");
        }

    }
   catch(err)
   {
    $('#visaLoader').hide();
      console.error(err,[this.passfrontsidefile]);
    }

 }
 importantCase($event)
 {
   
   this.ImpCase=$event.target.checked;
   this.ImpCaseval=$event.target.value;
  
 }
 getMaritalStatus($event)
 {
   this.MaritalStatus=$event.target.value;
 }

 checkEmailpassenger(e)
 {
   var regExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
   var emails =e.target.value;
   console.info(emails);
   var emailArray = emails.split(";");
   var invEmails = "";
   if(emailArray.length > 7) {
     this.emailMsg = "only 7 email id allowed";
   } else {
     for(var i = 0; i <= (emailArray.length - 1); i++){
       if(regExp.test(emailArray[i])){
         this.emailMsg = "Email validated!";
         this.emailValidationStatus = true;
         //console.log(this.emailError);
       }else{
         invEmails += emailArray[i] + "\n";
       }
     }
   }
   
   if(invEmails.trim() != ""){
     this.emailMsg = "Invalid emails:\n" + invEmails;
     this.emailValidationStatus = false;
     //console.log(this.emailError);
   }
   // else{
   //   this.emailMsg =null;
   // }

 }
 checkPanno(){

  var regExp = /^([a-zA-Z]([a-zA-Z]([a-zA-Z]([a-zA-Z]([a-zA-Z]([0-9]([0-9]([0-9]([0-9]([a-zA-Z])?)?)?)?)?)?)?)?)?)?$/;
  var panno = $("#PancardNo").val();
   if(this.visaObj.Pantype=='With PAN')
   {
  var PannoArray = panno.split(";");
  var invpanNo = "";
  if(panno.length<10) {
    this.panNovalidate=true;
  } else {
    for(var i = 0; i <= (PannoArray.length - 1); i++){
      if(regExp.test(PannoArray[i])){
        this.PanMsg = "Pan No validated!";
        this.panNovalidate=false;
      }else{
        invpanNo += PannoArray[i] + "\n";
        this.panNovalidate=true;
       
      }
    }
  }
  
  if(invpanNo.trim() != ""){
    this.PanMsg = "Invalid Pan No:\n" + invpanNo;
    this.panNovalidate=true;
   // $('#btnvisaSave').prop('disabled', true);
  }
  else{
    if(panno.length<10)
    {
      this.PanMsg = "Invalid Pan No:\n" + invpanNo;
     // $('#btnvisaSave').prop('disabled', true);
    }
    else{
    this.PanMsg="";
    this.panNovalidate=false;
   // $('#btnvisaSave').prop('disabled', false);
    }
   
  }
  }
  else{
    this.panNovalidate=false;
   // $('#btnvisaSave').prop('disabled', false);
  }
}
getVisaCustomerByPassportNo($event){

  this._visaService._getVisaCustomerByPassportNo($event.target.value)
  .subscribe((successData:any)=>{
    if(successData.status==true && successData.result!=null){
      var response=successData.result;
      this.visaObj.visaCustomer.Id=0;
      this.visaObj.visaCustomer.passportNumber=response.passportNumber;
      this.visaObj.visaCustomer.givenName=response.givenName;
      this.visaObj.visaCustomer.surname=response.surname;
      this.visaObj.visaCustomer.mobileNo=response.mobileNo;
      this.visaObj.gender=response.gender;
      // if(response.passportExpiry!=null && response.passportExpiry!=undefined && response.passportExpiry!=""){
      //   this.visaObj.visaCustomer.passportExpiry = this.dateFormatChange(successData.result.passportExpiry);
      // }
      // if(response.dateOfBirth!=null && response.dateOfBirth!=undefined && response.dateOfBirth!=""){
      //   this.visaObj.visaCustomer.dateOfBirth =  this.datePipe.transform(response.dateOfBirth,'M/d/yyyy');
      // }
    }
    else{
      this.visaObj.visaCustomer.Id=0;
      this.visaObj.visaCustomer.passportNumber=this.visaObj.passportNo;
      this.visaObj.visaCustomer.givenName=this.visaObj.PersonalFirstName;
      this.visaObj.visaCustomer.surname=this.visaObj.PersonalLastName;
      this.visaObj.visaCustomer.mobileNo=this.visaObj.visaCustomer.mobileNo;
      this.visaObj.visaCustomer.Gender=this.visaObj.gender;
      // this.visaObj.visaCustomer.passportExpiry = this.datePipe.transform(this.visaObj.expirdateTime,'M/d/yyyy');
      // this.visaObj.visaCustomer.dateOfBirth =  this.datePipe.transform(this.visaObj.dob,'M/d/yyyy');


    }
  },
    (err: HttpErrorResponse) => {
      console.error(err.message);    // SHOW ERRORS IF ANY.
    });
}

getFileUploadDetails(e, uploadDocName, docCode)
{
  debugger;
  console.warn("e==",e.target);
  console.warn("eid=",e.target.id);
  var passanger;
  let fileSized=(Math.round(e.target.files[0].size * 100 / 1024) /100);
  

  if(fileSized>95)
  {
     alert("Max 95 KB file Upload then this file Size:"+fileSized+":KB"); 
  }
  else{
  if(uploadDocName==''||uploadDocName==null){
    //uploadDocName=$('#docName_'+e.target.id.split('-')[1]).val(); 
    uploadDocName=e.target.id.split('-')[1]; 
   }
  
   
    var typedd =e.target.files[0].name;
    var abc= typedd.split('.');
    
    this.fileExtension=typedd.split('.').pop();
    this.fileExtensionback=typedd.split('.').pop();
   if(!this.dubaivisaservice.isimgArray(this.allowimgextensions,this.fileExtensionback))
    {
      alert('Only  JPEG and JPG file are allowed');
      $("#file-" + docCode).val('');
    }
    else{

      if(docCode==''||docCode==null){
        passanger=true;
        docCode=e.target.id.split('-')[1];
        //$("#" + docCode).parent().find('.fa-upload').removeClass('fa-upload').addClass('fa-spinner fa-spin');
      }
      else{
       $("#file-" + docCode).parent().find('.fa-upload').removeClass('fa-upload').addClass('fa-spinner fa-spin');
       }
    
    // let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + `${this.token}`);
    var formData = new FormData();
    formData.append(docCode, e.target.files[0]);
    this._http.post(this._visaService.ApiUrl + "/VisaApplDocument/UploadDocument", formData, { headers: headers } )
    .subscribe((successData:any) => {
      
     // console.log(successData);
      var count = this.myFiles.length;
      for (var i = 0; i < count; i++) {
        if (this.myFiles[i].URL == docCode) {
          // this.myDocFilesType.splice(i, 1);
          this.myFiles.splice(i, 1);
        }
      }
      //var documentName=this._visaService..find()
      // this._VisaDocuments.
        var tempObj = {
          URL:docCode,
          documentName:uploadDocName,
          availability:'Original',
          fileName:successData.result.fileName,
          bucketName:successData.result.bucketName,
          encryptionKey:successData.result.encryptionKey,
          contentType:successData.result.contentType,
        }
        
        this.myFiles.push(tempObj);

        console.info("this.myFiles",this.myFiles);
      
          $("#file-" + docCode).parent().find('.fas').removeClass('fa-spinner fa-spin');
        
        // show file previw start from here
        var string2='image';
        if(tempObj.contentType.indexOf(string2) != -1){
  
         // console.log(string2 + "found");
          $('#fileimg-'+docCode).show();
          $("#file-"+docCode).hide();
          $('#fileimg-'+docCode).attr('src', this._visaService.ApiUrl.split("/api")[0]+"/Upload/Tempdocument/"+tempObj.bucketName+"/"+tempObj.fileName);
        }else{
          $('#fileimg-'+docCode).hide();
          $("#file-" + docCode).parent().find('.fas').addClass('fa-file-upload');
        }
      
      
        $('#imgDel-' + docCode).show();
        $("#imgbrows-"+docCode).hide();
      

    },
      (err: HttpErrorResponse) => {
        $("#file-" + docCode).parent().find('.fas').removeClass('fa-spinner fa-spin');
        this._toastr.error("Document Upload Failed");
        console.error(err);    // SHOW ERRORS IF ANY.
      })
     }
   }



}

delUploadedDoc(documentName,documentCode){
 
  var delDocObj =  this.myFiles.find(x=>x.URL==documentCode);
  var delIndexPosition=this.myFiles.findIndex(x=>x.URL==documentCode);
   if(delDocObj!=undefined && delIndexPosition>-1){
       this.dubaivisaservice._delUploadedDocument(0,delDocObj.bucketName,delDocObj.fileName)
       .subscribe((successData:any)=>{
           if(successData.status==true){
              this._toastr.success(successData.messsage);
             
              $('#imgDel-'+documentCode).hide();
              $('#imgUpload-'+documentCode).hide();
              
              $("#fileimg-" + documentCode).hide();
               $("#file-"+ documentCode).parent().find('.fas').addClass('fa-upload');
              this.myFiles.splice(delIndexPosition, 1);  // delete   uploaded doc from object
              //console.log(this.myFiles);
              if(documentCode=="PassportFrontSide")
              {
                this.passfrontsidefile=null;
                
              }
              if(documentCode=="PassportBackSide")
              {
                this.passbacksidefile=null;
              }

              $("#file-"+ documentCode).val('');
           }
       },
       (err: HttpErrorResponse) => {
         this._toastr.error('Error in Deleting the Document!');
         console.log (err);    // SHOW ERRORS IF ANY.
       })
   }

  
   $('.'+documentCode).hide();
}
dateFormatChange(dateData) {
  debugger;
  if (dateData!="" && dateData!="Invalid date" && dateData!=null && dateData.length<=11) {
    return dateData.split('/')[1] + '/' + dateData.split('/')[0] + '/' + dateData.split('/')[2];
  }
  else 
  {
 return this.datePipe.transform(dateData,'M/d/yyyy');
  }
}

assignformvalues()
{
  this.visaObj.passportNo=$('#passportNo').val();
  this.visaObj.fK_PassportId=$('#PassportType').val();
  this.visaObj.issuedateTime=$('#issuedateTime').val();
  this.visaObj.expirdateTime=$('#expirdateTime').val();
  this.visaObj.PassportIssuePlace=$('#PassportIssuePlace').val();
  this.visaObj.fk_PassIssueCountryid=$('#fk_PassIssueCountryid').val();
  this.visaObj.Ecrtype=$('#Ecrtype').val();
  this.visaObj.Sign=$('#Sign').val();
  this.visaObj.Pantype=$('#Pantype').val();
  this.visaObj.Insurance=$('#Insurance').val();
  this.visaObj.PersonalFirstName=$('#PersonalFirstName').val();
  this.visaObj.PersonalMiddleName=$('#PersonalMiddleName').val();
  this.visaObj.PersonalLastName=$('#PersonalLastName').val();
  this.visaObj.gender=$('#gender').val();
  this.visaObj.dob=$('#dob').val();
  this.visaObj.fk_birthCountryId=$('#fk_birthCountryId').val();
  this.visaObj.fK_NationalityMasterId=$('#fK_NationalityMasterId').val();
  this.visaObj.PaxType=$('#PaxType').val();
  this.visaObj.customerEmail=$('#emailId').val();
  this.visaObj.visaCustomer.mobileNo=$('#mob-num').val();

  this.visaObj.fatherName=$('#fatherName').val();
  this.visaObj.PersonalMotherName=$('#PersonalMotherName').val();
  this.visaObj.HusbandName=$('#HusbandName').val();
  this.visaObj.MaritalStatus=$('#MaritalStatus').val();
  this.visaObj.PersonalBirthPlace=$('#PersonalBirthPlace').val();
  this.visaObj.PancardNo=$('#PancardNo').val();
  this.visaObj.fK_ProfessionId=$('#ProfessionId').val();
  this.visaObj.barcodeno=$('#barcodeno').val();
  this.visaObj.fk_comingCountryId=$('#fk_comingCountryId').val();
  this.visaObj.sChannelArabicFirstName=$('#sChannelArabicFirstName').val();
  this.visaObj.sChannelArabicMiddleName=$('#sChannelArabicMiddleName').val();
  this.visaObj.sChannelArabicLastName=$('#sChannelArabicLastName').val();
  this.visaObj.sChannelArabicMotherName=$('#sChannelArabicMotherName').val();
  this.visaObj.sChannelArabicBirthPlace=$('#sChannelArabicBirthPlace').val();
  this.visaObj.sChannelArabicPassportIssuePlace=$('#sChannelArabicPassportIssuePlace').val();
  this.visaObj.fk_Personalnationalid=$('#fk_Personalnationalid').val();
  this.visaObj.fk_PersonalPRVnationalid=$('#fk_PersonalPRVnationalid').val();
  this.visaObj.fK_ReligionId=$('#fK_ReligionId').val();
  this.visaObj.fK_FaithName=$('#fK_FaithName').val();
  this.visaObj.fK_educationid=$('#fK_educationid').val();
  this.visaObj.fK_langid=$('#fK_langid').val();
  this.visaObj.fk_Personalnationalid=$('#fk_Personalnationalid').val();
  console.info(this.visaObj);
}

 saveUAEVisaApplication(statusMasterId,buttonId) {

  
  try {
    this.assignformvalues();
     debugger;
   //  const oneDay = 24 * 60 * 60 * 1000;
     const passportExpireddatechk= new Date(this.datePipe.transform(this.currentdate.setMonth(new Date().getMonth() + 6),'M/d/yy')); 
     const exppassrport=new Date(this.dateFormatChange(this.visaObj.expirdateTime));
    // const diffDays = Math.round(Math.abs((exppassrport.getDay(*) - passportExpireddatechk) / oneDay));
    if(this.travelDays<1) {
      this._toastr.error("Invalid travel date");
     } 
 else if(this.travelDays>this.expDays){
         this._toastr.error("Your Passport is Expired!");
     }
 else if(passportExpireddatechk>=exppassrport)
     {
      this._toastr.error("check passport expiry date!");
     }
 else{

    if(this.travelDays<3) {
      this._toastr.info("Less than 3 days left for travel!");
     }

    if(this.travelDays>90) {
    this._toastr.info("The travel is later than 3 months!");
    }
     
 
   
    debugger;
  var validUAEcheck=true;

  if(this.UserBranchData.length>1 && ( this.visaObj.fK_AppBranchId==undefined || this.visaObj.fK_AppBranchId==0 || this.visaObj.fK_AppBranchId==null)){
    $(".AppBranch.required").fadeIn();
    validUAEcheck = false;
  }
if(validUAEcheck==false) {
      this._toastr.warning("Please fill all the required fileds!!");
      return false;
    }
 //   var validUAEcheck=this._globle.validateUaeVisaFormPassenger(this.passfrontsidefile, this.passbacksidefile,this.ImpCase,this.MaritalStatus,this.panNovalidate)
 var validUAEcheck=this._globle.validateUaeVisaForm(this.passfrontsidefile, this.passbacksidefile,this.ImpCase,this.MaritalStatus,this.panNovalidate);

 if(validUAEcheck==true)
 {
    this.visaObj.lstVisaAppFollowUps=[];
    this.visaObj.lstVisaAppFollowUps=[{
      Remarks:this.visaObj.visaRemark,
      Fk_StatusId: statusMasterId
    }]

    if(this.visaObj.fK_AppBranchId!=0){
      this.visaObj.lstVisaApplBranch.push(
        {
          fK_BranchMasterId: this.visaObj.fK_AppBranchId
        })
    }

    if (this.visaObj.vendorCode != null) {
      this.visaObj.vendorName = '';
    }
    // file not upload  after working

    this.travedatechecked=true;
   
    console.info("this.myFiles==",this.myFiles);
        for (var i = 0; i < this.myFiles.length; i++) {

                     this.myFiles[i].availability=this.myFiles[i].URL;
      
     
                      if(this.dubaivisaservice._docList.find(x=>x.docCode==this.myFiles[i].URL)==undefined){
                        this.myFiles[i].documentName='';
                      }
                  else{
      
                     if(this.dubaivisaservice._docList.find(x=>x.docCode==this.myFiles[i].URL).documentName==undefined){
                     this.myFiles[i].documentName= '';
                  }else{
                  this.myFiles[i].documentName=this.dubaivisaservice._docList.find(x=>x.docCode==this.myFiles[i].URL).documentName;
                 }
      
                      }
  
    
        }

        var templstVisaDocs=this.myFiles;
        // for(var i=0;i<this.dubaivisaservice._docList.length;i++){
        //   var checkedAvailability=this.dubaivisaservice._docList[i].docCode;
        //   if(checkedAvailability!=undefined){
        //     // var docName=this.myFiles.find(x=>x.URL!==this._visaService._docList[i].docName);
        //       if(templstVisaDocs.length==0){
        //         templstVisaDocs.push({
        //             availability:checkedAvailability,
        //             DocumentName:this.dubaivisaservice._docList[i].documentName,
        //             URL:this.dubaivisaservice._docList[i].docCode
        //           })
        //       } else{
        //             //  for(var j=0; j<this.myFiles.length;j++){
        //                 if((templstVisaDocs.find(x=>x.URL===this.dubaivisaservice._docList[i].docCode))==undefined){
        //                   // var documentCode=this._visaService._docList.find(x=>x.URL===this._visaService._docList[i].docCode).Id;
        //                   var documentName =  this.dubaivisaservice._docList[i].documentName;
        //                     if(documentName ==undefined){
        //                       documentName = this.dubaivisaservice._docList[i].docCode;
        //                     }
        //                     templstVisaDocs.push({
        //                       availability:checkedAvailability,
        //                       DocumentName:documentName,
        //                       URL:this.dubaivisaservice._docList[i].docCode
        //                     })
        //                 }
        //             // }
        //       }
        //   }
        // }

      /*  for (var i = 0; i < templstVisaDocs.length; i++) {
          if(templstVisaDocs[i].documentName==''){
            templstVisaDocs[i].documentName=templstVisaDocs[i].URL; 
          }
        }

        this.visaObj.lstVisaDocument = templstVisaDocs;
*/
        this.visaObj.lstVisaDocument=this.passengerFiles;
       // this.visaObj.lstVisaPassangerDetail=[];
        
        
        var fK_StatusMasterId = this.dubaivisaservice._statusMaster.find(x => x.id == this._statusMaster.FreeStructure).id;
       

       // this.visaObj.travelDate =this.dateFormatChange(this.visaObj.travelDate); 
        //this.datePipe.transform(this.visaObj.travelDate,'M/d/yyyy');
        this.visaObj.ExpCollectionDate=this.dateFormatChange(this.visaObj.issuedateTime);
        this.visaObj.ExpectedProcessDate=this.dateFormatChange(this.visaObj.expirdateTime);
       // this.visaObj.dob=this.dateFormatChange(this.visaObj.dob);
       // this.today=this.datePipe.transform('01/02/2021','M/d/yyyy');

        //
        this.visaObj.visaCustomer.Id=this.visaObj.visaCustomer.Id;
        this.visaObj.visaCustomer.passportNumber=this.visaObj.passportNo.toUpperCase();
        this.visaObj.visaCustomer.givenName=this.visaObj.PersonalFirstName.toUpperCase();
        this.visaObj.visaCustomer.surname=this.visaObj.PersonalLastName.toUpperCase();
        this.visaObj.visaCustomer.mobileNo=this.visaObj.visaCustomer.mobileNo;
        this.visaObj.visaCustomer.Gender=this.visaObj.gender;
        this.visaObj.visaCustomer.PassengerEmail=null;
        this.visaObj.visaCustomer.passportExpiry=this.dateFormatChange(this.visaObj.expirdateTime);
        this.visaObj.visaCustomer.dateOfBirth=this.dateFormatChange(this.visaObj.dob);
        this.visaObj.visaCustomer.FK_NationalityMasterId=this.visaObj.fK_NationalityMasterId;

        this.visaObj.lstApplicationStatus = [];
        this.visaObj.lstApplicationStatus = [{
          fK_StatusMasterId: statusMasterId,
          remark: this.visaObj.visaRemark,
          visaFees: 0,
          vfsFees: 0,
          serviceCharges:0,
          DiscountedServCharge: 0,
          DiscountedServVal: 0,
          creditLimitAvailable: 0
        }]

        
        this.visaObj.lstVisaPassangerDetail=[];
        var  DubaiVisaAdd=[{
          address:'Mumbai',
          city:"Mumbai",
          pincode:"400059",
          country:"INDIA",
          insideUae:"Yes",
          buildingVill:"1",
          uEAFloor:"1",
          uAEFlat_Vill:"1",
          dubaiArea:"Bur Dubai",
          cityinsideUae:"Dubai",
          mobileinsideUse:"1234567890"
           }];

        var objVisaPassangerDetail={
          Id:0,
          Fk_VisaAppId:0,
          PassangerOrder:1,
          UniqueId:'',
          PassportNum:this.visaObj.passportNo.toUpperCase(),
          PassportExp:this.dateFormatChange(this.visaObj.expirdateTime),
          GivenName:this.visaObj.PersonalFirstName.toUpperCase(),
          Surname:this.visaObj.PersonalLastName.toUpperCase(),
          PlaceOfIssue:this.visaObj.PassportIssuePlace.toUpperCase(),
          DOB:this.dateFormatChange(this.visaObj.dob),
          PaxType:this.visaObj.PaxType.trim(),
          Gender:this.visaObj.gender.trim(),
          Fk_NationalityId:this.visaObj.fK_NationalityMasterId,
          Fk_ProfessionId:null,
          DocumentName:'',
          BucketName:'',
          FileName:'',
          ContentType:'',
          EncryptionKey:'',
          CreatedOn:this.datePipe.transform('01/02/2021','M/d/yyyy'),
          CreatedBy:0,
          ModifiedOn:this.datePipe.transform('01/02/2021','M/d/yyyy'),
          ModifiedBy:0,
          Markup_on_Base_Fare:'',
          fK_PassportId:this.visaObj.fK_PassportId,
          Issue_date:this.visaObj.ExpCollectionDate,
          Fk_PassportIssueCountryid:this.visaObj.fK_NationalityMasterId,
          ECRType:this.visaObj.Ecrtype,
          Signatures:this.visaObj.Sign,
          PanType:this.visaObj.Pantype,
          Insurance:this.visaObj.Insurance,
          MiddleName:this.visaObj.PersonalMiddleName!=null && this.visaObj.PersonalMiddleName!='' ? this.visaObj.PersonalMiddleName.toUpperCase() : '',
          Fk_BirthCountryid:this.visaObj.fk_birthCountryId,
          FatherName:this.visaObj.fatherName.toUpperCase(),
          MotherName:this.visaObj.PersonalMotherName.toUpperCase(),
          HusbandName:this.visaObj.HusbandName!=null && this.visaObj.HusbandName!=''? this.visaObj.HusbandName.toUpperCase():'',
          MaritalStatus:this.visaObj.MaritalStatus,
          BirthPlace:this.visaObj.PersonalBirthPlace,
          PanCardNo:this.visaObj.PancardNo!=null && this.visaObj.PancardNo!='' ? this.visaObj.PancardNo.toUpperCase():'',
          Fk_DUEProfessionId:this.visaObj.fK_ProfessionId,
          Fk_ComingCountryid:this.visaObj.fk_comingCountryId,
          ArebicFirstName:this.visaObj.sChannelArabicFirstName,
          ArebicMiddleName:this.visaObj.sChannelArabicMiddleName,
          ArebicLastName:this.visaObj.sChannelArabicLastName,
          ArebicMotherName:this.visaObj.sChannelArabicMotherName,
          ArebicBirthPlace:this.visaObj.sChannelArabicBirthPlace,
          ArebicpassportPlace:this.visaObj.sChannelArabicPassportIssuePlace,
          Fk_birthNationalityid:this.visaObj.fk_Personalnationalid,
          Fk_PreviousNationalityid:this.visaObj.fk_PersonalPRVnationalid,
          religionName:this.visaObj.fK_ReligionId,
          Fk_Faithid:this.visaObj.fK_FaithName,
          eid:this.visaObj.fK_educationid,
          Languid:this.visaObj.fK_langid,
          lstUEAAddress:DubaiVisaAdd
         }   
    


         this.visaObj.lstVisaPassangerDetail.push(objVisaPassangerDetail);
       // this.visaObj.lstVisaPassangerDetail=this.lstPassangerDetails;
        console.info("this.visaObj.lstPassangerDetails==",this.visaObj.lstVisaPassangerDetail);
        this.visaObj.applicationRef=this.datePipe.transform(new Date(),'yyMMddhhmmss');

   var DubaiVisaApplication={
    id:0,
    RiyaVisaPNR:'123456',
    ICUST:this.visaObj.icust,
    CustomerName:this.visaObj.customerName,
    CustomerType:this.visaObj.customerType,
    CustomerLocation:this.visaObj.customerLocation,
    CustomerPhoneNumber:this.visaObj.customerPhoneNumber,
    CustomerGroup:this.visaObj.customerGroup,
    CustomerEmail:this.visaObj.customerEmail,
    CustomerSEZ:this.visaObj.customerSEZ,
    RefNo:'',
    XONumber:this.visaObj.xONumber==undefined ? '':this.visaObj.xONumber,
    AgentType:'',
    Entries:'',
    FK_VisaTypeId:0,
    BarcodeNumber:this.visaObj.barcodeno==undefined ? '':this.visaObj.barcodeno,
    ERP_Id:'',
    New_ERP_Id:'',
    ERP_Request:'',
    ApplicationSource:'',
    TravelDate:this.dateFormatChange(this.visaObj.travelDate),
    ImpCase:this.ImpCaseval,
    PassportPlaceofIssue:this.visaObj.PassportIssuePlace,
    PaxType:this.visaObj.PaxType,
    ExpCollectionDate:this.visaObj.ExpCollectionDate,
    FK_BranchMasterId:0,
    Fk_OrignalBranchId:0,
    FK_TransferBranchId:0,
    FK_OldTransferBranchId:0,
    FK_DeliveryPartnerId:0,
    FK_VisaCountryMasterId:this.countryid,
    FK_ProfessionId:null,
    FK_MstProcessingOptionsId:this.visaObj.fK_MstProcessingOptionsId,
    FK_UserDetailID:0,
    FK_VendorId:null,
    ExpectedProcessDate: this.visaObj.ExpectedProcessDate,
    CourierPartner:'',
    TrackingNumber:'',
    DeliveryReferenceNo:'',
    DeliveryDate:null,
    ERPPushStatus:false,
    CreatedOn:this.datePipe.transform('01/02/2021','M/d/yyyy'),
    CreatedBy:0,
    ModifiedOn:this.datePipe.transform('01/02/2021','M/d/yyyy'),
    ModifiedBy:0,
    CostCenter:this.visaObj.costcenter==undefined ? '':this.visaObj.costcenter,
    EMPCode:this.visaObj.empcode==undefined ? '':this.visaObj.empcode,
    Location:this.visaObj.location==undefined ? '':this.visaObj.location,
    Department:this.visaObj.department==undefined ? '':this.visaObj.department,
    BPCode:this.visaObj.bpcode==undefined ? '':this.visaObj.bpcode,
    CompanyCode:this.visaObj.companycode==undefined ? '':this.visaObj.companycode,
    CustomerNo: this.visaObj.customerNo,
    DivisionCode:this.visaObj.divisionCode,
    CustPostingGroup: this.visaObj.custPostingGroup,
    ErrorMsg:'',
    lstVisaDocument:this.visaObj.lstVisaDocument,
    lstVisaAppFollowUps:this.visaObj.lstVisaAppFollowUps,
    lstApplicationStatus:this.visaObj.lstApplicationStatus,
    lstVisaPassangerDetail:this.visaObj.lstVisaPassangerDetail,
    lstVisaMiscFees:[],
    lstVisaApplBranch:this.visaObj.lstVisaApplBranch,
    lstVisaVFSVASFees:[],
    FK_Uaeid:this.visaObj.fK_VisaTypeId,
    visaCustomer:this.visaObj.visaCustomer,
    FK_VisaCustomerId:this.visaObj.visaCustomer.Id,
    VisaAppType:'Dubai Application',
    ApplicationRef:this.visaObj.applicationRef
        }  
     
      $('#'+buttonId).prop('disabled', true);
      $('#visaLoader').show();
      console.info(DubaiVisaApplication)
       this.dubaivisaservice.saveDubaiVisaApplication(DubaiVisaApplication).subscribe((res:any)=>{
      
        $('#visaLoader').hide();
        $('#' + buttonId).prop('disabled',false);
        if(res.warning==true){
          $('#' + buttonId).prop('disabled', false);  
          this._toastr.warning(res.messsage);
          return false;

        }
        else if(res.status==false){
          $('#' + buttonId).prop('disabled', false);  
          this._toastr.error(res.messsage);
          return false; 
        }

        else if(res.status==false){
          $('#' + buttonId).prop('disabled', false);  
          this._toastr.error(res.messsage);
          return false; 
        }
        else{
         
           if(res.result!=null)
           {
          this.lstdubaiVisaFeeApplicationStatus={
            CurrentStatus:false,
            FK_VisaApplicationId:res.result.dubaiAppId,
            FK_StatusMasterId:fK_StatusMasterId,
            FK_Chargeid1:0,
            FK_Chargeid2:0,
            FK_Chargeid3:0

          }
          // this.lstdubaiVisaFeeApplicationStatus.FK_Chargeid1=0;
          // this.lstdubaiVisaFeeApplicationStatus.FK_Chargeid2=0;
          // this.lstdubaiVisaFeeApplicationStatus.FK_Chargeid3=0;
          this.visaFeesCharge1Right=true;
          this.visaFeesCharge2Right=true;
          this.visaFeesCharge3Right=true;
          this.clearDataBindings();

          this._toastr.success('Visa Application Saved Succesfully!');
          //$("#DubaiVisa-Charges").modal('show');
         //this._router.navigate([this.visaUserType + '/B2BPayment'], { queryParams: { id:this.visaObj.applicationRef}});
       
          this._router.navigate([this.visaUserType + '/B2bAppSuccess'], { queryParams: { id: this.visaObj.applicationRef}});  
          }
          else {
            this._toastr.error(res.messsage);
          }

         

        }
        
       },
       err=>{
        $('#' + buttonId).prop('disabled', false);  
        $('#visaLoader').hide();
        console.warn(err);
          }
       )
        
       
      
      }
     else{
       // alert("Please fill all the required fileds!");
        this._toastr.warning("Please fill all the required fileds!!");
        return false;
         }
  }
       }
   catch(exception)
       {
    console.info(exception,[this.visaObj]);
       }


   }

   clearDataBindings() {
    this.travedatechecked=false;
    this.visaObj.visaCustomer = {};
    
    this.visaObj.paxType = 0;
    this.visaObj.passportPlaceofIssue = '';
    this.visaObj.icust='';
    this.visaObj.customerName='';
    this.visaObj.customerLocation='';
    this.visaObj.customerPhoneNumber='';
    this.visaObj.xONumber='';
    this.visaObj.visaCustomer.mobileNo='';
    this.visaObj.customerGroup='';
    this.visaObj.costcenter='';
    this.visaObj.empcode='';
    this.visaObj.location='';
    this.visaObj.department='';
    this.visaObj.bpcode='';
    this.visaObj.companycode='';
    this.visaObj.customerEmail='';
    this.visaObj.fK_MstProcessingOptionsId=0;
    this.visaObj.fK_VisaTypeId=0;
    this.visaObj.passportNo='';
    this.visaObj.fK_PassportId=this.PassportTypeData.find(p=>p.typeName=="Normal").id;
    this.visaObj.issuedateTime='';
    this.visaObj.expirdateTime='';
    this.visaObj.PassportIssuePlace='';
    this.visaObj.fk_PassIssueCountryid=this.countryData.result.find(x=>x.countryCode=="IN").id;
    this.visaObj.fk_birthCountryId=this.countryData.result.find(y=>y.countryCode=="IN").id;
   
    this.visaObj.fK_NationalityMasterId=this.countryData.result.find(x=>x.countryCode=="IN").id; // for deafault country India
    this.visaObj.fk_comingCountryId=this.countryData.result.find(t=>t.countryCode=="IN").id;
    this.visaObj.fk_Personalnationalid=this.countryData.result.find(n=>n.countryCode=="IN").id;
    this.visaObj.fk_PersonalPRVnationalid=this.countryData.result.find(pr=>pr.countryCode=="IN").id;
    this.visaObj.Ecrtype='0';
    this.visaObj.Sign='0';
    this.visaObj.Pantype='0';
    this.visaObj.Insurance='0';
    this.visaObj.PersonalFirstName='';
    this.visaObj.PersonalMiddleName='';
    this.visaObj.PersonalLastName='';
    this.visaObj.gender='0';
    this.visaObj.dob='';
    this.visaObj.fatherName='';
    this.visaObj.PersonalMotherName='';
    this.visaObj.HusbandName='';
    this.visaObj.MaritalStatus='0';
    this.visaObj.PersonalBirthPlace='';
    this.visaObj.PancardNo='';
    this.visaObj.fK_ProfessionId=0;
    this.visaObj.travelDate='';
    this.visaObj.barcodeno='';
    this.visaObj.sChannelArabicFirstName='';
    this.visaObj.sChannelArabicMiddleName='';
    this.visaObj.sChannelArabicLastName='';
    this.visaObj.sChannelArabicMotherName='';
    this.visaObj.sChannelArabicBirthPlace='';
    this.visaObj.sChannelArabicPassportIssuePlace='';
    this.visaObj.fK_ReligionId='0';
    this.visaObj.fK_FaithName=0;
    this.visaObj.fK_educationid=0;
    this.visaObj.fK_langid=this.LangData.find(l=>l.id==1).id;
    this.visaObj.visaRemark='';
    // code to clear the uploaded documets
    this.passportimagupload=false;
    this.passportfrontserverfile='';
    this.passportbackserverfile='';
    this.passportPage1Src='imageSrc';
    this.passportPage2Src='imageSrc';
    $('#passportfrontside').val('');
    $('#passportBackside').val('');
    this.passfrontsidefile=null;
    $("#file-PassportFrontSide").val('');
    this.passbacksidefile=null;
    $("#file-PassportBackSide").val('');
    $("#file-photo").val('');
   
    for (var i = 0; i < this.myFiles.length; i++) {
      $('#imgDel-' + this.myFiles[i].URL).hide();
      $('#fileimg-'+this.myFiles[i].URL).hide();
      $("#file-" + this.myFiles[i].URL).parent().find('.fas').removeClass('fa-file').addClass('fa-upload');

    }
    this.myFiles = [];

  }


  selectEvent(item) {
    // do something with selected item
  }

  onChangeSearch(search: string) {
    // fetch remote data from here
   
  }

  onFocused(e) {
    // do something
  }
  
  onAddDubaiVisaFees(form,formData)
  {
    
   
   
    
     $('#btndubaiviasfees').prop('disabled', true);
     $('#visaLoader').show();
    
    try{
       this.dubaivisaservice.saveDubaiVisaFeesAdd(formData).subscribe((res:any)=>{
        $('#btndubaiviasfees').prop('disabled', false);
        $('#visaLoader').hide();
        this.dubaiVisaFees.enabled;
         if(res.status==false)
         {
          this._toastr.error(res.messsage);
         }
         else {
          this._toastr.success('Visa Fees  Saved Succesfully!');
        
          this.lstdubaiVisaFeeApplicationStatus.FK_Chargeid1=0;
          this.lstdubaiVisaFeeApplicationStatus.FK_Chargeid2=0;
          this.lstdubaiVisaFeeApplicationStatus.FK_Chargeid3=0;
          this.lstdubaiVisaFeeApplicationStatus.Processing_Fee=null;
          this.lstdubaiVisaFeeApplicationStatus.ServiceCharges=null;
          this.lstdubaiVisaFeeApplicationStatus.VisaFees=null;
          this.lstdubaiVisaFeeApplicationStatus.Charge_Amount1=null;
          this.lstdubaiVisaFeeApplicationStatus.Charge_Amount2=null;
          this.lstdubaiVisaFeeApplicationStatus.Charge_Amount3=null;
          this.lstdubaiVisaFeeApplicationStatus.MarkupOnBasefare=null;
          this.lstdubaiVisaFeeApplicationStatus.Remark='';
          this.visaFeesCharge1Right=true;
          this.visaFeesCharge2Right=true;
          this.visaFeesCharge3Right=true;
         
         }
         $("#DubaiVisa-Charges").modal('hide');
         this._router.navigate([this.visaUserType+'/VisaApplications','Open']);
       },
       err=>{
        console.warn(err);
        $('#visaLoader').hide();

       });

    }
    catch(exception)
    {
      console.log(exception);
    }
  }

  getVisaServiceType()
  {
    this.dubaivisaservice.getVisaServiceType().subscribe((resp:any)=>{
        if(resp.status==true)
        {
        this.serviseTypes=resp.result;
        }
        else{
          console.warn("Service Type Not found");
        }
      
      },
      err=>{
        console.log(err);
      });

  }

 
  public VisaFeesCharge1Validator()
  {
   
     if(this.lstdubaiVisaFeeApplicationStatus.FK_Chargeid1!='0')
      {
        this.dubaiVisaFees.form.get('Charge_Amount1').setValidators([Validators.required]);
        this.dubaiVisaFees.form.get('Charge_Amount1').updateValueAndValidity();
        this.visaFeesCharge1Right=false;
      }
      else {
        this.dubaiVisaFees.form.get('Charge_Amount1').setValidators([Validators.nullValidator]);
        this.dubaiVisaFees.form.get('Charge_Amount1').updateValueAndValidity();
        this.lstdubaiVisaFeeApplicationStatus.Charge_Amount1=null;
        this.visaFeesCharge1Right=true;
      }
  }

  public VisaFeesCharge2Validator()
  {
   
     if(this.lstdubaiVisaFeeApplicationStatus.FK_Chargeid2!='0')
      {
        this.dubaiVisaFees.form.get('Charge_Amount2').setValidators([Validators.required]);
        this.dubaiVisaFees.form.get('Charge_Amount2').updateValueAndValidity();
        this.visaFeesCharge2Right=false;
      }
      else {
        this.dubaiVisaFees.form.get('Charge_Amount2').setValidators([Validators.nullValidator]);
        this.dubaiVisaFees.form.get('Charge_Amount2').updateValueAndValidity();
        this.lstdubaiVisaFeeApplicationStatus.Charge_Amount2=null;
        this.visaFeesCharge2Right=true;
      }
  }
  public VisaFeesCharge3Validator()
  {
   
     if(this.lstdubaiVisaFeeApplicationStatus.FK_Chargeid3!='0')
      {
        this.dubaiVisaFees.form.get('Charge_Amount3').setValidators([Validators.required]);
        this.dubaiVisaFees.form.get('Charge_Amount3').updateValueAndValidity();
        this.visaFeesCharge3Right=false;
      }
      else {
        this.dubaiVisaFees.form.get('Charge_Amount3').setValidators([Validators.nullValidator]);
        this.dubaiVisaFees.form.get('Charge_Amount3').updateValueAndValidity();
        this.lstdubaiVisaFeeApplicationStatus.Charge_Amount3=null;
        this.visaFeesCharge3Right=true;
      }
  }

  public insurenChecked($event)
  {
    let panvalue=$event.target.value;
    if(panvalue=="Without PAN")
    {
     this.visaObj.Insurance="With Insurance";
    }
    else{
      this.visaObj.Insurance="0";
    }
  }

  public FeespoupClosed()
  {
    this._router.navigate([this.visaUserType+'/VisaApplications','Open']);
  }
 

}
